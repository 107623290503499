import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
function checkResults(process, idx) {
  if (
    process.specs[idx].appearance === "check1" ||
    process.specs[idx].appearance === "check2"
  ) {
    if (
      process.specs[idx].obs_1_color === "#f7aeae" ||
      process.specs[idx].obs_2_color === "#f7aeae" ||
      process.specs[idx].obs_3_color === "#f7aeae" ||
      process.specs[idx].obs_4_color === "#f7aeae" ||
      process.specs[idx].obs_5_color === "#f7aeae"
    ) {
      process.specs[idx].results = "NOT OK";
    } else {
      process.specs[idx].results = "OK";
    }
  }
  if (process.specs[idx].appearance === "check3") {
    if (
      process.specs[idx].obs_1 === "not ok" ||
      process.specs[idx].obs_2 === "not ok" ||
      process.specs[idx].obs_3 === "not ok" ||
      process.specs[idx].obs_4 === "not ok" ||
      process.specs[idx].obs_5 === "not ok"
    ) {
      process.specs[idx].results = "NOT OK";
    } else {
      process.specs[idx].results = "OK";
    }
  }
}
const CreateSetupProcessBody = ({ process }) => {
  const [render, setRender] = useState(false);
  function handleChangeSetupBodyText({ target }, idx) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    process.specs[idx][target.name] = value;
    checkResults(process, idx);
    setRender((prev) => !prev);
  }
  function handleChangeSetupBody(
    { target },
    idx,
    mean,
    utolerance,
    ltolerance,
    old_utl,
    old_ltl
  ) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    console.log(value);
    process.specs[idx][target.name] = value;
    let utl;
    let ltl;
    let uv;
    let lv;
    if (old_utl || old_ltl) {
      utl = parseFloat(old_utl);
      ltl = parseFloat(old_ltl);
      const mean = (utl + ltl) / 2;
      uv = (mean + utl) / 2;
      lv = (mean + ltl) / 2;
    } else {
      utl = parseFloat(mean) + parseFloat(utolerance);
      ltl = parseFloat(mean) - parseFloat(ltolerance);

      uv = parseFloat(mean) + parseFloat(utolerance) / 2;
      lv = parseFloat(mean) - parseFloat(ltolerance) / 2;
    }

    if (value > utl || value < ltl) {
      const name = `${target.name}_color`;
      process.specs[idx][name] = "#f7aeae";
      setRender((prev) => !prev);
    } else if ((uv < value && value <= utl) || (lv > value && value >= ltl)) {
      const name = `${target.name}_color`;
      process.specs[idx][name] = "#fbfbac";
      setRender((prev) => !prev);
    } else if (value >= lv && value <= uv) {
      const name = `${target.name}_color`;
      process.specs[idx][name] = "#a4d1a4";
      setRender((prev) => !prev);
    }

    if (value === "") {
      const name = `${target.name}_color`;
      process.specs[idx][name] = "#ffffff";
    }

    checkResults(process, idx);
  }
  console.log(process);
  return (
    <section style={{ padding: "1rem" }}>
      {process?.specs.map((spec, idx) => (
        <Wrapper key={spec._id}>
          <Input
            name="parameter"
            label="Parameter"
            style={{ width: "230px" }}
            type="text"
            variant="filled"
            readOnly
            value={spec.parameter}
          />

          <Input
            name="specification"
            label="Specification"
            style={{ width: "230px" }}
            type="text"
            variant="filled"
            readOnly
            value={spec.specification}
          />
          {(spec.appearance === "check1" || spec.appearance === "check2") && (
            <Input
              name="obs_1"
              label="1"
              type="number"
              variant="filled"
              style={{ width: "130px" }}
              inputProps={{
                step: 0.0001,
                style: {
                  borderColor: spec.obs_1_color,
                  backgroundColor: spec.obs_1_color,
                },
              }}
              onChange={(e) =>
                handleChangeSetupBody(
                  e,
                  idx,
                  spec.mean,
                  spec.utolerance,
                  spec.ltolerance,
                  spec.utl,
                  spec.ltl
                )
              }
              defaultValue={spec.obs_1}
            />
          )}
          {spec.appearance === "check3" && (
            <FormControl
              variant="filled"
              className="select-field"
              style={{ marginTop: "1rem", margin: "0.6rem", width: "115px" }}
            >
              <InputLabel>1</InputLabel>
              <Select
                name="obs_1"
                defaultValue={spec.obs_1}
                onChange={(e) => handleChangeSetupBodyText(e, idx)}
              >
                <MenuItem>None</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="not ok">NOT OK</MenuItem>
              </Select>
            </FormControl>
          )}

          {/* second check */}
          {(spec.appearance === "check1" || spec.appearance === "check2") && (
            <Input
              name="obs_2"
              label="2"
              type="number"
              variant="filled"
              style={{ width: "130px" }}
              inputProps={{
                step: 0.0001,
                style: {
                  borderColor: spec.obs_2_color,
                  backgroundColor: spec.obs_2_color,
                },
              }}
              onChange={(e) =>
                handleChangeSetupBody(
                  e,
                  idx,
                  spec.mean,
                  spec.utolerance,
                  spec.ltolerance,
                  spec.utl,
                  spec.ltl
                )
              }
              defaultValue={spec.obs_2}
            />
          )}

          {spec.appearance === "check3" && (
            <FormControl
              variant="filled"
              className="select-field"
              style={{ marginTop: "1rem", margin: "0.6rem", width: "115px" }}
            >
              <InputLabel>1</InputLabel>
              <Select
                name="obs_2"
                defaultValue={spec.obs_2}
                onChange={(e) => handleChangeSetupBodyText(e, idx)}
              >
                <MenuItem>None</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="not ok">NOT OK</MenuItem>
              </Select>
            </FormControl>
          )}

          {/* Third Check */}
          {(spec.appearance === "check1" || spec.appearance === "check2") && (
            <Input
              name="obs_3"
              label="3"
              type="number"
              variant="filled"
              style={{ width: "130px" }}
              inputProps={{
                step: 0.0001,
                style: {
                  borderColor: spec.obs_3_color,
                  backgroundColor: spec.obs_3_color,
                },
              }}
              onChange={(e) =>
                handleChangeSetupBody(
                  e,
                  idx,
                  spec.mean,
                  spec.utolerance,
                  spec.ltolerance,
                  spec.utl,
                  spec.ltl
                )
              }
              defaultValue={spec.obs_3}
            />
          )}
          {spec.appearance === "check3" && (
            <FormControl
              variant="filled"
              className="select-field"
              style={{ marginTop: "1rem", margin: "0.6rem", width: "115px" }}
            >
              <InputLabel>1</InputLabel>
              <Select
                name="obs_3"
                defaultValue={spec.obs_3}
                onChange={(e) => handleChangeSetupBodyText(e, idx)}
              >
                <MenuItem>None</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="not ok">NOT OK</MenuItem>
              </Select>
            </FormControl>
          )}
          {/* Fourth Check */}
          {(spec.appearance === "check1" || spec.appearance === "check2") && (
            <Input
              name="obs_4"
              label="4"
              type="number"
              variant="filled"
              style={{ width: "130px" }}
              inputProps={{
                step: 0.0001,
                style: {
                  borderColor: spec.obs_4_color,
                  backgroundColor: spec.obs_4_color,
                },
              }}
              onChange={(e) =>
                handleChangeSetupBody(
                  e,
                  idx,
                  spec.mean,
                  spec.utolerance,
                  spec.ltolerance,
                  spec.utl,
                  spec.ltl
                )
              }
              defaultValue={spec.obs_4}
            />
          )}
          {spec.appearance === "check3" && (
            <FormControl
              variant="filled"
              className="select-field"
              style={{ marginTop: "1rem", margin: "0.6rem", width: "115px" }}
            >
              <InputLabel>1</InputLabel>
              <Select
                name="obs_4"
                defaultValue={spec.obs_4}
                onChange={(e) => handleChangeSetupBodyText(e, idx)}
              >
                <MenuItem>None</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="not ok">NOT OK</MenuItem>
              </Select>
            </FormControl>
          )}
          {/* Fifth Check */}
          {(spec.appearance === "check1" || spec.appearance === "check2") && (
            <Input
              name="obs_5"
              label="5"
              type="number"
              style={{ width: "130px" }}
              variant="filled"
              inputProps={{
                step: 0.0001,
                style: {
                  borderColor: spec.obs_5_color,
                  backgroundColor: spec.obs_5_color,
                },
              }}
              onChange={(e) =>
                handleChangeSetupBody(
                  e,
                  idx,
                  spec.mean,
                  spec.utolerance,
                  spec.ltolerance,
                  spec.utl,
                  spec.ltl
                )
              }
              defaultValue={spec.obs_5}
            />
          )}
          {spec.appearance === "check3" && (
            <FormControl
              variant="filled"
              className="select-field"
              style={{ marginTop: "1rem", margin: "0.6rem", width: "115px" }}
            >
              <InputLabel>1</InputLabel>
              <Select
                name="obs_5"
                defaultValue={spec.obs_5}
                onChange={(e) => handleChangeSetupBodyText(e, idx)}
              >
                <MenuItem>None</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="not ok">NOT OK</MenuItem>
              </Select>
            </FormControl>
          )}

          <p
            style={{
              backgroundColor: "#fafafa",
              padding: "1rem",
              width: "190px",
              margin: 0,
              marginLeft: "1.5rem",
            }}
          >
            Results = {spec.results}
          </p>
        </Wrapper>
      ))}
    </section>
  );
};

export default CreateSetupProcessBody;
