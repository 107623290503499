export const mergeFmeaData = (fmea) => {
  return fmea.processes.map((process) => {
    return {
      process_name: process?.process_name,
      occ_process: process?.fmeas?.map((fmea) => ({
        requirement: fmea.requirement,
        potential_mode: fmea.potential_mode,
        apr: { prod_qty: null, reject_qty: null },
        may: { prod_qty: null, reject_qty: null },
        jun: { prod_qty: null, reject_qty: null },
        jul: { prod_qty: null, reject_qty: null },
        aug: { prod_qty: null, reject_qty: null },
        sept: { prod_qty: null, reject_qty: null },
        oct: { prod_qty: null, reject_qty: null },
        nov: { prod_qty: null, reject_qty: null },
        dec: { prod_qty: null, reject_qty: null },
        jan: { prod_qty: null, reject_qty: null },
        feb: { prod_qty: null, reject_qty: null },
        mar: { prod_qty: null, reject_qty: null },
        total_prod_qty: null,
        total_reject_qty: null,
        avg_ppm: null,
        avg_ppm_by_thousand: null,
        occ_rank: null,
      })),
    };
  });
};
