import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

const PdirHeadForm = ({ pdirData, image, setImage, handleChangePdir }) => {
  return (
    <section>
      <Wrapper style={{ marginTop: "1rem" }}>
        <div>
          <Input
            label="Customer"
            name="customer"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.customer}
          />
        </div>
        <div>
          <Input
            label="Location"
            name="location"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.location}
          />
        </div>
        <div>
          <Input
            label="Lot No."
            name="lot_no"
            type="text"
            onChange={(e) => handleChangePdir(e)}
            defaultValue={pdirData.lot_no}
          />
        </div>
        <div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>Inspection Level</InputLabel>
            <Select
              name="inspection_level"
              defaultValue={pdirData.inspection_level}
              onChange={(e) => handleChangePdir(e)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="s1">S1</MenuItem>
              <MenuItem value="s2">S2</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Input
            label="Quantity"
            name="quantity"
            type="number"
            onChange={(e) => handleChangePdir(e)}
            defaultValue={pdirData.quantity}
          />
        </div>
        <div>
          <Input
            label="Invoice No."
            name="invoice_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.invoice_no}
          />
        </div>
        <div>
          <Input
            label="Invoice Date"
            name="invoice_date"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.invoice_date}
          />
        </div>
        <div>
          <Input
            label="Material"
            name="material"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.material}
          />
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <Input
            label="Description"
            name="desc"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.desc}
          />
        </div>
        <div>
          <Input
            label="Size"
            name="size"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.size}
          />
        </div>
        <div>
          <Input
            label="Finish"
            name="finish"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.finish}
          />
        </div>
        <div>
          <Input
            label="Sketch"
            name="sketch"
            type="file"
            onBlur={(e) => setImage(e.target.files[0])}
            defaultValue={image}
          />
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <Input
            label="Sample Taken"
            name="sample_taken"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.sample_taken}
          />
        </div>
        <div>
          <Input
            label="Sample Recorded"
            name="sample_recorded"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.sample_recorded}
          />
        </div>
        <div>
          <Input
            label="Destructive Sample Taken"
            name="des_sample_taken"
            type="text"
            style={{ width: "300px" }}
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.des_sample_taken}
          />
        </div>
        <div>
          <Input
            label="Destructive Sample Recorded"
            name="des_sample_recorded"
            type="text"
            style={{ width: "300px" }}
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.des_sample_recorded}
          />
        </div>
      </Wrapper>
      <TextField
        type="text"
        variant="filled"
        name="remarks"
        multiline
        className="textarea-field"
        label="Remarks"
        style={{
          width: "98.8%",
          margin: "1rem 0.7rem",
        }}
        onBlur={(e) => handleChangePdir(e)}
        defaultValue={pdirData.remarks}
      />
      <TextField
        type="text"
        variant="filled"
        name="notes"
        multiline
        className="textarea-field"
        label="Notes"
        style={{
          width: "98.8%",
          margin: "1rem 0.7rem",
        }}
        onBlur={(e) => handleChangePdir(e)}
        defaultValue={pdirData.notes}
      />
    </section>
  );
};

export default PdirHeadForm;
