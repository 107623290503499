import axios from "axios";
import { useState, useEffect } from "react";

const useGetData = (occMatrixId) => {
  const [moduleData, setModuleData] = useState({
    // created_year: new Date(created_year),
    processes: [],
  });
  const [occData, setOccData] = useState({
    processes: [],
  });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (occMatrixId) {
      axios
        .get(`/api/getOccMatrixModule/${occMatrixId}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
      axios
        .get(`/api/getOcc`)
        .then((res) => {
          setOccData(res.data);
        })
        .catch((err) => {
          console.error(err);
          // setError(err);
          // setIsLoading(false);
        });
    }
    // else {
    //   axios
    //     .get(`/api/get/process/fmea/${partId}`)
    //     .then((res) => {
    //       const response = res.data;
    //       setModuleData((item) => {
    //         item.created_year = created_year;
    //         item.part_id = partId;
    //         item.part_name = response.part_id?.part_name;
    //         item.part_number = response.part_id?.part_number;
    //         item.processes = response.processes.map((process) => {
    //           return {
    //             process_name: process?.process_name,
    //             occ_process: process?.fmeas?.map((fmea) => ({
    //               requirement: fmea.requirement,
    //               potential_mode: fmea.potential_mode,
    //               aug: { prod_qty: null, reject_qty: null },
    //               sept: { prod_qty: null, reject_qty: null },
    //               oct: { prod_qty: null, reject_qty: null },
    //               nov: { prod_qty: null, reject_qty: null },
    //               dec: { prod_qty: null, reject_qty: null },
    //               jan: { prod_qty: null, reject_qty: null },
    //               feb: { prod_qty: null, reject_qty: null },
    //               mar: { prod_qty: null, reject_qty: null },
    //               total_prod_qty: null,
    //               total_reject_qty: null,
    //               avg_ppm: null,
    //               avg_ppm_by_thousand: null,
    //               occ_rank: null,
    //             })),
    //           };
    //         });
    //         return { ...item };
    //       });
    //       console.log(moduleData);
    //     })
    //     .catch((err) => {
    //       setIsLoading(false);
    //       setError(err);
    //     });
    // }
  }, []);
  return {
    moduleData,
    occData,
    isLoading,
    error,
  };
};

export default useGetData;
