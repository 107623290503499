import React, { useState } from "react";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import "../../style/controlPlan.css";
import Loader from "../../components/Control/Loader";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import Typography from "@material-ui/core/Typography";
import Styled from "styled-components";
import { DatePicker } from "@material-ui/pickers";
import { useQuery } from "../../components/Control/useQuery";
import TableReadEditCell from "../../components/TableReadEditCell";
const Table = Styled.table`
  margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0
        }
        b{
		    font-family:"Roboto",sans-serif;
          margin:0,
        }
    }
`;

export default function ProcessProductAuditView({ Data }) {
  return <>{Data ? <SpanningTable controlData={Data} /> : <Loader />}</>;
}

function SpanningTable({ controlData }) {
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const [render, setRender] = useState(false);
  return (
    <>
      <div
        className="card_table"
        style={{ padding: "0.6rem", overflowX: "scroll" }}
      >
        {controlData && (
          <Table cellSpacing="0" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  colSpan="15"
                  // align="left"
                  rowSpan={4}
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images?.CompanyLogo}
                      alt={textInputs?.companyName}
                      height="46"
                      width="120"
                    />
                  </CenteredLogoImg>
                  <span>{textInputs?.companyName}</span>
                </th>
                <th
                  align="left"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Format No.: F SYS 30
                </th>
              </tr>
              <tr>
                <th
                  align="left"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Issue Date: 01/04/2019
                </th>
              </tr>
              <tr>
                <th
                  align="left"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Revision: 03
                </th>
              </tr>
              <tr>
                <th
                  align="left"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Rev. Date: 01/07/2022
                </th>
              </tr>
              <tr>
                <td
                  colSpan={17}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                  }}
                >
                  PROCESS/PRODUCT AUDIT
                </td>
              </tr>
              <tr>
                <td
                  colSpan={17}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Date:
                  {isView ? (
                    formatDate(controlData.date)
                  ) : (
                    <DatePicker
                      // label="pic Date"
                      name="date"
                      format="dd/MM/yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#f8f9fa",
                      }}
                      value={controlData?.date}
                      onChange={(date) => {
                        controlData.date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontWeight: "bold" }} height="50px">
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "50px",
                    minWidth: "50px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  OPERATION NO.
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PROCESS NAME/ OPERATION DESCRIPTION
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  MACHINE, DEVICE, JIG, TOOLS FOR Mfg.
                </td>
                <td
                  colSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  CHARACTERISTICS
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  SPECIAL CHARACTERISTICS
                </td>
                <td
                  colSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  METHODS
                </td>
                <td
                  align="center"
                  rowSpan="4"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  RECORDING YES / NO
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  RESPONSIBILITY
                </td>
                <td
                  align="center"
                  rowSpan="4"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  CONTROL METHOD (FORMAT NO & DESCRIPTION)
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  REACTION PLAN INCLUDING CORRECTIVE ACTION
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    minWidth: "250px",
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  OBSERVATION
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  REMARKS
                </td>
              </tr>
              <tr style={{ fontWeight: "bold" }} height="50px">
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  NO.
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PRODUCT
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PROCESS
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PRODUCT / PROCESS SPECIFICATION TOLERENCES
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  EVALUATION MEASUREMENT TECHNIQUE
                </td>

                <td
                  colSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  SAMPLE
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  OPERATOR /<br />
                  INSPECTOR / <br />
                  INCHARGE / <br />
                  SUPERVISOR / <br />
                </td>
              </tr>
              <tr style={{ fontWeight: "bold" }} height="50px">
                <td
                  align="center"
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                    width: "80px",
                    minWidth: "80px",
                  }}
                >
                  SIZE
                </td>
                <td
                  align="center"
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                    width: "80px",
                    minWidth: "80px",
                  }}
                >
                  FREQUENCY
                </td>
              </tr>
              <tr style={{ height: "40px" }}></tr>
              {controlData.processes.map((process, index) => (
                <React.Fragment key={index}>
                  {process.specs.map((spec, indx) => (
                    <React.Fragment key={indx}>
                      <tr>
                        {indx === 0 && (
                          <>
                            <td
                              rowSpan={process.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                            >
                              {process.process_no}
                            </td>
                            <td
                              rowSpan={process.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                            >
                              {process.process_name}
                            </td>
                          </>
                        )}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.machine}
                          {spec.machine && spec.tool_no ? "/" : ""}
                          {spec.tool_no}
                        </td>

                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {indx + 1}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.char_product}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.char_process}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.special_character}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.control_product}
                          {spec.control_process && spec.control_product
                            ? "/"
                            : ""}
                          {spec.control_process}
                          {/* <span style={{ display: "block", color: "red" }}> */}
                          {spec.control_product_remark &&
                            `* ${spec.control_product_remark}`}
                          {/* </span> */}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_measure}
                        </td>
                        {/* <td align="center" style={{border:'1px solid black'}}>{spec.error_proofing}</td> */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_size}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_by}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.recording}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.responsibility}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_process}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.reaction_plan}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          <TableReadEditCell
                            name="obs"
                            type="text"
                            style={{ width: "100%", height: "40px" }}
                            value={spec.obs}
                            isView={isView}
                            handleChange={(e) => {
                              spec.obs = e.target.value;
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          <TableReadEditCell
                            name="remarks"
                            type="text"
                            style={{ width: "200px", height: "40px" }}
                            value={spec.remarks}
                            isView={isView}
                            handleChange={(e) => {
                              spec.remarks = e.target.value;
                              setRender((prev) => !prev);
                            }}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
}
