import React from "react";
import Styled from "styled-components";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../../../components/Control/formatDate";
import { Wrapper } from "../../../../components/Control/Flexbox";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
        b{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
        strong{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
    }
    .icon td{
    	font-size:1.3rem;
    }
`;

const style = { border: "1px solid black", textAlign: "center" };

const PfdTableSheet = ({ data, partId, pfdId }) => {
  const history = useHistory();

  const getProcessFlow = (item) => {
    let value = null;
    if (item.symbol === 1) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          ◯
        </td>
      );
    } else if (item.symbol === 2) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          ▭
        </td>
      );
    } else if (item.symbol === 3) {
      value = (
        <td
          style={{ fontSize: "5rem", ...style }}
          data-f-sz="48"
          // data-a-v="top"
        >
          ⌼
        </td>
      );
    } else if (item.symbol === 4) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          △
        </td>
      );
    } else {
      value = <td style={style}></td>;
    }
    return value;
  };

  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <>
      {pfdId ? (
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            PROCESS FLOW DIAGRAM
          </Typography>
          <Wrapper>
            <Tooltip arrow title="Print table">
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    borderRadius: "0",
                    color: "white",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `PFD - ${data?.part_id?.part_number}.xlsx`,
                        sheet: {
                          name: "pfd_diagram",
                        },
                      },
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </Wrapper>
        </div>
      ) : (
        <Wrapper
          style={{
            position: "absolute",
            top: "0.2rem",
            right: "1rem",
            zIndex: "100000",
          }}
        >
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: `PFD - ${data?.part_id?.part_number}.xlsx`,
                      sheet: {
                        name: "pfd_diagram",
                      },
                    },
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      )}

      {/* for qucik view */}

      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-cols-width="10, 15, 20, 25, 25, 35"
          data-default-wrap="true"
        >
          <thead>
            <tr data-height="20">
              <th
                colSpan="4"
                rowSpan="4"
                align="center"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.CompanyLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>

              <th align="left" style={{ border: "1px solid black" }}>
                FORMAT NO:
              </th>
              <th align="left" style={{ border: "1px solid black" }}>
                SLF PFD 01
              </th>
            </tr>
            <tr>
              <th align="left" style={{ border: "1px solid black" }}>
                ISSUE DATE:
              </th>
              <th align="left" style={{ border: "1px solid black" }}>
                01/04/2019{" "}
              </th>
            </tr>
            <tr>
              <th align="left" style={{ border: "1px solid black" }}>
                REVISION NO:
              </th>
              <th align="left" style={{ border: "1px solid black" }}>
                03{" "}
              </th>
            </tr>
            <tr>
              <th align="left" style={{ border: "1px solid black" }}>
                REVISION DATE:
              </th>
              <th align="left" style={{ border: "1px solid black" }}>
                03/10/2023
              </th>
            </tr>

            <tr data-height="40">
              <th
                colSpan="6"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-fill-color="#1d1d1d"
              >
                PROCESS FLOW DIAGRAM
              </th>
            </tr>
            <tr data-height="30">
              <td
                colSpan="3"
                align="left"
                style={{ border: "1px solid black" }}
              >
                Item Description : {data?.part_id?.part_name}
              </td>
              <td
                colSpan="2"
                align="left"
                style={{ border: "1px solid black" }}
              >
                PFD No: {data?.pfd_no}
              </td>
              <td align="left" style={{ border: "1px solid black" }}>
                Date (Origin):
                {formatDate(data?.part_id?.date_orig)}
              </td>
            </tr>
            <tr data-height="30">
              <td
                colSpan="3"
                align="left"
                style={{ border: "1px solid black" }}
              >
                Item Code : {data?.part_id?.part_number}
              </td>
              <td
                colSpan="2"
                align="left"
                style={{ border: "1px solid black" }}
              ></td>
              <td align="left" style={{ border: "1px solid black" }}>
                Rev. Date : {formatDate(data?.date_review)}
              </td>
            </tr>

            <tr data-height="30">
              <td
                colSpan="3"
                align="left"
                style={{ border: "1px solid black" }}
              >
                Customer: {data?.part_id?.customer}
              </td>
              <td
                colSpan="2"
                align="left"
                style={{ border: "1px solid black" }}
              ></td>
              <td align="left" style={{ border: "1px solid black" }}>
                Rev. No.: {data?.revision_no}
              </td>
            </tr>
            <tr data-height="30">
              <td
                colSpan="6"
                align="left"
                style={{ border: "1px solid black" }}
              >
                Core Team : {data?.part_id?.core_team}
              </td>
            </tr>
            <tr data-height="30">
              <th
                colSpan={6}
                align="left"
                style={{ border: "1px solid black" }}
              >
                LEGENDS:
              </th>
            </tr>
            <tr data-height="100">
              <td
                colSpan={3}
                align="left"
                style={{ border: "1px solid black" }}
              >
                OPERATION WITH INSPECTION
              </td>
              <td
                style={{ fontSize: "5rem", ...style }}
                data-f-sz="60"
                data-a-v="top"
              >
                ⧇
              </td>
              <td align="left" style={{ border: "1px solid black" }}>
                STAGE INSPECTION
              </td>
              <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
                ▭
              </td>
            </tr>
            <tr data-height="100">
              <td
                colSpan={3}
                align="left"
                style={{ border: "1px solid black" }}
              >
                STORAGE
              </td>
              <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
                ▽
              </td>
              <td align="left" style={{ border: "1px solid black" }}>
                TRANSPORTATION
              </td>
              <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
                ↓
              </td>
            </tr>
            <tr data-height="100">
              <td
                align="left"
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                OPERATION
              </td>
              <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
                ◯
              </td>
              <td style={{ border: "1px solid black" }}></td>
              <td style={{ border: "1px solid black" }}></td>
            </tr>
            <tr data-height="60">
              <th style={style} align="center" rowSpan={2} data-f-sz="12">
                OPERATION NO.
              </th>
              <th style={style} align="center" rowSpan={2} data-f-sz="12">
                PROCESS DESCRIPTION
              </th>
              <th style={style} align="center" rowSpan={2} data-f-sz="12">
                PROCESS FLOW DIAGRAM
              </th>
              <th colSpan="2" style={style} align="center" data-f-sz="12">
                CHARACTERISTICS
              </th>
              <th style={style} rowSpan={2} align="center" data-f-sz="12">
                INCOMING SOURCE OF VARIATION
              </th>
            </tr>
            <tr data-height="30">
              <th style={style} align="center" data-f-sz="12">
                PRODUCT
              </th>
              <th style={style} align="center" data-f-sz="12">
                PROCESS
              </th>
            </tr>
          </thead>
          <tbody>
            {data.processes
              .filter((process) => process.isHide)
              .map((item) =>
                item.movement ? (
                  <>
                    <tr data-height="100">
                      <td style={style} data-f-sz="11">
                        {item?.process_no}
                      </td>
                      <td style={style} data-f-sz="11">
                        {item?.process_name}
                      </td>
                      {getProcessFlow(item)}
                      <td style={style} data-f-sz="11">
                        {item?.product}
                      </td>
                      <td style={style} data-f-sz="11">
                        {item?.process}
                      </td>
                      <td style={style} data-f-sz="11">
                        {item?.variation}
                      </td>
                    </tr>
                    <tr data-height="50" maxHeight="20px">
                      <td style={style}></td>
                      <td style={style}></td>
                      <td
                        style={{ fontSize: "3.5rem", ...style }}
                        data-f-sz="36"
                      >
                        ↓
                      </td>
                      <td style={style}></td>
                      <td style={style}></td>
                      <td style={style}></td>
                    </tr>
                  </>
                ) : (
                  <tr data-height="100">
                    <td style={style} data-f-sz="11">
                      {item?.process_no}
                    </td>
                    <td style={style} data-f-sz="11">
                      {item?.process_name}
                    </td>
                    {getProcessFlow(item)}
                    <td style={style} data-f-sz="11">
                      {item?.product_char}
                    </td>
                    <td style={style} data-f-sz="11">
                      {item?.process_char}
                    </td>
                    <td style={style} data-f-sz="11">
                      {item?.variation}
                    </td>
                  </tr>
                ),
              )}
          </tbody>
          {
            <tbody>
              <tr style={{ fontWeight: "bold" }} data-height="30" height="60px">
                <td
                  colSpan="1"
                  style={{ border: "1px solid black" }}
                  align="center"
                  data-f-sz="11"
                >
                  Revision No.
                </td>
                <td
                  colSpan="1"
                  style={{ border: "1px solid black" }}
                  align="center"
                  data-f-sz="11"
                >
                  Revision Date
                </td>
                <td
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  align="center"
                  data-f-sz="11"
                >
                  Revision Summary
                </td>
                <td
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  align="center"
                  data-f-sz="11"
                >
                  Reason
                </td>
              </tr>
              {data?.revisionData.map((item, index) => {
                // if (item.section === "control_plan" || !item.section) return;
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        colSpan="1"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.rev_no}
                      </td>
                      <td
                        colSpan="1"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {formatDate(item.rev_date)}
                      </td>
                      <td
                        colSpan="3"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.summary}
                      </td>
                      <td
                        colSpan="3"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.reason}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          }
          <tbody>
            <tr style={{ height: "60px" }} data-height="30">
              <td
                align="left"
                style={{ fontWeight: 500, border: "1px solid black" }}
                colSpan="3"
                data-f-sz="11"
              >
                Prepared By:
              </td>

              <td
                align="left"
                style={{ fontWeight: 500, border: "1px solid black" }}
                colSpan="3"
                data-f-sz="11"
              >
                Approved By:
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default PfdTableSheet;
