import React, { useEffect, useState } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import AnnexureAForm from "./AnnexureAForm";
import AnnexureBForm from "./AnnexureBForm";
import AnnexureCForm from "./AnnexureCForm";
import { toSuperScriptAnnexure } from "../../components/Control/toSuperScript";
import { DatePicker } from "@material-ui/pickers";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Delete from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
const AddEditAnnexure = ({
  annexureType,
  setAnnexureType,
  setIsAddEditModal,
  id,
  isView,
}) => {
  const [data, setData] = useState({
    revision_no: 0,
    revision_date: new Date(),
  });
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "PART",
    `Are you sure you want to ${id ? "update" : "save"} this part?`,
  );
  function handleChange(e) {
    let convertedValue = toSuperScriptAnnexure(e.target.value);
    setData((prev) => ({ ...prev, [e.target.name]: convertedValue }));
  }

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getAnnexureById/${id}`)
        .then((res) => {
          if (res.data) {
            setData(res.data);
            setAnnexureType(res.data.annexure_type);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setData({
        revision_date: new Date(),
        revision_no: 0,
      });
    }
  }, [id]);

  const handleImagePdf = (e) => {
    setFile(e.target.files[0]);
    e.target.value = "";
  };
  const removeFiles = () => {
    const values = { ...data };
    values.sop_img = null;
    setFile(null);
    setData(values);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!data.revision_date) {
      return alert("Please! also pick revision date ");
    }
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }
    const formData = new FormData();
    if (id) {
      for (let key in data) {
        if (data.hasOwnProperty(key) && data[key]) {
          formData.append(key, data[key]);
        }
      }
      if (file) {
        formData.append("sop_img", file);
      }
      await axios
        .put(`/api/update/annexure/${id}`, formData)
        .then((res) => {
          if (res.data) {
            setIsAddEditModal(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      data.annexure_type = annexureType;
      for (let key in data) {
        if (data.hasOwnProperty(key) && data[key]) {
          formData.append(key, data[key]);
        }
      }
      if (file) {
        formData.append("sop_img", file);
      }
      await axios
        .post("/api/create/annexure", formData)
        .then((res) => {
          if (res.data) {
            setIsAddEditModal(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const redirectToCp = async () => {
    history.push({
      pathname: `/edit/controlplan/${data.part_id}/${
        data.control_id?._id
      }?partNumber=${data.part_number}&partName=${""}`,
      state: data,
    });
  };
  return (
    <div className="root-admin">
      <div style={{ marginLeft: "2.2rem", marginTop: "1rem" }}></div>
      <form
        style={{
          margin: "1.8rem",
          marginTop: "0.2rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <Wrapper>
          <FormControl
            className="select-field"
            variant="filled"
            style={{ width: "310px" }}
          >
            <InputLabel>Select Annexure Type</InputLabel>
            <Select
              name="category"
              disabled={data.annexure_type}
              value={annexureType}
              key={annexureType}
              style={{ fontSize: "0.8rem" }}
              disableUnderline
              onChange={(e) => {
                setAnnexureType(e.target.value);
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="annexure-a">Annexure A</MenuItem>
              <MenuItem value="annexure-b">Annexure B</MenuItem>
              <MenuItem value="annexure-c">Annexure C</MenuItem>
            </Select>
          </FormControl>

          <Input
            type="text"
            name="m_c"
            placeholder="M/C"
            disabled={isView}
            required={true}
            onChange={(e) =>
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            }
            value={data.m_c}
          />
          <Input
            type="text"
            name="raw_material"
            placeholder="Raw Material"
            disabled={isView}
            required={true}
            onChange={(e) =>
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            }
            value={data.raw_material}
          />
          <Input
            type="text"
            name="standard"
            placeholder="standard"
            disabled={isView}
            required={true}
            onChange={(e) =>
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            }
            value={data.standard}
          />
          {isView || id ? (
            <a href={data.sop_img} target="blank">
              <Button color="primary" variant="filled">
                view sop image
              </Button>
            </a>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1.2rem",
              }}
            >
              <input
                id="contained-button-file"
                type="file"
                name="pcs_imageOrPdf"
                accept="image/png,image/jpeg,image/jpg"
                onChange={handleImagePdf}
                hidden
              />
              <label
                htmlFor="contained-button-file"
                style={{
                  marginBottom: "0",
                }}
              >
                <Button variant="outlined" component="span" color="secondary">
                  <CloudUpload style={{ marginRight: "1rem" }} />
                  Upload Picture
                </Button>
              </label>
              {(file || data.sop_img) && (
                <Button
                  variant="outlined"
                  type="button"
                  color="primary"
                  style={{
                    marginLeft: "0.6rem",
                  }}
                  onClick={removeFiles}
                  startIcon={<Delete />}
                >
                  REMOVE
                </Button>
              )}
            </div>
          )}
        </Wrapper>
        <Wrapper>
          <Input
            type="number"
            name="control_no"
            required
            placeholder="Control No."
            disabled={isView || id}
            onChange={(e) =>
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            }
            value={data.control_no}
          />
          <Input
            type="text"
            name="part_number"
            required
            placeholder="Item Code"
            disabled={isView || id}
            onChange={(e) =>
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            }
            value={data.part_number}
          />

          <Input
            type="number"
            required
            name="revision_no"
            placeholder="Rev No."
            disabled={isView || data.part_id || id}
            onChange={(e) =>
              setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            }
            value={data.control_id?.revision_no || data.revision_no}
          />

          <div>
            <DatePicker
              value={data.revision_date || null}
              label="Revision Date"
              required
              disabled={isView || id}
              className="date-field"
              inputVariant="filled"
              format="dd/MM/yyyy"
              clearable={true}
              onChange={(date) => {
                setData((prev) => ({ ...prev, revision_date: date }));
              }}
            />
          </div>
        </Wrapper>
        {/* Annexure Form */}
        {annexureType === "annexure-a" && (
          <AnnexureAForm
            {...{ id, handleChange, data, isView, annexureType }}
          />
        )}
        {annexureType === "annexure-b" && (
          <AnnexureBForm
            {...{ id, handleChange, data, isView, annexureType }}
          />
        )}
        {annexureType === "annexure-c" && (
          <AnnexureCForm
            {...{ id, handleChange, data, isView, annexureType }}
          />
        )}

        <div
          className="btn_save"
          style={{
            position: "absolute",
            right: "150px",
            top: "9px",
          }}
        >
          {!isView && (
            <>
              {id ? (
                <Button
                  variant="outlined"
                  color="white"
                  size="large"
                  style={{ color: "#e9e9e9" }}
                  startIcon={<PublishIcon />}
                  onClick={() => {
                    redirectToCp();
                  }}
                >
                  UPDATE
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="outlined"
                  color="white"
                  size="large"
                  style={{ color: "#e9e9e9" }}
                  startIcon={<PublishIcon />}
                >
                  SAVE
                </Button>
              )}
            </>
          )}
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default AddEditAnnexure;
