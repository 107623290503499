import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import { Wrapper } from "../../../components/Control/Flexbox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { usePermission } from "../../../components/usePermission";

const Table = Styled.table`
  width: 98%;
  margin: auto;
  margin: 1rem;
  th {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    border: 1px solid black;
    padding: 0.4rem;
    p {
      margin: 0;
    }
  }

  td {
    font-size: 12px;
    border: 1px solid black;
    padding: 0.3rem;
    p {
      margin: 0;
    }
  }
  span {
    font-weight: 500;
  }

  .input {
    outline: none;
    width: 100%;
    border: 2px solid #f9e0e0;
    height: 50px;
    text-align: center;
  }
  
  td {
    text-align: center;
  }
`;
const CreateViewDetectionZoneTable = () => {
  const [moduleData, setModuleData] = useState({
    processes: Array.from({ length: 10 }, (_) => {
      return {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        10: null,
      };
    }),
  });
  const [render, setRender] = useState(false);
  const [apiRender, setApiRender] = useState(false);
  const history = useHistory();
  const permissions = usePermission(5);
  const isView = permissions?.includes("edit") ? false : true;

  useEffect(() => {
    axios
      .get(`/api/getDetectionZone`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [apiRender]);

  const updateModule = async () => {
    let isUpdate = window.confirm(
      "Are you sure you want to update this document?"
    );
    if (isUpdate) {
      let res = await axios
        .put(`/api/updateDetectionZone/${moduleData?._id}`, moduleData)
        .catch((err) => console.log(err));
      if (res?.data?.status === "success") {
        alert("submitted successfully");
        setApiRender((prev) => !prev);
      }
    }
  };

  const submitModule = async () => {
    let isSubmit = window.confirm(
      "Are you sure you want to save this document?"
    );
    if (isSubmit) {
      let res = await axios
        .post("/api/createDetectionZone", moduleData)
        .catch((err) => console.log(err));
      if (res?.data?.status === "success") {
        alert("submitted successfully");
        setApiRender((prev) => !prev);
      }
    }
  };

  const handleChange = (e, process) => {
    const { name, value } = e.target;
    process[name] = value;
    setRender((prev) => !prev);
  };
  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          DETECTION ZONE
        </Typography>

        <Wrapper>
          {moduleData?._id
            ? permissions?.includes("edit") && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={updateModule}
                  style={{ backgroundColor: "#003566" }}
                  startIcon={<DoubleArrowIcon />}
                >
                  Update
                </Button>
              )
            : permissions?.includes("edit") && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitModule}
                  style={{ backgroundColor: "#003566" }}
                  startIcon={<SaveIcon />}
                >
                  Save & Continue
                </Button>
              )}
        </Wrapper>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section>
          <Table
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "90%",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan={12}
                  style={{ textAlign: "center", fontSize: "1.5rem" }}
                >
                  Severity
                </th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num, ind) => (
                  <th key={ind + "num"} style={{ textAlign: "center" }}>
                    {num}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {moduleData?.processes?.map((process, index) => (
                <tr key={index + "heading"} style={{ height: "62.5px" }}>
                  {index === 0 && (
                    <th
                      rowSpan={10}
                      style={{
                        transform: "rotate(-90deg)",
                        fontSize: "1.5rem",
                        textAlign: "center",
                      }}
                    >
                      Detection
                    </th>
                  )}
                  <th>{index + 1}</th>

                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num, ind) => (
                    <th key={ind + "data"}>
                      {isView ? (
                        process?.[num]
                      ) : (
                        <input
                          type="number"
                          name={num}
                          value={process?.[num]}
                          autoComplete="off"
                          className="input"
                          onChange={(e) => {
                            handleChange(e, process);
                          }}
                          onWheel={() => document.activeElement.blur()}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default CreateViewDetectionZoneTable;
