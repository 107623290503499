import { Button, Paper, Tab, Tabs } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory, link } from "react-router-dom";
import Modal from "../../components/Control/Modal";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import UpdateIcon from "@material-ui/icons/Update";
import Tooltip from "@material-ui/core/Tooltip";
import { useQuery } from "../../components/Control/useQuery";
import { Wrapper } from "../../components/Control/Flexbox";
import useConfirm from "../../components/Control/useConfirm";
import formatDate from "../../components/Control/formatDate";
import CreateEditInspectionReport from "./CreateEditInspectionReport";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import { useAuth } from "../../context/GlobalContext";
import AddEditNCLog from "./component/AddEditNCLog";
import TableToExcel from "@dayalk/table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";

const InspectionReportTabs = () => {
  const { partId, id } = useParams();
  const { state } = useAuth();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const isView = query.get("isView") === "true" ? true : false;
  const history = useHistory();
  const [data, setData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [render, setRender] = useState(false);
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );

  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE",
    "Are you sure you want to update?"
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    "Are you sure you want to save?"
  );

  const [pirReportData, setPirReportData] = useState({
    part_id: "",
    revision_no: "",
    revision_date: "",
    pirReport_date: new Date(),
    shift: "",
    pirs: [],
    times: [],
    start_time: [],
  });
  const [ncrLog, setNcrLogs] = useState(Array(12));
  const [processes, setProcesses] = useState([]);
  const [machineList, setMachineList] = useState([]);
  const [isAddEditModal, setIsAddEditModal] = useState(false);
  const saveOrSubmit = useRef(null);
  useEffect(() => {
    axios
      .get(`/api/getpirFromControlPlan/data/${partId}`)
      .then((res) => {
        if (res.data) {
          const controlPlanProcesses = res.data.processes.map(
            (process) => process.process_name
          );
          setProcesses(controlPlanProcesses);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/get/machine`)
      .then((res) => {
        const data = res.data.result.map((item) => item.machine_no);
        setMachineList(data);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(pirReportData);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOnePirData/${id}`)
        .then((res) => setPirReportData(res.data))
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`/api/getpirFromControlPlan/data/${partId}`)
        .then((res) => {
          const response = res.data;
          if (response) {
            let pirObj = {
              part_id: partId,
              partName: response.part_id.part_name,
              partNo: response.part_id.part_number,
              customer_id: response.part_id.customer_id,
              // for temp basic
              revision_no: response.revision_no,
              revision_date: response.revision_date,
              pirReport_date: new Date(),
              times: Array.from(Array(4)),
              start_time: Array.from(Array(4)),
              result: Array.from(Array(12)),
              pirs: [],
              times: [],
            };
            response.processes.map((process) => {
              process.specs.map((spec, spc_idx) => {
                pirObj.pirs.push({
                  parameter: spec.char_product || spec.char_process,
                  check4_symbol: spec.check4_symbol,
                  checkbox4_num: spec.checkbox4_num,
                  specification: spec.control_product,
                  methods: spec.methods_measure,
                  appearance: spec.appearance,
                  mean: spec.mean,
                  utolerance: spec.utolerance,
                  ltolerance: spec.ltolerance,
                  utl: spec.utl,
                  ltl: spec.ltl,
                  check_0_colors: Array.from(Array(14)),
                  check_0_values: Array.from(Array(14)),
                });
              });
            });
            setPirReportData(pirObj);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleIsAllCellFilled = () => {
    //0,1 -> 0, 2,3 -> 1, 4,5-> 2 , 7, 8 -> 3
    let count = Array(7).fill(0); // 0 or 8 true
    pirReportData?.pirs?.forEach((pir) =>
      pir?.check_0_values.forEach(
        (item, index) =>
          item !== null && item !== "" && item && count[Math.floor(index / 2)]++
      )
    );
    // * 2 cause 2 cell at at at time
    const pirLength = pirReportData.pirs.length * 2;
    const isValid = count.every((item) => item === 0 || item === pirLength);
    return isValid;
  };

  const handleSubmitAndSave = async (isSubmit) => {
    const isAllDataFilled = handleIsAllCellFilled();
    const params = [
      "customer",
      "mc_no",
      "po_no",
      "rm_mrm",
      "process_stage",
      "shift",
      "prd",
    ];

    for (const item of params) {
      if (
        pirReportData[item] === undefined ||
        pirReportData[item] === null ||
        pirReportData[item] === ""
      ) {
        alert("Please fill all the fields of the header");
        return;
      }
    }

    if (!isAllDataFilled) {
      alert("Please fill all the data of the current cell");
      return;
    }

    pirReportData.isSubmitted = isSubmit;
    pirReportData.username = state.user.username;
    pirReportData.is_admin_request = "pending";
    pirReportData.report_prepared_by = state.user.name;
    pirReportData.prepared_by_designation = state.user.designation;

    // creating ncr log
    const allNrcLogs = [];
    ncrLog.forEach((item) => {
      if (item) {
        allNrcLogs.push({
          ...item,
          date: pirReportData?.pirReport_date,
          stage: pirReportData?.process_stage,
          machine_no: pirReportData?.mc_no,
          production_order_no: pirReportData?.po_no,
          qa_sign: pirReportData?.report_prepared_by,
        });
      }
    });

    if (allNrcLogs.length > 0) {
      await axios
        .post(`/api/createNCrReport/data`, {
          pir_date: pirReportData.pirReport_date,
          ncrLog: allNrcLogs,
        })
        .then((res) => {})
        .catch((err) => console.log(err));
    }

    if (id) {
      axios
        .put(`/api/updatePirData/${pirReportData._id}`, pirReportData)
        .then((res) => {
          window.alert("successfully updated");
          const messageData = {
            messageType: "pir",
            part_name: `${partName}`,
            part_id: partId,
            message: `PIR of ${partName} submitted by ${
              state.user.name
            } on ${formatDate(new Date())}`,
            link: `/ViewPir/${partId}?partNumber=${partNumber}&partName=${partName}`,
          };
          {
            state.user.userType === 3 &&
              axios
                .post(`/api/add/message`, messageData)
                .then((res) => "")
                .catch((err) => console.log(err));
          }
          history.goBack();
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/createNewPir/data`, pirReportData)
        .then((res) => {
          window.alert("successfully created");
          const messageData = {
            messageType: "pir",
            part_name: `${partName}`,
            part_id: partId,
            message: `PIR of ${partName} submitted by ${
              state.user.name
            } on ${formatDate(new Date())}`,
            link: `/ViewPir/${partId}?partNumber=${partNumber}&partName=${partName}`,
          };
          {
            state.user.userType === 3 &&
              axios
                .post(`/api/add/message`, messageData)
                .then((res) => "")
                .catch((err) => console.log(err));
          }
          history.goBack();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <main>
      <div
        className="divflex"
        style={{ fontSize: "24px", justifyContent: "space-between" }}
      >
        PROCESS INSPECTION REPORT
        <Wrapper justify="space-evenly">
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  color: "white",
                  width: "fit-content",
                  borderRadius: "0",
                  paddingTop: "9px",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: `Inspection Report - ${
                        pirReportData?.partNo ||
                        pirReportData.part_id.part_number
                      }.xlsx`,
                      sheet: {
                        name: "Inspection Report",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
        {!isView && (
          <Wrapper>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                {state.user.userType === 3 && (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      backgroundColor: "#003566",
                      marginRight: "0.5rem",
                    }}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      saveOrSubmit.current = false;
                      if (!ncrLog.every((item) => !item)) {
                        setIsAddEditModal(true);
                        return;
                      }
                      handleSubmitAndSave(false);
                    }}
                  >
                    Save & Continue
                  </Button>
                )}

                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    saveOrSubmit.current = true;
                    if (!ncrLog.every((item) => !item)) {
                      setIsAddEditModal(true);
                      return;
                    }
                    handleSubmitAndSave(true);
                  }}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          </Wrapper>
        )}
      </div>
      <div>
        <DialogUpdate />
        <DialogSubmit isSubmitContent={true} />
      </div>
      <CreateEditInspectionReport
        {...{ processes, pirReportData, machineList, ncrLog, setNcrLogs }}
      />

      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
      <Modal
        openPopup={isAddEditModal}
        title="NC LOG"
        closeButtonName="save and continue"
        closeModal={() => {
          const ans = window.confirm(
            "⚠️ Are you sure you want to save and submit? You won't be able to fill in the data after NC submission."
          );
          if (!ans) return;
          handleSubmitAndSave(saveOrSubmit.current);
          setIsAddEditModal(false);
        }}
      >
        <AddEditNCLog {...{ ncrLog, pirReportData }} />
      </Modal>
    </main>
  );
};

export default InspectionReportTabs;
