import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
const style = {
  backgroundColor: "#fafafa",
  padding: "1rem",
  paddingBottom: "1.1rem",
  textAlign: "center",
  margin: 0,
};
const CreateRequestMan = ({ requestData, handleChangeRequest }) => {
  return (
    <Wrapper
      style={{
        marginLeft: "0.5rem",
        marginTop: "1rem",
        alignItems: "start",
        justifyContent: "center",
      }}
      column
    >
      <Wrapper>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          CHANGE ITEM
        </p>
        <p
          style={{
            ...style,
            marginLeft: "1.2rem",
            width: "595px",
          }}
        >
          CHANGE DETAILS
        </p>
        <p
          style={{
            ...style,
            width: "190px",
            marginLeft: "1.2rem",
          }}
        >
          CHANGE REQUIRED
        </p>
        <p
          style={{
            ...style,
            marginLeft: "1.2rem",
          }}
        >
          REASON OF CHANGE
        </p>
      </Wrapper>

      <Wrapper style={{ marginTop: "0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operator/Inspector on Leave with information
        </p>
        <Input
          type="text"
          name="man_change_1"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_1}
        />
        <Input
          type="text"
          name="man_reason_1"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_1}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operator/Inspector on Leave without information
        </p>
        <Input
          type="text"
          name="man_change_2"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_2}
        />
        <Input
          type="text"
          name="man_reason_2"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_2}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operator/Inspector leave middle of shift due to any work
        </p>
        <Input
          type="text"
          name="man_change_3"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_3}
        />
        <Input
          type="text"
          name="man_reason_3"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_3}
        />
      </Wrapper>

      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operator suddenly leave work place due to accident/illness
        </p>
        <Input
          type="text"
          name="man_change_4"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_4}
        />
        <Input
          type="text"
          name="man_reason_4"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_4}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operator/Inspector Works after more than 30 days leave
        </p>
        <Input
          type="text"
          name="man_change_5"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_5}
        />
        <Input
          type="text"
          name="man_reason_5"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_5}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          New inspector appointed at final stage
        </p>
        <Input
          type="text"
          name="man_change_6"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_6}
        />
        <Input
          type="text"
          name="man_reason_6"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_6}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operator/Inspector Works Continous after 12 hours
        </p>
        <Input
          type="text"
          name="man_change_7"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_7}
        />
        <Input
          type="text"
          name="man_reason_7"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_7}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          New inspector/operator appointed
        </p>
        <Input
          type="text"
          name="man_change_8"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_8}
        />
        <Input
          type="text"
          name="man_reason_8"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_8}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Inspector fail in poison test
        </p>
        <Input
          type="text"
          name="man_change_9"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_9}
        />
        <Input
          type="text"
          name="man_reason_9"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_9}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operatorr fail in observance
        </p>
        <Input
          type="text"
          name="man_change_10"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_10}
        />
        <Input
          type="text"
          name="man_reason_10"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_10}
        />
      </Wrapper>
      <Wrapper style={{ marginTop: "-0.5rem" }}>
        <p
          style={{
            ...style,
            width: "192px",
          }}
        >
          MAN
        </p>
        <p
          style={{
            ...style,
            width: "595px",
            marginLeft: "1.2rem",
          }}
        >
          Operatorr/ Inspector not working as per work instructions
        </p>
        <Input
          type="text"
          name="man_change_11"
          style={{ marginLeft: "0.8rem" }}
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_change_11}
        />
        <Input
          type="text"
          name="man_reason_11"
          onBlur={handleChangeRequest}
          defaultValue={requestData.man_reason_11}
        />
      </Wrapper>
    </Wrapper>
  );
};

export default CreateRequestMan;
