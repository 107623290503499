import axios from "axios";
import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import formatDate from "../../components/Control/formatDate";

const Table = Styled.table`
    margin-bottom: 1rem;
    width:98%;
    margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0
        }
        b{
		    font-family:"Roboto",sans-serif;
          margin:0,
        }
    }
`;
const RevisionLog = ({ revisionData }) => {
  return (
    <Table>
      <tbody>
        <tr style={{ fontWeight: "bold" }}>
          <td
            colSpan="2"
            style={{ border: "1px solid black" }}
            align="center"
            rowSpan="2"
          >
            Revision No.
          </td>
          <td
            colSpan="2"
            style={{ border: "1px solid black" }}
            align="center"
            rowSpan="2"
          >
            Revision Date
          </td>
          <td
            colSpan="8"
            style={{ border: "1px solid black" }}
            align="center"
            rowSpan="2"
          >
            Revision Summary
          </td>
          <td
            colSpan="4"
            style={{ border: "1px solid black" }}
            align="center"
            rowSpan="2"
          >
            Revision Reason
          </td>
        </tr>
        <tr style={{ height: "60px" }}></tr>
        {revisionData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <tr>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                >
                  {item.rev_no}
                </td>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                >
                  {formatDate(item.rev_date)}
                </td>
                <td
                  colSpan="8"
                  style={{ border: "1px solid black" }}
                  align="center"
                >
                  {item.summary}
                </td>
                <td
                  colSpan="4"
                  style={{ border: "1px solid black" }}
                  align="center"
                >
                  {item.reason}
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

export default RevisionLog;
