import { React, useEffect, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";

import { DivStyled } from "./container.style";
import { mergeFmeaData } from "./helper/occMatrixdata";
import axios from "axios";
import { useHistory } from "react-router-dom";

const OccMatrixTitleModal = ({ setIsModuleModal }) => {
  const [occMatrix, setOccMatrixData] = useState({
    created_year: new Date(),
    title: "",
  });
  const history = useHistory();

  useEffect(() => {
    axios.get("/api/masterFmeaFormat").then((res) => {
      if (res.data) {
        delete res.data._id;
        const values = { ...occMatrix };
        values.processes = mergeFmeaData(res.data);
        setOccMatrixData(values);
      }
    });
  }, []);
  const handleSumbit = async () => {
    if (!occMatrix.title) {
      alert(`please enter both required value ---> title and uid`);
    } else {
      await axios
        .post("/api/createOccMatrixModule", occMatrix)
        .then((res) => {
          if (res?.data?.status === "success") {
            history.push(`/occMatrixTable/${res?.data?._id}?isView=false`);
            setIsModuleModal(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section style={{ padding: "0.3rem 0rem 0.3rem 0rem" }}>
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          SUBMIT
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          name="title"
          defaultValue={occMatrix?.title}
          onBlur={(e) => {
            setOccMatrixData((prev) => ({ ...prev, title: e.target.value }));
          }}
        />
      </DivStyled>

      <DivStyled>
        <label>Select Year:</label>
        <DatePicker
          views={["year"]}
          className="date-field"
          InputProps={{
            disableUnderline: true,
          }}
          clearable
          style={{
            borderLeft: "2px solid #ced4da",
            backgroundColor: "#fff",
            paddingLeft: "0.5rem",
            width: "100%",
          }}
          value={occMatrix?.created_year || null}
          onChange={(date) => {
            setOccMatrixData((prev) => ({
              ...prev,
              created_year: date,
            }));
          }}
        />
      </DivStyled>
    </section>
  );
};

export default OccMatrixTitleModal;
