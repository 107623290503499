import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { useAuth } from "../../../context/GlobalContext";
import formatDate from "../../../components/Control/formatDate";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import Styled from "styled-components";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";

import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	margin-bottom: 1rem;
  width: 100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;

const ViewDailyProductionTable = () => {
  const [dailyProductionData, setDailyProductionData] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/getOneDailyProduction/${id}`)
      .then((res) => {
        setDailyProductionData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <section>
      <div style={{ paddingBottom: "4rem" }}>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            DAILY PRODUCTION REPORT
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip arrow title="Print table">
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: "Daily Production.xlsx",
                        sheet: {
                          name: "Daily Production",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            margin: "0.1rem 0.5rem",
            paddingBottom: "4rem",
            overflowX: "scroll",
          }}
        >
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 20, 8, 10, 10, 10, 10, 13, 10, 12, 12, 12"
          >
            <thead>
              <tr data-height="80" style={{ height: "80px" }}>
                <th
                  colSpan="12"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.CompanyLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan="4"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  {/* DOC. NO:- ARC/F/PRD/24 */}
                </th>
              </tr>

              <tr>
                <th
                  colSpan="12"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  DAILY PRODUCTION REPORT
                </th>

                <th
                  colSpan="4"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REV. NO.:- 00/01/07/2018
                </th>
              </tr>
              <tr data-height="25">
                <th
                  colSpan={8}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Date:- {formatDate(dailyProductionData?.dailyProduction_date)}
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  Shift
                </th>
                <th
                  colSpan={3}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {dailyProductionData?.shift}
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Department:-
                </th>
                <th
                  colSpan={2}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {dailyProductionData?.department}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "M/C NO.",
                  "OPERATOR NAME",
                  "PART NO.",
                  "PART NAME",
                  "OPERATION NO",
                  "LOT NO.",
                  "IN TIME",
                  "OUT TIME",
                  "WORKING HOUR",
                  "CYCLE TIME",
                  "PRODUCTION",
                  "REJECTION",
                  "Reason of Failure",
                  "REWORK",
                  "WORK EFF.",
                  "REMARK",
                ].map((heading, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                    data-f-sz="10"
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </tbody>
            {dailyProductionData &&
              dailyProductionData.processes.map((item, index) => (
                <tr key={item._id || index} height="50px" data-height="60">
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.machine_no}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.operator_name}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.part_id?.part_number}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.part_name}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.process_name}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.lot_no}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.in_time}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.out_time}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.working_hour}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.cycle_time}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.production}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.rejection}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.potential_mode},{item.potential_cause}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.rework}
                  </td>

                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.work_eff}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.remark}
                  </td>
                </tr>
              ))}
          </Table>
        </div>
      </div>
    </section>
  );
};

export default ViewDailyProductionTable;
