// b===> rej qty and  a =======> prod qty
export const calcPPM = (a, b) => {
  // console.log(a, b, objKey);
  if (a === undefined || b === undefined || a === null || b === null)
    return null;
  return (((+b || 0) / (+a || 1)) * 1000000).toFixed(4);
};

export const calcTotalProduction = (process) => {
  const monthArr = [
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
    "jan",
    "feb",
    "mar",
  ];
  let totalProd = 0;
  monthArr.forEach((monthKey) => {
    totalProd = totalProd + (+process[monthKey].prod_qty || 0);
  });

  return (process.total_prod_qty = totalProd);
};
export const calcTotalRejection = (process) => {
  const monthArr = [
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sept",
    "oct",
    "nov",
    "dec",
    "jan",
    "feb",
    "mar",
  ];
  let totalRejection = 0;
  monthArr.forEach((monthKey) => {
    totalRejection = totalRejection + (+process[monthKey].reject_qty || 0);
  });

  return (process.total_reject_qty = totalRejection);
};

export const calcAvgPPM = (process) => {
  if (
    process?.total_reject_qty === undefined ||
    process?.total_prod_qty === undefined ||
    process?.total_reject_qty === null ||
    process?.total_prod_qty === null
  )
    return null;
  process.avg_ppm =
    ((+process?.total_reject_qty || 0) / (+process?.total_prod_qty || 1)) *
    1000000;
  return (process.avg_ppm = +process.avg_ppm.toFixed(6));
};

export const calcAvgPPMByThousand = (process) => {
  process.avg_ppm_by_thousand = (+process?.avg_ppm || 0) / 1000;
  return (process.avg_ppm_by_thousand =
    +process.avg_ppm_by_thousand.toFixed(6));
};

export const getOccRank = (process, occData) => {
  // console.log(process, "ocmatirx");
  // console.log(occData, "--------->");
  // console.log(process?.avg_ppm_by_thousand);
  if (
    // process?.avg_ppm_by_thousand === undefined ||
    // process?.avg_ppm_by_thousand === null ||
    occData?.processes?.length < 1
  ) {
    return null;
  }

  for (const { criteria_1, criteria_2, occ_rank } of occData.processes) {
    if (
      process?.avg_ppm_by_thousand >= criteria_1 &&
      process?.avg_ppm_by_thousand <= criteria_2
    ) {
      return (process.occ_rank = occ_rank);
    }
  }

  // }
};
