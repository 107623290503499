import { Button, Checkbox } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import useConfirm from "../../components/Control/useConfirm";
import Styled from "styled-components";
import Publish from "@material-ui/icons/Publish";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Delete from "@material-ui/icons/Delete";

const Table = Styled.table`
    margin-bottom: 1rem;
    width:98%;
    margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0
        }
        b{
		    font-family:"Roboto",sans-serif;
          margin:0,
        }
    }
`;

const PublishAnnexure = ({ annexureType, setIsPublishModal, id }) => {
    const [data, setData] = useState([]);
    const [controlData, setControlData] = useState({
        processes: [],
    });
    const [pfdData, setPfdData] = useState({
        processes: [],
    });
    const [render, setRender] = useState(false);
    const [DialogUpdate, confirmUpdate] = useConfirm(
        "UPDATE STATUS",
        "Are you sure you want to update this?",
    );
    const [file, setFile] = useState(null);
    useEffect(() => {
        if (id) {
            axios
                .get(`/api/getAnnexureById/${id}`)
                .then((res) => {
                    if (res.data) {
                        res.data.isSubmittedNo = "2";
                        setData([res.data]);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [id]);
    useEffect(() => {
        if (id) {
            axios
                .get(`/api/getMasterControlPlan?annexureType=${annexureType}`)
                .then((res) => {
                    if (res.data) {
                        setControlData(res.data);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [id]);
    useEffect(() => {
        if (id) {
            axios
                .get(`/api/getMasterPfdPlan?annexureType=${annexureType}`)
                .then((res) => {
                    if (res.data) {
                        setPfdData(res.data);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [id]);
    const publishAnnexure = async () => {
        if (!file) {
            return alert("please choose image!");
        }
        let isConfirm = await confirmUpdate();
        if (isConfirm) {
            const formData = new FormData();
            formData.append("sop_img", file);
            await axios
                .put(`/api/update/annexure/${id}`, formData)
                .then((res) => {
                    if (res.data) {
                        axios
                            .post(`/api/publish/annexure`, {
                                parts: data,
                                controlData,
                                pfdData: pfdData,
                            })
                            .then((res) => {
                                if (res.data.status === "failed") {
                                    alert("unable to publish because part name not found");
                                }
                                if (res.data.status === "success") {
                                    alert("Status Change successfully");
                                    setFile(null);
                                    setIsPublishModal(false);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => console.log(err));
        }
    };
    const handleChange = (e, item, changeType) => {
        const data = {
            pfd: pfdData,
            controlplan: controlData,
        };
        item.isHide = e.target.checked;

        if (data[changeType].processes.length === 0)
            return setRender((prev) => !prev);
        data[changeType].processes.forEach((element) => {
            if (element.process_no === item.process_no) {
                console.log("true");
                element.isHide = e.target.checked;
            }
        });
        setRender((prev) => !prev);
    };
    const handleImagePdf = (e) => {
        setFile(e.target.files[0]);
        e.target.value = "";
    };
    const removeFiles = () => {
        const values = { ...data };
        values.sop_img = null;
        setFile(null);
        setData(values);
    };
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1.2rem",
                    marginBottom: "1.5rem",
                }}
            >
                <input
                    id="contained-button-file"
                    type="file"
                    name="pcs_imageOrPdf"
                    accept="image/png,image/jpeg,image/jpg"
                    onChange={handleImagePdf}
                    hidden
                />
                <label
                    htmlFor="contained-button-file"
                    style={{
                        marginBottom: "0",
                    }}
                >
                    <Button variant="outlined" component="span" color="secondary">
                        <CloudUpload style={{ marginRight: "1rem" }} />
                        Upload Picture
                    </Button>
                </label>
                {file && (
                    <Button
                        variant="outlined"
                        type="button"
                        color="primary"
                        style={{
                            marginLeft: "0.6rem",
                        }}
                        onClick={removeFiles}
                        startIcon={<Delete />}
                    >
                        REMOVE
                    </Button>
                )}
            </div>
            <Table>
                <tbody>
                    <tr style={{ fontWeight: "bold" }}>
                        <td style={{ border: "1px solid black" }} align="center">
                            #
                        </td>
                        <td style={{ border: "1px solid black" }} align="center">
                            Process Name.
                        </td>
                        <td style={{ border: "1px solid black" }} align="center">
                            Process Number
                        </td>
                        <td style={{ border: "1px solid black" }} align="center">
                            Action
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={4}>CONTROL PLAN</th>
                    </tr>
                    {controlData.processes.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td align="center" style={{ border: "1px solid black" }}>
                                        {index + 1}
                                    </td>
                                    <td style={{ border: "1px solid black" }} align="center">
                                        {item.process_name}
                                    </td>
                                    <td style={{ border: "1px solid black" }} align="center">
                                        {item.process_no}
                                    </td>
                                    <td style={{ border: "1px solid black" }} align="center">
                                        <Checkbox
                                            checked={item.isHide || ""}
                                            onClick={(e) => {
                                                handleChange(e, item, "pfd");
                                            }}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                    <tr>
                        <th colSpan={4}>PFD</th>
                    </tr>
                    {pfdData.processes.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td align="center" style={{ border: "1px solid black" }}>
                                        {index + 1}
                                    </td>
                                    <td style={{ border: "1px solid black" }} align="center">
                                        {item.process_name}
                                    </td>
                                    <td style={{ border: "1px solid black" }} align="center">
                                        {item.process_no}
                                    </td>
                                    <td style={{ border: "1px solid black" }} align="center">
                                        <Checkbox
                                            checked={item.isHide || ""}
                                            onClick={(e) => {
                                                handleChange(e, item, "controlplan");
                                            }}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
            <DialogUpdate />
            <div
                className="btn_save"
                style={{
                    position: "absolute",
                    right: "150px",
                    top: "9px",
                }}
            >
                <Button
                    type="submit"
                    variant="outlined"
                    color="white"
                    size="large"
                    style={{ color: "#e9e9e9" }}
                    startIcon={<Publish />}
                    onClick={() => publishAnnexure()}
                >
                    Publish
                </Button>
            </div>
        </div>
    );
};

export default PublishAnnexure;
