import axios from "axios";
import { useState, useEffect } from "react";

const useGetData = () => {
  const [moduleData, setModuleData] = useState({
    // created_year: new Date(created_year),
    processes: [],
    revisionData: [],
  });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/get/fmea`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return {
    moduleData,
    isLoading,
    error,
  };
};

export default useGetData;
