import React, { useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Lock, LockOpen } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import useDebounce from "../../components/Control/useDebounce";
import formatDate from "../../components/Control/formatDate";
import TextField from "@material-ui/core/TextField";
import ConfirmDialogueForInput from "../../components/Control/ConfirmDialogueForInput";
import useConfirm from "../../components/Control/useConfirm";
import { useAuth } from "../../context/GlobalContext";
import { usePermission } from "../../components/usePermission";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

function ViewChild({ partId }) {
  const classes = useStyles();
  const [partData, setPartData] = useState([]);
  const [render, setRender] = useState(true);
  const { state } = useAuth();
  const childId = useRef(null);
  // custom hook for dialogue
  const [DialogUnlock, confirmUnlock] = useConfirm(
    "UNLOCK PART",
    "Are you sure you want to unlock this part?"
  );
  const [DialogLock, confirmLock] = useConfirm(
    "LOCK PART",
    "Are you sure you want to lock this part?"
  );
  const permissions = usePermission(3);

  // confirm dialogue for delete and if there is input field
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [partNumber, setPartNumber] = useState("");

  const fetchData = () => {
    axios
      .get(`/api/getChildPart/${partId}`)
      .then((res) => {
        setPartData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useDebounce(fetchData, 0, [render]);

  const deletePart = async (childId) => {
    const res = await axios
      .delete(`/api/delete/part/${childId}?parentId=${partId}`)
      .catch((err) => console.log(err));

    if (res.data?.status === "success") {
      setRender((prev) => !prev);
    }
  };
  const lockPart = async (partName) => {
    const ans = await confirmLock();

    if (ans) {
      axios
        .put(`/api/update/lockstatus?partName=${partName}`, { isLocked: true })
        .catch((err) => console.log(err));
      setRender((prev) => !prev);
    }
  };
  const unlockPart = async (partName) => {
    const ans = await confirmUnlock();

    if (ans) {
      axios
        .put(`/api/update/lockstatus?partName=${partName}`, { isLocked: false })
        .catch((err) => console.log(err));
      setRender((prev) => !prev);
    }
  };

  const removeChild = (id, parentId, childId) => {
    if (window.confirm("Are you sure you want to remove this child?")) {
      axios
        .delete(`/api/remove/childFromPart/${id}/${parentId}/${childId}`)
        .then((res) => {
          if (res.data.status === "success") {
            alert("child remove successfully");
            setRender((prev) => !prev);
          }
        });
    }
  };
  return (
    <section style={{ margin: "1rem", marginBottom: "4rem" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                #
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Number
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Description
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Category
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Remove Child
              </TableCell>
            </TableRow>
          </TableHead>
          {partData && (
            <TableBody>
              {partData.map(
                (item, index) =>
                  item._id !== partId && (
                    <React.Fragment key={item._id}>
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {item.child_id.part_number}
                        </TableCell>
                        <TableCell align="center">
                          {item.child_id.part_name}
                        </TableCell>
                        <TableCell align="center">
                          {item.child_id.category}
                        </TableCell>
                        <TableCell align="center">
                          {formatDate(item.child_id.createdAt)}
                        </TableCell>
                        <TableCell align="center">
                          <>
                            {state?.user?.isFullAdmin &&
                              (item.isLocked ? (
                                <Tooltip title="Lock" arrow>
                                  <IconButton
                                    size="small"
                                    // onClick={() => unlockPart(item.part_name)}
                                    color="secondary"
                                  >
                                    <Lock style={{ fontSize: "1.4rem" }} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Unlock" arrow>
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    // onClick={() => lockPart(item.part_name)}
                                  >
                                    <LockOpen style={{ fontSize: "1.4rem" }} />
                                  </IconButton>
                                </Tooltip>
                              ))}

                            <Tooltip title="View" arrow>
                              <Link
                                to={`/view_process/${item.child_id._id}?partnumber=${item.child_id.part_number}&partname=${item.child_id.part_name}`}
                              >
                                <IconButton
                                  size="small"
                                  style={{ marginLeft: "0.5rem" }}
                                >
                                  <VisibilityIcon
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#003566",
                                    }}
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                size="small"
                                style={{ marginLeft: "0.5rem" }}
                                onClick={() => {
                                  childId.current = item.child_id._id;
                                  setPartNumber(item.child_id.part_number);
                                  setConfirmOpenForDelete(true);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "red",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </>
                        </TableCell>
                        <TableCell align="center">
                          {permissions?.includes("edit") && (
                            <Button
                              variant="outlined"
                              color="white"
                              style={{
                                color: "#fafafa",
                                backgroundColor: "#161A1D",
                              }}
                              onClick={() =>
                                removeChild(
                                  item._id,
                                  item.part_id,
                                  item.child_id._id
                                )
                              }
                              //   startIcon={<DeleteIcon style={{ fontSize: "1.4rem" }} />}
                            >
                              REMOVE CHILD
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <DialogUnlock isSubmitContent={false} />
      <DialogLock isSubmitContent={true} />
      <ConfirmDialogueForInput
        title="DELETE PART"
        open={confirmOpenForDelete}
        setOpen={setConfirmOpenForDelete}
        onConfirm={(childId) => deletePart(childId)}
        isSubmitContent={false}
        itemPartName={partNumber}
        itemId={childId.current}
        textFieldValue={textFieldValue}
        setTextFieldValue={setTextFieldValue}
      >
        <p style={{ margin: 0, fontSize: "1rem" }}>
          Are you sure you want to delete this part?
        </p>
        <p style={{ margin: 0, fontSize: "1rem" }}>
          Enter the <b>Item Number :</b> "{`${partNumber}`}" to confirm part
          deletion:
        </p>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="false"
          label="Item Number"
          type="text"
          fullWidth
          onChange={(e) => setTextFieldValue(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </ConfirmDialogueForInput>
    </section>
  );
}

export default ViewChild;
