import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

import FmeaProcess from "./FmeaProcess";
import { Add } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import lodash from "lodash";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import axios from "axios";
import useConfirm from "../../../components/Control/useConfirm";
import ECNModal from "../ECNModal";
import "./fmea.style.css";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";
import { useQuery } from "../../../components/Control/useQuery";

const CreatePfmea = () => {
  const { partId, id } = useParams();
  const [expandState, setExpandState] = useState([]);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [render, setRender] = useState(false);
  const [isECNModal, setIsECNModal] = useState(false);

  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE PART",
    "Revision no. already exists! Are you sure you want to overwrite?"
  );
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdateFmea, confirmUpdateFmea] = useConfirm(
    "UPDATE",
    "Are you sure you want to update?"
  );
  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    "REMOVE",
    "Are you sure you want to remove this process?"
  );
  const [DialogCopyProcess, confirmCopyProcess] = useConfirm(
    "COPY PROCESS",
    "Are you sure you want to copy processes from PFD?"
  );
  const [fmeaData, setFmeaData] = useState({
    processes: [],
  });
  const [prevFmeaData, setPrevFmeaData] = useState(null);

  useEffect(() => {
    axios
      .get(`/api/get/fmeaPart/${partId}/${id}`)
      .then((res) => {
        if (res.data) {
          setFmeaData(res.data);
          setPrevFmeaData(lodash.cloneDeep(res.data));
        }
      })
      .catch((err) => console.error(err));
  }, [useEffectRender]);

  const handleFmeaImport = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios
      .post("/api/import/fmeaData", formData)
      .then((res) => {
        const uploadData = res.data.partFmea.processes;
        uploadData.forEach(
          (item, index) => (item.s_no = fmeaData.processes.length + index + 1)
        );
        fmeaData.processes = [...fmeaData.processes, ...uploadData];
        e.target.value = "";
        setRender((prev) => !prev);
      })
      .catch((err) => {
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            "Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted."
          );
        }
      });
  };

  const handleCopyProcesses = async () => {
    let isCopy = await confirmCopyProcess();
    if (isCopy) {
      axios.get(`/api/get/pfdPart/${partId}/${id}`).then((res) => {
        const extractProcess = res.data.processes.map(
          ({ process_no, process_name }, index) => {
            return {
              s_no: fmeaData.processes.length + index + 1,
              process_name: `(${process_no}) ${process_name}`,
              fmeas: [],
            };
          }
        );
        fmeaData.processes = [...fmeaData.processes, ...extractProcess];
        setRender((prev) => !prev);
      });
    }
  };

  const handleFmeaSubmit = async () => {
    if (fmeaData._id) {
      let isUpdate = false;
      let isPost = false;
      const isRev = await axios
        .get(`/api/get/isrevno/fmea/${partId}/${fmeaData.revision_no}`)
        .catch((err) => console.log(err));
      if (isRev.data.status === "success") {
        if (id) {
          isUpdate = await confirmUpdate();
        } else {
          isUpdate = true;
        }
      }
      if (isRev.data.status === "fail") {
        isPost = true;
      }
      if (isUpdate) {
        let isSave = await confirmUpdateFmea();
        if (isSave) {
          axios
            .put(`/api/update/fmeaPart/${fmeaData._id}`, fmeaData)
            .then(() => {
              alert("FMEA changes saved successfully");
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
      if (isPost) {
        setIsECNModal(true);
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        fmeaData.part_id = partId;
        fmeaData.revision_no = 0;
        axios
          .post("/api/create/fmeaPart", fmeaData)
          .then(() => {
            alert("FMEA submitted successfully");
            setUseEffectRender((prev) => !prev);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const handleSubmitRevision = async (revisionData) => {
    if (lodash.isEmpty(revisionData)) {
      alert("you can't create revision");
      return;
    }
    delete fmeaData._id;
    delete fmeaData.createdAt;
    delete fmeaData.updatedAt;
    delete fmeaData.__v;
    fmeaData.processes.forEach((process) => {
      delete process._id;
      process.fmeas.forEach((fail) => delete fail._id);
    });
    revisionData = Object.keys(revisionData).map((key) => ({
      rev_no: fmeaData.revision_no,
      summary: revisionData[key].value,
      reason: revisionData[key].reason,
      section: revisionData[key].section,
    }));

    fmeaData.revisionData = [...fmeaData.revisionData, ...revisionData];
    await axios
      .put(`/api/setIsLatestRevsionFalse/fmea/${partId}`)
      .catch((error) => console.log(error));
    fmeaData.isLatestRevision = true;
    axios
      .post("/api/create/fmeaPart", fmeaData)
      .then(() => {
        alert("FmeaPart submitted successfully");
        history.push(`/revisedTable/fmea/${partId}`);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const removeProcess = async (index) => {
    const isDelete = await confirmRemoveProcess();
    if (isDelete) {
      const values = { ...fmeaData };
      values.processes.splice(index, 1);
      setFmeaData(values);
    }
  };

  const handleChangeProcess = (e, index) => {
    fmeaData.processes[index][e.target.name] = e.target.value;
    if (e.target.name === "s_no") {
      fmeaData.processes.sort((a, b) => Number(a.s_no) - Number(b.s_no));
      setRender((prev) => !prev);
    }
  };

  const applyToAllCategory = async (revisionData) => {
    if (lodash.isEmpty(revisionData)) {
      alert("you can't create revision");
      return;
    }
    if (
      window.confirm("Are you sure you want to apply changes to all parts?")
    ) {
      await axios
        .post(
          `/api/fmea/ecn/applyToAll/?categoryName=${fmeaData.part_id.category}`,
          revisionData
        )
        .then(() => {
          alert("PFMEA changes applied successfully");
          history.push(`/revisedTable/fmea/${partId}`);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          EDIT PFMEA
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <div style={{ marginTop: "9px", marginRight: "0.5rem" }}>
              {" "}
              <input
                id="contained-button-file"
                type="file"
                name="excelFile"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleFmeaImport}
                hidden
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  color="secondary"
                  size="large"
                  style={{ backgroundColor: "#fff" }}
                >
                  <CloudUploadIcon style={{ marginRight: "1rem" }} />
                  Upload FMEA
                </Button>
              </label>
            </div>
            {fmeaData._id ? (
              <Link
                to={`/fmea/diagram/${partId}/${fmeaData._id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "0.5rem",
                    color: "#054A29",
                  }}
                  startIcon={<VisibilityIcon />}
                >
                  View FMEA
                </Button>
              </Link>
            ) : (
              ""
            )}

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              // onClick={() => setOpenModal(true)}
              onClick={handleFmeaSubmit}
              style={{
                backgroundColor: "#003566",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
            >
              Save & Continue
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "red",
                marginRight: "0.5rem",
              }}
              startIcon={<CloseIcon />}
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            >
              Close
            </Button>
          </div>
        </div>
        <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
      </main>

      <main style={{ margin: "1rem", paddingBottom: "4rem" }}>
        <Wrapper
          style={{
            margin: "-2rem 1rem 2rem 1rem",
            // paddingBottom: "4rem",
          }}
        >
          <Input
            type="Number"
            name="revision_no"
            placeholder="Revision Number"
            onChange={(e) =>
              setFmeaData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            disabled={!id}
            value={fmeaData.revision_no}
          />
        </Wrapper>
        <div>
          <Wrapper
            justify="space-between"
            style={{ borderBottom: "1px solid #c80202" }}
          >
            <p className="card-btn-para" style={{ color: "#c80202" }}>
              • ADD{" "}
              <span style={{ fontWeight: 400, color: "black" }}>Process</span>
            </p>
            <Wrapper>
              <Wrapper
                style={{
                  height: "45px",
                  width: "175px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  gap: "0.5rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => handleCopyProcesses()}
                justify="center"
              >
                <FileCopyIcon style={{ fontSize: "1.4rem" }} />
                <span>Copy Process </span>
              </Wrapper>
              <Wrapper
                style={{
                  height: "45px",
                  width: "175px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  gap: "0.5rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  let values = { ...fmeaData };
                  values.processes.push({
                    s_no: fmeaData.processes.length + 1,
                    fmeas: [],
                  });
                  setFmeaData(values);
                  setExpandState((prev) => {
                    prev[fmeaData.processes.length - 1] = true;
                    return [...prev];
                  });
                }}
                justify="center"
              >
                <Add />
                <span>Add Process</span>
              </Wrapper>
            </Wrapper>
          </Wrapper>

          {fmeaData.processes.map((process, index) => (
            <FmeaProcess
              key={uuidv4()}
              {...{
                removeProcess,
                process,
                index,
                fmeaData,
                handleChangeProcess,
                expandState,
                setExpandState,
              }}
            />
          ))}
        </div>
        <DialogUpdate />
        <DialogSave isSubmitContent={true} />
        <DialogUpdateFmea isSubmitContent={true} />
        <DialogRemoveProcess />
        <DialogClose isSubmitContent={false} />
        <DialogCopyProcess />

        {isECNModal && (
          <ECNModal
            openPopup={isECNModal}
            closeModal={() => setIsECNModal(false)}
            partData={fmeaData}
            documentType="fmea"
            ecnTitle="ECN - PFMEA CHANGES"
            partDataForRevision={prevFmeaData}
            submit={handleSubmitRevision}
            {...{ applyToAllCategory }}
          />
        )}
      </main>
    </>
  );
};

export default CreatePfmea;
