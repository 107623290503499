import Input from "../../../components/Control/Input";
import React from "react";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { MultipleInput } from "../../../components";
import { Wrapper } from "../../../components/Control/Flexbox";
import fmeaModalStyle from "../PFMEA/fmeaModalStyle.module.css";

const Fmea = ({ fmeaModalData, handleFmeaModalData }) => {
  return (
    <div style={{ padding: "0.7rem" }}>
      <section className={fmeaModalStyle.main_container}>
        <div className={fmeaModalStyle.firstContainer}>
          <Input
            type="text"
            name="requirement"
            placeholder="Requirement"
            className="fmea-input"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.requirement}
          />
          <Input
            type="text"
            name="potential_mode"
            className="fmea-input"
            placeholder="Potential Failure Mode"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.potential_mode}
          />
          <Input
            type="number"
            name="severity"
            className="fmea-input"
            placeholder="Severity"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.severity}
          />
          <FormControl
            style={{
              marginTop: "0.6rem",
            }}
            variant="filled"
            className="select-field fmea-input"
          >
            <InputLabel>Class </InputLabel>
            <Select
              name="failure_class"
              defaultValue={fmeaModalData.failure_class}
              onChange={(e) => handleFmeaModalData(e)}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="*">* - Fit</MenuItem>
              <MenuItem value="⟐">⟐ - Function</MenuItem>
              <MenuItem value="△">△ - Critical Char.</MenuItem>
            </Select>
          </FormControl>
          <Input
            type="text"
            name="control_prevention"
            className="fmea-input"
            placeholder="Current Process Control Prevention"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.control_prevention}
          />
          <Input
            type="text"
            name="control_detection"
            className="fmea-input"
            placeholder="Current Process Control Detection"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.control_detection}
          />
          <Input
            type="text"
            name="recommendation_action"
            className="fmea-input"
            placeholder="Recommended Actions"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.recommendation_action}
          />
          <Input
            type="number"
            name="det"
            className="fmea-input"
            placeholder="Detection Rating (DET)"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.det}
          />
          <Input
            type="text"
            name="resp_target"
            className="fmea-input"
            placeholder="Resp. & Target completion date"
            onBlur={(e) => handleFmeaModalData(e)}
            defaultValue={fmeaModalData.resp_target}
          />
        </div>
        <div className={fmeaModalStyle.secondContainer}>
          <div>
            <MultipleInput
              data={fmeaModalData.potential_cause}
              title="Add Cause(s) of  Failures"
              listTitle="Click Dropdown to View PCF List"
            />
          </div>
          <div>
            <MultipleInput
              data={fmeaModalData.eof}
              title="Add Effect(s) of Failures"
              listTitle="Click Dropdown to View EoF List"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Fmea;
