import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Loader from "../../components/Control/Loader";
import formatDate from "../../components/Control/formatDate";
import { DatePicker } from "@material-ui/pickers";
import { useAuth } from "../../context/GlobalContext";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

function Inspectiontable() {
  const { partId } = useParams();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useAuth();
  const userType = state.user.userType;

  useEffect(() => {
    if (selectedDate) {
      setIsLoading(true);
      axios
        .get(
          `/api/get/monthly/incomingReport/${partId}/${selectedDate}/${userType}`
        )
        .then((res) => {
          setData(
            res.data.sort((a, b) =>
              a.incoming_date.localeCompare(b.incoming_date)
            )
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [render, selectedDate]);

  const DeletePir = (id) => {
    let isDeleted = window.confirm("Do you want to delete this Report?");
    if (isDeleted) {
      axios
        .delete(`/api/delete/incoming/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        INCOMING REPORT
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />

      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE <span className="subheading-secondary">REPORTS</span>
        </p>
      </div>
      <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate}
          onChange={setSelectedDate}
        />
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Username
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Date
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Status
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item.part_id.part_number}
                  </TableCell>
                  <TableCell align="center">{item.username}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.incoming_date)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      ...(item.isSubmitted
                        ? { backgroundColor: "green" }
                        : { backgroundColor: "red" }),
                      color: "white",
                    }}
                  >
                    {item.isSubmitted ? (
                      <span>SUBMITTED</span>
                    ) : (
                      <span>DRAFT</span>
                    )}
                  </TableCell>

                  {item.isSubmitted && state.user.userType === 3 ? (
                    <TableCell align="center">
                      <Tooltip title="INCOMING" arrow>
                        <Link to={`/view/incoming/excelTable/${item._id}`}>
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{ fontSize: "1.4rem", color: "maroon" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Block" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <BlockIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Block" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <BlockIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      <Tooltip title="INCOMING" arrow>
                        <Link to={`/view/incoming/excelTable/${item._id}`}>
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{ fontSize: "1.4rem", color: "maroon" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <Link
                          to={`/edit/incoming/${item._id}?partNumber=${partNumber}&partName=${partName}`}
                        >
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <EditIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => DeletePir(item._id)}
                        >
                          <DeleteIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <div
        style={{
          position: "absolute",
          top: "4.7rem",
          right: "1.8rem",
        }}
      >
        {userType === 3 && (
          <Link
            to={`/create/incoming/${partId}?partNumber=${partNumber}&partName=${partName}`}
            style={{ color: "white", textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginBottom: "2rem", backgroundColor: "#054a29" }}
              startIcon={<AddCircleIcon />}
            >
              New Report
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}

export default Inspectiontable;
