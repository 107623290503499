import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import TableScroll from "../../../components/TableScroll";
import formatDate from "../../../components/Control/formatDate";
import TableReadEditCell from "../../../components/TableReadEditCell";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import axios from "axios";
const Table = styled.table`
  width: 98%;
  margin: auto;
  margin: 1rem;
  th {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    border: 1px solid black;
    padding: 0.4rem;
    p {
      margin: 0;
    }
  }

  td {
    font-size: 12px;
    border: 1px solid black;
    padding: 0.3rem;
    p {
      margin: 0;
    }
  }
  span {
    font-weight: 500;
  }

  .input {
    outline: none;
    width: 100%;
    border: 2px solid #f9e0e0;
    width: 100px;
    height: 50px;
    text-align: center;
  }
  .text-center th,
  td {
    text-align: center;
  }
`;
const AddEditNCLog = ({ ncrLog, pirReportData }) => {
    const [render, setRender] = useState(false);
    const [defects, setDefects] = useState([]);

    useEffect(() => {
        axios
            .get(`/api/defectList/defectForAutoComplete`)
            .then((res) => setDefects(res.data))
            .catch((err) => console.log(err));
    }, []);

    return (
        <TableScroll>
            <Table>
                <thead>
                    <tr style={{ textTransform: "uppercase" }}>
                        {[
                            "DATE",
                            "TIME",
                            "STAGE",
                            "SUSPECTED QTY",
                            "UOM",
                            "NATURE OF NC",
                            "RED BIN ANALYSIS & ACTION PLAN",
                            "DISPOSITION TYPE",
                            "PRD SIGN",
                            "REMARKS",
                        ].map((head, index) => (
                            <th
                                style={{ textTransform: "uppercase", textAlign: "center" }}
                                rowSpan={index === 6 || index === 7 ? 1 : 2}
                                colSpan={
                                    (index === 6 && 3) ||
                                    (index === 7 && 4) ||
                                    (index === 9 && 2) ||
                                    1
                                }
                            >
                                {head}
                            </th>
                        ))}
                    </tr>
                    <tr style={{ textTransform: "uppercase" }}>
                        {[
                            "CONTAINMENT ACTION",
                            "ROOT CAUSE",
                            "Action Taken",
                            "OK QTY",
                            "SCRAP QTY",
                            "REWORKED QTY",
                            "ACCEPTED UD QTY",
                        ].map((head, index) => (
                            <th style={{ textTransform: "uppercase", textAlign: "center" }}>
                                {head}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {ncrLog &&
                        ncrLog?.length &&
                        ncrLog.map((item, index) => (
                            <tr key={index + "head"}>
                                <td style={{ minWidth: "120px" }}>
                                    {formatDate(pirReportData?.pirReport_date)}
                                </td>
                                <td style={{ minWidth: "120px" }}>
                                    {moment(item?.time).format("h:mm:ss a")}
                                </td>
                                <td style={{ minWidth: "200px" }}>
                                    {pirReportData?.process_stage}
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="suspected_qty"
                                        type="number"
                                        value={item?.suspected_qty}
                                        handleChange={(e) => {
                                            item.suspected_qty = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="uom"
                                        type="text"
                                        value={item?.uom}
                                        handleChange={(e) => {
                                            item.uom = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td style={{ minWidth: "300px" }}>
                                    <Autocomplete
                                        id="defect_name"
                                        options={defects}
                                        getOptionLabel={(option) => option.defect_name}
                                        value={item?.defect_id}
                                        onChange={(e, value) => {
                                            item.defect_id = value;
                                            setRender((prev) => !prev);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                }}
                                            />
                                        )}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="containment_action"
                                        type="text"
                                        value={item.containment_action}
                                        handleChange={(e) => {
                                            item.containment_action = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="root_cause"
                                        type="text"
                                        value={item.root_cause}
                                        handleChange={(e) => {
                                            item.root_cause = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="action_taken"
                                        type="text"
                                        value={item.action_taken}
                                        handleChange={(e) => {
                                            item.action_taken = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="ok_qty"
                                        type="number"
                                        value={item.ok_qty}
                                        handleChange={(e) => {
                                            item.ok_qty = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="scrap_qty"
                                        type="number"
                                        value={item.scrap_qty}
                                        handleChange={(e) => {
                                            item.scrap_qty = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="reworked_qty"
                                        type="number"
                                        value={item.reworked_qty}
                                        handleChange={(e) => {
                                            item.reworked_qty = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="accepted_qty"
                                        type="number"
                                        value={item.accepted_qty}
                                        handleChange={(e) => {
                                            item.accepted_qty = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="prd_sign"
                                        type="text"
                                        value={item.prd_sign}
                                        handleChange={(e) => {
                                            item.prd_sign = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                                <td colSpan={2}>
                                    <TableReadEditCell
                                        style={{ width: "120px" }}
                                        name="remarks"
                                        type="text"
                                        value={item.remarks}
                                        handleChange={(e) => {
                                            item.remarks = e.target.value;
                                            setRender((prev) => !prev);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </TableScroll>
    );
};

export default AddEditNCLog;
