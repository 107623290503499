import Styled from "styled-components";

export const CenteredLogoImg = Styled.div`
   & > img {
     position: absolute;
     left: 2rem;
     width:120px;
     height:46px;
   } 

  & > span {
    font-weight:bold !important;
  }
`;
