import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import ControlPlanPart from "../../AddEditDuplicatePart/ControlPLan/ControlPlanPart";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import lodash from "lodash";
import useConfirm from "../../../components/Control/useConfirm";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker } from "@material-ui/pickers";
import cpGrid from "../../AddEditDuplicatePart/ControlPLan/createCPGrid.module.css";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import MasterECNModal from "../MasterECNModal";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-input": {
      padding: theme.spacing(2),
    },
  },
}));

const CreateControlPlan = () => {
  const { annexureType, documentType, id } = useParams();
  const history = useHistory();
  const [render, setRender] = useState(false);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [controlPlanData, setControlPlanData] = useState({
    processes: [],
  });
  const [prevControlPlanData, setPrevControlPlanData] = useState(null);
  const [isECNModal, setIsECNModal] = useState(false);
  const [expandState, setExpandState] = useState([]);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE PART",
    "Revision no. already exists! Are you sure you want to overwrite?"
  );

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );

  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdateCpData, confirmUpdateCpData] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );

  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    "REMOVE",
    "Are you sure you want to remove this process?"
  );

  useEffect(() => {
    axios
      .get(`/api/getMasterById/${documentType}/${id}`)
      .then((res) => {
        if (res.data) {
          setControlPlanData(res.data);
          setPrevControlPlanData(lodash.cloneDeep(res.data));
        }
      })
      .catch((err) => console.log(err));
  }, [useEffectRender]);

  const handleControlPlanInputs = (e) => {
    controlPlanData[e.target.name] = e.target.value;
    setRender((prev) => !prev);
  };

  const handleControlPlanSubmit = async () => {
    if (controlPlanData._id) {
      let isUpdate = false;
      let isPost = false;
      const isRev = await axios
        .get(
          `/api/isMasterRevno/${documentType}/${annexureType}/${controlPlanData.revision_no}`
        )
        .catch((err) => console.log(err));
      if (isRev.data.status === "success") {
        if (id) {
          isUpdate = await confirmUpdate();
        } else {
          isUpdate = true;
        }
      }
      if (isRev.data.status === "fail") {
        isPost = true;
      }
      if (isUpdate) {
        let isSave = await confirmUpdateCpData();
        if (isSave) {
          axios
            .put(
              `/api/updateMasterFormat/${documentType}/${controlPlanData._id}`,
              controlPlanData
            )
            .then(() => {
              alert("Control Plan changes saved successfully");
            })
            .catch((err) => console.log(err));
        }
      }
      if (isPost) {
        setIsECNModal(true);
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        controlPlanData.revision_no = 0;
        axios
          .post(`/api/createMasterFormat/${documentType}`, controlPlanData)
          .then(() => {
            alert("Control Plan submitted successfully");
            setUseEffectRender((prev) => !prev);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleSubmitRevision = async (revisionData) => {
    if (lodash.isEmpty(revisionData)) {
      alert("you can't create revision");
      return;
    }
    delete controlPlanData._id;
    delete controlPlanData.createdAt;
    delete controlPlanData.updatedAt;
    delete controlPlanData.__v;
    controlPlanData.processes.forEach((process) => {
      delete process._id;
      process.specs.forEach((spec) => delete spec._id);
    });
    revisionData = Object.keys(revisionData)
      .map((key) => {
        if (!revisionData[key].tolerance_change) {
          return {
            rev_no: controlPlanData.revision_no,
            summary: revisionData[key].value,
            reason: revisionData[key].reason,
            section: revisionData[key].section,
          };
        } else {
          return;
        }
      })
      .filter((obj) => obj !== undefined);
    controlPlanData.revisionData = [
      ...controlPlanData.revisionData,
      ...revisionData,
    ];
    axios
      .post(`/api/createMasterFormat/${documentType}`, controlPlanData)
      .then(() => {
        alert("control plan submitted successfully");
        history.goBack();
      })
      .catch((err) => console.log(err));
  };

  const removeProcess = async (index) => {
    const isDelete = await confirmRemoveProcess();
    if (isDelete) {
      const values = { ...controlPlanData };
      values.processes.splice(index, 1);
      setControlPlanData(values);
    }
  };
  const handleChangeProcess = (e, index) => {
    controlPlanData.processes[index][e.target.name] = e.target.value;
    if (e.target.name === "s_no") {
      controlPlanData.processes.sort((a, b) => Number(a.s_no) - Number(b.s_no));
      setRender((prev) => !prev);
    }
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
            justifyContent: "space-between",
          }}
        >
          EDIT CONTROL PLAN
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleControlPlanSubmit}
              style={{
                backgroundColor: "#003566",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
            >
              Save & Continue
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "red",
                marginRight: "0.5rem",
              }}
              startIcon={<CloseIcon />}
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </main>
      <div style={{ margin: "1rem", paddingBottom: "4rem" }}>
        <div className={cpGrid.cpInputGrid}>
          <Input
            type="text"
            name="customer"
            placeholder="Customer"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.customer}
          />
          <Input
            type="Number"
            name="revision_no"
            placeholder="Later Revision"
            onChange={(e) => handleControlPlanInputs(e)}
            disabled={!id}
            value={controlPlanData.revision_no}
          />
          <Input
            type="text"
            name="supplier"
            placeholder="Supplier/Plant"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.supplier}
          />
          <Input
            type="text"
            name="supplier_code"
            placeholder="Supplier Code"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.supplier_code}
          />
          <Input
            type="text"
            name="key_contact"
            placeholder="Key Contact"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.key_contact}
          />
          <Input
            type="text"
            name="core_team"
            placeholder="Core Team"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.core_team}
          />

          <FormControl variant="filled" className="select-field">
            <InputLabel>Select Type</InputLabel>
            <Select
              name="part_type"
              value={controlPlanData.part_type}
              key={controlPlanData.part_type}
              onChange={(e) => handleControlPlanInputs(e)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="prototype">Prototype</MenuItem>
              <MenuItem value="pre-Launch">Pre-Launch</MenuItem>
              <MenuItem value="production">Production</MenuItem>
            </Select>
          </FormControl>

          <Input
            type="text"
            name="remarks"
            placeholder="Remarks"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.remarks}
          />
          <DatePicker
            inputVariant="filled"
            name="supplier_approval_date"
            style={{ paddingLeft: "0.6rem" }}
            label="Supplier Approval Date"
            value={controlPlanData.supplier_approval_date || null}
            onChange={(date) => {
              controlPlanData.supplier_approval_date = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
          <DatePicker
            inputVariant="filled"
            name="other_approval_date"
            style={{ paddingLeft: "0.6rem" }}
            label="Other Approval Date"
            value={controlPlanData.other_approval_date || null}
            onChange={(date) => {
              controlPlanData.other_approval_date = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
          <DatePicker
            inputVariant="filled"
            name="customer_other_approval_date"
            style={{ paddingLeft: "0.6rem" }}
            label="Other Approval Date Customer"
            value={controlPlanData.customer_other_approval_date || null}
            onChange={(date) => {
              controlPlanData.customer_other_approval_date = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
          <DatePicker
            inputVariant="filled"
            name="date_review"
            style={{ paddingLeft: "0.6rem" }}
            label="Revision Date"
            value={controlPlanData.date_review || null}
            onChange={(date) => {
              controlPlanData.date_review = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
        </div>
        <Wrapper
          style={{
            marginLeft: "0.76rem",
            marginTop: "1rem",
            gap: "1rem",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              setControlPlanData((prev) => ({
                ...prev,
                other_approval_date: null,
                customer_other_approval_date: null,
                date_review: null,
              }))
            }
            startIcon={<ClearAllIcon />}
          >
            RESET DATE
          </Button>
          <p style={{ fontSize: "0.8rem", margin: 0 }}>
            Click to clear/reset all dates
          </p>
        </Wrapper>

        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD{" "}
            <span style={{ fontWeight: 400, color: "black" }}>Process</span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "175px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
                display: "flex",
                gap: "0.5rem",
                marginRight: "0.2rem",
              }}
              onClick={() => {
                const values = { ...controlPlanData };
                values.processes.push({
                  s_no: controlPlanData.processes.length + 1,
                  process_no: "",
                  process_name: "",
                  specs: [],
                });
                setControlPlanData(values);

                setExpandState((prev) => {
                  prev[controlPlanData.processes.length - 1] = true;
                  return [...prev];
                });
              }}
              justify="center"
            >
              <Add />
              <span>Add Process</span>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        {controlPlanData.processes.map((process, index) => (
          <ControlPlanPart
            key={uuidv4()}
            {...{
              controlPlanData,
              setControlPlanData,
              removeProcess,
              process,
              index,
              handleChangeProcess,
              setExpandState,
              expandState,
            }}
          />
        ))}
      </div>
      <DialogUpdate />
      <DialogSave isSubmitContent={true} />
      <DialogUpdateCpData isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogRemoveProcess />
      {isECNModal && (
        <MasterECNModal
          openPopup={isECNModal}
          closeModal={() => setIsECNModal(false)}
          partData={controlPlanData}
          documentType="controlplan"
          ecnTitle="ECN - CONTROL PLAN CHANGES"
          partDataForRevision={prevControlPlanData}
          submit={handleSubmitRevision}
        />
      )}
    </>
  );
};

export default CreateControlPlan;
