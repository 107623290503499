import React, { useEffect, useState } from "react";
import { images, textInputs } from "../../constants/constants";

import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import Styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";
import { obtainedResultCell } from "./helpers";
import { DatePicker } from "@material-ui/pickers";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
const Table = Styled.table`
	
    width:100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }
    .heading{
        th{
            text-align:center;
        }
        td{
            text-align:center;
        }
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        padding-left: 4px;
        height: 40px;
    }

`;

const CreateEditViewBreakLoad = ({ isView, data, handleChange }) => {
  const [render, setRender] = useState(false);
  return (
    <main style={{ margin: "1rem", paddingBottom: "3rem", overflow: "scroll" }}>
      <Table
        id="table-to-xls"
        data-default-wrap="true"
        data-cols-width="10, 20, 18, 30, 10, 30 "
      >
        <tbody>
          <tr data-height="80">
            <th
              colSpan="7"
              align="left"
              rowSpan={4}
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
              }}
              data-a-v="middle"
            >
              <CenteredLogoImg>
                <img
                  src={images.CompanyLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th>FORMAT NO: F QAD 63</th>
          </tr>
          <tr>
            <th data-f-sz="10">ISSUE DATE: 08/03/2022</th>
          </tr>
          <tr>
            <th data-f-sz="10">REVISION NO: 00</th>
          </tr>
          <tr>
            <th data-f-sz="10">REVISION DATE: 00</th>
          </tr>
          <tr>
            <th
              colSpan={8}
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
                textAlign: "center",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              BREAKLOAD REGISTER
            </th>
          </tr>
          <tr>
            <td colSpan={4} data-f-sz="10">
              ITEM CODE: {data.item_code}
            </td>
            <td colSpan={4} data-f-sz="10">
              SIZE:
              <TableReadEditCell
                type="text"
                name="size"
                value={data.size}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={4} data-f-sz="10">
              MATERIAL: {data.material}
            </td>
            <td colSpan={4} data-f-sz="10">
              GRADE:{" "}
              <TableReadEditCell
                type="text"
                name="grade"
                value={data.grade}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} data-f-sz="10">
              GRPO NO.:{" "}
              <TableReadEditCell
                type="text"
                name="mrm_no"
                value={data.mrm_no}
                {...{ isView, handleChange }}
              />
            </td>

            <td data-f-sz="10" colSpan={2}>
              QTY:{" "}
              <TableReadEditCell
                type="Number"
                name="qty"
                value={data.qty}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={4} data-f-sz="10">
              E.P:{" "}
              <TableReadEditCell
                type="text"
                name="ep"
                value={data.ep}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={4} data-f-sz="10">
              IN DATE:{" "}
              <TableReadEditCell
                type="text"
                name="in_date"
                value={data.in_date}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={4} data-f-sz="10">
              TEST DATE:{" "}
              <TableReadEditCell
                type="text"
                name="test_date"
                value={data.test_date}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              LOT CODE:{" "}
              <TableReadEditCell
                type="text"
                name="lot_code"
                value={data.lot_code}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={3} data-f-sz="10">
              MATERIAL TYPE:{" "}
              <TableReadEditCell
                type="text"
                name="material_type"
                value={data.material_type}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={3}>
              INSPECTION DATE:{" "}
              {isView ? (
                formatDate(data.inspection_date)
              ) : (
                <DatePicker
                  name="inspection_date"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  value={data.inspection_date}
                  onChange={(date) => {
                    data.inspection_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
          </tr>

          <tr className="heading">
            {["S.NO.", "LOT CODE", "COIL ID", "SIZE", "T.S / BREAKLOAD"].map(
              (heading) => (
                <th>{heading}</th>
              )
            )}
            <th colSpan={3}>RESULTS</th>
          </tr>
          {data.breakloads.map((breakload, index) => (
            <tr className="heading">
              <td style={{ textAlign: "center" }}>
                {" "}
                <TableReadEditCell
                  type="Number"
                  name="s_no"
                  value={breakload.s_no}
                  {...{ isView, handleChange, index }}
                />
              </td>
              {isView ? (
                <td style={{ textAlign: "center" }} colSpan={2}>
                  <TableReadEditCell
                    type="text"
                    name="lot_code"
                    value={`${
                      breakload.lot_code ? breakload.lot_code : ""
                    } ${"   "}   ${
                      breakload?.coil_id ? breakload?.coil_id : ""
                    }`}
                    {...{ isView, handleChange, index }}
                  />
                </td>
              ) : (
                <>
                  <td style={{ textAlign: "center" }}>
                    <TableReadEditCell
                      type="text"
                      name="lot_code"
                      value={breakload.lot_code}
                      {...{ isView, handleChange, index }}
                    />
                  </td>
                  <td>
                    <TableReadEditCell
                      type="text"
                      name="coil_id"
                      value={breakload.coil_id}
                      {...{ isView, handleChange, index }}
                    />
                  </td>
                </>
              )}
              <td>
                <TableReadEditCell
                  type="number"
                  name="size"
                  value={breakload.size}
                  {...{ isView, handleChange, index }}
                />
              </td>
              <td>
                <TableReadEditCell
                  type="number"
                  name="ts_breakload"
                  value={breakload.ts_breakload}
                  {...{ isView, handleChange, index }}
                />
              </td>
              {obtainedResultCell(data, isView, index, handleChange)}
            </tr>
          ))}
          <tr>
            <th colSpan={4}>Prepared By:</th>
            <th colSpan={4}>Verified By:</th>
          </tr>
          <tr>
            <td colSpan={4}>
              Signature:
              <TableReadEditCell
                type="text"
                name="bl_prep_signature"
                value={data.bl_prep_signature}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={4}>
              Signature:
              <TableReadEditCell
                type="text"
                name="bl_verify_signature"
                value={data.bl_verify_signature}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              Name:{data.report_prepared_by}
              {/* <TableReadEditCell */}
              {/*   type="text" */}
              {/*   name="bl_prep_name" */}
              {/*   value={data.bl_prep_name} */}
              {/*   {...{ isView, handleChange }} */}
              {/* /> */}
            </td>
            <td colSpan={4}>
              Name:{data.report_approved_by}
              {/* <TableReadEditCell */}
              {/*   type="text" */}
              {/*   name="bl_verify_name" */}
              {/*   value={data.bl_verify_name} */}
              {/*   {...{ isView, handleChange }} */}
              {/* /> */}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              Designation:
              <TableReadEditCell
                type="text"
                name="bl_prep_designation"
                value={data.bl_prep_designation}
                {...{ isView, handleChange }}
              />
            </td>
            <td colSpan={4}>
              Designation:
              <TableReadEditCell
                type="text"
                name="bl_verify_designation"
                value={data.bl_verify_designation}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </main>
  );
};

export default CreateEditViewBreakLoad;
