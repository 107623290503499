import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import formatDate from "../../components/Control/formatDate";
import { DatePicker } from "@material-ui/pickers";
import useConfirm from "../../components/Control/useConfirm";
import { useAuth } from "../../context/GlobalContext";
import { useQuery } from "../../components/Control/useQuery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Wrapper } from "../../components/Control/Flexbox";
import Input from "../../components/Control/Input";
import { Search } from "@material-ui/icons";
import useDebounce from "../../components/Control/useDebounce";
import ApprovalSelect from "./ApprovalSelect";
import Update from "@material-ui/icons/Update";
import SupervisorAction from "./SupervisorAction";
import AdminAction from "./AdminAction";
import BlockIcon from "@material-ui/icons/Block";
import { usePermission } from "../../components/usePermission";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};
function IncomingBreakloadList() {
  const { rmId } = useParams();
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  const [search, setSearch] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE INCOMING/BREAKLOAD",
    "Are you sure you want to delete this INCOMING/BREAKLOAD?"
  );
  const history = useHistory();
  const query = useQuery();
  const itemDesc = query.get("item_desc");
  const itemCode = query.get("item_code");
  const permissions = usePermission(7);
  const fetchData = async () => {
    if (selectedDate) {
      const res = await axios
        .get(
          `/api/get/inspection_breakload/${rmId}/${state.user.userType}/${selectedDate}?search=${search}`
        )
        .catch((err) => {
          console.log(err);
        });
      setData(res.data);
    }
  };
  useDebounce(fetchData, 1000, [search, render, selectedDate]);
  const handleChangeStatus = (e, index) => {
    const values = [...data];
    values[index].is_admin_request = null;
    values[index].is_admin_request_no = e.target.value;
    setData(values);
  };

  const handleDelete = async (id) => {
    let isDeleted = await confirmDelete();
    if (isDeleted) {
      axios
        .delete(`/api/remove/inspection_breakload/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const updateStatus = async (id, index) => {
    let isConfirm = window.confirm("Are you sure you want to update status");
    if (!isConfirm) return;

    axios
      .patch(`/api/updateRmIcomingBreakloadById/${id}`, {
        is_admin_request_no: data[index].is_admin_request_no,
        report_approved_by: state.user?.name,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("Status Change successfully");
        }
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        <div>RM INCOMING / BREAKLOAD</div>
        <div>
          {permissions?.includes("edit") && state.user.userType === 3 && (
            <Link
              to={`/incomingbreakload/tab/${rmId}?isView=false`}
              style={{ color: "white", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  // marginBottom: "2rem",
                  backgroundColor: "#054a29",
                }}
                startIcon={<AddCircleIcon />}
              >
                New Incoming/BreakLoad
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div
        className="divflex"
        style={{
          // height: "4rem",
          border: "none",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <b>Item Code :</b> &nbsp; {itemCode}
        </div>
        <div>
          <b>Item Description :</b> &nbsp; {itemDesc}
        </div>
      </div>

      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE <span className="subheading-secondary">REPORTS</span>
        </p>
      </div>
      <Wrapper>
        <div style={{ marginTop: "0.8rem", marginLeft: "1rem", flex: 0.2 }}>
          <DatePicker
            views={["month", "year"]}
            label="Month & Year"
            className="date-field"
            inputVariant="filled"
            value={selectedDate}
            onChange={setSelectedDate}
          />
        </div>
        <div style={{ flex: "1" }}>
          <Input
            placeholder="Search Item Code , GRPO"
            variant="filled"
            style={{
              width: "100%",
              marginLeft: "1.2rem",
              marginTop: "1rem",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              ITEM CODE
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              ITEM DESCRIPTION
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              GRPO
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              INSPECTION DATE
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              STATUS
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              ACTION
            </TableCell>
            {state.user?.userType === 2 && (
              <TableCell style={{ fontWeight: "bold" }} align="center">
                UPDATE STATUS
              </TableCell>
            )}
            <TableCell style={{ fontWeight: "bold" }} align="center">
              UPLOAD
            </TableCell>
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.item_code}</TableCell>
                  <TableCell align="center">{item.item_desc}</TableCell>
                  <TableCell align="center">{item.mrm_no}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.inspection_date)}
                  </TableCell>
                  {state.user?.userType === 2 && (
                    <TableCell align="center">
                      <ApprovalSelect
                        {...{
                          handleChangeStatus,
                          item,
                          index,
                          statusObj,
                        }}
                      />
                    </TableCell>
                  )}
                  {state.user.userType !== 3 && (
                    <AdminAction {...{ item, handleDelete, rmId }} />
                  )}
                  {/* for supervisor */}
                  {state.user.userType === 3 && (
                    <SupervisorAction {...{ item, handleDelete, rmId }} />
                  )}

                  {state.user?.userType === 2 && (
                    <TableCell align="center">
                      {permissions?.includes("edit") ? (
                        <Button
                          variant="outlined"
                          color="white"
                          style={{
                            color: "#fafafa",
                            backgroundColor: "#161A1D",
                          }}
                          onClick={(e) => {
                            updateStatus(item._id, index);
                          }}
                          startIcon={<Update style={{ fontSize: "1.4rem" }} />}
                        >
                          Update
                        </Button>
                      ) : (
                        <BlockIcon style={{ color: "#c92a2a" }} />
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    {permissions?.includes("edit") ? (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() =>
                          history.push(
                            `/incomingbreakload/tab/${rmId}/${item._id}?isView=false&isModal=true`
                          )
                        }
                      >
                        Attach Material TC
                      </Button>
                    ) : (
                      <BlockIcon style={{ color: "#c92a2a" }} />
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <DialogDelete />
    </div>
  );
}

export default IncomingBreakloadList;
