import React, { useState } from "react";
function FmeaFailures({ process, topRpn, getTopRpn, setTopRpn }) {
  const [render, setRender] = useState(false);
  const handleChangeFailure = (e, failInd) => {
    const value = e.target.innerText;
    const name = e.target.dataset.name;
    process.failures[failInd][name] = value;
  };

  const handleInputValue = (e, failInd) => {
    const value = e.target.value;
    const name = e.target.name;
    process.failures[failInd][name] = value;
    if (name === "severity" || name === "det" || name === "occ") {
      process.failures[failInd].rpn =
        +process.failures[failInd].occ ||
        0 * +process.failures[failInd].det ||
        0 * +process.failures[failInd].severity ||
        0;
      const obtainedRpn = getTopRpn();
      setTopRpn(obtainedRpn);
    }
    setRender((prev) => !prev);
  };

  return (
    <>
      {process?.failures?.map((fail, failInd) => (
        <tr>
          {failInd === 0 && (
            <td
              align="center"
              style={{ border: "1px solid black" }}
              rowSpan={process.failures.length}
            >
              {process.process_name}
            </td>
          )}
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.requirement}
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.potential_mode}
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.eof}
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.severity}
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.failure_class}
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.potential_cause}
          </td>
          <td
            align="center"
            style={{ border: "1px solid black", backgroundColor: "#f9e0e0" }}
          >
            <input
              autoComplete="off"
              name="occ"
              className="input"
              type="number"
              defaultValue={fail.occ}
              onChange={(e) => handleInputValue(e, failInd)}
            />
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.control_prevention}
          </td>

          <td align="center" style={{ border: "1px solid black" }}>
            {fail.control_detection}
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {fail.det}
          </td>
          <td
            align="center"
            style={{
              border: "1px solid black",
              backgroundColor: topRpn.includes(fail.rpn) && "yellow",
            }}
          >
            {(+fail.severity || 0) * (+fail.occ || 0) * (+fail.det || 0)}
          </td>
          <td
            align="center"
            contenteditable="true"
            data-name="rec_action"
            onKeyUp={(e) => {
              handleChangeFailure(e, failInd);
            }}
            style={{ border: "1px solid black", backgroundColor: "#f9e0e0" }}
          >
            {fail.rec_action}
          </td>
          <td
            align="center"
            contenteditable="true"
            style={{ border: "1px solid black", backgroundColor: "#f9e0e0" }}
            data-name="resp_target"
            onKeyUp={(e) => {
              handleChangeFailure(e, failInd);
            }}
          >
            {fail.resp_target}
          </td>
          <td
            align="center"
            contenteditable="true"
            data-name="action_taken"
            onKeyUp={(e) => {
              handleChangeFailure(e, failInd);
            }}
            style={{ border: "1px solid black", backgroundColor: "#f9e0e0" }}
          >
            {fail.action_taken}
          </td>
          <td
            align="center"
            style={{ border: "1px solid black", backgroundColor: "#f9e0e0" }}
          >
            <input
              autoComplete="off"
              name="last_sev"
              className="input"
              type="number"
              defaultValue={fail.last_sev}
              onChange={(e) => handleInputValue(e, failInd)}
            />
          </td>
          <td
            align="center"
            style={{ border: "1px solid black", backgroundColor: "#f9e0e0" }}
          >
            <input
              autoComplete="off"
              name="last_occ"
              className="input"
              type="number"
              defaultValue={fail.last_occ}
              onChange={(e) => handleInputValue(e, failInd)}
            />
          </td>
          <td
            align="center"
            style={{ border: "1px solid black", backgroundColor: "#f9e0e0" }}
          >
            <input
              autoComplete="off"
              name="last_det"
              className="input"
              type="number"
              defaultValue={fail.last_det}
              onChange={(e) => handleInputValue(e, failInd)}
            />
          </td>
          <td align="center" style={{ border: "1px solid black" }}>
            {(+fail.last_sev || 0) *
              (+fail.last_occ || 0) *
              (+fail.last_det || 0)}
          </td>
        </tr>
      ))}
    </>
  );
}

export default FmeaFailures;
