import { DatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

const CreateRequestHeadForm = ({
  requestData,
  handleChangeRequest,
  setRequestData,
}) => {
  const [render, setRender] = useState(false);
  const handleChangeCheckbox = (e) => {
    requestData.is4M = e.target.name;
    setRender((prev) => !prev);
  };
  return (
    <>
      <Wrapper style={{ flexWrap: "wrap" }}>
        <Input
          type="text"
          name="req_deptt"
          placeholder="Requested Deptt"
          onBlur={handleChangeRequest}
          defaultValue={requestData.req_deptt}
        />
        <Input
          type="time"
          name="time"
          style={{ width: "190px" }}
          placeholder="Time"
          onBlur={handleChangeRequest}
          defaultValue={requestData.time}
        />

        <Input
          type="text"
          name="machine_name"
          placeholder="Machine Name"
          onBlur={handleChangeRequest}
          defaultValue={requestData.machine_name}
        />
        <Input
          type="text"
          name="part_name"
          placeholder="Item Code"
          disabled={true}
          onBlur={handleChangeRequest}
          defaultValue={requestData.part_name}
        />
        <Input
          type="text"
          name="change_req_by"
          placeholder="Change Requested By"
          onBlur={handleChangeRequest}
          defaultValue={requestData.change_req_by}
        />
        <DatePicker
          inputVariant="filled"
          name="req_date"
          style={{ paddingLeft: "0.6rem" }}
          label="Requested Date"
          value={requestData.req_date || null}
          onChange={(date) =>
            setRequestData((prev) => ({
              ...prev,
              req_date: date,
            }))
          }
          format="dd/MM/yyyy"
          className="date-field"
        />
      </Wrapper>
      <Wrapper>
        <input
          type="checkbox"
          className="spec-checkbox"
          name="man"
          style={{ marginTop: "0.4rem" }}
          onChange={handleChangeCheckbox}
          checked={requestData.is4M === "man"}
        />
        <Input
          type="text"
          name="desc_4m"
          placeholder="Man"
          disabled={requestData.is4M !== "man"}
          onBlur={handleChangeRequest}
          defaultValue={requestData.is4M === "man" ? requestData.desc_4m : null}
        />
        <input
          type="checkbox"
          className="spec-checkbox"
          name="machine"
          style={{ marginTop: "0.4rem" }}
          onChange={handleChangeCheckbox}
          checked={requestData.is4M === "machine"}
        />
        <Input
          type="text"
          name="desc_4m"
          placeholder="Machine"
          disabled={requestData.is4M !== "machine"}
          onBlur={handleChangeRequest}
          defaultValue={
            requestData.is4M === "machine" ? requestData.desc_4m : null
          }
        />
        <input
          type="checkbox"
          className="spec-checkbox"
          name="material"
          style={{ marginTop: "0.4rem" }}
          onChange={handleChangeCheckbox}
          checked={requestData.is4M === "material"}
        />
        <Input
          type="text"
          name="desc_4m"
          placeholder="Material"
          disabled={requestData.is4M !== "material"}
          onBlur={handleChangeRequest}
          defaultValue={
            requestData.is4M === "material" ? requestData.desc_4m : null
          }
        />
        <input
          type="checkbox"
          className="spec-checkbox"
          style={{ marginTop: "0.4rem" }}
          name="method"
          onChange={handleChangeCheckbox}
          checked={requestData.is4M === "method"}
        />
        <Input
          type="text"
          name="desc_4m"
          placeholder="Method"
          disabled={requestData.is4M !== "method"}
          onBlur={handleChangeRequest}
          defaultValue={
            requestData.is4M === "method" ? requestData.desc_4m : null
          }
        />
      </Wrapper>
    </>
  );
};

export default CreateRequestHeadForm;
