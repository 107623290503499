import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import { Link, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Add, Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../../components/Control/useDebounce";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import formatDate from "../../../components/Control/formatDate";
import { useAuth } from "../../../context/GlobalContext";
import Quick4M from "./Quick4M";
import TextField from "@material-ui/core/TextField";
import ConfirmDialogueForInput from "../../../components/Control/ConfirmDialogueForInput";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});
const View4MDatabse = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState("desc_4m");
  const [tabValue, setTabValue] = useState(0);
  const [render, setRender] = useState(false);
  const history = useHistory();
  const { state } = useAuth();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [itemId, setItemId] = useState("");
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [deleteItemName, setDeleteItemName] = useState("");
  const [type, setType] = useState(null);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/get/4m/database/${tabValue}?page=${page}&search=${search}&sortBy=${filter}&perPage=20`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.results);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };
  useDebounce(fetchData, 300, [page, search, filter, tabValue, render]);

  const deleteForm = (id) => {
    axios
      .delete(`/api/remove/request_form/${id}`)
      .then(() => {
        setRender((prev) => !prev);
        setItemId("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFileUpload = (event) => {
    const formData = new FormData();
    formData.append(
      "4mform",
      event.target.files[0],
      event.target.files[0].name
    );
    axios
      .post(`/api/upload/4mform/${itemId}/${type}`, formData)
      .then((res) => {
        if (res.status === 200) setRender((prev) => !prev);
      })
      .catch((err) => {
        window.alert("Wrong File Type");
      });
  };

  return (
    <main style={{ paddingBotton: "3rem" }}>
      <Input type="file" id="upload" onChange={onFileUpload} hidden />
      <ConfirmDialogueForInput
        title="DELETE PART"
        open={confirmOpenForDelete}
        setOpen={setConfirmOpenForDelete}
        onConfirm={(itemId) => deleteForm(itemId)}
        isSubmitContent={false}
        itemPartName={deleteItemName}
        itemId={itemId}
        textFieldValue={textFieldValue}
        setTextFieldValue={setTextFieldValue}
      >
        <p style={{ margin: 0, fontSize: "1rem" }}>
          Are you sure you want to delete this{" "}
          {`${["Man", "Machine", "Method", "Material"][tabValue]}`}?
        </p>
        <p style={{ margin: 0, fontSize: "1rem" }}>
          Enter the{" "}
          <b>
            "{`${["Man", "Machine", "Method", "Material"][tabValue]}`} Name"
          </b>{" "}
          to confirm deletion:
        </p>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="false"
          label="Item Number"
          type="text"
          fullWidth
          onChange={(e) => setTextFieldValue(e.target.value)}
        />
      </ConfirmDialogueForInput>
      <Quick4M
        target={""}
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
          setRender((prev) => !prev);
        }}
      />
      <div className="divflex" style={{ fontSize: "24px" }}>
        VIEW DATABASE
        <div>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setOpenModal(true)}
            style={{
              backgroundColor: "maroon",
              marginRight: "1rem",
            }}
            startIcon={<Add />}
          >
            Quick Add 4M
          </Button>
          {state.user.userType === 3 && (
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => history.push("/supervisor/viewparttable/4m")}
              style={{
                backgroundColor: "#054a29",
              }}
              startIcon={<Add />}
            >
              NEW Form
            </Button>
          )}
        </div>
      </div>
      <Paper elevation={2} style={{ margin: "1rem 1.4rem" }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="MAN" />
          <Tab label="MACHINE" />
          <Tab label="METHOD" />
          <Tab label="MATERIAL" />
        </Tabs>
      </Paper>

      <Wrapper>
        <div style={{ flex: "0.8" }}>
          <Input
            placeholder="Search 4M Database"
            variant="filled"
            style={{
              width: "96%",
              marginLeft: "1.2rem",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginRight: "1.5rem", marginTop: "0.2rem", flex: "0.2" }}
        >
          <InputLabel>Filter</InputLabel>
          <Select
            name="part_type"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={filter}
          >
            <MenuItem value="desc_4m">A - Z</MenuItem>
            <MenuItem value="createdAt">Most Recent First</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              {tabValue === 0 && "Man"} {tabValue === 1 && "Machine"}{" "}
              {tabValue === 2 && "Method"} {tabValue === 3 && "Material"}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Created At
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">
                    {perPage * (page - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.desc_4m}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {item.isTemporary === false ? (
                      <>
                        <Tooltip title="View Request Form" arrow>
                          <Link to={`/view4m/requestform/${item._id}`}>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                            >
                              <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="View Setup Form" arrow>
                          <Link to={`/view4m/setupform/${item._id}`}>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                            >
                              <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "maroon" }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {item.is_request_submitted ? (
                          <Tooltip title="View Request Form" arrow>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                              onClick={() => {
                                axios
                                  .get(
                                    `/api/view/old4MForm/${item._id}/request`
                                  )
                                  .then((response) => {
                                    window.open(
                                      "https://mushin-labs.s3.ap-south-1.amazonaws.com/" +
                                        response.data,
                                      "_blank"
                                    );
                                  });
                              }}
                            >
                              <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Upload Request Form" arrow>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                              onClick={() => {
                                document.getElementById("upload").click();
                                setItemId(item._id);
                                setType("request");
                              }}
                            >
                              <Add
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {item.is_setup_submitted ? (
                          <Tooltip title="View Setup Form" arrow>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                              onClick={() => {
                                axios
                                  .get(`/api/view/old4MForm/${item._id}/setup`)
                                  .then((response) => {
                                    window.open(
                                      "https://mushin-labs.s3.ap-south-1.amazonaws.com/" +
                                        response.data,
                                      "_blank"
                                    );
                                  });
                              }}
                            >
                              <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "maroon" }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Upload Setup Form" arrow>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.4rem" }}
                              onClick={() => {
                                document.getElementById("upload").click();
                                setItemId(item._id);
                                setType("setup");
                              }}
                            >
                              <Add
                                style={{ fontSize: "1.4rem", color: "maroon" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        style={{ marginLeft: "0.4rem" }}
                        onClick={() => {
                          setItemId(item._id);
                          setDeleteItemName(item.desc_4m);
                          setConfirmOpenForDelete(true);
                        }}
                      >
                        <DeleteIcon
                          style={{ fontSize: "1.4rem", color: "#c80202" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
    </main>
  );
};

export default View4MDatabse;
