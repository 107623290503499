import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { usePermission } from "../../components/usePermission";
import { Add } from "@material-ui/icons";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
import useConfirm from "../../components/Control/useConfirm";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "../../components/Control/useQuery";
import formatDate from "../../components/Control/formatDate";
import Visibility from "@material-ui/icons/Visibility";

const ProcessProductAuditList = () => {
  const [data, setData] = useState([]);
  const { partId } = useParams();
  const [render, setRender] = useState(false);
  const query = useQuery();
  const partNumber = query.get("partnumber");
  const partName = query.get("partname");
  const permissions = usePermission(3);
  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this document ?"
  );

  const fetchData = () => {
    axios
      .get(`/api/getProcessProductAudit/${partId}`)
      .then((res) => {
        if (res.data) {
          delete res.data._id;
          setData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(fetchData, 100, []);

  async function deleteDocument(id) {
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/deleteProcessProductAudit/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          PROCESS PRODUCT AUDIT LIST
          <div>
            {permissions?.includes("edit") && (
              <Link
                to={`/createProcessProductAudit/${partId}?partNumber=${partNumber}&partName=${partName}&isView=false`}
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    background: "#064a29",
                    color: "#fafafa",
                    marginRight: "1rem",
                  }}
                  startIcon={<Add />}
                >
                  NEW PROCESS PRODUCT AUDIT
                </Button>
              </Link>
            )}
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Audit Date
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }} align="center"> */}
                {/*   Mac Address */}
                {/* </TableCell> */}
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {formatDate(item.date)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "250px",
                            width: "220px",
                            alignItems: "center",
                          }}
                        >
                          {permissions?.includes("view") && (
                            <Link
                              to={`/createProcessProductAudit/${partId}/${item._id}?partNumber=${partNumber}&partName=${partName}&isView=true`}
                            >
                              <Tooltip title="View" arrow>
                                <IconButton
                                  size="small"
                                  style={{
                                    marginRight: ".5rem",
                                  }}
                                >
                                  <Visibility
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#003566",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}
                          {permissions?.includes("edit") && (
                            <Link
                              to={`/createProcessProductAudit/${partId}/${item._id}?partNumber=${partNumber}&partName=${partName}&isView=false`}
                            >
                              <Tooltip title="Edit" arrow>
                                <IconButton
                                  size="small"
                                  style={{
                                    marginRight: ".5rem",
                                  }}
                                >
                                  <EditIcon
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#003566",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}

                          {permissions?.includes("delete") && (
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.8rem",
                                }}
                                onClick={() => {
                                  deleteDocument(item._id);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#c80202",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </main>
      <DialogDelete />
    </>
  );
};

export default ProcessProductAuditList;
