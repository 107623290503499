import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import Observation from "./Observation";
function checkResults(pdir) {
  if (
    pdir.appearance === "check1" ||
    pdir.appearance === "check2" ||
    pdir.appearance === "check4"
  ) {
    let obsArr = [
      +pdir.obs_1 || 0,
      +pdir.obs_2 || 0,
      +pdir.obs_3 || 0,
      +pdir.obs_4 || 0,
      +pdir.obs_5 || 0,
      +pdir.obs_6 || 0,
      +pdir.obs_7 || 0,
      +pdir.obs_8 || 0,
      +pdir.obs_9 || 0,
      +pdir.obs_10 || 0,
      +pdir.obs_11 || 0,
      +pdir.obs_12 || 0,
      +pdir.obs_13 || 0,
      +pdir.obs_14 || 0,
      +pdir.obs_15 || 0,
    ];
    pdir.min = Math.min(...obsArr);
    pdir.max = Math.max(...obsArr);
    if (
      pdir.obs_1_color === "#f7aeae" ||
      pdir.obs_2_color === "#f7aeae" ||
      pdir.obs_3_color === "#f7aeae" ||
      pdir.obs_4_color === "#f7aeae" ||
      pdir.obs_5_color === "#f7aeae" ||
      pdir.obs_6_color === "#f7aeae" ||
      pdir.obs_7_color === "#f7aeae" ||
      pdir.obs_8_color === "#f7aeae" ||
      pdir.obs_9_color === "#f7aeae" ||
      pdir.obs_10_color === "#f7aeae" ||
      pdir.obs_11_color === "#f7aeae" ||
      pdir.obs_12_color === "#f7aeae" ||
      pdir.obs_13_color === "#f7aeae" ||
      pdir.obs_14_color === "#f7aeae" ||
      pdir.obs_15_color === "#f7aeae"
    ) {
      pdir.results = "NOT OK";
    } else {
      pdir.results = "OK";
    }
  }
  if (pdir.appearance === "check3") {
    pdir.min = 0;
    pdir.max = 0;
    if (
      pdir.obs_1 === "not ok" ||
      pdir.obs_2 === "not ok" ||
      pdir.obs_3 === "not ok" ||
      pdir.obs_4 === "not ok" ||
      pdir.obs_5 === "not ok" ||
      pdir.obs_6 === "not ok" ||
      pdir.obs_7 === "not ok" ||
      pdir.obs_8 === "not ok" ||
      pdir.obs_9 === "not ok" ||
      pdir.obs_10 === "not ok" ||
      pdir.obs_11 === "not ok" ||
      pdir.obs_12 === "not ok" ||
      pdir.obs_13 === "not ok" ||
      pdir.obs_14 === "not ok" ||
      pdir.obs_15 === "not ok"
    ) {
      pdir.results = "NOT OK";
    } else {
      pdir.results = "OK";
    }
  }
}
const PdirListForm = ({ index, pdirData, pdir }) => {
  const [render, setRender] = useState();

  function handleChangePdirText({ target }, index) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    pdirData.pdir_list[index][target.name] = value;
    checkResults(pdir);
    setRender((prev) => !prev);
  }

  function handleChangePdirList(
    { target },
    index,
    mean,
    utolerance,
    ltolerance,
    old_utl,
    old_ltl,
    check4_symbol,
    checkbox4_num
  ) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    pdirData.pdir_list[index][target.name] = value;
    let utl;
    let ltl;
    let uv;
    let lv;

    //max
    if (check4_symbol && checkbox4_num) {
      if (check4_symbol === "<=") {
        if (value <= checkbox4_num) {
          const name = `${target.name}_color`;
          pdirData.pdir_list[index][name] = "#a4d1a4";

          setRender((prev) => !prev);
        } else {
          const name = `${target.name}_color`;
          pdirData.pdir_list[index][name] = "#f7aeae";

          setRender((prev) => !prev);
        }
      }
      //min
      if (check4_symbol === ">=") {
        if (value >= checkbox4_num) {
          const name = `${target.name}_color`;
          pdirData.pdir_list[index][name] = "#a4d1a4";
          setRender((prev) => !prev);
        } else {
          const name = `${target.name}_color`;
          pdirData.pdir_list[index][name] = "#f7aeae";
          setRender((prev) => !prev);
        }
      }
    }
    if (old_utl || old_ltl) {
      utl = parseFloat(old_utl);
      ltl = parseFloat(old_ltl);
      const mean = (utl + ltl) / 2;
      uv = (mean + utl) / 2;
      lv = (mean + ltl) / 2;
    } else {
      utl = parseFloat(mean) + parseFloat(utolerance);
      ltl = parseFloat(mean) - parseFloat(ltolerance);

      uv = parseFloat(mean) + parseFloat(utolerance) / 2;
      lv = parseFloat(mean) - parseFloat(ltolerance) / 2;
    }

    if (value > utl || value < ltl) {
      const name = `${target.name}_color`;
      pdirData.pdir_list[index][name] = "#f7aeae";
      setRender((prev) => !prev);
    } else if ((uv < value && value <= utl) || (lv > value && value >= ltl)) {
      const name = `${target.name}_color`;
      pdirData.pdir_list[index][name] = "#a4d1a4";
      setRender((prev) => !prev);
    } else if (value >= lv && value <= uv) {
      const name = `${target.name}_color`;
      pdirData.pdir_list[index][name] = "#a4d1a4";
      setRender((prev) => !prev);
    }

    if (value === "") {
      const name = `${target.name}_color`;
      pdirData.pdir_list[index][name] = "#ffffff";
    }

    checkResults(pdir);
  }

  return (
    <section>
      <Observation
        {...{
          index,
          pdir,
          pdirData,
          handleChangePdirList,
          handleChangePdirText,
        }}
      />
      <Wrapper>
        <Input
          name="remarks"
          label="Remarks"
          type="text"
          style={{ width: "416px" }}
          variant="filled"
          onChange={(e) => handleChangePdirText(e, index)}
          defaultValue={pdir.remarks}
        />
        <p
          style={{
            backgroundColor: "#fafafa",
            padding: "1rem",
            width: "190px",
            margin: 0,
            marginLeft: "0.5rem",
          }}
        >
          Min = {pdir.min}
        </p>
        <p
          style={{
            backgroundColor: "#fafafa",
            padding: "1rem",
            width: "190px",
            margin: 0,
            marginLeft: "1rem",
          }}
        >
          Max = {pdir.max}
        </p>
        <p
          style={{
            backgroundColor: "#fafafa",
            padding: "1rem",
            width: "190px",
            margin: 0,
            marginLeft: "1.5rem",
          }}
        >
          Results = {pdir.results}
        </p>
      </Wrapper>
    </section>
  );
};

export default PdirListForm;
