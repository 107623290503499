import React, { useEffect, useState } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Input from "../../components/Control/Input";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
// import SaveIcon from "@material-ui/icons/Save";
// import ClearAllIcon from "@material-ui/icons/ClearAll";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const AddEditMachine = ({ setIsNewMachineModal, id }) => {
  const selectDepartment = [
    "None",
    "Cutting Section",
    "Hardening",
    "CNC Section",
    "VMC SECTION",
    "NEW BLOCK",
    "Cutting Section",
    "Grinding Section",
    "Drilling Section",
    "Hex punch",
    "Milling Section",
    "Thread Rolling Section",
    "Buffing Section",
    "Compressor room",
    "Generator room",
    "Final inspection",
  ];
  const [machineData, setMachineData] = useState({});

  const [autocompleteData, setAutocompleteData] = useState("");
  const [allDepartment, setAllDepartment] = useState([]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "MACHINE",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handleMachineData(e) {
    setMachineData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get_machineById/${id}`)
        .then((res) => {
          setMachineData(res.data);
          setAutocompleteData(res.data?.department);
        })
        .catch((err) => console.log(err));
    } else {
      setMachineData({
        date_orig: new Date(),
      });
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        setAllDepartment(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/update_machine/${id}`, machineData)
        .then((res) => {
          setIsNewMachineModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/machine", machineData)
        .then((res) => {
          setIsNewMachineModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="root-admin">
      <form
        style={{
          margin: "1.8rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <div>
          <div className="supplier-section">
            <section className="supplier-section-part">
              <Input
                type="text"
                name="machine_name"
                placeholder="Machine Name"
                required
                onChange={handleMachineData}
                value={machineData?.machine_name}
              />
              <Input
                type="text"
                name="machine_no"
                placeholder="Machine Id"
                onChange={handleMachineData}
                value={machineData.machine_no}
              />
              <Input
                type="number"
                name="strokes_per_minute"
                placeholder="Strokes Per Minute"
                onChange={handleMachineData}
                value={machineData.strokes_per_minute}
              />
              <Autocomplete
                style={{
                  marginTop: "17.5px",
                  marginLeft: "15px",
                  width: 300,
                }}
                id="training_topics"
                options={allDepartment}
                getOptionLabel={(option) => option.department_name}
                getOptionSelected={(option, value) =>
                  option.department_name === value?.department_name
                }
                value={autocompleteData}
                onChange={(e, value) => {
                  console.log(value);
                  machineData.department = value?._id;
                  setAutocompleteData(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Department"
                    // variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </section>

            <div
              className="btn_save"
              style={{
                position: "absolute",
                right: "150px",
                top: "9px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="white"
                size="large"
                style={{ color: "#e9e9e9" }}
                startIcon={<PublishIcon />}
              >
                {id ? "UPDATE" : "SUBMIT"}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default AddEditMachine;
