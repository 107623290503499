import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAuth } from "../../context/GlobalContext";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";
import { usePermission } from "../../components/usePermission";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const documentTitle = {
  pfd: "LIST OF PROCESS FLOW DIAGRAM",
  controlplan: "LIST OF CONTROL PLAN",
  fmea: "LIST OF FMEA",
};

const THead = (
  <TableHead>
    <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        #
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Item Number
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Type
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Revision number
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Action
      </TableCell>
    </TableRow>
  </TableHead>
);
function RevisedTable() {
  const { partId, revisedType } = useParams();
  const history = useHistory();
  const { state } = useAuth();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const permissions = usePermission(3);

  const classes = useStyles();
  const [data, setData] = useState([]);

  const [render, setRender] = useState(false);
  useEffect(() => {
    axios
      .get(`/api/get/revisedTable/${revisedType}/${partId}`)
      .then((data) => setData(data.data))
      .catch((err) => console.log(err.message));
  }, [render]);

  return (
    <div>
      <div style={{ paddingBottom: "3rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          {documentTitle[revisedType]}
          {state.user.userType === 2 && (
            <Wrapper style={{ gap: "1rem" }}>
              {!data.length && permissions?.includes("edit") && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#003566",
                  }}
                  onClick={() => history.push(`/edit/${revisedType}/${partId}`)}
                  startIcon={<Add />}
                >
                  Create {revisedType}
                </Button>
              )}
            </Wrapper>
          )}
        </div>
        <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
        <Table
          style={{ marginTop: "3rem" }}
          className={classes.table}
          size="small"
        >
          {THead}
          {data && (
            <TBody
              {...{
                data,
                setRender,
                revisedType,
                partId,
                partName,
                partNumber,
              }}
            />
          )}
        </Table>
      </div>
    </div>
  );
}

const filterPart = (parts, isFullAdmin) => {
  if (isFullAdmin) {
    return parts;
  } else {
    return parts.filter((part) => !part.isLocked);
  }
};

function TBody({ data, setRender, revisedType, partId, partName, partNumber }) {
  const classes = useStyles();
  const { state } = useAuth();
  const permissions = usePermission(3);
  function removeDocument(id) {
    if (window.confirm("Are you sure you want to delete this Part?")) {
      axios
        .delete(`/api/delete/${revisedType}/${partId}/${id}`)
        .then(() => {
          alert(`${revisedType} deleted successfully.`);
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }
  return (
    <TableBody>
      {data.map((item, index) => (
        <React.Fragment key={item._id}>
          <TableRow className={classes.rootRow}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{item.part_id.part_number}</TableCell>
            <TableCell align="center">
              {item.revision_no > 0 ? "Revised" : "Original"}
            </TableCell>
            <TableCell align="center">{item.revision_no}</TableCell>

            <TableCell align="center">
              {revisedType === "pfd" && state.user.userType === 2 && (
                <Tooltip title="PFD" arrow>
                  <Link to={`/pfd/diagram/${partId}/${item._id}`}>
                    <IconButton size="small">
                      <VisibilityIcon
                        style={{ fontSize: "1.4rem", color: "maroon" }}
                      />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {revisedType === "controlplan" && (
                <Tooltip title="Control Plan" arrow>
                  <Link to={`/control_plan/${partId}/${item._id}`}>
                    <IconButton size="small">
                      <VisibilityIcon
                        style={{ fontSize: "1.4rem", color: "#003566" }}
                      />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {revisedType === "fmea" && (
                <Tooltip title="FMEA" arrow>
                  <Link to={`/fmea/diagram/${partId}/${item._id}`}>
                    <IconButton size="small">
                      <VisibilityIcon
                        style={{ fontSize: "1.4rem", color: "#003566" }}
                      />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {permissions?.includes("edit") && (
                <Tooltip title="Edit" arrow>
                  <Link
                    to={`/edit/${revisedType}/${partId}/${item._id}?partNumber=${partNumber}&partName=${partName}`}
                  >
                    <IconButton
                      size="small"
                      style={{
                        marginLeft: "0.6rem",
                        color: "#161a1d",
                        fontSize: "1.4rem",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
              {/* {state.user.userType === 2 && item.revision_no > 0 ? ( */}
              {/*   <Tooltip title="Delete" arrow> */}
              {/*     <IconButton */}
              {/*       size="small" */}
              {/*       style={{ marginLeft: "0.5rem" }} */}
              {/*       onClick={() => removeDocument(item._id)} */}
              {/*     > */}
              {/*       <DeleteIcon */}
              {/*         style={{ */}
              {/*           fontSize: "1.4rem", */}
              {/**/}
              {/*           color: "red", */}
              {/*         }} */}
              {/*       /> */}
              {/*     </IconButton> */}
              {/*   </Tooltip> */}
              {/* ) : ( */}
              {/*   <Tooltip title="Block" arrow> */}
              {/*     <IconButton size="small" style={{ marginLeft: "0.5rem" }}> */}
              {/*       <BlockIcon */}
              {/*         style={{ */}
              {/*           fontSize: "1.4rem", */}
              {/**/}
              {/*           color: "red", */}
              {/*         }} */}
              {/*       /> */}
              {/*     </IconButton> */}
              {/*   </Tooltip> */}
              {/* )} */}
              <Tooltip title="Block" arrow>
                <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                  <BlockIcon
                    style={{
                      fontSize: "1.4rem",

                      color: "red",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  );
}

export default RevisedTable;
