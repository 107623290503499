import React from "react";
import Description from "@material-ui/icons/Description";
import AdjustIcon from "@material-ui/icons/Adjust";
import BorderInnerIcon from "@material-ui/icons/BorderInner";
import CameraRearIcon from "@material-ui/icons/CameraRear";
import SmsFailed from "@material-ui/icons/SmsFailed";
import GamesIcon from "@material-ui/icons/Games";
import { Link } from "react-router-dom";
import homePage from "../../components/Admin/HomePage.module.css";
const FmeaTiles = () => {
  return (
    <main
      style={{ height: "100vh" }}
      className={`${homePage.container} ${homePage.main}`}
    >
      {/* <div> */}
      {/*   <Link to={`/generateFmeaLists`} className="panel"> */}
      {/*     <VerticalAlignCenterIcon */}
      {/*       style={{ fontSize: "3rem", color: "maroon" }} */}
      {/*     /> */}
      {/*   </Link> */}
      {/*   <p className="card-btn-para"> */}
      {/*     Generate */}
      {/*     <span className="card-btn-span">FMEA</span> */}
      {/*   </p> */}
      {/* </div> */}

      <div>
        <Link to={`/masterFmeaFormatList`} className="panel">
          <SmsFailed style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          FMEA <span className="card-btn-span">Formats</span>
        </p>
      </div>
      <div>
        <Link to={`/occMatrixModuleList`} className="panel">
          <BorderInnerIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          Occurence Matrix <span className="card-btn-span">Module</span>
        </p>
      </div>
      <div>
        <Link to={`/tableFmeaOccurenceMatrix`} className="panel">
          <GamesIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          Occurence <span className="card-btn-span">Matrix</span>
        </p>
      </div>
      <div>
        <Link to={`/tableFmeaSeverityZone`} className="panel">
          <AdjustIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          Severity <span className="card-btn-span">Zone</span>
        </p>
      </div>
      <div>
        <Link to={`/tableFmeaDetectionZone`} className="panel">
          <CameraRearIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          Detection <span className="card-btn-span">Zone</span>
        </p>
      </div>
      <div>
        <Link to={`/tableFmeaPriorityLevel`} className="panel">
          <Description style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          Priority <span className="card-btn-span">Level</span>
        </p>
      </div>
    </main>
  );
};

export default FmeaTiles;
