import React from "react";
import { Wrapper } from "../../components/Control/Flexbox";
import Input from "../../components/Control/Input";
import { DatePicker } from "@material-ui/pickers";

const IncomingHeadForm = ({ incomingData, incomingDate, setincomingDate }) => {
  const handleChangePdir = ({ target }) => {
    incomingData[target.name] = target.value;
  };
  return (
    <section>
      <Wrapper style={{ marginTop: "1rem" }}>
        <div>
          <Input
            label="Invoice No"
            name="incoming_invoice_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={incomingData.incoming_invoice_no}
          />
        </div>

        <div>
          <Input
            label="MRIR No"
            name="incoming_mrir_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={incomingData.incoming_mrir_no}
          />
        </div>
        <div>
          <Input
            label="Lot No."
            name="incoming_lot_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={incomingData.incoming_lot_no}
          />
        </div>

        <div>
          <Input
            label="Sheet No"
            name="incoming_sheet_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={incomingData.incoming_sheet_no}
          />
        </div>
      </Wrapper>

      <Wrapper style={{ marginTop: "1rem" }}>
        <div style={{ marginLeft: "8px" }}>
          <DatePicker
            label="Invoice Date"
            name="incoming_invoice_date"
            inputVariant="filled"
            value={
              incomingDate.incoming_invoice_date ||
              incomingData.incoming_invoice_date ||
              null
            }
            onChange={(date) => {
              incomingData.incoming_invoice_date = date;
              setincomingDate((prev) => ({
                ...prev,
                incoming_invoice_date: date,
              }));
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
        </div>
        <div style={{ marginLeft: "1%" }}>
          <DatePicker
            inputVariant="filled"
            name="incoming_mrir_date"
            label="MRIR Date"
            value={
              incomingDate.incoming_mrir_date ||
              incomingData.incoming_mrir_date ||
              null
            }
            onChange={(date) => {
              incomingData.incoming_mrir_date = date;
              setincomingDate((prev) => ({
                ...prev,
                incoming_mrir_date: date,
              }));
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
        </div>
        <div>
          <Input
            label="Lot Qty"
            name="incoming_lot_qty"
            type="Number"
            inputProps={{
              step: 0.01,
            }}
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={incomingData.incoming_lot_qty}
          />
        </div>
        <div>
          <Input
            label="Heat No"
            name="incoming_heat_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={incomingData.incoming_heat_no}
          />
        </div>
      </Wrapper>
    </section>
  );
};

export default IncomingHeadForm;
