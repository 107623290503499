export const mergerRowsFmea = (fmeaData) => {
  const fmeaParams = [
    { param: "requirement" },
    { param: "potential_mode" },
    // { param: "severity" },
    { param: "failure_class" },
    { param: "potential_cause" },
    { param: "control_prevention" },
    { param: "control_detection" },
    { param: "recommendation_action" },
    { param: "det" },
    { param: "eof" },
  ];

  for (let [processIndex, process] of fmeaData.processes.entries()) {
    let prevRowValue = {};
    fmeaParams.forEach((item) => (prevRowValue[item.param] = Math.random()));
    let idxOfParmas = {};
    // console.log(prevRowValue);

    for (let [fmeaIndex, fmea] of process.fmeas.entries()) {
      fmeaParams.forEach((item, index) => {
        if (Array.isArray(fmea[item.param])) {
          fmea[item.param] = fmea[item.param][0];
        }
        if (prevRowValue[item.param] === fmea[item.param]) {
          fmeaData.processes[processIndex].fmeas[idxOfParmas[item.param]][
            `${item.param}_rowSpan`
          ] += 1;
          fmea[`${item.param}_include`] = false;
        } else {
          idxOfParmas[item.param] = fmeaIndex;
          fmea[`${item.param}_rowSpan`] = 1;
          fmea[`${item.param}_include`] = true;
          prevRowValue[item.param] = fmea[item.param] || "";
        }
      });
    }
  }
};

// const createRows = (modal) => {
//   let newFmea = [];
//   for (let [key, process] of fmeaData.processes.entries()) {
//     let prev = "";
//     let counter = 1;
//     let index = -1;
//     for (let [key, fmea] of process.fmeas.entries()) {
//       if (prev === fmea[modal]) {
//         newFmea[index].rowSpan += 1;
//         newFmea[index].stop = newFmea[index].rowSpan;
//       } else {
//         counter = 1;
//         index++;
//         newFmea[index] = {};
//         newFmea[index][modal] = fmea[modal];
//         newFmea[index].rowSpan = counter;
//         newFmea[index].stop = newFmea[index].rowSpan;
//         prev = fmea[modal];
//       }
//     }
//   }
//   return newFmea;
// };
// const editFmea = () => {
//   for (let [procKey, process] of fmeaData.processes.entries()) {
//     let prev = "";
//     let counter = 1;
//     let index = 0;
//     for (let [key, fmea] of process.fmeas.entries()) {
//       if (prev === fmea.potential_mode) {
//         counter++;
//         fmeaData.processes[procKey].fmeas[index].rowSpan = counter;
//         fmea.potential_mode_include = false;
//       } else {
//         counter = 1;
//         index = key;
//         fmea.rowSpan = counter;
//         fmea.potential_mode_include = true;
//         prev = fmea.potential_mode;
//       }
//     }
//   }
// };

// editFmea();
