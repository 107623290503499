import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BlockIcon from "@material-ui/icons/Block";
import IconButton from "@material-ui/core/IconButton";
import { Link, useHistory, useParams } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Add } from "@material-ui/icons";
import CoverForm from "./CoverForm";
import axios from "axios";
import Modal from "../Control/Modal";
import DesignRecord from "./modules/DesignRecord";
import Pfd from "./modules/Pfd";
import Fmea from "./modules/Fmea";
import DimensionInspection from "./modules/DimensionInspection";
import MaterialPerformance from "./modules/MaterialPerformance";
import ControlPlan from "./modules/ControlPlan";
import QualifiedLab from "./modules/QualifiedLab";
import CheckingAids from "./modules/CheckingAids";
import Warrant from "./modules/Warrant";
import PackingStandard from "./modules/PackingStandard";
import ApprovedSupplier from "./modules/ApprovedSupplier";
// import DownloadExcel from "./DownloadExcel";
import { Wrapper } from "../Control/Flexbox";
import MSATable from "./modules/MSATable";
// import InspectionStandard from "./modules/InspectionStandard";
import AAR from "./modules/AAR";
import Performance from "./modules/Performance";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    "& .MuiTableRow-root:hover": {
      background: "#efefef",
    },
  },
});

const ppapobj = [
  {
    name: "Design Record (Only Drawing)",
    submit: "Attached",
    retain: "Retained",
    value: "design_record",
    remarks: "Customer Drawing",
    isPpap: true,
    modalState: "setIsDesignRecord",
  },
  {
    name: "Engineering Change note (If any)",
    submit: "Not Applicable",
    retain: "Not Applicable",
    remarks: "",
    isPpap: false,
  },
  {
    name: "Engineering approval wherever required",
    submit: "Not Applicable",
    retain: "Not Applicable",
    remarks: "",
    isPpap: false,
  },
  {
    name: "DFMEA",
    submit: "Not Applicable",
    retain: "Not Applicable",
    remarks: "",
    isPpap: false,
  },
  {
    name: "Process Flow Diagram",
    submit: "Attached",
    retain: "Retained",
    remarks: "",
    value: "process_flow_diagram",
    isPpap: true,
    modalState: "setIsPfd",
  },
  {
    name: "PFMEA",
    submit: "Attached",
    retain: "Retained",
    value: "pfmea",
    remarks: "",
    isPpap: true,
    modalState: "setIsFmea",
  },
  {
    name: "Dimensional Inspection Report",
    submit: "Attached",
    retain: "Retained",
    value: "dimension_report",
    remarks: "",
    isPpap: true,
    modalState: "setIsDimensionInspection",
  },
  {
    name: "Material, Performance Test Results",
    submit: "Attached",
    retain: "Retained",
    value: "material_performance",
    remarks: "",
    isPpap: true,
    modalState: "setIsMatPer",
  },
  {
    name: "Initial Process study Report (SPC)",
    submit: "Attached",
    retain: "Retained",
    remarks: "Downlaod and give separately",
    isPpap: true,
    spc: true,
  },
  {
    name: "MSA Report",
    submit: "Attached",
    retain: "Retained",
    remarks: "",
    modalState: "setIsMSA",
    isPpap: true,
    value: "msa",
  },
  {
    name: "Qualified  Lab Documentation",
    submit: "Attached",
    retain: "Retained",
    value: "qualified_lab",
    remarks: "",
    isPpap: true,
    modalState: "setIsQualified",
  },

  {
    name: "Control Plan",
    submit: "Attached",
    retain: "Retained",
    value: "control_plan",
    remarks: "",
    isPpap: true,
    modalState: "setIsControlPlan",
  },
  {
    name: "Appearance Approval Report(AAR) If Applicable",
    submit: "Attached",
    retain: "Retained",
    value: "aar",
    remarks: "",
    isPpap: true,
    modalState: "setIsAAR",
  },
  {
    name: "Sample Product",
    submit: "Submitted",
    retain: "-",
    remarks: "",
    isPpap: false,
  },
  {
    name: "Master samples",
    submit: "-",
    retain: "Retained",
    remarks: "",
    isPpap: false,
  },
  {
    name: "Checking aids",
    submit: "Attached",
    retain: "Retained",
    value: "checking_aid",
    remarks: "",
    isPpap: true,
    modalState: "setIsAids",
  },
  {
    name: "Bulk Material requirement check list ",
    submit: "Not Applicable",
    retain: "Not Applicable",
    remarks: "",
    isPpap: false,
  },
  {
    name: "Part Submission Warrant",
    submit: "Attached",
    retain: "Retained",
    remarks: "",
    value: "psw",
    isPpap: true,
    modalState: "setIsWarrant",
  },
  {
    name: "Customer specific requirement (Records of Compliances with customer specific requirements)",
    submit: "-",
    retain: "-",
    remarks: "",
    isPpap: false,
  },
  {
    name: "Packing Standard",
    submit: "Attached",
    retain: "Retained",
    remarks: "",
    isPpap: true,
    value: "packing_standard",
    modalState: "setIsStand",
  },
  {
    name: "Performance Test Results",
    submit: "Attached",
    retain: "-",
    remarks: "",
    isPpap: true,
    value: "performance_test",
    modalState: "setIsPerformance",
  },
];

function EditPPAP() {
  const classes = useStyles();
  const { id } = useParams();
  const [ppapData, setPpapData] = useState(null);
  const [isDesignRecord, setIsDesignRecord] = useState(false);
  const [isPfd, setIsPfd] = useState(false);
  const [isFmea, setIsFmea] = useState(false);
  const [isDimensionInspection, setIsDimensionInspection] = useState(false);
  const [isMatPer, setIsMatPer] = useState(false);
  const [isControlPlan, setIsControlPlan] = useState(false);
  const [isQualified, setIsQualified] = useState(false);
  const [isAAR, setIsAAR] = useState(false);
  const [isAids, setIsAids] = useState(false);
  const [isWarrant, setIsWarrant] = useState(false);
  const [isPackStand, setIsStand] = useState(false);
  const [isPerformance, setIsPerformance] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [packStandImg, setPackStandImg] = useState(null);
  const [render, setRender] = useState(false);
  const [isMSA, setIsMSA] = useState(false);

  // setting state for opening alert dialogue box
  const [confirmOpen, setConfirmOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    axios.get(`/api/get/ppap/${id}`).then((res) => {
      setPpapData(res.data);
    });
  }, [render]);
  const handeSubmit = async () => {
    setIsLoading(true);

    if (image1 || image2 || image3 || image4 || image5 || packStandImg) {
      let form_data = new FormData();

      if (image1) {
        form_data.append("img_1", image1);
      }
      if (image2) {
        form_data.append("img_2", image2);
      }
      if (image3) {
        form_data.append("img_3", image3);
      }
      if (image4) {
        form_data.append("img_4", image4);
      }
      if (image5) {
        form_data.append("img_5", image5);
      }
      if (packStandImg) {
        form_data.append("pack_stand_img", packStandImg);
      }
      await axios
        .patch(`/api/edit/ppap/${id}`, form_data)
        .catch((err) => setIsLoading(false));
      setImage1(null);
      setImage2(null);
      setImage3(null);
      setImage4(null);
      setImage5(null);
      setPackStandImg(null);
    }
    delete ppapData.img_1;
    delete ppapData.img_2;
    delete ppapData.img_3;
    delete ppapData.img_4;
    delete ppapData.img_5;
    delete ppapData.pack_stand_img;
    await axios
      .patch(`/api/edit/ppap/${id}`, ppapData)
      .catch((err) => setIsLoading(false));
    setIsLoading(false);
    setRender((prev) => !prev);
  };
  const closeModal = () => {
    setIsDesignRecord(false);
    setIsPfd(false);
    setIsFmea(false);
    setIsDimensionInspection(false);
    setIsMatPer(false);
    setIsControlPlan(false);
    setIsQualified(false);
    setIsAAR(false);
    setIsAids(false);
    setIsWarrant(false);
    setIsStand(false);
    setIsPerformance(false);
    setIsMSA(false);
    handeSubmit();
  };
  const openModal = (value) => {
    switch (value) {
      case "setIsDesignRecord":
        setIsDesignRecord(true);
        break;
      case "setIsPfd":
        setIsPfd(true);
        break;
      case "setIsFmea":
        setIsFmea(true);
        break;
      case "setIsDimensionInspection":
        setIsDimensionInspection(true);
        break;
      case "setIsMatPer":
        setIsMatPer(true);
        break;
      case "setIsControlPlan":
        setIsControlPlan(true);
        break;
      case "setIsQualified":
        setIsQualified(true);
        break;
      case "setIsAAR":
        setIsAAR(true);
        break;
      case "setIsAids":
        setIsAids(true);
        break;
      case "setIsWarrant":
        setIsWarrant(true);
        break;
      case "setIsStand":
        setIsStand(true);
        break;
      case "setIsPerformance":
        setIsPerformance(true);
        break;
      case "setIsMSA":
        setIsMSA(true);
        break;
    }
  };
  const viewHandle = (value, ppapData, ppapobj) => {
    if (value === "msa" && !ppapData.msa_id) {
      setConfirmOpen(true);
      return;
    }

    if (value === "msa" && ppapData.msa_id) {
      axios.get(`/api/view/msa/${ppapData.msa_id}`).then((response) => {
        if (response.status === 200)
          window.open(
            "https://docs.google.com/spreadsheets/d/1dhiDvpTj4M291Zj3mioBYT8rpo2Z7ZTADkp55gEZrqE/edit?usp=sharing",
            "_blank"
          );
      });
      return;
    }
    if (
      value === "control_plan" ||
      value === "pfmea" ||
      value === "process_flow_diagram"
    ) {
      if (value === "control_plan" && !ppapData.control_id) {
        setConfirmOpen(true);
        return;
      }
      if (value === "pfmea" && !ppapData.fmea_id) {
        setConfirmOpen(true);
        return;
      }
      if (value === "process_flow_diagram" && !ppapData.pfd_id) {
        setConfirmOpen(true);
        return;
      }
    }

    history.push({
      pathname: "/view/ppap",
      state: {
        ppapData,
        isEqual: value,
        ppapobj,
      },
    });
    // if(value ==='pfmea'){}
  };
  if (isLoading) {
    return (
      <Wrapper style={{ height: "90vh" }} justify="center">
        <h3>Saving...</h3>
      </Wrapper>
    );
  }
  return (
    <main style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PRODUCTION PART APPROVAL PROCESS
        <div>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={handeSubmit}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#003566",
              marginLeft: "0.7rem",
            }}
            startIcon={<SaveIcon />}
          >
            Save & Continue
          </Button>
        </div>
      </div>
      <section style={{ padding: "1rem" }}>
        {ppapData && <CoverForm {...{ setPpapData, ppapData }} />}
      </section>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell
              style={{ fontWeight: "bold", width: "500px" }}
              align="center"
            >
              PPAP Document Requirement
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Submit (Customer folder)
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Retain (Inhouse folder)
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Remarks
            </TableCell>
            <TableCell
              style={{ fontWeight: "bold", width: "150px" }}
              align="center"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ppapobj.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{item.name}</TableCell>
              <TableCell align="center">{item.submit}</TableCell>
              <TableCell align="center">{item.retain}</TableCell>
              <TableCell align="center">{item.remarks}</TableCell>
              <TableCell align="center">
                {console.log(item)}
                {item.isPpap ? (
                  item.spc ? (
                    <>
                      <Tooltip title="Block" arrow>
                        <IconButton size="small">
                          <BlockIcon
                            style={{ fontSize: "1.4rem", color: "red" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View" arrow>
                        <Link
                          target="_blank"
                          to={`/spc/${ppapData?.part_name}`}
                        >
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <VisibilityIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Create" arrow>
                        <IconButton
                          size="small"
                          onClick={() => openModal(item.modalState)}
                        >
                          <Add
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => {
                            viewHandle(item.value, ppapData, ppapobj);
                          }}
                        >
                          <VisibilityIcon
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                ) : (
                  <>
                    <Tooltip title="Block" arrow>
                      <IconButton size="small">
                        <BlockIcon
                          style={{ fontSize: "1.4rem", color: "red" }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Block" arrow>
                      <Link>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <BlockIcon
                            style={{ fontSize: "1.4rem", color: "red" }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {ppapData && (
        <section>
          <Modal
            openPopup={isWarrant}
            title="PART SUBMISSION WARRANT"
            closeModal={closeModal}
          >
            <Warrant {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isDesignRecord}
            title="FIRST PIECE APPROVAL"
            closeModal={closeModal}
          >
            <DesignRecord
              {...{
                setImage1,
                setImage2,
                setImage3,
                setImage4,
                setImage5,
              }}
            />
          </Modal>
          <Modal
            openPopup={isPfd}
            title="ADD PFD TO PPAP"
            closeModal={closeModal}
          >
            <Pfd {...{ ppapData }} />
          </Modal>
          <Modal openPopup={isFmea} title="FMEA" closeModal={closeModal}>
            <Fmea {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isDimensionInspection}
            title="DIMENSIONS"
            closeModal={closeModal}
          >
            <DimensionInspection {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isMatPer}
            title="MATERIAL PERFORMANCE"
            closeModal={closeModal}
          >
            <MaterialPerformance {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isQualified}
            title="QUALIFIED LAB"
            closeModal={closeModal}
          >
            <QualifiedLab {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isControlPlan}
            title="ADD CONTROL PLAN TO PPAP"
            closeModal={closeModal}
          >
            <ControlPlan {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isMSA}
            title="ADD MSA TO PPAP"
            closeModal={closeModal}
          >
            <MSATable {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isAids}
            title="CHECKING AIDS"
            closeModal={closeModal}
          >
            <CheckingAids {...{ ppapData }} />
          </Modal>
          {/* <Modal
            openPopup={isApprovedSupplier}
            title="LIST OF APPROVED SUPPLIERS"
            closeModal={closeModal}
          >
            <ApprovedSupplier {...{ ppapData }} />
          </Modal> */}

          <Modal
            openPopup={isPackStand}
            title="PACKING STANDARD"
            closeModal={closeModal}
          >
            <PackingStandard {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isPerformance}
            title="PERFORMANCE TEST"
            closeModal={closeModal}
          >
            <Performance {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isAAR}
            title="ADD AAR TO PPAP"
            closeModal={closeModal}
          >
            <AAR {...{ ppapData }} />
          </Modal>
          {/* <Modal
            openPopup={isInspectionStandard}
            title="INSPECTION STANDARD"
            closeModal={closeModal}
          >
            <InspectionStandard {...{ ppapData }} />
          </Modal> */}
        </section>
      )}
      {/* <DownloadExcel {...{ ppapData, ppapobj }} /> */}
    </main>
  );
}

export default EditPPAP;
