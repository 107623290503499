import React, { useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  //   Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import formatDate from "../../components/Control/formatDate";
import { useAuth } from "../../context/GlobalContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

function PartList() {
  const classes = useStyles();
  const [partData, setPartData] = useState([]);
  const { state } = useLocation();
  const globalState = useAuth();
  const [page, setPage] = useState(1);
  const [render, setRender] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();
  const [filter, setFilter] = useState("part_number");
  const { docType } = useParams("docType");

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/get/part/publish?page=${page}&sortBy=${filter}&search=${search}`,
        {
          headers: { isFullAdmin: globalState.state?.user?.isFullAdmin },
        },
      )
      .catch((err) => {
        console.log(err);
      });
    setPartData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, render, filter]);

  const docTypeView = (docType, item) => {
    switch (docType !== null || docType !== undefined) {
      case docType === "generateFmea":
        return (
          <Tooltip title="View" arrow>
            <Link
              to={`/generateFmeaLists/${item._id}?partNumber=${item.part_number}&partName=${item.part_name}`}
            >
              <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                <VisibilityIcon
                  style={{ fontSize: "1.4rem", color: "#003566" }}
                />
              </IconButton>
            </Link>
          </Tooltip>
        );
      case docType === "occurenceMatrix":
        return (
          <Tooltip title="View" arrow>
            <Link
              to={`/occMatrixModuleList/${item._id}?partNumber=${item.part_number}&partName=${item.part_name}`}
            >
              <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                <VisibilityIcon
                  style={{ fontSize: "1.4rem", color: "#003566" }}
                />
              </IconButton>
            </Link>
          </Tooltip>
        );
      default:
        return;
    }
  };

  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PART LIST
      </div>
      <TableContainer component={Paper}>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Part Number"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginRight: "1.5rem", marginTop: "0.8rem", flex: "0.2" }}
          >
            <InputLabel>Filter</InputLabel>
            <Select
              name="part_type"
              onChange={(e) => setFilter(e.target.value)}
              defaultValue={filter}
            >
              <MenuItem value="part_number">A - Z</MenuItem>
              <MenuItem value="category">Category</MenuItem>
              <MenuItem value="createdAt">Most Recent First</MenuItem>
              <MenuItem value="createdAt_old">Oldest First</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                #
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Number
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Description
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {partData && (
            <TableBody>
              {partData.map((item, index) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.part_number}</TableCell>
                    <TableCell align="center">{item.part_name}</TableCell>

                    <TableCell align="center">
                      {formatDate(item.createdAt)}
                    </TableCell>

                    <TableCell align="center">
                      {docTypeView(docType, item)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </TableContainer>
    </>
  );
}

export default PartList;
