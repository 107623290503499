import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import CreateSetupHead from "../CreateSetupForm/CreateSetupHead";
import CreateSetupProcessHead from "../CreateSetupForm/CreateSetupProcessHead";
import Loader from "../../../components/Control/Loader";
import { useHistory } from "react-router-dom";

const EditSetupForm = () => {
  const { id } = useParams();
  const [setupData, setSetupData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    axios
      .get(`/api/get/request_form/${id}`)
      .then((res) => {
        setSetupData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const isSubmit = window.confirm(
      "Are you sure you want to submit this Setup Form?"
    );
    if (isSubmit) {
      setIsLoading(true);
      setupData.is_setup_submitted = true;
      setupData.is_admin_setup = "pending";
      setupData.admin_setup_no = 1;

      axios
        .put(`/api/edit/request_form/${id}`, setupData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === "success") {
            history.push("/supervisor/view/4mTable");
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const saveLater = () => {
    const isSubmit = window.confirm(
      "Are you sure you want to save this Setup Form?"
    );
    if (isSubmit) {
      setIsLoading(true);
      setupData.is_setup_submitted = false;
      setupData.is_admin_setup = "nothing";
      axios
        .put(`/api/edit/request_form/${id}`, setupData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === "success") {
            history.push("/supervisor/view/4mTable");
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
      <div className="divflex" style={{ fontSize: "24px" }}>
        SETUP FORM
      </div>
      <form
        style={{
          marginTop: "2rem",
          marginLeft: "1rem",
          marginRight: "1rem",
          paddingBottom: "4rem",
        }}
      >
        {setupData && <CreateSetupHead {...{ setupData, setSetupData }} />}
        {setupData && <CreateSetupProcessHead {...{ setupData }} />}
        <Wrapper
          style={{
            position: "absolute",
            top: "4.6rem",
            right: "1.6rem",
            gap: "1rem",
          }}
        >
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={saveLater}
            style={{ backgroundColor: "#003566" }}
            startIcon={<DoubleArrowIcon />}
          >
            Save & Continue
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            style={{ backgroundColor: "#054a29" }}
            startIcon={<SaveIcon />}
          >
            SUBMIT
          </Button>
        </Wrapper>
      </form>
    </main>
  );
};

export default EditSetupForm;
