import React, { useRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import "../../style/viewProcess.css";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { useAuth } from "../../context/GlobalContext";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import viewModule from "./viewAdminModule.module.css";

import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import DescriptionIcon from "@material-ui/icons/Description";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import AddEditPart from "../AddEditDuplicatePart/AddEditPart";
import WrapTextIcon from "@material-ui/icons/WrapText";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import TimelineIcon from "@material-ui/icons/Timeline";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import Modal from "../../components/Control/Modal";
import { Edit } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import AddChildAndChildPartList from "../ViewAdminPartTable/AddChildAndChildPartList";
import { Wrapper } from "../../components/Control/Flexbox";
import { usePermission } from "../../components/usePermission";

function AmdinModules() {
  const { state } = useAuth();
  const query = useQuery();
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const partNumber = query.get("partnumber");
  const partName = query.get("partname");
  const isChildList = useRef(false);
  const [isAddChildAndChildList, setIsAddChildAndChildList] = useState(false);
  const { partId } = useParams();
  const permissions = usePermission(3);

  if (state.user.userType == 2) {
    return (
      <>
        <div className="divflex" style={{ fontSize: "24px" }}>
          DOCUMENT LIST
          <Wrapper style={{ gap: "1rem" }}>
            {permissions?.includes("edit") && (
              <Button
                variant="outlined"
                color="white"
                style={{
                  color: "#fafafa",
                  backgroundColor: "#003566",
                }}
                onClick={() => {
                  setIsNewPartModal(true);
                }}
                startIcon={<Edit style={{ fontSize: "1rem" }} />}
              >
                EDIT PART
              </Button>
            )}
            <Button
              variant="outlined"
              color="white"
              style={{
                color: "#fafafa",
                backgroundColor: "#064a29",
              }}
              onClick={() => {
                setIsAddChildAndChildList(true);
              }}
              // startIcon={<view style={{ fontSize: "1.4rem" }} />}
            >
              VIEW CHILD
            </Button>
          </Wrapper>
        </div>
        <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
        <main className={`${viewModule.container} ${viewModule.main}`}>
          <div>
            <Link
              to={`/revisedTable/pfd/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <SwapHorizontalCircleIcon
                style={{ fontSize: "3rem", color: "maroon" }}
              />
            </Link>
            <p className="card-btn-para">
              Process Flow
              <span className="card-btn-span">Diagram</span>
            </p>
          </div>
          <div>
            <Link
              to={`/revisedTable/fmea/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <SmsFailedIcon style={{ fontSize: "3rem", color: "maroon" }} />
            </Link>
            <p className="card-btn-para">
              FMEA <span className="card-btn-span">Formats</span>
            </p>
          </div>
          <div>
            <Link
              to={`/revisedTable/controlplan/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <DescriptionIcon style={{ fontSize: "3rem", color: "maroon" }} />
            </Link>
            <p className="card-btn-para">
              Control <span className="card-btn-span">Plan</span>
            </p>
          </div>
          <div>
            <Link
              to={`/view/sopTable/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <VerticalSplitIcon
                style={{ fontSize: "3rem", color: "#003566" }}
              />
            </Link>
            <p className="card-btn-para">
              SOP <span className="card-btn-span">Module</span>
            </p>
          </div>
          <div>
            <Link
              to={`/processProductAuditList/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <DescriptionIcon style={{ fontSize: "3rem", color: "maroon" }} />
            </Link>
            <p className="card-btn-para">
              Process/Product <span className="card-btn-span">Audit</span>
            </p>
          </div>
          {/* <div>
            <Link
              to={`/pcd/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <PhotoSizeSelectActualIcon
                style={{ fontSize: "3rem", color: "maroon" }}
              />
            </Link>
            <p className="card-btn-para">
              Process Control <span className="card-btn-span">Drawing</span>{" "}
            </p>
          </div> */}
          {/* <div>
            <Link
              to={`/customerDrawing/table/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <PhotoAlbumIcon style={{ fontSize: "3rem", color: "maroon" }} />
            </Link>
            <p className="card-btn-para">
              Customer <span className="card-btn-span">Drawing</span>
            </p>
          </div> */}
          <div>
            <Link
              to={`/viewAdmin/pirLists/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <FindInPageIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>

            <p className="card-btn-para">
              Inspection & Setup
              <span className="card-btn-span">Reports</span>{" "}
            </p>
          </div>
          <div>
            <Link
              to={`/viewpdir/monthly/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <DynamicFeedIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>

            <p className="card-btn-para">
              PRE-DISPATCH INSEPECTION
              <span className="card-btn-span">REPORTS</span>
            </p>
          </div>

          {/* <div>
            <Link
              to={`/view/incomingTable/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <LibraryAddIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>
            <p className="card-btn-para">
              Incoming <span className="card-btn-span">Reports</span>
            </p>
          </div> */}
          {/* <div>
            <Link
              to={`/view/msaTable/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <AssessmentIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>
            <p className="card-btn-para">
              MSA <span className="card-btn-span">Reports</span>
            </p>
          </div> */}
          <div>
            <Link
              to={`/spc/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <WrapTextIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>
            <p className="card-btn-para">
              SPC <span className="card-btn-span">Reports</span>
            </p>
          </div>
          {/* <div>
            <Link
              to={`/viewfmeaTable/fmea/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <VerticalAlignCenterIcon
                style={{ fontSize: "3rem", color: "#064a29" }}
              />
            </Link>
            <p className="card-btn-para">
              Generate <span className="card-btn-span">FMEA</span>
            </p>
          </div> */}

          {/* <div>
            {" "}
            <Link
              to={`/select/ppap/${partId}?partNumber=${partNumber}&partName=${partName}`}
              className="panel"
            >
              <TimelineIcon style={{ fontSize: "3rem", color: "#064a29" }} />
            </Link>
            <p className="card-btn-para">
              Generate <span className="card-btn-span">PPAP</span>
            </p>
          </div> */}
          <Modal
            title={partId ? "update new part" : "create new part"}
            openPopup={isNewPartModal}
            closeModal={() => {
              setIsNewPartModal(false);
            }}
            backgroundColor="white"
          >
            <AddEditPart id={partId} {...{ setIsNewPartModal }} />
          </Modal>

          <Modal
            title={`child list ${partNumber}`}
            openPopup={isAddChildAndChildList}
            closeModal={() => setIsAddChildAndChildList(false)}
          >
            <AddChildAndChildPartList isChildList={true} partId={partId} />
          </Modal>
        </main>
      </>
    );
  } else {
    return (
      <div>
        <h1>Page Not Found</h1>
      </div>
    );
  }
}

export default AmdinModules;
