import React, { useEffect, useState } from "react";
// import FmeaFailures from "./FmeaFailures";
import formatDate from "../../../components/Control/formatDate";
import Styled from "styled-components";
// import { Wrapper } from "../../../../components/Control/Flexbox";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { useAuth } from "../../../context/GlobalContext";
import { images, textInputs } from "../../../constants/constants";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import SaveIcon from "@material-ui/icons/Save";
import { Wrapper } from "../../../components/Control/Flexbox";
import { useQuery } from "../../../components/Control/useQuery";
import useGetData from "./helper/useGetData";
// import { mergerRowsFmea } from "../../AddEditDuplicatePart/quickView/partPlanQuickView/MergerRows.utils";
import EditableTableCell from "../../../components/Control/EditableTableCell";
import TBodyRow from "./TBodyRow";
import { StyledTableHeaderRow } from "../../../golbalStyles/tableFreeze/RowFreeze.styled";
import { StyledTableHeaderTh1 } from "../../../golbalStyles/tableFreeze/ColumnFrezze.styled";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;

        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }
    .input {
        outline: none;
        border: none;
        height: 50px;
        text-align:center;
        padding:0;
    }
    .center-heading{
      text-align:center;
    }

`;
// const getTopRpn = (processes) => {
//   let arrs = [];
//   processes?.forEach((process) => {
//     process.failures?.forEach((failure) => {
//       arrs.push(failure.rpn);
//     });
//   });
//   let topRpn = [0, 0, 0];
//   for (let i = 0; i < 4; i++) {
//     arrs.forEach((arr) => {
//       if (!topRpn.includes(arr)) {
//         if (+topRpn[i] < +arr) {
//           topRpn[i] = arr;
//         }
//       }
//     });
//   }
//   return topRpn;
// };

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const OccMatrixModuleTable = () => {
  const { occMatrixId } = useParams();
  const query = useQuery();
  // const created_year = query.get("created_year");
  const isView = query.get("isView") === "true" ? true : false;
  const history = useHistory();
  const [render, setRender] = useState(false);
  const { moduleData, occData, isLoading, error } = useGetData(occMatrixId);
  const [getformatedYear, setFromatedYear] = useState({
    currentYear: "",
    nextYear: "",
  });

  useEffect(() => {
    const year = getYearRange(moduleData?.created_year);
    setFromatedYear((prev) => ({
      ...prev,
      currentYear: year.split("-")[0].trim().slice(2),
      nextYear: year.split("-")[1].trim().slice(2),
    }));
  }, [moduleData]);

  const updateModule = async () => {
    let isUpdate = window.confirm(
      "Are you sure you want to update this document?"
    );
    if (isUpdate) {
      let res = await axios
        .put(`/api/updateOccMatrixModule/${occMatrixId}`, moduleData)
        .catch((err) => console.log(err));
      if (res.data?.status === "success") {
        history.push(`/occMatrixModuleList`);
      }
    }
  };
  // console.log(moduleData);

  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          OCCURENCE MATRIX
        </Typography>
        <Wrapper>
          {!isView && (
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={updateModule}
              style={{ backgroundColor: "#003566" }}
              startIcon={<DoubleArrowIcon />}
            >
              Update
            </Button>
          )}
        </Wrapper>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section style={{ overflowX: "auto", height: "100vh" }}>
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 5, 7, 18, 5, 18, 18, 5, 5, 15, 15, 15, 4, 4, 4, 4"
          >
            <thead>
              <tr>
                <th
                  colSpan="38"
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    borderRight: "0px",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.CompanyLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  FORMAT NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  F QAD 25
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  ISSUE DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01/04/19
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01/08/2022
                </th>
              </tr>
              <tr>
                <th
                  colSpan={44}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  OCCURANCE MATRIX{" "}
                </th>
              </tr>
            </thead>
            <tr>
              <th colSpan="44">
                Title:
                <EditableTableCell
                  name="title"
                  type="text"
                  isView={isView}
                  value={moduleData.title}
                  handleChange={(e) => {
                    moduleData.title = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  style={{ width: "90px" }}
                />
              </th>
            </tr>
            <tbody>
              <StyledTableHeaderRow top={"-1px"} zIndex={4}>
                <StyledTableHeaderTh1
                  left={0}
                  width="110px"
                  minWidth="110px"
                  align="center"
                  color="#fff"
                  colSpan={1}
                  rowSpan={2}
                >
                  Sr No
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  left={"110px"}
                  width="100px"
                  minWidth="100px"
                  color="#fff"
                  align="center"
                  colSpan={1}
                  rowSpan={2}
                >
                  Stage
                </StyledTableHeaderTh1>
                <StyledTableHeaderTh1
                  left={"210px"}
                  width="150px"
                  minWidth="150px"
                  color="#fff"
                  align="center"
                  colSpan={1}
                  rowSpan={2}
                >
                  Part Defect
                </StyledTableHeaderTh1>

                {[
                  `April - ${getformatedYear.currentYear}`,
                  `May - ${getformatedYear.currentYear}`,
                  `June - ${getformatedYear.currentYear}`,
                  `July - ${getformatedYear.currentYear}`,
                  `August - ${getformatedYear.currentYear}`,
                  `September - ${getformatedYear.currentYear}`,
                  `October - ${getformatedYear.currentYear}`,
                  `November - ${getformatedYear.currentYear}`,
                  `December - ${getformatedYear.currentYear}`,
                  `January - ${getformatedYear.nextYear}`,
                  `February - ${getformatedYear.nextYear}`,
                  `March - ${getformatedYear.nextYear}`,
                  "Avg. PPM",
                  "Avg PPM /1000",
                  "OCC Rank",
                  "Total Production",
                  "Total Rejection",
                ].map((heading, index) => (
                  <th
                    style={{ textAlign: "center" }}
                    key={index + heading}
                    colSpan={index < 12 ? 3 : 1}
                    rowSpan={index < 12 ? 1 : 2}
                  >
                    {heading}
                  </th>
                ))}
              </StyledTableHeaderRow>
              <StyledTableHeaderRow top={"49px"}>
                {Array.from({ length: 12 }, (_, index) => (
                  <React.Fragment key={index + "sub"}>
                    <th style={{ textAlign: "center" }}>Production Qty</th>
                    <th style={{ textAlign: "center" }}>Rej. Qty</th>
                    <th style={{ textAlign: "center" }}>PPM</th>
                  </React.Fragment>
                ))}
              </StyledTableHeaderRow>
              {moduleData?.processes?.map((process, processInd) =>
                process?.occ_process?.map((occMatrix, occMatIndx) => (
                  <React.Fragment key={occMatIndx + "calcRow" + 10.1}>
                    <TBodyRow
                      {...{
                        process,
                        processInd,
                        occMatrix,
                        occMatIndx,
                        occData,
                        isView,
                        // handleChange,
                      }}
                    />
                  </React.Fragment>
                ))
              )}
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default OccMatrixModuleTable;
