import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import { Wrapper } from "../../../components/Control/Flexbox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { usePermission } from "../../../components/usePermission";

const Table = Styled.table`
  width: 98%;
  margin: auto;
  margin: 1rem;
  th {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    border: 1px solid black;
    padding: 0.4rem;
    p {
      margin: 0;
    }
  }

  td {
    font-size: 12px;
    border: 1px solid black;
    padding: 0.3rem;
    p {
      margin: 0;
    }
  }
  span {
    font-weight: 500;
  }

  .input {
    outline: none;
    width: 100%;
    border: 2px solid #f9e0e0;
    height: 50px;
    text-align: center;
  }
  
  td {
    text-align: center;
  }
`;
const CreateViewOccMatrixTable = () => {
  const [moduleData, setModuleData] = useState({
    processes: Array.from({ length: 10 }, (_) => {
      return {
        criteria_1: null,
        criteria_2: null,
        occ_rank: null,
      };
    }),
  });
  const [render, setRender] = useState(false);
  const [apiRender, setApiRender] = useState(false);
  const history = useHistory();
  const permissions = usePermission(5);
  const isView = permissions?.includes("edit") ? false : true;

  useEffect(() => {
    axios
      .get(`/api/getOcc`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [apiRender]);

  const updateModule = async () => {
    let isUpdate = window.confirm(
      "Are you sure you want to update this document?"
    );
    if (isUpdate) {
      let res = await axios
        .put(`/api/updateOcc/${moduleData?._id}`, moduleData)
        .catch((err) => console.log(err));
      if (res?.data?.status === "success") {
        alert("submitted successfully");
        setApiRender((prev) => !prev);
      }
    }
  };

  const submitModule = async () => {
    let isSubmit = window.confirm(
      "Are you sure you want to save this document?"
    );
    if (isSubmit) {
      let res = await axios
        .post("/api/createOccData", moduleData)
        .catch((err) => console.log(err));
      if (res?.data?.status === "success") {
        alert("submitted successfully");
        setApiRender((prev) => !prev);
      }
    }
  };

  const handleChange = (e, process) => {
    const { name, value } = e.target;
    process[name] = value;
    setRender((prev) => !prev);
  };
  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          OCCURENCE MATRIX
        </Typography>

        <Wrapper>
          {moduleData?._id
            ? permissions?.includes("edit") && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={updateModule}
                  style={{ backgroundColor: "#003566" }}
                  startIcon={<DoubleArrowIcon />}
                >
                  Update
                </Button>
              )
            : permissions?.includes("edit") && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitModule}
                  style={{ backgroundColor: "#003566" }}
                  startIcon={<SaveIcon />}
                >
                  Save & Continue
                </Button>
              )}
        </Wrapper>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section>
          <Table
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "80%",
            }}
          >
            <thead>
              <tr>
                <th colSpan={2} style={{ textAlign: "center" }}>
                  Criteria
                </th>
                <th colSpan={1} style={{ textAlign: "center" }}>
                  Rank
                </th>
              </tr>
            </thead>
            <tbody>
              {moduleData?.processes?.map((process, index) => (
                <tr key={index + "heading"}>
                  <th style={{ textAlign: "center" }}>
                    {isView ? (
                      process.criteria_1
                    ) : (
                      <input
                        type="number"
                        name="criteria_1"
                        value={process.criteria_1}
                        autoComplete="off"
                        className="input"
                        onChange={(e) => {
                          handleChange(e, process);
                        }}
                        onWheel={() => document.activeElement.blur()}
                      />
                    )}
                  </th>
                  <th style={{ textAlign: "center" }}>
                    {isView ? (
                      process.criteria_2
                    ) : (
                      <input
                        type="number"
                        name="criteria_2"
                        value={process.criteria_2}
                        autoComplete="off"
                        className="input"
                        onChange={(e) => {
                          handleChange(e, process);
                        }}
                        onWheel={() => document.activeElement.blur()}
                      />
                    )}
                  </th>
                  <th style={{ textAlign: "center" }}>
                    {isView ? (
                      process.occ_rank
                    ) : (
                      <input
                        type="number"
                        name="occ_rank"
                        value={process.occ_rank}
                        autoComplete="off"
                        className="input"
                        onChange={(e) => {
                          handleChange(e, process);
                        }}
                        onWheel={() => document.activeElement.blur()}
                      />
                    )}
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default CreateViewOccMatrixTable;
