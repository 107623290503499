import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import formatDate from "../../../components/Control/formatDate";
import { useAuth } from "../../../context/GlobalContext";
import PrintIcon from "@material-ui/icons/Print";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    p{
      margin:0;
    }
    span{
      font-weight:500;
    }

`;

function tConv24(time24) {
  if (time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
}

function ViewPdirTable() {
  const { id } = useParams();
  const history = useHistory();
  const [pdirData, setPdirData] = useState({});
  const { state } = useAuth();

  console.log(pdirData);
  const DeletePir = (id) => {
    let isDeleted = window.confirm("Do you want to delete this Report?");
    if (isDeleted) {
      axios
        .delete(`/api/delete/pdir/${id}`)
        .then(() => {
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/get/pdir/${id}`);
        setPdirData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  const Showcheck2lower = (mean, low) => mean - low;
  const Showcheck2Upper = (mean, upr) => mean + upr;

  console.log(pdirData);

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PRE-DISPATCH INSPECTION REPORT
        </Typography>
        <div style={{ display: "flex" }}>
          {state.user.userType === 2 && (
            <>
              <Wrapper>
                <Tooltip title="Edit" arrow>
                  <Link to={`/pdir/edit/${pdirData._id}`}>
                    <IconButton
                      size="large"
                      style={{
                        marginRight: "1rem",
                        color: "white",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    size="large"
                    style={{
                      marginRight: "1rem",
                      color: "white",
                    }}
                    onClick={() => DeletePir(pdirData._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Wrapper>
            </>
          )}
          <Wrapper>
            <Tooltip title="Print table" arrow>
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip title="Export as spreadsheet" arrow>
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `PDIR - ${
                          pdirData.part_id.part_name
                        } - ${formatDate(pdirData.pdir_date)}.xlsx`,
                        sheet: {
                          name: "PDIR Sheet",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </Wrapper>
        </div>
      </div>
      <Table
        cellSpacing="0"
        style={{ marginTop: "0.6rem" }}
        id="table-to-xls"
        data-default-wrap="true"
        data-cols-width="8, 14, 16, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 9, 9, 10, 12"
      >
        <tbody>
          <tr>
            <th
              colSpan={18}
              rowSpan="4"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
            >
              <CenteredLogoImg>
                <img src={images.CompanyLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              DOCUMENT NO:
            </th>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              F QAD 09
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              ISSUE DATE:
            </th>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              01/04/2017
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              REVISION NO:
            </th>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              02
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              REVISION DATE:
            </th>
            <th
              align="left"
              colSpan={2}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              14/09/2021
            </th>
          </tr>
          <tr data-height="40">
            <td
              colSpan={22}
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
                textTransform: "uppercase",
              }}
              data-fill-color="FF000000"
            >
              PRE-DISPATCH INSPECTION REPORT
            </td>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={11}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              CUSTOMER: <span>{pdirData?.part_id?.customer}</span>
            </th>
            <th
              align="left"
              colSpan={11}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              DATE: <span>{formatDate(pdirData.pdir_date)}</span>
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={11}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              LOCATION: <span>{pdirData.location}</span>
            </th>
            <th
              align="left"
              colSpan={11}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              LOT NO & QUANTITY:{" "}
              <span>
                {pdirData.lot_no} & {pdirData.quantity}
              </span>
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={8}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              INVOICE NO & DATE:{" "}
              <span>
                {pdirData.invoice_no} & {pdirData.invoice_date}
              </span>
            </th>
            <th
              align="left"
              colSpan={7}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              SAMPLE TAKEN: <span>{pdirData.sample_taken}</span>
            </th>
            <th
              align="left"
              colSpan={7}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              SAMPLE RECORDED: <span>{pdirData.sample_recorded}</span>
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={4}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              DESCRIPTION: <span>{pdirData.desc}</span>
            </th>
            <th
              align="left"
              colSpan={9}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              DESTRUCTIVE TESTING SAMPLE TAKEN:{" "}
              <span>{pdirData.des_sample_taken}</span>
            </th>
            <th
              align="left"
              colSpan={9}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              SAMPLE RECORDED: <span>{pdirData.des_sample_recorded}</span>
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={4}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              MATERIAL: <span>{pdirData.desc}</span>
            </th>
            <th
              align="left"
              colSpan={18}
              rowSpan={6}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              SKETCH:{" "}
              <img
                src={pdirData.sketch}
                alt="sketch"
                height={300}
                width="100%"
              />
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={4}
              style={{ border: "1px solid black", height: "40px" }}
            ></th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={4}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              SIZE: <span>{pdirData.size}</span>
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={4}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              FINISH: <span>{pdirData.finish}</span>
            </th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={4}
              style={{ border: "1px solid black", height: "40px" }}
            ></th>
          </tr>
          <tr>
            <th
              align="left"
              colSpan={4}
              style={{ border: "1px solid black" }}
              data-f-sz="10"
            >
              ITEM CODE:{" "}
              <span>
                {pdirData?.part_id?.part_name},{pdirData?.part_id?.part_number}
              </span>
            </th>
          </tr>
          <tr>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
              data-f-sz="10"
            >
              S.NO
            </th>

            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
              data-f-sz="10"
            >
              PARAMETER
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
              data-f-sz="10"
            >
              ACCEPTANCE CRITERIA
            </th>

            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              colSpan={15}
              data-f-sz="10"
            >
              OBSERVATIONS (IN RANGE)
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              colSpan={2}
              data-f-sz="10"
            >
              RANGE
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
              data-f-sz="10"
            >
              RESULTS
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
              data-f-sz="10"
            >
              REMARKS
            </th>
          </tr>
          <tr>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              1
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              2
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              3
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              4
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              5
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              6
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              7
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              8
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              9
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              10
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              11
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              12
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              13
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              14
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              15
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              Min
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              data-f-sz="10"
            >
              Max
            </th>
          </tr>
          {pdirData.pdir_list?.map((pdir, index) => (
            <tr key={pdir._id}>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
                data-f-sz="9"
              >
                {index + 1}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
                data-f-sz="9"
              >
                {pdir.specification}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
                data-f-sz="9"
              >
                {pdir.parameter}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_1}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_2}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_3}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_4}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_5}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_6}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_7}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_8}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_9}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_10}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_11}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_12}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_13}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_14}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.obs_15}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
                data-f-sz="9"
              >
                {pdir.min}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
                data-f-sz="9"
              >
                {pdir.max}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
                data-f-sz="9"
              >
                {pdir.results}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
                data-f-sz="9"
              >
                {pdir.remarks}
              </td>
            </tr>
          ))}
        </tbody>
        <tr style={{ height: "60px" }}>
          <td
            align="left"
            colSpan={22}
            style={{ fontWeight: 600, border: "1px solid black" }}
            data-f-sz="9"
          >
            Remarks: {pdirData.remarks}
          </td>
        </tr>
        <tr style={{ height: "60px" }}>
          <td
            align="left"
            colSpan={22}
            style={{ fontWeight: 600, border: "1px solid black" }}
            data-f-sz="9"
          >
            NOTE: {pdirData.notes}
          </td>
        </tr>
        <tr style={{ height: "60px" }}>
          <td
            align="left"
            colSpan={2}
            style={{ fontWeight: 500, border: "1px solid black" }}
            data-f-sz="10"
          >
            Prepared By:
          </td>
          <td
            align="left"
            colSpan={11}
            style={{ border: "1px solid black" }}
            data-f-sz="10"
          >
            {pdirData.report_prepared_by}
          </td>

          <td
            align="left"
            colSpan={2}
            style={{ fontWeight: 500, border: "1px solid black" }}
            data-f-sz="10"
          >
            Approved By:
          </td>
          <td
            align="left"
            style={{ border: "1px solid black" }}
            data-f-sz="10"
            colSpan={7}
          >
            {pdirData.report_approved_by}
          </td>
        </tr>
        <tr style={{ height: "60px" }}>
          <td
            align="left"
            colSpan={2}
            style={{ border: "1px solid black" }}
            data-f-sz="10"
          >
            Designation:
          </td>
          <td
            align="left"
            colSpan={11}
            style={{ border: "1px solid black" }}
            data-f-sz="10"
          >
            {pdirData.prepared_by_designation}
          </td>
          <td
            align="left"
            colSpan={2}
            style={{ border: "1px solid black" }}
            data-f-sz="10"
          >
            Designation:
          </td>
          <td
            align="left"
            colSpan={7}
            style={{ border: "1px solid black" }}
            data-f-sz="10"
          >
            {" "}
            {pdirData.approved_by_designation}
          </td>
        </tr>
      </Table>
    </div>
  );
}

export default ViewPdirTable;
