import axios from "axios";
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Search } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { Add } from "@material-ui/icons";

import useDebounce from "../../../components/Control/useDebounce";
import formatDate from "../../../components/Control/formatDate";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});
const AddCategory = () => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [render, setRender] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("part_number");
  const [categoryData, setCategoryData] = useState([]);
  const [addCategory, setAddCategory] = useState({});

  const fetctCategoryData = () => {
    axios
      .get(
        `/api/getAllCategory/data?page=${page}&sortBy=${filter}&search=${search}&perPage=10`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setCategoryData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };
  useDebounce(fetctCategoryData, 300, [page, filter, search, render]);

  const handleSubmit = () => {
    axios
      .post(`/api/createCategory/add`, addCategory)
      .then((res) => {
        setRender((prev) => !prev);
        setAddCategory({ category: "" });
        alert("success");
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (id) => {
    axios
      .delete(`/api/deleteCategory/${id}`)
      .then((res) => {
        alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <section>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "3rem",
        }}
      >
        <Input
          style={{
            width: "25rem",
          }}
          type="text"
          name="category"
          placeholder="Category"
          onChange={(e) => setAddCategory({ [e.target.name]: e.target.value })}
          value={addCategory?.category}
        />
        <Button
          type="button"
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleSubmit()}
          style={{
            backgroundColor: "maroon",
            marginRight: "1rem",
          }}
          startIcon={<Add />}
        >
          Quick Add Category
        </Button>
      </div>
      <Wrapper>
        <div style={{ flex: "0.8" }}>
          <Input
            placeholder="Search By Category Name"
            variant="filled"
            style={{
              width: "95%",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginRight: "1.5rem", flex: "0.2" }}
        >
          <InputLabel>Filter</InputLabel>
          <Select
            name="part_type"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={filter}
          >
            <MenuItem value="category">A - Z</MenuItem>
            <MenuItem value="createdAt">Most Recent First</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {["#", "Category", "Created At", "Action"].map((item, index) => (
              <TableCell
                style={{ fontWeight: "bold", textTransform: "capitalize" }}
                align="center"
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {categoryData && (
          <TableBody>
            {categoryData?.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.category}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(item._id)}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
    </section>
  );
};

export default AddCategory;
