import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const QualifiedLabReport = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          QUALIFIED LAB DOCUMENTAION
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "Qualified Lab Documentation.xlsx",
                      sheet: {
                        name: "QUALIFIED LAB DOCUMENTAION",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="5,32, 15, 17, 60, 15"
        >
          <thead>
            <tr data-height="100" height="100">
              <th
                colSpan="6"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.CompanyLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
            </tr>

            <tr>
              <td
                colSpan="6"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                QUALIFIED LAB DOCUMENTAION{" "}
              </td>
            </tr>
            <tr height="40" data-height="35">
              <td
                align="left"
                colSpan={6}
                style={{
                  border: "1px solid black",
                }}
                data-font-sz="11"
              >
                <span style={{ fontWeight: "bold" }}>Name of laboratory:</span>{" "}
                {ppapData.name_of_lab}
              </td>
            </tr>
            <tr data-height="100">
              <td
                align="left"
                colSpan={6}
                style={{
                  border: "1px solid black",
                }}
                data-font-sz="11"
              >
                Function:- The laboratory is equipped advanced testing
                instrument and equipments ,can test joint effect of blind
                rivets,wire tensile strength ,Mandrel break load ,blind rivet
                tensile strength ,and shear strength , rivet body hardness ,
                rivet body and manderl dimension and so on.
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="11"
              >
                S NO.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="11"
              >
                NAME OF INST. & EQUIPMENT
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="11"
              >
                ITEM NO
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="11"
              >
                BRAND NAME
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="11"
              >
                FUNCTION
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="11"
              >
                REMARKS
              </th>
            </tr>
            {ppapData.qualified_labs.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="11"
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="11"
                >
                  {item.name_of_inst}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="11"
                >
                  {item.item_no}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="11"
                >
                  {item.brand_name}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="11"
                >
                  {item.function}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="11"
                >
                  {item.remarks}
                </td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td
              colSpan={6}
              style={{
                border: "1px solid black",
              }}
              data-font-sz="11"
            >
              Lab appointed operator: <span>{ppapData.loa}</span>
            </td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default QualifiedLabReport;
