import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import QualifiedLabReport from "./QualifiedLabReport";
import DimensionReport from "./DimensionReport";
import MaterialPerformance from "./MaterialPerformance";
import PFMEA from "./PFMEA/PFMEA";
import CheckingAids from "./CheckingAids";
import ApprovedSupplier from "./ApprovedSupplier";
import PackingStandard from "./PackingStandard";
import WarrantReport from "./WarrantReport";
import DesignRecordReport from "./DesignRecordReport";
import InspectionStandard from "./InspectionStandard";
import ControlPlanTableSheet from "../../../Pages/AddEditDuplicatePart/quickView/partPlanQuickView/ControlPlanTableSheet";
import PfdTableSheet from "../../../Pages/AddEditDuplicatePart/quickView/partPlanQuickView/PfdTableSheet";
import AAR from "./AAR";
import PerformanceTest from "./PerformanceTest";

const ViewPrintout = () => {
  const { state } = useLocation();
  const { isEqual, ppapData, partId } = state;
  const [data, setData] = useState(null);
  const [pirData, setPirData] = useState(null);
  useEffect(() => {
    if (isEqual === "process_flow_diagram") {
      axios
        .get(`/api/get/pfd/${ppapData.pfd_id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
    if (isEqual === "pfmea") {
      axios
        .get(`/api/get/fmea/${ppapData.fmea_id}`)
        .then((res) => {
          if (res.data.status === "failed") {
            return setPirData(null);
          }
          setPirData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (isEqual === "control_plan") {
      axios
        .get(`/api/get/controlPlanPart/${partId}/${ppapData.control_id}`)
        .then((res) => {
          setData(res.data);
        });
    }
  }, []);

  // console.log(state);
  // console.log(data);
  return (
    <main>
      {isEqual === "design_record" && <DesignRecordReport {...{ ppapData }} />}
      {isEqual === "process_flow_diagram" && data && (
        <PfdTableSheet data={data} pfdId={ppapData.pfd_id} />
      )}
      {isEqual === "pfmea" && pirData && <PFMEA {...{ pirData }} />}

      {isEqual === "dimension_report" && <DimensionReport {...{ ppapData }} />}
      {isEqual === "material_performance" && (
        <MaterialPerformance {...{ ppapData }} />
      )}
      {isEqual === "qualified_lab" && <QualifiedLabReport {...{ ppapData }} />}
      {isEqual === "control_plan" && data && (
        <ControlPlanTableSheet
          Data={data}
          controlPlanId={ppapData.control_id}
        />
      )}
      {isEqual === "checking_aid" && <CheckingAids {...{ ppapData }} />}
      {isEqual === "approved_supplier" && (
        <ApprovedSupplier {...{ ppapData }} />
      )}
      {isEqual === "packing_standard" && <PackingStandard {...{ ppapData }} />}
      {isEqual === "psw" && <WarrantReport {...{ ppapData }} />}
      {isEqual === "inspection_standard" && (
        <InspectionStandard {...{ ppapData }} />
      )}
      {isEqual === "aar" && <AAR {...{ ppapData }} />}
      {isEqual === "performance_test" && <PerformanceTest {...{ ppapData }} />}
    </main>
  );
};

export default ViewPrintout;
