import Input from "../../Control/Input";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Wrapper } from "../../Control/Flexbox";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ExpandLess, ExpandMore, Remove, Add } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { Checkbox, TextField } from "@material-ui/core";

const AAR = (props) => {
  const { ppapData } = props;
  const [render, setRender] = useState(false);
  const [isSpecs, setIsSpecs] = useState([]);
  function handleSpec(e, index) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ppapData.aars[index][e.target.name] = value;
  }
  function handleAAR(e, index) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ppapData[e.target.name] = value;
  }
  function append(where, data) {
    ppapData[where].push(data);
    setRender((prev) => !prev);
  }

  function remove(where, index) {
    if (window.confirm("Are you sure you want to delete Dimension?")) {
      ppapData[where].splice(index, 1);
      setRender((prev) => !prev);
    }
  }

  const hideSpecs = (index) => {
    setIsSpecs((prev) => [...prev, index]);
  };

  const openSpecs = (index) => {
    const values = [...isSpecs];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsSpecs(values);
  };

  return (
    <div className="specs-container" style={{ marginBottom: "3rem" }}>
      <Wrapper>
        <Input
          type="text"
          name="supplier_name"
          placeholder="Supplier Name"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.supplier_name}
        />
        <Input
          type="text"
          name="customer_name"
          placeholder="Customer Name"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData?.part_id?.customer_name}
        />
        <Input
          type="text"
          name="part_name"
          placeholder="Part Name"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData?.part_id?.part_name}
          disabled
        />
        <Input
          type="text"
          name="part_number"
          placeholder="Part Number"
          disabled
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData?.part_id?.part_number}
        />
        <div>
          <DatePicker
            value={ppapData.aar_date || null}
            label="Date"
            className="date-field"
            inputVariant="filled"
            format="dd/MM/yyyy"
            onChange={(date) => {
              ppapData.aar_date = date;
              setRender((prev) => !prev);
            }}
          />
        </div>
      </Wrapper>
      <Wrapper>
        <Input
          type="text"
          name="raw_material"
          placeholder="Raw Material"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.raw_material}
        />
        <Input
          type="text"
          name="sample_size"
          placeholder="Sample Size"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.sample_size}
        />
        <Input
          type="text"
          name="drawing_no"
          placeholder="Drawing Number"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.drawing_no}
        />
        <Input
          type="text"
          name="supplier_code"
          placeholder="Supplier Code"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.supplier_code}
        />
        <Input
          type="text"
          name="eng_level"
          placeholder="Eng. Level"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.eng_level}
        />
      </Wrapper>
      <Wrapper style={{ gap: "2rem" }}>
        <div>
          <Checkbox
            defaultChecked={ppapData.aar_psw}
            name="aar_psw"
            color="primary"
            onChange={handleAAR}
          />
          Part Submission Warrant
        </div>

        <div>
          <Checkbox
            defaultChecked={ppapData.aar_ss}
            name="aar_ss"
            color="primary"
            onChange={handleAAR}
          />
          Special Sample
        </div>
        <div>
          <Checkbox
            defaultChecked={ppapData.aar_resub}
            name="aar_resub"
            color="primary"
            onChange={handleAAR}
          />
          Resubmission
        </div>
        <div>
          <Checkbox
            defaultChecked={ppapData.aar_ec}
            name="aar_ec"
            color="primary"
            onChange={handleAAR}
          />
          Engineering Change
        </div>
        <div>
          <Checkbox
            defaultChecked={ppapData.aar_fpc}
            name="aar_fpc"
            color="primary"
            onChange={handleAAR}
          />
          Production Shipment
        </div>
      </Wrapper>
      <Wrapper>
        <Input
          type="text"
          name="aar_prepared"
          placeholder="Prepared By"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.aar_prepared}
        />
        <Input
          type="text"
          name="aar_approved"
          placeholder="Approved By"
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.aar_approved}
        />
        <TextField
          type="text"
          variant="filled"
          name="aar_comment"
          multiline
          className="textarea-field"
          label="Comment"
          style={{
            width: "98.8%",
            margin: "1rem 0.7rem",
          }}
          onBlur={(e) => handleAAR(e)}
          defaultValue={ppapData.aar_comment}
        />
      </Wrapper>
      <div>
        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD <span style={{ fontWeight: 400, color: "black" }}>AAR</span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#c80202",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                append("aars", {
                  s_no: ppapData?.aars.length + 1,
                });
              }}
              justify="center"
            >
              <Add />
            </Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
              }}
              justify="center"
              onClick={() => {
                ppapData?.aars?.sort((a, b) => Number(a.s_no) - Number(b.s_no));
                setRender((prev) => !prev);
              }}
            >
              <RefreshIcon />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </div>

      {ppapData &&
        ppapData?.aars?.map((spec, index) => (
          <div
            key={uuidv4()}
            style={{
              marginBottom: "0.3rem",
              marginTop: "0.6rem",
              padding: "0rem",
              border: "1px solid #f0f2f0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#fafafa",
                alignItems: "center",
              }}
              className="spec-header"
            >
              <Wrapper>
                <Input
                  type="Number"
                  name="s_no"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.s_no}
                  style={{ width: "80px" }}
                />
                <Wrapper
                  justify="center"
                  style={{
                    marginLeft: "0.4rem",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  {spec.parameter}
                </Wrapper>
              </Wrapper>
              <Wrapper>
                {isSpecs.includes(index) ? (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => openSpecs(index)}
                  >
                    <ExpandLess />
                  </Wrapper>
                ) : (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => hideSpecs(index)}
                  >
                    <ExpandMore />
                  </Wrapper>
                )}
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: "#151a1d",
                    color: "white",
                    cursor: "pointer",
                  }}
                  justify="center"
                  onClick={() => remove("aars", index)}
                >
                  <Remove />
                </Wrapper>
              </Wrapper>
            </div>
            {isSpecs.includes(index) && (
              <div className="specs-field">
                <Wrapper>
                  <Input
                    type="text"
                    name="parameter"
                    placeholder="Parameter"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.parameter}
                  />
                  <Input
                    type="text"
                    name="specification"
                    placeholder="Specification"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.specification}
                  />
                  <Input
                    type="text"
                    name="inspection"
                    placeholder="Inspection"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.inspection}
                  />
                </Wrapper>
                <Wrapper>
                  <Input
                    type="text"
                    name="obs_1"
                    placeholder="1"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.obs_1}
                  />
                  <Input
                    type="text"
                    name="obs_2"
                    placeholder="2"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.obs_2}
                  />
                  <Input
                    type="text"
                    name="obs_3"
                    placeholder="3"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.obs_3}
                  />
                  <Input
                    type="text"
                    name="obs_4"
                    placeholder="4"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.obs_4}
                  />
                  <Input
                    type="text"
                    name="obs_5"
                    placeholder="5"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.obs_5}
                  />
                  <Input
                    type="text"
                    name="remarks"
                    placeholder="remarks"
                    onBlur={(e) => handleSpec(e, index)}
                    defaultValue={spec.remarks}
                  />
                </Wrapper>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
export default AAR;
