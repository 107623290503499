import React from "react";
import { useAuth } from "../../context/GlobalContext";
import AdminRm from "./AdminRm";
import SupervisorRm from "./SupervisorRm";

function RmTableList() {
  const { state } = useAuth();
  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        RM INCOMING / BREAKLOAD
      </div>

      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE
          <span className="subheading-secondary"> REPORTS</span>
        </p>
      </div>
      {state.user.userType === 2 ? <AdminRm /> : <SupervisorRm />}
    </>
  );
}

export default RmTableList;
