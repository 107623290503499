import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TableCell, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import { usePermission } from "../../components/usePermission";

const AdminAction = ({ rmId, item, handleDelete }) => {
  const permissions = usePermission(7);

  return (
    <TableCell align="center">
      <Tooltip title="View" arrow>
        {permissions?.includes("view") ? (
          <Link to={`/incomingbreakload/tab/${rmId}/${item._id}?isView=true`}>
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>
      <Tooltip title="Edit" arrow>
        {permissions?.includes("edit") ? (
          <Link to={`/incomingbreakload/tab/${rmId}/${item._id}?isView=false`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <EditIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>
      <Tooltip title="Delete" arrow>
        {permissions?.includes("delete") ? (
          <IconButton
            size="small"
            onClick={() => handleDelete(item._id)}
            style={{ marginLeft: "0.5rem" }}
          >
            <DeleteIcon
              style={{
                fontSize: "1.4rem",
                color: "#c80202",
              }}
            />
          </IconButton>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>
    </TableCell>
  );
};

export default AdminAction;
