import React, { useState, useRef } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import { Link, useParams } from "react-router-dom";
import Input from "../../components/Control/Input";
import { Add, Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import { Wrapper } from "../../components/Control/Flexbox";
import formatDate from "../../components/Control/formatDate";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useAuth } from "../../context/GlobalContext";
import ViewChild from "./ViewChild";
import Modal from "../../components/Control/Modal";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

const allSupervisorActionButton = (featureName, item) => {
  switch (featureName) {
    case "4m": {
      return (
        <Tooltip title="Create" arrow>
          <Link
            to={`/create/request_form/${item._id}?partnumber=${item.part_number}`}
          >
            <IconButton size="small">
              <Add
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
    case "controlplan": {
      return (
        <Tooltip title="View" arrow>
          <Link
            to={`/revisedTable/controlplan/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
    case "msa": {
      return (
        <Tooltip title="View" arrow>
          <Link
            to={`/view/msaTable/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
    case "inspection": {
      return (
        <Tooltip title="View" arrow>
          <Link
            to={`/view/incomingTable/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
    case "pcd": {
      return (
        <Tooltip title="View" arrow>
          <Link
            to={`/pcd/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
    case "customerDrawing": {
      return (
        <Tooltip title="View" arrow>
          <Link
            to={`/customerDrawing/table/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
    case "pdir": {
      return (
        <Tooltip title="View" arrow>
          <Link
            to={`/viewpdir/monthly/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
    case "pir": {
      return (
        <>
          <Tooltip title="View" arrow>
            <Link
              to={`/viewSupervisor/pirLists/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
            >
              <IconButton size="small">
                <VisibilityIcon
                  style={{
                    fontSize: "1.4rem",
                    color: "#003566",
                  }}
                />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="PIR in new tab" arrow>
            <Link
              to={`/viewSupervisor/pirLists/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
              target="_blank"
            >
              <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                <OpenInNewIcon
                  style={{
                    fontSize: "1.4rem",
                    color: "#c80202",
                  }}
                />
              </IconButton>
            </Link>
          </Tooltip>
        </>
      );
    }
    case "sop": {
      return (
        <Tooltip title="View" arrow>
          <Link
            to={`/view/sopTable/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
          >
            <IconButton size="small">
              <VisibilityIcon
                style={{
                  fontSize: "1.4rem",
                  color: "#003566",
                }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }
  }
};

const SupervisorPartTable = () => {
  const classes = useStyles();
  const [partData, setPartData] = useState([]);
  const partNumber = useRef(null);
  const [page, setPage] = useState(1);
  const { featureName } = useParams();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("part_number");
  const [perPage, setPerPage] = useState();
  const { state } = useAuth();
  const partId = useRef(null);
  const [isViewChildModal, setIsViewChildModal] = useState(false);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/get/part/publish?page=${page}&sortBy=${filter}&search=${search}`,
        {
          headers: { isFullAdmin: state?.user?.isFullAdmin },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setPartData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, filter]);

  return (
    <TableContainer>
      <Wrapper>
        <div style={{ flex: "0.8" }}>
          <Input
            placeholder="Search Part Number"
            variant="filled"
            style={{
              width: "96%",
              marginLeft: "1.2rem",
              marginTop: "1rem",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginRight: "1.5rem", marginTop: "0.8rem", flex: "0.2" }}
        >
          <InputLabel>Filter</InputLabel>
          <Select
            name="part_type"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={filter}
          >
            <MenuItem value="part_number">A - Z</MenuItem>
            <MenuItem value="category">Category</MenuItem>
            <MenuItem value="createdAt">Most Recent First</MenuItem>
            <MenuItem value="createdAt_old">Oldest First</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Part Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Part Description
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Category
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Created At
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              View Child
            </TableCell>
          </TableRow>
        </TableHead>
        {partData && (
          <TableBody>
            {partData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">
                    {perPage * (page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.part_number}</TableCell>
                  <TableCell align="center">{item.part_name}</TableCell>
                  <TableCell align="center">{item.category}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {allSupervisorActionButton(featureName, item)}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color="white"
                      style={{
                        color: "#fafafa",
                        backgroundColor: "#161A1D",
                      }}
                      onClick={() => {
                        partId.current = item._id;
                        partNumber.current = item.part_number;
                        setIsViewChildModal(true);
                      }}

                      // startIcon={<Update style={{ fontSize: "1.4rem" }} />}
                    >
                      VIEW CHILD
                    </Button>
                    {/* <Tooltip title="View" arrow>
                      <IconButton
                        size="small"
                        style={{ marginLeft: "0.5rem" }}
                        onClick={() => {
                          partId.current = item._id;
                          partNumber.current = item.part_number;
                          setIsViewChildModal(true);
                        }}
                      >
                        <VisibilityIcon
                          style={{ fontSize: "1.4rem", color: "#003566" }}
                        />
                      </IconButton>
                    </Tooltip> */}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
      <Modal
        title={`child list (${partNumber.current})`}
        openPopup={isViewChildModal}
        closeModal={() => setIsViewChildModal(false)}
      >
        <ViewChild
          partId={partId.current}
          {...{ allSupervisorActionButton, featureName }}
        />
      </Modal>
    </TableContainer>
  );
};

export default SupervisorPartTable;
