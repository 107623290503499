import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CoverForm from "./CoverForm";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const prefillQualified = [
  {
    s_no: 1,
    name_of_inst: "Universal testing machine",
    item_no: "WDW -100",
    brand_name: "JPTE",
    function:
      "Wire tensile strength ,mandrel break load, rivet load, rivet tensile and shear strength etc.",
    remarks: "Main equipment",
  },
  {
    s_no: 2,
    name_of_inst: "Hardness tester",
    item_no: "TSMDM",
    brand_name: "BIE",
    function: "To measure hardness of work piece",
    remarks: "Main equipment",
  },
  {
    s_no: 3,
    name_of_inst: "Riveter",
    item_no: "SRC -56",
    brand_name: "SRC",
    function: "Blind rivet joint effect testing",
    remarks: "",
  },
  {
    s_no: 4,
    name_of_inst: "Computer and printer",
    item_no: "-",
    brand_name: "Dell",
    function: "Composing test and data analysis",
    remarks: "",
  },
  {
    s_no: 5,
    name_of_inst: "Air compressor",
    item_no: "-",
    brand_name: "ELGI",
    function: "Supply compressed air for air riveter",
    remarks: "",
  },
  {
    s_no: 6,
    name_of_inst: "Vernier caliper",
    item_no: "0-150 mm",
    brand_name: "Mitutoyo",
    function: "Length measurement",
    remarks: "",
  },
  {
    s_no: 7,
    name_of_inst: "Micrometer",
    item_no: "0 - 25 mm",
    brand_name: "Mitutoyo",
    function: "Length measurement",
    remarks: "",
  },
];

const prefillAar = [
  {
    s_no: 1,
    parameter: "Free from Crack",
    specification: "No Cracks",
    inspection: "Visual",
  },
  {
    s_no: 2,
    parameter: "Free from Dent",
    specification: "No Dent",
    inspection: "Visual",
  },
  {
    s_no: 3,
    parameter: "Free from Rust",
    specification: "No Rust",
    inspection: "Visual",
  },
  {
    s_no: 4,
    parameter: "Free from Pitted",
    specification: "No Pitting",
    inspection: "Visual",
  },
  {
    s_no: 5,
    parameter: "Free from Scratch",
    specification: "No Scratch",
    inspection: "Visual",
  },
  {
    s_no: 6,
    parameter: "Free from Black Spot",
    specification: "No Balck Spot",
    inspection: "Visual",
  },
  {
    s_no: 7,
    parameter: "Free from Color Mismatch",
    specification: "No color mismatch w.r.t RAL Book",
    inspection: "Visual",
  },
  {
    s_no: 8,
    parameter: "Free from Burr",
    specification: "No Burr",
    inspection: "Visual",
  },
];

const prefillAids = [
  {
    s_no: 1,
    checking_aid: "Vernier Caliper Digital & Manual",
    range: "0-150 mm",
    least_count: "0.01",
    cali_freq: "Annually",
    remarks: "",
  },
  {
    s_no: 2,
    checking_aid: "Micrometer Manual",
    range: "0-25 mm",
    least_count: "0.01",
    cali_freq: "Annually",
    remarks: "",
  },
  {
    s_no: 3,
    checking_aid: "Micrometer -Digital",
    range: "0 - 25 mm",
    least_count: "0.01",
    cali_freq: "Annually",
    remarks: "",
  },
  {
    s_no: 4,
    checking_aid: "Dial Gauge",
    range: "0 - 10 mm",
    least_count: "0.01",
    cali_freq: "Annually",
    remarks: "",
  },
  {
    s_no: 5,
    checking_aid: "Universal Testing Machine Digital",
    range: "10 Ton",
    least_count: "2kg",
    cali_freq: "Annually",
    remarks: "",
  },
  {
    s_no: 6,
    checking_aid: "SST Chamber",
    range: "-",
    least_count: "-",
    cali_freq: "Annually",
    remarks: "",
  },
  {
    s_no: 7,
    checking_aid: "Pin Gauge",
    range: "1.0 To 5.0 mm",
    least_count: "0.01",
    cali_freq: "Annually",
    remarks: "",
  },
  {
    s_no: 8,
    checking_aid: "Riveting Guns",
    range: "-",
    least_count: "-",
    cali_freq: "-",
    remarks: "",
  },
];

function GeneratePPAP() {
  const { partId } = useParams();
  const [ppapData, setPpapData] = useState({ part_id: partId });
  const history = useHistory();
  const handeSubmit = () => {
    axios
      .post("/api/add/ppap", ppapData)
      .then((res) => {
        if (res.data?.status === "success") {
          history.push(`/select/ppap/${partId}`);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    ppapData.qualified_labs = [...prefillQualified];
    ppapData.aars = [...prefillAar];
    ppapData.checking_aids = [...prefillAids];
  }, []);
  return (
    <main style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PRODUCTION PART APPROVAL PROCESS
        <div style={{ marginLeft: "0.7rem" }}>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={handeSubmit}
            style={{ color: "#e9e9e9", backgroundColor: "#003566" }}
            startIcon={<SaveIcon />}
          >
            Save & Continue
          </Button>
        </div>
      </div>
      <section style={{ padding: "3rem 1rem" }}>
        <CoverForm {...{ handeSubmit, setPpapData, ppapData }} />
      </section>
    </main>
  );
}

export default GeneratePPAP;
