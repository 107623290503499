import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

const CreateSetupHead = ({ setupData, setSetupData }) => {
  const handleChangeSetup = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    setupData[target.name] = value;
  };
  return (
    <section>
      <Wrapper style={{ flexWrap: "wrap" }}>
        <Input
          type="text"
          name="new_part"
          placeholder="New Part"
          onBlur={handleChangeSetup}
          defaultValue={setupData.new_part}
        />
        <Input
          type="text"
          name="ecn_part"
          placeholder="ECN Part"
          onBlur={handleChangeSetup}
          defaultValue={setupData.ecn_part}
        />
        <Input
          type="text"
          name="pcn_part"
          placeholder="PCN Part"
          onBlur={handleChangeSetup}
          defaultValue={setupData.pcn_part}
        />
        <Input
          type="text"
          name="ipc_part"
          placeholder="IPC Part"
          onBlur={handleChangeSetup}
          defaultValue={setupData.ipc_part}
        />
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginLeft: "0.5rem", marginTop: "0.6rem" }}
        >
          <InputLabel>Select Type</InputLabel>
          <Select
            name="setup_type"
            style={{ width: "200px" }}
            defaultValue={setupData.setup_type}
            onChange={handleChangeSetup}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="regular">Regular Production</MenuItem>
            <MenuItem value="4m">4M Change</MenuItem>
            <MenuItem value="abnormality">Abnormality</MenuItem>
          </Select>
        </FormControl>
        <Input
          type="text"
          name="po_no"
          placeholder="P.O NO"
          onBlur={handleChangeSetup}
          defaultValue={setupData.po_no}
        />
        <main
          className="add_to_pir"
          style={{
            backgroundColor: "#fafafa",
            padding: "0.7rem 0",
            marginLeft: "0.5rem",
            width: "195px",
          }}
        >
          <Checkbox
            name="setup_approval"
            defaultChecked={setupData.setup_approval}
            onChange={handleChangeSetup}
            color="primary"
          />
          Setup Approval
        </main>
        <main
          className="add_to_pir"
          style={{
            backgroundColor: "#fafafa",
            padding: "0.7rem 0",
            marginLeft: "0.8rem",
            width: "195px",
          }}
        >
          <Checkbox
            name="retro_insp"
            defaultChecked={setupData.retro_insp}
            onChange={handleChangeSetup}
            color="primary"
          />
          Retroactive Insp.
        </main>
        <main
          className="add_to_pir"
          style={{
            backgroundColor: "#fafafa",
            padding: "0.7rem 0",
            marginLeft: "0.9rem",
            width: "195px",
          }}
        >
          <Checkbox
            name="containment_insp"
            defaultChecked={setupData.containment_insp}
            onChange={handleChangeSetup}
            color="primary"
          />
          Containment Insp.
        </main>
        <main
          className="add_to_pir"
          style={{
            backgroundColor: "#fafafa",
            padding: "0.7rem 0",
            marginLeft: "1rem",
            width: "195px",
          }}
        >
          <Checkbox
            name="other_setup"
            defaultChecked={setupData.other_setup}
            onChange={handleChangeSetup}
            color="primary"
          />
          Other
        </main>
        <DatePicker
          inputVariant="filled"
          name="setup_date"
          style={{ paddingLeft: "0.6rem" }}
          label="Setup Date"
          value={setupData.setup_date || null}
          onChange={(date) =>
            setSetupData((prev) => ({
              ...prev,
              setup_date: date,
            }))
          }
          format="dd/MM/yyyy"
          className="date-field"
        />
        <Input
          type="text"
          name="item_no"
          placeholder="Item No"
          onBlur={handleChangeSetup}
          disabled={true}
          defaultValue={setupData.part_name}
        />
        <Input
          type="text"
          name="mrn"
          placeholder="MRN"
          onBlur={handleChangeSetup}
          defaultValue={setupData.mrn}
        />
        <Input
          type="text"
          placeholder="Machine ID & Name"
          name="machine_name"
          disabled={true}
          onBlur={handleChangeSetup}
          defaultValue={setupData.machine_name}
        />
        <Input
          type="text"
          placeholder="Item Description"
          name="part_number"
          disabled
          onBlur={handleChangeSetup}
          defaultValue={setupData.part_number}
        />
        <Input
          type="text"
          placeholder="Shift"
          name="setup_shift"
          onBlur={handleChangeSetup}
          defaultValue={setupData.setup_shift}
        />
        <Input
          type="text"
          placeholder="Customer Name"
          name="customer_name"
          onBlur={handleChangeSetup}
          defaultValue={setupData.customer_name}
        />

        <Input
          type="text"
          placeholder="Tool No & Name"
          name="tool_no_name"
          onBlur={handleChangeSetup}
          defaultValue={setupData.tool_no_name}
        />

        <Input
          type="text"
          placeholder="Time"
          name="setup_time"
          onBlur={handleChangeSetup}
          defaultValue={setupData.setup_time}
        />

        <Input
          type="text"
          placeholder="Operation No. & Name"
          name="oper_no"
          onBlur={handleChangeSetup}
          defaultValue={setupData.oper_no}
        />
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginLeft: "0.5rem", marginTop: "0.6rem" }}
        >
          <InputLabel>Type of Change</InputLabel>
          <Select
            name="type_change"
            style={{ width: "200px" }}
            defaultValue={setupData.type_change}
            onChange={handleChangeSetup}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="man">Man</MenuItem>
            <MenuItem value="machine">Machine</MenuItem>
            <MenuItem value="material">Material</MenuItem>
            <MenuItem value="method">Method</MenuItem>
          </Select>
        </FormControl>

        <Input
          type="text"
          placeholder="Sample Size"
          name="sample_size"
          onBlur={handleChangeSetup}
          defaultValue={setupData.sample_size}
        />
        <Input
          type="text"
          placeholder="Job Setup"
          name="job_setup"
          onBlur={handleChangeSetup}
          defaultValue={setupData.job_setup}
        />
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginLeft: "0.5rem", marginTop: "0.6rem" }}
        >
          <InputLabel>Category of Change</InputLabel>
          <Select
            name="category_change"
            style={{ width: "200px" }}
            defaultValue={setupData.category_change}
            onChange={handleChangeSetup}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="planned">Planned</MenuItem>
            <MenuItem value="unplanned">Unplanned</MenuItem>
          </Select>
        </FormControl>
        <Input
          type="text"
          placeholder="Retroactive & Containment"
          name="retroactive"
          onBlur={handleChangeSetup}
          defaultValue={setupData.retroactive}
        />
        {/* <Input
          type="text"
          placeholder="Total Produced Qty."
          name="total_produced_qty"
          onBlur={handleChangeSetup}
          defaultValue={setupData.total_produced_qty}
        />
        <Input
          type="text"
          placeholder="Total OK Qty."
          name="total_ok_qty"
          onBlur={handleChangeSetup}
          defaultValue={setupData.total_ok_qty}
        /> */}
        <Input
          type="text"
          placeholder="Total Retroactive Qty."
          name="total_retro_qty"
          onBlur={handleChangeSetup}
          defaultValue={setupData.total_retro_qty}
        />
        <Input
          type="text"
          placeholder="Containment Qty."
          name="containment_qty"
          onBlur={handleChangeSetup}
          defaultValue={setupData.containment_qty}
        />
        <Input
          type="text"
          placeholder="Rejection Qty."
          name="rejection_qty"
          onBlur={handleChangeSetup}
          defaultValue={setupData.rejection_qty}
        />
        <Input
          type="text"
          placeholder="Total Rework Qty."
          name="total_rework_qty"
          onBlur={handleChangeSetup}
          defaultValue={setupData.total_rework_qty}
        />
      </Wrapper>
      <TextField
        type="text"
        variant="filled"
        name="notes"
        multiline
        className="textarea-field"
        label="Notes"
        style={{
          width: "98.8%",
          margin: "1rem 0.7rem",
        }}
        onBlur={handleChangeSetup}
        defaultValue={setupData.notes}
      />
    </section>
  );
};

export default CreateSetupHead;
