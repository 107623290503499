import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../context/GlobalContext";
import formatDate from "../../../components/Control/formatDate";
import { Wrapper } from "../../../components/Control/Flexbox";
import "../../../style/controlPlan.css";
import Loader from "../../../components/Control/Loader";
import Pagination from "../../../components/Control/Pagination";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import Styled from "styled-components";
import PrintIcon from "@material-ui/icons/Print";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
  margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0
        }
        b{
		    font-family:"Roboto",sans-serif;
          margin:0,
        }
    }
`;

export default function ControlPlanTableSheet({ Data, controlPlanId }) {
  const { state } = useAuth();
  // console.log(Data, "control");

  return (
    <>
      {Data ? (
        <SpanningTable ControlPlanData={Data} controlPlanId={controlPlanId} />
      ) : (
        <Loader />
      )}
    </>
  );
}

function SpanningTable({ ControlPlanData, controlPlanId }) {
  const controlData = ControlPlanData;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [processesPerPage] = useState(3);
  // Get current processes
  const indexOfLastProcess = currentPage * processesPerPage;
  const indexOfFirstProcess = indexOfLastProcess - processesPerPage;
  const currentProcesses = controlData?.processes?.slice(
    indexOfFirstProcess,
    indexOfLastProcess,
  );
  const lastpage = Math.ceil(controlData?.processes?.length / processesPerPage);
  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          CONTROL PLAN
        </Typography>
      </div>
      <div
        className="card_table"
        style={{ padding: "0.6rem", overflowX: "scroll" }}
      >
        {controlData && (
          <Table cellSpacing="0" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  colSpan="12"
                  rowSpan="4"
                  // align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images?.CompanyLogo}
                      alt={textInputs?.companyName}
                      height="46"
                      width="120"
                    />
                  </CenteredLogoImg>
                  <span>{textInputs?.companyName}</span>
                </th>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  DOCUMENT NO:
                </th>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  SLF CP 01
                </th>
              </tr>
              <tr>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  ISSUE DATE:
                </th>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  01/04/2019
                </th>
              </tr>
              <tr>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  REVISION NO:
                </th>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  02
                </th>
              </tr>
              <tr>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  REVISION DATE:
                </th>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                >
                  03/10/2023
                </th>
              </tr>
              <tr>
                <td
                  colSpan={16}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                  }}
                >
                  CONTROL PLAN
                </td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Control Plan No: {controlData.control_plan_no}
                </td>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Supplier / Plant Approval Date:
                  {formatDate(controlData.date_review)}
                </td>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Date (Origin):
                  {formatDate(controlData?.date_orig)}{" "}
                </td>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Control Plan Criteria:
                </td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Item Description:
                  {controlData.part_id && controlData.part_id.part_name}
                </td>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Customer Engineering Approval Date (If Required):
                  {formatDate(controlData?.supplier_approval_date)}
                </td>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Rev. Date:
                  {formatDate(controlData.date_review)}
                </td>
                <td
                  colSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Prototype
                </td>
                {controlData.part_type === "prototype" ? (
                  <td
                    colspan="2"
                    style={{
                      border: "1px solid black",
                    }}
                    align="left"
                  >
                    <span
                      style={{
                        fontSize: "2.5rem",
                      }}
                    >
                      ☑
                    </span>
                  </td>
                ) : (
                  <td
                    colspan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                  >
                    <span
                      style={{
                        fontSize: "2.5rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      □
                    </span>
                  </td>
                )}
              </tr>
              <tr>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Item Code:
                  {controlData.part_id && controlData.part_id.part_number}
                </td>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Key Contact/Phone:
                  {controlData?.key_contact}
                </td>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Rev. No.:
                </td>
                <td
                  colSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Pre-launch
                </td>
                {controlData.part_type === "pre-Launch" ? (
                  <td
                    colspan="2"
                    style={{
                      border: "1px solid black",
                    }}
                    align="left"
                  >
                    <span
                      style={{
                        fontSize: "2.5rem",
                      }}
                    >
                      ☑
                    </span>
                  </td>
                ) : (
                  <td
                    colspan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                  >
                    <span
                      style={{
                        fontSize: "2.5rem",
                      }}
                    >
                      □
                    </span>
                  </td>
                )}
              </tr>
              <tr>
                <td
                  colSpan={4}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Customer:
                  {controlData?.customer}
                </td>
                <td
                  colSpan={8}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Core Team:
                  {controlData?.core_team}
                </td>
                <td
                  colSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Production
                </td>

                {controlData.part_type === "production" ? (
                  <td
                    colspan="2"
                    align="left"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "2.5rem",
                      }}
                    >
                      ☑
                    </span>
                  </td>
                ) : (
                  <td
                    colspan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                  >
                    <span
                      style={{
                        fontSize: "2.5rem",
                      }}
                    >
                      □
                    </span>
                  </td>
                )}
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontWeight: "bold" }} height="50px">
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "50px",
                    minWidth: "50px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  OPERATION NO.
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PROCESS NAME/ OPERATION DESCRIPTION
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  MACHINE, DEVICE, JIG, TOOLS FOR Mfg.
                </td>
                <td
                  colSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  CHARACTERISTICS
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  SPECIAL CHARACTERISTICS
                </td>
                <td
                  colSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  METHODS
                </td>
                <td
                  align="center"
                  rowSpan="4"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  RECORDING YES / NO
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  RESPONSIBILITY
                </td>
                <td
                  align="center"
                  rowSpan="4"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  CONTROL METHOD (FORMAT NO & DESCRIPTION)
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  REACTION PLAN INCLUDING CORRECTIVE ACTION
                </td>
                <td
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  REMARKS
                </td>
              </tr>
              <tr style={{ fontWeight: "bold" }} height="50px">
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  NO.
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PRODUCT
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PROCESS
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  PRODUCT / PROCESS SPECIFICATION TOLERENCES
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                    transform: "rotate(-90deg)",
                  }}
                >
                  EVALUATION MEASUREMENT TECHNIQUE
                </td>

                <td
                  colSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  SAMPLE
                </td>
                <td
                  rowSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  OPERATOR /<br />
                  INSPECTOR / <br />
                  INCHARGE / <br />
                  SUPERVISOR / <br />
                </td>
              </tr>
              <tr style={{ fontWeight: "bold" }} height="50px">
                <td
                  align="center"
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                    width: "80px",
                    minWidth: "80px",
                  }}
                >
                  SIZE
                </td>
                <td
                  align="center"
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                    width: "80px",
                    minWidth: "80px",
                  }}
                >
                  FREQUENCY
                </td>
              </tr>
              <tr style={{ height: "40px" }}></tr>
              {currentProcesses.map((process, index) => (
                <React.Fragment key={index}>
                  {process.specs.map((spec, indx) => (
                    <React.Fragment key={indx}>
                      <tr>
                        {indx === 0 && (
                          <>
                            <td
                              rowSpan={process.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                            >
                              {process.process_no}
                            </td>
                            <td
                              rowSpan={process.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                            >
                              {process.process_name}
                            </td>
                          </>
                        )}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.machine}
                          {spec.machine && spec.tool_no ? "/" : ""}
                          {spec.tool_no}
                        </td>

                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {indx + 1}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.char_product}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.char_process}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.special_character}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.control_product}
                          {spec.control_process && spec.control_product
                            ? "/"
                            : ""}
                          {spec.control_process}
                          {/* <span style={{ display: "block", color: "red" }}> */}
                          {spec.control_product_remark &&
                            `* ${spec.control_product_remark}`}
                          {/* </span> */}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_measure}
                        </td>
                        {/* <td align="center" style={{border:'1px solid black'}}>{spec.error_proofing}</td> */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_size}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_by}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.recording}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.responsibility}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.methods_process}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.reaction_plan}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec.remarks}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
            {controlData.revisionData[0] && (
              <tbody>
                <tr style={{ fontWeight: "bold" }}>
                  <td
                    colSpan="2"
                    style={{ border: "1px solid black" }}
                    align="center"
                    rowSpan="2"
                  >
                    Revision No.
                  </td>
                  <td
                    colSpan="2"
                    style={{ border: "1px solid black" }}
                    align="center"
                    rowSpan="2"
                  >
                    Revision Date
                  </td>
                  <td
                    colSpan="8"
                    style={{ border: "1px solid black" }}
                    align="center"
                    rowSpan="2"
                  >
                    Revision Summary
                  </td>
                  <td
                    colSpan="4"
                    style={{ border: "1px solid black" }}
                    align="center"
                    rowSpan="2"
                  >
                    Revision Reason
                  </td>
                </tr>
                <tr style={{ height: "60px" }}></tr>
                {controlData.revisionData.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td
                          colSpan="2"
                          style={{ border: "1px solid black" }}
                          align="center"
                        >
                          {item.rev_no}
                        </td>
                        <td
                          colSpan="2"
                          style={{ border: "1px solid black" }}
                          align="center"
                        >
                          {formatDate(item.rev_date)}
                        </td>
                        <td
                          colSpan="8"
                          style={{ border: "1px solid black" }}
                          align="center"
                        >
                          {item.summary}
                        </td>
                        <td
                          colSpan="4"
                          style={{ border: "1px solid black" }}
                          align="center"
                        >
                          {item.reason}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            )}
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td
                  colSpan="2"
                  rowSpan="2"
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Legends:
                </td>
                <td
                  colSpan="4"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  <span>△</span> = Poka Yoka
                </td>
                <td
                  colSpan="5"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  <span>n</span> = Sample Size
                </td>
                <td
                  colSpan="5"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  <span>c</span> = Rejection No.
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
            </tbody>
          </Table>
        )}
      </div>

      <Pagination
        page={currentPage}
        processesPerPage={processesPerPage}
        totalProcesses={controlData?.processes.length}
        paginate={(number) => paginate(number)}
      />
    </>
  );
}
