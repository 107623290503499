import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import Styled from "styled-components";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;

const ViewDailyProductionTable = () => {
  const [productionPlanningData, setProductionPlanningData] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/getOneProductionPlanning/${id}`)
      .then((res) => {
        setProductionPlanningData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section>
      <div style={{ paddingBottom: "4rem" }}>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
          >
            Production Planning & Control Report
          </Typography>
        </div>
        <div
          style={{
            margin: "0.1rem 0.5rem",
            paddingBottom: "4rem",
            overflowX: "scroll",
          }}
        >
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 5, 7, 18, 5, 18, 18, 5, 5, 15, 15, 15"
          >
            <thead>
              <tr data-height="80" style={{ height: "70px" }}>
                <th
                  colSpan="6"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.CompanyLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {/* DOC NO:ARC/F/PPC/01 */}
                </th>
              </tr>

              <tr>
                <th
                  colSpan="6"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  Production Planning & Control Report
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  REV.NO/DATE-02/07/2019
                </th>
              </tr>
              <tr>
                <th
                  colSpan={5}
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  Date:-{" "}
                  {formatDate(productionPlanningData?.productionPlanning_date)}
                </th>
                <th style={{ border: "1px solid black" }}>Shift</th>
                <th style={{ border: "1px solid black" }}>
                  {productionPlanningData?.shift}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "S.NO",
                  "Machine.no",
                  "Part.no",
                  "Production Plan",
                  "Actual Qty",
                  "Heat.No",
                  "REMARK",
                ].map((heading, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </tbody>
            {productionPlanningData &&
              productionPlanningData.processes.map((item, index) => (
                <tr key={item._id || index}>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.sr_no}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.machine_no}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.part_no}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.production_plan}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.actual_qty}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.heat_no}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.remark}
                  </td>
                </tr>
              ))}
          </Table>
        </div>
      </div>
    </section>
  );
};

export default ViewDailyProductionTable;
