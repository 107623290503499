import { useAuth } from "../context/GlobalContext";

export const usePermission = (id) => {
    const { state } = useAuth();
    let permissions = [];
    const accessIndex = state.user?.access?.findIndex((obj) => obj.id === id);
    if (accessIndex !== -1) {
        permissions = state.user?.access[accessIndex].permissions;
    }
    return permissions;
};
