import React from "react";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../Control/formatDate";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import TableToExcel from "@dayalk/table-to-excel";
import { images, textInputs } from "../../../constants/constants";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const PerformanceTest = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PERFORMANCE TEST RESULTS
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "Performance Test Result.xlsx",
                      sheet: {
                        name: "Product performance",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="10, 25, 25, 25, 35, 15"
        >
          <tr data-height="100" height="100">
            <th
              colSpan="6"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
              data-a-v="middle"
            >
              <CenteredLogoImg>
                <img src={images.CompanyLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
          </tr>

          <tr>
            <td
              colSpan="6"
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              PERFORMANCE TEST RESULTS
            </td>
          </tr>

          <tr>
            <td
              align="left"
              colSpan={4}
              style={{
                border: "1px solid black",
              }}
            >
              Date: {formatDate(ppapData.perf_date)}
            </td>
            <td
              align="left"
              colSpan={2}
              style={{
                border: "1px solid black",
              }}
            >
              Page: 1 of 1
            </td>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={4}
              style={{
                border: "1px solid black",
              }}
            >
              Supplier: {ppapData?.part_id?.supplier_name}
            </td>
            <td
              align="left"
              colSpan={2}
              style={{
                border: "1px solid black",
              }}
            >
              Part No: {ppapData?.part_id?.part_number}
            </td>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={4}
              style={{
                border: "1px solid black",
              }}
            >
              Lab: {ppapData.perf_lab}
            </td>
            <td
              align="left"
              colSpan={2}
              style={{
                border: "1px solid black",
              }}
            >
              Part Name: {ppapData?.part_id?.part_name}
            </td>
          </tr>
          <tbody>
            <tr>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                S NO.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                REQUIREMENT
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                TEST FREQ.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                TEST QTY.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  width: "25%",
                }}
              >
                SUPLIER TEST RESULTS AND TEST CONDITIONS
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  width: "10%",
                }}
              >
                RESULTS
              </th>
            </tr>
            {ppapData.performances.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.requ}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.test_freq}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.test_qty}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.test_cond}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.remarks}
                </td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td
              colSpan={6}
              style={{
                border: "1px solid black",
              }}
            >
              Signature: <span>{ppapData.perf_sign}</span>
            </td>
          </tr>
          <tr>
            <td
              colSpan={6}
              style={{
                border: "1px solid black",
              }}
            >
              Date: <span>{formatDate(ppapData.perf_approval_date)}</span>
            </td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default PerformanceTest;
