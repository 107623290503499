import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import useConfirm from "../../../components/Control/useConfirm";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { toSuperScript } from "../../../components/Control/toSuperScript";

const RMSpecs = ({
  spec,
  index,
  rmData,
  setExpandState,
  setRmData,
  expandState,
}) => {
  const [DialogDeleteSpec, confirmDeleteSpec] = useConfirm(
    "DELETE SPECIFICATION",
    "Are you sure you want to delete this specifications?"
  );

  async function removeSpec(index) {
    const isDelete = await confirmDeleteSpec();
    if (isDelete) {
      const values = { ...rmData };
      values.specs.splice(index, 1);
      setRmData(values);
      setExpandState((prev) => {
        prev[index] = false;
        return [...prev];
      });
    }
  }

  const handleAppearance = (e, index) => {
    const values = { ...rmData };
    values.specs[index].appearance = e.target.name;
    setRmData(values);
  };

  const rmSpecHandler = (e, index) => {
    const values = { ...rmData };
    let value = e.target.value;
    if (e.target.name === "unit") {
      console.log(toSuperScript(value));
      value = toSuperScript(value);
    }
    values.specs[index][e.target.name] = value;
    if (e.target.name === "s_no") {
      values.specs.sort((a, b) => Number(a.s_no) - Number(b.s_no));
    }
    setRmData(values);
  };
  return (
    <section>
      <div
        style={{
          padding: "0rem",
          margin: "1rem",
          border: "1px solid #f0f2f0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fafafa",
            alignItems: "center",
          }}
          className="spec-header"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3rem",
            }}
          >
            <Wrapper>
              <Input
                type="Number"
                name="s_no"
                key={spec.s_no}
                onBlur={(e) => rmSpecHandler(e, index)}
                defaultValue={spec.s_no}
                style={{ width: "80px" }}
              />
            </Wrapper>

            <div
              style={{
                fontSize: "1rem",
                width: "150px",
              }}
            >
              {spec?.characteristic}
            </div>
          </div>
          <Wrapper>
            {expandState[index] ? (
              <Wrapper
                style={{
                  height: "45px",
                  width: "130px",
                  backgroundColor: "#003566",
                  cursor: "pointer",
                  color: "white",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
                onClick={() =>
                  setExpandState((prev) => {
                    prev[index] = false;
                    return [...prev];
                  })
                }
              >
                <ExpandLess style={{ marginLeft: "0.5rem" }} />
                <span>Collapse</span>
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  height: "45px",
                  width: "130px",
                  backgroundColor: "#003566",
                  cursor: "pointer",
                  color: "white",
                  gap: "0.5rem",
                }}
                onClick={() =>
                  setExpandState((prev) => {
                    prev[index] = true;
                    return [...prev];
                  })
                }
              >
                <ExpandMore style={{ marginLeft: "0.5rem" }} />
                <span>Expand</span>
              </Wrapper>
            )}
            <Wrapper
              style={{
                height: "45px",
                width: "45px",
                color: "#f1f3f5",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
                backgroundColor: "#D70012",
              }}
              justify="center"
              onClick={() => removeSpec(index)}
            >
              <DeleteIcon style={{ color: "#f8f9fa" }} />
            </Wrapper>
          </Wrapper>
        </div>
        {expandState[index] && (
          <div style={{ margin: "0.6rem 0rem 0.6rem 0rem" }}>
            <Wrapper style={{ flexWrap: "wrap" }}>
              {/* <Input
                className="input_field_width"
                type="text"
                name="characteristic"
                placeholder="Characteristic"
                onBlur={(e) => rmSpecHandler(e, index)}
                defaultValue={spec?.characteristic}
              /> */}
              <FormControl
                variant="filled"
                className="select-field"
                style={{
                  marginLeft: "0.5rem",
                  width: "20rem",
                }}
              >
                <InputLabel>Select Characteristic</InputLabel>
                <Select
                  name="characteristic"
                  value={spec?.characteristic}
                  key={spec?.characteristic + index}
                  onChange={(e) => {
                    rmSpecHandler(e, index);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Diameter">Diameter</MenuItem>
                  <MenuItem value="Tensile Strength">Tensile Strength</MenuItem>
                  <MenuItem value="Breakload">Breakload</MenuItem>
                  <MenuItem value="Appearance">Appearance</MenuItem>
                </Select>
              </FormControl>

              <Input
                className="input_field_width"
                type="text"
                name="unit"
                placeholder="Unit"
                onChange={(e) => rmSpecHandler(e, index)}
                value={spec?.unit}
              />
              <Input
                className="input_field_width"
                type="text"
                name="method"
                placeholder="Method"
                onBlur={(e) => rmSpecHandler(e, index)}
                defaultValue={spec?.method}
              />
              <Input
                className="input_field_width"
                type="text"
                name="sample_taken"
                placeholder="Sample Taken"
                onBlur={(e) => rmSpecHandler(e, index)}
                defaultValue={spec?.sample_taken}
              />
            </Wrapper>
            <p
              style={{
                marginLeft: "0.5rem",
                marginTop: "2rem",
                fontWeight: 600,
              }}
            >
              Product/Process Specification/Tolerance
            </p>
            <section>
              <Wrapper wid="20rem">
                <input
                  type="checkbox"
                  className="spec-checkbox"
                  name="check1"
                  onChange={(e) => handleAppearance(e, index)}
                  checked={spec.appearance === "check1"}
                />
                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  name="ltl"
                  placeholder="Lower Limit"
                  required
                  type="Number"
                  inputProps={{
                    step: 0.01,
                  }}
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.ltl}
                  disabled={spec.appearance !== "check1"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check1" ? "#fafafa" : "black",
                    },
                  }}
                />
                ~
                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  name="utl"
                  placeholder="Upper Limit"
                  required
                  type="Number"
                  inputProps={{
                    step: 0.01,
                  }}
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.utl}
                  disabled={spec.appearance !== "check1"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check1" ? "#fafafa" : "black",
                    },
                  }}
                />
                <div style={{ width: "15.3rem", marginRight: ".9rem" }}></div>
                <Input
                  className="input_field_width"
                  type="text"
                  name="control_product_remark"
                  placeholder="Tolerance Remarks"
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.control_product_remark}
                  disabled={spec.appearance !== "check1"}
                  style={{ width: "250px", marginRight: "16.3rem" }}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check1" ? "#fafafa" : "black",
                    },
                  }}
                />
              </Wrapper>
              <Wrapper wid="19rem">
                <input
                  type="checkbox"
                  className="spec-checkbox"
                  name="check2"
                  onChange={(e) => handleAppearance(e, index)}
                  checked={spec.appearance === "check2"}
                />
                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  name="mean"
                  placeholder="Mean"
                  required
                  inputProps={{
                    step: 0.01,
                  }}
                  type="Number"
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.mean}
                  disabled={spec.appearance !== "check2"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check2" ? "#fafafa" : "black",
                    },
                  }}
                />
                +
                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  name="utolerance"
                  placeholder="Upper Tolerance"
                  required
                  type="Number"
                  inputProps={{
                    step: 0.01,
                  }}
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.utolerance}
                  disabled={spec.appearance !== "check2"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check2" ? "#fafafa" : "black",
                    },
                  }}
                />
                -
                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  name="ltolerance"
                  placeholder="Lower Tolerance"
                  required
                  type="Number"
                  inputProps={{
                    step: 0.01,
                  }}
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.ltolerance}
                  disabled={spec.appearance !== "check2"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check2" ? "#fafafa" : "black",
                    },
                  }}
                />
                <Input
                  // className="input_field_width"
                  type="text"
                  name="control_product_remark"
                  placeholder="Tolerance Remarks"
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.control_product_remark}
                  disabled={spec.appearance !== "check2"}
                  style={{ width: "15.3rem" }}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check2" ? "#fafafa" : "black",
                    },
                  }}
                />
              </Wrapper>
              <Wrapper wid="20rem">
                <input
                  name="check3"
                  type="checkbox"
                  className="spec-checkbox"
                  onChange={(e) => handleAppearance(e, index)}
                  checked={spec.appearance === "check3"}
                />
                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  type="text"
                  name="control_product"
                  placeholder="Spec./Tolerance"
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.control_product}
                  disabled={spec.appearance !== "check3"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check3" ? "#fafafa" : "black",
                    },
                  }}
                />
              </Wrapper>
              <Wrapper wid="19rem">
                <input
                  name="check4"
                  type="checkbox"
                  className="spec-checkbox"
                  onChange={(e) => handleAppearance(e, index)}
                  checked={spec.appearance === "check4"}
                />

                <FormControl
                  variant="filled"
                  className="select-field"
                  style={{
                    width: "14.5rem",
                    marginLeft: "8px",
                    marginRight: "1rem",
                  }}
                  disabled={spec.appearance !== "check4"}
                >
                  <InputLabel>Min/Max</InputLabel>

                  <Select
                    name="check4_symbol"
                    defaultValue={spec.check4_symbol}
                    onChange={(e) => rmSpecHandler(e, index)}
                    style={{
                      color:
                        spec.appearance !== "check4" ? "transparent" : "#111",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value=">=">{"Min."}</MenuItem>
                    <MenuItem value="<=">{"Max."}</MenuItem>
                  </Select>
                </FormControl>
                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  type="Number"
                  inputProps={{
                    step: 0.01,
                  }}
                  name="checkbox4_num"
                  placeholder="Limit"
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.checkbox4_num}
                  disabled={spec.appearance !== "check4"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check4" ? "#fafafa" : "black",
                    },
                  }}
                />
                <div style={{ width: "15.3rem", marginRight: ".9rem" }}></div>

                <Input
                  // className="input_field_width"
                  style={{ width: "15.3rem" }}
                  type="text"
                  name="control_product_remark"
                  placeholder="Tolerance Remarks"
                  onBlur={(e) => rmSpecHandler(e, index)}
                  defaultValue={spec.control_product_remark}
                  disabled={spec.appearance !== "check4"}
                  InputProps={{
                    style: {
                      color: spec.appearance !== "check4" ? "#fafafa" : "black",
                    },
                  }}
                />
              </Wrapper>
            </section>
          </div>
        )}
      </div>
      <DialogDeleteSpec isSubmitContent={false} />
    </section>
  );
};

export default RMSpecs;
