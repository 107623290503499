import React from "react";
import Styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";
const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	border: 1px solid black;


    th{
        font-size: 13px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100%;
        border: none;
        padding-left: 4px;
        height: 40px;
    }
`;

const heading = {
  "annexure-a": "ANNEXURE A",
  "annexure-b": "ANNEXURE B",
  "annexure-c": "ANNEXURE C",
};

const AnnexureCForm = ({ id, handleChange, data, isView, annexureType }) => {
  return (
    <section>
      <Table>
        <tr>
          <th
            colSpan={2}
            style={{
              fontSize: "1.2rem",
              fontWeight: 600,
              backgroundColor: "#1d1d1d",
              color: "white",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {heading[annexureType]}
          </th>
        </tr>
        <tr>
          <td>BR Shank Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="br_shank_diameter"
              required
              value={data.br_shank_diameter}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Rivet Head Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="rivet_head_diameter"
              required
              value={data.rivet_head_diameter}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Head Thickness</td>
          <td>
            <TableReadEditCell
              type="text"
              name="head_thickness"
              required
              value={data.head_thickness}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Blind Rivet Length</td>
          <td>
            <TableReadEditCell
              type="text"
              name="blind_revet_length"
              required
              value={data.blind_revet_length}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Mandrel Shank Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="mandrel_shank_dia"
              required
              value={data.mandrel_shank_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Mandrel Protrusion</td>
          <td>
            <TableReadEditCell
              type="text"
              name="mandrel_protrusion"
              required
              value={data.mandrel_protrusion}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Mandrel Breakload</td>
          <td>
            <TableReadEditCell
              type="text"
              name="mandrel_breakload_c"
              required
              value={data.mandrel_breakload_c}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Shear Strength</td>
          <td>
            <TableReadEditCell
              type="text"
              name="shear_strength_c"
              required
              value={data.shear_strength_c}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Tensile Strength</td>
          <td>
            <TableReadEditCell
              type="text"
              name="tensile_strength_c"
              required
              value={data.tensile_strength_c}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Mandrel Retention</td>
          <td>
            <TableReadEditCell
              type="text"
              name="mandrel_retention_c"
              required
              value={data.mandrel_retention_c}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
      </Table>
    </section>
  );
};

export default AnnexureCForm;
