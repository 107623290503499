import React, { useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import { Add, Remove, Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { Paper } from "@material-ui/core";

import Input from "../../components/Control/Input";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import useDebounce from "../../components/Control/useDebounce";

const PickItemMasterPart = ({ data }) => {
  const [partData, setPartData] = useState([]);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("item_no");
  const [render, setRender] = useState(false);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/getMasterModule/data/?page=${page}&sortBy=${filter}&search=${search}&perPage=10`,
      )
      .catch((err) => {
        console.log(err);
      });
    setPartData(res.data?.results);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, filter]);
  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Item Number"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="item_no">A - Z</MenuItem>
                <MenuItem value="createdAt">Most Recent First</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item No.
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Description
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Created At
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partData.length > 0 &&
                partData.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item.item_no}</TableCell>
                      <TableCell align="center">
                        {item.item_description}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(item.date_created)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        {data.item_desc === item.item_description ? (
                          <Tooltip title="remove" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.5rem",
                              }}
                              onClick={() => {
                                data.part_id = null;
                                data.item_desc = null;
                                data.item_code = null;
                              }}
                            >
                              <Remove
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#c92a2a",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="add" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.5rem",
                              }}
                              onClick={() => {
                                data.part_id = item._id;
                                data.item_desc = item?.item_description;
                                data.item_code = item?.item_no;
                                setRender((prev) => !prev);
                              }}
                            >
                              <Add
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
      </main>
    </>
  );
};

export default PickItemMasterPart;
