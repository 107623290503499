import React from "react";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import BorderInnerIcon from "@material-ui/icons/BorderInner";
import GamesIcon from "@material-ui/icons/Games";
import homePage from "../../components/Admin/HomePage.module.css";
import {
  Assessment,
  CloudUpload,
  DesktopMac,
  Event,
  Receipt,
} from "@material-ui/icons";

const AnnualProcessProductAuditTiles = () => {
  return (
    <main
      style={{ height: "100vh" }}
      className={`${homePage.container} ${homePage.main}`}
    >
      <div>
        <a
          href="https://slf.secure.aqua.mushinlabs.com/common/annualPmPlanUtilityChecklist?"
          rel="noopener noreferrer"
          className="panel"
          style={{ fontSize: "3rem", color: "#003566" }}
        >
          <Receipt style={{ fontSize: "3rem", color: "#003566" }} />
        </a>
        <p className="card-btn-para">
          ANNUAL PROCESS &<span className="card-btn-span">STATUS</span>
        </p>
      </div>
      <div>
        <a
          href="https://slf.secure.aqua.mushinlabs.com/common/spcPlanChecklist"
          rel="noopener noreferrer"
          className="panel"
        >
          <BorderInnerIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </a>
        <p className="card-btn-para">
          SPC <span className="card-btn-span">STATUS</span>
        </p>
      </div>
      <div>
        <a
          href="https://slf.secure.aqua.mushinlabs.com/common/spc/partlist"
          rel="noopener noreferrer"
          className="panel"
        >
          <GamesIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </a>
        <p className="card-btn-para">
          SPC <span className="card-btn-span">REPORT</span>
        </p>
      </div>
    </main>
  );
};

export default AnnualProcessProductAuditTiles;
