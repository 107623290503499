import React, { useEffect, useState } from "react";
import axios from "axios";
import { Wrapper } from "../../components/Control/Flexbox";
import { Button } from "@material-ui/core";
import "../../style/fmea.css";
import { DatePicker } from "@material-ui/pickers";
import { useHistory } from "react-router-dom";

const convertForFmea = (partData, from, to, partId, prevFmeaData) => {
  const prevobj = {};
  const prevFailureObj = {};
  if (prevFmeaData) {
    prevobj.fmea_operation = prevFmeaData.fmea_operation;
    prevobj.pfmea_no = prevFmeaData.pfmea_no;
    prevobj.process_resp = prevFmeaData.process_resp;
    prevobj.rev_and_date = prevFmeaData.rev_and_date;
    prevobj.key_date = prevFmeaData.key_date;
    prevobj.model_year_vehicle = prevFmeaData.model_year_vehicle;
    prevobj.drawing_rev_no = prevFmeaData.drawing_rev_no;
    prevobj.date_critical = prevFmeaData.date_critical;
    prevobj.date_major = prevFmeaData.date_major;
    prevobj.date_minor = prevFmeaData.date_minor;
    prevobj.fmea_operation = prevFmeaData.fmea_operation;
    prevFmeaData.processes.forEach((process) => {
      process.failures.forEach((failure, failIndex) => {
        prevFailureObj[process.process_name + failIndex] = failure;
      });
    });
  }
  const fmeaData = {
    part_id: partId,
    part_number: partData.part_id?.part_number,
    part_name: partData.part_id?.part_name,
    processes: [],
    core_team: partData.part_id?.core_team,
    revision_no: partData.revision_no,
    fmea_date_orig: new Date().toDateString(),
    from: from,
    to: to,
    revisionData: partData.revisionData || [],
    ...prevobj,
  };

  partData.processes.forEach((process) => {
    let obj = {};
    obj.process_name = process.process_name;
    obj.failures = [];
    let failureObj = {};
    process.fmeas.forEach((failure, failureIndex) => {
      failureObj = {};
      if (prevFailureObj.hasOwnProperty(process.process_name + failureIndex)) {
        failureObj = {
          ...prevFailureObj[process.process_name + failureIndex],
        };
      }
      failureObj.requirement = failure.requirement;
      failureObj.potential_mode = failure.potential_mode;
      failureObj.eof = failure.eof;
      failureObj.severity = failure.severity;
      failureObj.failure_class = failure.failure_class;
      failureObj.potential_cause = failure.potential_cause;
      // failureObj.occ = occData
      //   ? occData.hasOwnProperty(process.process_name)
      //     ? occData[process.process_name].hasOwnProperty(
      //         failure.potential_mode + "-" + failure.potential_cause[0]
      //       )
      //       ? occData[process.process_name][
      //           failure.potential_mode + "-" + failure.potential_cause[0]
      //         ]
      //       : 2
      //     : 2
      //   : 2;
      failureObj.control_prevention = failure.control_prevention;
      failureObj.control_detection = failure.control_detection;
      failureObj.det = failure.det;
      // failureObj.rpn =
      // parseInt(failure.severity || 1) *
      // (occData
      //   ? occData.hasOwnProperty(process.process_name)
      //     ? occData[process.process_name].hasOwnProperty(
      //         failure.potential_mode + "-" + failure.potential_cause[0]
      //       )
      //       ? occData[process.process_name][
      //           failure.potential_mode + "-" + failure.potential_cause[0]
      //         ]
      //       : 2
      //     : 2
      //   : 2) *
      // parseInt(failure.det || 1);
      obj.failures.push(failureObj);
    });

    fmeaData.processes.push(obj);
  });
  return fmeaData;
};

function AddFmea({ partId, fmeaId }) {
  const [data, setData] = useState(null);
  const [fmeaData, setFmeaData] = useState(null);
  const [prevFmeaData, setPrevFmeaData] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const history = useHistory();
  useEffect(() => {
    axios
      .get(`/api/get/process/fmea/${partId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (fmeaId) {
      axios
        .get(`/api/get/fmea/${fmeaId}`)
        .then((res) => {
          if (res.data) {
            setPrevFmeaData(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  useEffect(() => {
    if (data && from && to) {
      const obtainedFmea = convertForFmea(data, from, to, partId, prevFmeaData);
      setFmeaData(obtainedFmea);
    }
  }, [from, to, partId, fmeaId]);

  return (
    <>
      <div>
        <Wrapper
          style={{
            marginTop: "1rem",
            marginLeft: "1rem",
            gap: "1rem",
            marginBottom: "2rem",
          }}
        >
          <div>
            <DatePicker
              value={from || null}
              label="From"
              className="date-field"
              inputVariant="filled"
              format="dd/MM/yyyy"
              onChange={setFrom}
            />
          </div>
          <div>
            <DatePicker
              value={to || null}
              label="To"
              className="date-field"
              inputVariant="filled"
              format="dd/MM/yyyy"
              onChange={setTo}
            />
          </div>
          {fmeaData && (
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  display: "inline-flex",
                  backgroundColor: "#054a29",
                }}
                onClick={() =>
                  history.push("/generatefmea/report", {
                    data: {
                      fmeaData: { ...fmeaData },
                    },
                  })
                }
                // startIcon={<SaveIcon />}
              >
                Generate Report
              </Button>
            </div>
          )}
        </Wrapper>
      </div>
    </>
  );
}

export default AddFmea;
