import { Dialog, DialogTitle, DialogContent, Button } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((props) => ({
  root: {
    width: "100%",
    "& .MuiDialog-paper": {
      margin: "5px",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1500px",
      minWidth: "1000px",
      // backgroundColor: "#f0f2f0",
      backegroundColor: props.backgroundColor
        ? props.backgroundColor
        : "#f0f2f0",
    },
  },
}));

export default function Modal(props) {
  const classes = useStyles(props);

  const { title, children, openPopup, closeModal, closeButtonName } = props;
  return (
    <Dialog open={openPopup} className={classes.root}>
      <DialogTitle
        style={{
          backgroundColor: "#212529",
          height: "60px",
          padding: "0.6rem 1rem",
          textTransform: "uppercase",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: 400,
              fontFamily: "Nunito",
              letterSpacing: "0.1em",
              color: "#fff",
              marginLeft: "0.6rem",
            }}
          >
            {title}
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => closeModal()}
              startIcon={<CloseIcon />}
            >
              {closeButtonName ? closeButtonName : "CLOSE"}
            </Button>
          </div>
        </div>
      </DialogTitle>

      <DialogContent style={{}}>{children}</DialogContent>
    </Dialog>
  );
}
