import React, { useEffect, useState } from "react";
import FmeaFailures from "./FmeaFailures";
import formatDate from "../../components/Control/formatDate";
import Styled from "styled-components";
import { Wrapper } from "../../components/Control/Flexbox";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../context/GlobalContext";
import { images, textInputs } from "../../constants/constants";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import SaveIcon from "@material-ui/icons/Save";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;

        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }
    .input {
        outline: none;
        border: none;
        height: 50px;
        text-align:center;
        padding:0;
    }
    .center-heading{
      text-align:center;
    }

`;
const getTopRpn = (processes) => {
  let arrs = [];
  processes?.forEach((process) => {
    process.failures?.forEach((failure) => {
      arrs.push(failure.rpn);
    });
  });
  let topRpn = [0, 0, 0];
  for (let i = 0; i < 4; i++) {
    arrs.forEach((arr) => {
      if (!topRpn.includes(arr)) {
        if (+topRpn[i] < +arr) {
          topRpn[i] = arr;
        }
      }
    });
  }
  return topRpn;
};

const FmeaTable = () => {
  let { state } = useLocation();
  const [fmeaData, setFmeaData] = useState({});
  const [topRpn, setTopRpn] = useState([]);
  const { state: states } = useAuth();
  const { id } = useParams();
  const history = useHistory();
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  const handleChangeFmea = (e) => {
    const value = e.target.innerText;
    const name = e.target.dataset.name;
    fmeaData[name] = value;
  };
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get/fmea/${id}`)
        .then((res) => {
          if (res.data) {
            setFmeaData(res.data);
            let obtainedRpn = getTopRpn(res.data?.processes);
            setTopRpn(obtainedRpn);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setFmeaData(state.data?.fmeaData);
      let obtainedRpn = getTopRpn(state.data?.fmeaData?.processes);
      setTopRpn(obtainedRpn);
    }
  }, []);
  const updateFmea = async () => {
    let isUpdate = window.confirm(
      "Are you sure you want to update this document?"
    );
    if (isUpdate) {
      let res = await axios
        .put(`/api/update/fmea/${id}`, fmeaData)
        .catch((err) => console.log(err));
      if (res.data?.status === "success") {
        history.push(
          `/viewfmeaTable/fmea/${fmeaData.part_id._id}?partNumber=${fmeaData.part_id?.part_number}&partName=${fmeaData.part_id?.part_name}`
        );
      }
    }
  };

  const submitFmea = async () => {
    let isSubmit = window.confirm(
      "Are you sure you want to save this document?"
    );
    if (isSubmit) {
      let res = await axios
        .post("/api/create/fmea", fmeaData)
        .catch((err) => console.log(err));
      if (res.data?.status === "success") {
        history.push(
          `/viewfmeaTable/fmea/${fmeaData.part_id}?partNumber=${fmeaData.part_number}&partName=${fmeaData.part_name}`
        );
      }
    }
  };

  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          FAILURE MODE & EFFECT ANALYSIS
        </Typography>
        <Wrapper>
          {id ? (
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={updateFmea}
              style={{ backgroundColor: "#003566" }}
              startIcon={<DoubleArrowIcon />}
            >
              Update
            </Button>
          ) : (
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={submitFmea}
              style={{ backgroundColor: "#003566" }}
              startIcon={<SaveIcon />}
            >
              Save & Continue
            </Button>
          )}
        </Wrapper>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section style={{ overflow: "scroll" }}>
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 5, 7, 18, 5, 18, 18, 5, 5, 15, 15, 15, 4, 4, 4, 4"
          >
            <thead>
              <tr>
                <th
                  colSpan="14"
                  rowSpan="4"
                  align="center"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    borderRight: "0px",
                    textAlign: "center",
                    position: "relative",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.CompanyLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  DOCUMENT NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  FMEA __
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  ISSUE DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01/04/2019
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION NO:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01
                </th>
              </tr>
              <tr data-height="15">
                <th
                  align="left"
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REVISION DATE:
                </th>
                <th
                  align="left"
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  15/03/2022
                </th>
              </tr>
              <tr>
                <th
                  colSpan={19}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  POTENTIAL FAILURE MODE AND EFFECTS ANALYSIS (PROCESS FMEA)
                </th>
              </tr>

              <tr>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Item No. :-
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData.part_number || fmeaData?.part_id?.part_number}
                </th>

                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Process Responsibility -
                </th>
                <th
                  colSpan={3}
                  contentEditable={true}
                  data-name="process_resp"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData.process_resp}
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  FMEA Date(Orig.) -{" "}
                </th>

                {/* confirm by rahul */}
                <th
                  colSpan={4}
                  contentEditable={true}
                  data-name="fmea_date_origin"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData?.fmea_date_origin}
                </th>
              </tr>
              <tr data-height="25">
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Item Description:
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData.part_name || fmeaData.part_id?.part_name}
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Key Date -{" "}
                </th>

                <th
                  colSpan={3}
                  contentEditable={true}
                  data-name="key_date"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData?.key_date}
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  FMEA Rev. No. & Date -{" "}
                </th>
                <th
                  colSpan={4}
                  contentEditable={true}
                  data-name="rev_and_date"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData?.rev_and_date}
                </th>
              </tr>
              <tr data-height="25">
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Model Year(s)/Vehicle (s) -
                </th>
                <th
                  colSpan={3}
                  contentEditable={true}
                  data-name="model_year_vehicle"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData.model_year_vehicle}
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Drawing Rev. No. -{" "}
                </th>
                <th
                  colSpan={3}
                  contentEditable={true}
                  data-name="drawing_rev_no"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData?.drawing_rev_no}
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  CFT Team -{" "}
                </th>
                <th
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData.core_team || fmeaData?.part_id?.core_team}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontWeight: "500" }} data-height="65">
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  Item / Function
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    // minWidth: "10rem",
                  }}
                  data-f-sz="10"
                >
                  Requirement
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "10rem",
                  }}
                  data-f-sz="10"
                >
                  Potential Failure Mode
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  Potential Effect(s) of Failure
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Sev.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Class
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  Potential cause(s) of Failure
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Occ.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  Current Process Controls (Prevention )
                </th>

                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  Current Process Controls (Detection )
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Det.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  R.P.N.
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  Recommended Action(s)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  Resp. & Target completion date
                </th>
                <th
                  align="center"
                  colSpan={5}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  Action Results
                </th>
              </tr>
              <tr>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    textAlign: "center",
                  }}
                  data-f-sz="10"
                >
                  Actions Taken
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Sev.
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Occ.
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Det.
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  R.P.N.
                </th>
              </tr>
              {fmeaData.processes?.map((process, index) => (
                <FmeaFailures
                  key={process._id}
                  {...{ process, index, topRpn, getTopRpn, setTopRpn }}
                />
              ))}
            </tbody>
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Rev No.
                </td>
                <td
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Date
                </td>
                <td
                  colSpan="9"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Reason For Revision
                </td>
                <td
                  colSpan="5"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Reason
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
              {fmeaData?.revisionData &&
                fmeaData?.revisionData?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr data-height="150">
                        <td
                          colSpan="2"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {item.rev_no}
                        </td>
                        <td
                          colSpan="3"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {formatDate(item.rev_date)}
                        </td>
                        <td
                          colSpan="9"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {item.summary}
                        </td>
                        <td
                          colSpan="5"
                          style={{ border: "1px solid black" }}
                          align="center"
                          data-f-sz="11"
                        >
                          {item.reason}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
            <tbody>
              <tr style={{ height: "60px" }} data-height="30">
                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Prepared By:
                </td>
                <td
                  colSpan="5"
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  data-f-sz="10"
                >
                  {states.user.name}
                </td>

                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Approved By:
                </td>
                <td
                  colSpan="6"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                ></td>
              </tr>
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default FmeaTable;
