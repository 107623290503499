import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../style/controlPlan.css";
import axios from "axios";
import Loader from "../../../components/Control/Loader";
import ControlPlanTableSheet from "./ControlPlanTableSheet";

export default function ViewControlPlan() {
  const { documentType, id } = useParams();
  const [data, setData] = useState("");
  useEffect(() => {
    axios
      .get(`/api/getMasterById/${documentType}/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        {data ? (
          <ControlPlanTableSheet Data={data} controlPlanId={id} />
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
