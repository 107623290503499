import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import AddEditDevice from "./AddEditDevice";
import { usePermission } from "../../components/usePermission";
const DeviceList = () => {
  const [data, setData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("machine_no");
  const [deviceId, setDeviceId] = useState(null);
  const permissions = usePermission(18);
  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this machine ?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/get/device?page=${page}&sortBy=${filter}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, render, filter, isOpenModal]);

  async function deleteDocument(id) {
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/deleteDevice/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          DEVICE LIST
          <div>
            {permissions?.includes("edit") && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setDeviceId(null);
                  setIsOpenModal(true);
                }}
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={<Add />}
              >
                New Device
              </Button>
            )}
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Device Name"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem> None</MenuItem>
                <MenuItem value="device_name">A - Z</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Device Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  IP Address
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell
                        align="center"
                        //  width={10}
                      >
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item.device_name}</TableCell>
                      <TableCell align="center">{item.ip_address}</TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "250px",
                            width: "220px",
                            alignItems: "center",
                          }}
                        >
                          {permissions?.includes("edit") && (
                            <Tooltip title="Edit Device" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: ".5rem",
                                }}
                                onClick={() => {
                                  setDeviceId(item._id);
                                  setIsOpenModal(true);
                                }}
                              >
                                <EditIcon
                                  style={{ fontSize: "1.4rem", color: "black" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          {permissions?.includes("delete") && (
                            <Tooltip title="Delete Device" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.8rem",
                                }}
                                onClick={() => {
                                  deleteDocument(item._id);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#c80202",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={deviceId ? "update device" : "create new device"}
          openPopup={isOpenModal}
          closeModal={() => {
            setIsOpenModal(false);
            setDeviceId(null);
          }}
          backgroundColor="white"
        >
          <AddEditDevice id={deviceId} {...{ setIsOpenModal }} />
        </Modal>
      </main>
      <DialogDelete />
    </>
  );
};

export default DeviceList;
