import TableReadEditCell from "../../components/TableReadEditCell";
let unitForTs;
let unitForDiameter;
const obtainedResultCell = (data, isView, index, handleChange) => {
  const getValuesForTsOrRequiredBreakload = (data) => {
    if (data) {
      const TsorBreaklodValue = data?.incomings?.find((element) => {
        return (
          element.characteristic === "Tensile Strength" ||
          element.characteristic === "Breakload"
        );
      });
      unitForTs = TsorBreaklodValue?.unit;
      console.log(unitForTs);
      return TsorBreaklodValue?.control_product;
    }
  };
  const getValuesForDiameter = (data) => {
    if (data) {
      const valueFprDaimeter = data?.incomings?.find((element) => {
        return element.characteristic === "Diameter";
      });
      unitForDiameter = valueFprDaimeter?.unit;
      console.log(unitForDiameter);
      return valueFprDaimeter?.control_product;
    }
  };

  if (index === 0) {
    return (
      <>
        <th style={{ textAlign: "center" }} data-f-sz="10" colSpan={2}>
          PDI
        </th>
        <th style={{ textAlign: "center" }} data-f-sz="10">
          MTC
        </th>
      </>
    );
  }
  if (index === 1) {
    return (
      <>
        <td style={{ textAlign: "center" }} colSpan={2}>
          <TableReadEditCell
            type="text"
            name="pdi"
            value={data.pdi}
            {...{ isView, handleChange }}
          />
        </td>
        <td style={{ textAlign: "left" }}>
          <TableReadEditCell
            type="text"
            name="mtc"
            value={data.mtc}
            {...{ isView, handleChange }}
          />
        </td>
      </>
    );
  }
  if (index === 2) {
    return (
      <>
        <th style={{ textAlign: "center" }} colSpan={3} data-f-sz="10">
          Required T.S / Breakload
        </th>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10"></td> */}
      </>
    );
  }
  if (index === 3) {
    return (
      <>
        <td style={{ textAlign: "center" }} data-f-sz="10" colSpan={3}>
          {/* <TableReadEditCell
            type="text"
            name="required_ts"
            value={data.required_ts}
            {...{ isView, handleChange }}
          /> */}
          {getValuesForTsOrRequiredBreakload(data)}
        </td>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10">
          <TableReadEditCell
            type="text"
            name="required_breakload"
            value={data.required_breakload}
            {...{ isView, handleChange }}
          />
        </td> */}
      </>
    );
  }
  if (index === 4) {
    return (
      <>
        <th style={{ textAlign: "center" }} colSpan={3} data-f-sz="10">
          Obtained T.S./Breakload
        </th>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10">
          Obtained 
        </td> */}
      </>
    );
  }
  if (index === 5) {
    return (
      <>
        <th style={{ textAlign: "left" }} data-f-sz="10">
          MIN:
        </th>
        <td style={{ textAlign: "left" }} data-f-sz="10" colSpan={2}>
          {data.ot_min && `${data.ot_min}  ${unitForTs}`}
        </td>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10">
          <TableReadEditCell
            type="text"
            name="obtained_break_load"
            value={data.obtained_break_load}
            {...{ isView, handleChange }}
          />
        </td> */}
      </>
    );
  }
  if (index === 6) {
    return (
      <>
        <th style={{ textAlign: "left" }} data-f-sz="10">
          MAX:
        </th>
        <td style={{ textAlign: "left" }} data-f-sz="10" colSpan={2}>
          {data.ot_max && `${data.ot_max}  ${unitForTs}`}
        </td>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10">
          <TableReadEditCell
            type="text"
            name="obtained_break_load_2"
            value={data.obtained_break_load_2}
            {...{ isView, handleChange }}
          />
        </td> */}
      </>
    );
  }
  if (index === 7) {
    return (
      <>
        <th style={{ textAlign: "center" }} colSpan={3} data-f-sz="10">
          Required Size
        </th>
      </>
    );
  }

  if (index === 8) {
    return (
      <>
        <td style={{ textAlign: "center" }} colSpan={3} data-f-sz="10">
          {getValuesForDiameter(data)}
        </td>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10">
          Required Size: -0.01 , +0.02
        </td> */}
      </>
    );
  }
  if (index === 9) {
    return (
      <>
        <th style={{ textAlign: "center" }} colSpan={3} data-f-sz="10">
          Obtained Size (mm)
        </th>
      </>
    );
  }
  if (index === 10) {
    return (
      <>
        <th style={{ textAlign: "left" }} data-f-sz="10">
          MIN:
        </th>
        <td style={{ textAlign: "left" }} data-f-sz="10" colSpan={2}>
          {data.obtained_size_min &&
            `${data.obtained_size_min}  ${unitForDiameter}`}
        </td>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10">
          <TableReadEditCell
            type="text"
            name="obtained_size"
            value={data.obtained_size}
            {...{ isView, handleChange }}
          />
        </td> */}
      </>
    );
  }
  if (index === 11) {
    return (
      <>
        <th style={{ textAlign: "left", width: "100px" }} data-f-sz="10">
          MAX:
        </th>
        <td style={{ textAlign: "left" }} data-f-sz="10" colSpan={2}>
          {data.obtained_size_max &&
            ` ${data.obtained_size_max}  ${unitForDiameter}`}
        </td>
        {/* <td style={{ textAlign: "left" }} data-f-sz="10">
          <TableReadEditCell
            type="text"
            name="obtained_size_2"
            value={data.obtained_size_2}
            {...{ isView, handleChange }}
          />
        </td> */}
      </>
    );
  }
  if (index === 13) {
    return (
      <>
        <th style={{ textAlign: "left" }} data-f-sz="10">
          Remarks:
        </th>
        <td style={{ textAlign: "left" }} data-f-sz="10" colSpan={2}>
          <TableReadEditCell
            type="text"
            name="breakload_remarks"
            value={data.breakload_remarks}
            {...{ isView, handleChange }}
          />
        </td>
      </>
    );
  }

  if (index === 14) {
    return (
      <>
        <th style={{ textAlign: "left" }} data-f-sz="10">
          Deviation No:
        </th>
        <td style={{ textAlign: "left" }} data-f-sz="10" colSpan={2}>
          <TableReadEditCell
            type="String"
            name="deviation_no"
            value={data.deviation_no}
            {...{ isView, handleChange }}
          />
        </td>
      </>
    );
  }
  if (index === 15) {
    return (
      <>
        <th style={{ textAlign: "left" }} data-f-sz="10">
          NC No:
        </th>
        <td style={{ textAlign: "left" }} data-f-sz="10" colSpan={2}>
          <TableReadEditCell
            type="text"
            name="nc_no"
            value={data.nc_no}
            {...{ isView, handleChange }}
          />
        </td>
      </>
    );
  }

  return (
    <>
      <td style={{ textAlign: "left" }} colSpan={3} data-f-sz="10"></td>
      {/* <td style={{ textAlign: "left" }} data-f-sz="10"></td> */}
    </>
  );
};

export { obtainedResultCell };
