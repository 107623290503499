import React, { useEffect, useState } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import axios from "axios";
import Input from "../../components/Control/Input";
import SaveIcon from "@material-ui/icons/Save";
import { DatePicker } from "@material-ui/pickers";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { Wrapper } from "../../components/Control/Flexbox";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
const AddEditPart = ({ setIsNewPartModal, id }) => {
  const [partData, setPartData] = useState({
    date_orig: new Date(),
  });
  const [render, setRender] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selectCategory, setSelectCategory] = useState();
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "DRAFT PART",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handlePartData(e) {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get/partById/${id}`)
        .then((res) => setPartData(res.data))
        .catch((err) => console.log(err));
    } else {
      setPartData({
        date_orig: new Date(),
      });
    }
  }, [id]);

  useEffect(() => {
    axios.get(`/api/getAllCategory/data`).then((res) => {
      setCategoryData(res.data?.results);
      setRender((prev) => !prev);
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/update/part/${id}`, partData)
        .then((res) => {
          setIsNewPartModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/part", partData)
        .then((res) => {
          setIsNewPartModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="root-admin">
      <form
        style={{
          margin: "1.8rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "4fr 1fr",
            width: "100%",
          }}
        >
          <div className="supplier-section">
            <section className="supplier-section-part">
              <Input
                type="text"
                name="part_number"
                required
                placeholder="Part Number"
                onChange={handlePartData}
                value={partData.part_number}
              />
              <Input
                type="text"
                name="part_name"
                placeholder="Item Description"
                required
                onChange={handlePartData}
                value={partData.part_name}
              />
              <Input
                type="text"
                name="customer"
                placeholder="Customer Name"
                onChange={handlePartData}
                value={partData.customer}
              />
            </section>
            <section className="supplier-section-part">
              <Input
                type="text"
                name="key_contact"
                placeholder="Key Contact / Phone"
                onChange={handlePartData}
                value={partData.key_contact}
              />
              <Input
                type="text"
                name="core_team"
                placeholder="Core Team"
                onChange={handlePartData}
                value={partData.core_team}
              />
              <Input
                type="text"
                name="supplier"
                placeholder="Supplier"
                onChange={handlePartData}
                value={partData.supplier}
              />
            </section>

            <section
              className="supplier-section-part"
              style={{ marginTop: "0.5rem" }}
            >
              <DatePicker
                inputVariant="filled"
                name="supplier_approval_date"
                style={{ paddingLeft: "0.6rem" }}
                label="Customer Engg Approval date"
                value={partData.supplier_approval_date || null}
                onChange={(date) =>
                  setPartData((prev) => ({
                    ...prev,
                    supplier_approval_date: date,
                  }))
                }
                format="dd/MM/yyyy"
                className="date-field"
              />

              <DatePicker
                inputVariant="filled"
                name="Other_approval_date"
                style={{ paddingLeft: "0.6rem" }}
                label="Customer Quality Approval date"
                value={partData.Other_approval_date || null}
                onChange={(date) =>
                  setPartData((prev) => ({
                    ...prev,
                    Other_approval_date: date,
                  }))
                }
                format="dd/MM/yyyy"
                className="date-field"
              />

              <DatePicker
                inputVariant="filled"
                name="date_orig"
                style={{ paddingLeft: "0.6rem" }}
                required
                label="DATE (Original)"
                value={partData.date_orig || null}
                onChange={(date) =>
                  setPartData((prev) => ({ ...prev, date_orig: date }))
                }
                format="dd/MM/yyyy"
                className="date-field"
              />
            </section>
            <section
              // className="supplier-section-part"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Input
                type="text"
                name="supplier_code"
                placeholder="Supplier Code"
                onChange={handlePartData}
                value={partData.supplier_code}
                style={{ minWidth: "310px" }}
              />

              <FormControl
                className="select-field"
                variant="filled"
                style={{ width: "310px" }}
              >
                <InputLabel>Select Category</InputLabel>
                <Select
                  name="category"
                  value={partData.category}
                  key={partData.category}
                  style={{ fontSize: "0.8rem" }}
                  disableUnderline
                  onChange={(e) => {
                    partData.category = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {categoryData?.map((data, idxc) => (
                    <MenuItem
                      style={{ fontSize: "0.8rem" }}
                      className="option"
                      key={idxc}
                      value={data.category}
                    >
                      {data.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </section>
            <Wrapper
              style={{
                marginLeft: "0.76rem",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  setPartData((prev) => ({
                    ...prev,
                    Other_approval_date: null,
                    supplier_approval_date: null,
                    date_orig: null,
                    date_rev: null,
                  }))
                }
                startIcon={<ClearAllIcon />}
              >
                RESET DATE
              </Button>
              <p style={{ fontSize: "0.8rem", margin: 0 }}>
                Click to clear/reset all dates
              </p>
            </Wrapper>

            <div
              className="btn_save"
              style={{
                position: "absolute",
                right: "150px",
                top: "9px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="white"
                size="large"
                style={{ color: "#e9e9e9" }}
                startIcon={<PublishIcon />}
              >
                {id ? "UPDATE" : "SUBMIT"}
              </Button>
            </div>
          </div>
          <div>
            <TextField
              type="text"
              variant="filled"
              name="notes"
              multiline={true}
              rows={18}
              maxRows={20}
              className="textarea-field"
              label="Notes"
              style={{
                margin: "1.3rem 0.7rem 0rem 0.5rem",
                paddingRight: "1.2rem",
                width: "100%",
              }}
              onChange={handlePartData}
              value={partData.notes}
            />
          </div>
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default AddEditPart;
