import moment from "moment";

function readableTime(date) {
  if (!date) return "";
  const newDate = moment(date);
  if (!newDate.isValid()) {
    return "";
  }
  return newDate.format("h:mm A");
}

export default readableTime;
