import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { TableCell, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import { usePermission } from "../../components/usePermission";
const Editable = ({ rmId, handleDelete, id }) => {
  const permissions = usePermission(7);

  return (
    <TableCell align="center">
      <Tooltip title="View" arrow>
        {permissions?.includes("view") ? (
          <Link to={`/incomingbreakload/tab/${rmId}/${id}?isView=true`}>
            <IconButton size="small">
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>

      <Tooltip title="Edit" arrow>
        {permissions?.includes("edit") ? (
          <Link to={`/incomingbreakload/tab/${rmId}/${id}?isView=false`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
            </IconButton>
          </Link>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>
      <Tooltip title="Delete" arrow>
        {permissions?.includes("delete") ? (
          <IconButton
            size="small"
            style={{ marginLeft: "0.5rem" }}
            onClick={() => handleDelete(id)}
          >
            <DeleteIcon
              style={{
                fontSize: "1.4rem",
                color: "red",
              }}
            />
          </IconButton>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>
    </TableCell>
  );
};
const NotEditable = ({ rmId, id }) => {
  const permissions = usePermission(7);

  return (
    <TableCell align="center">
      <Tooltip title="View" arrow>
        {permissions?.includes("view") ? (
          <Link to={`/incomingbreakload/tab/${rmId}/${id}?isView=true`}>
            <IconButton size="small">
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        ) : (
          <BlockIcon style={{ color: "#c92a2a" }} />
        )}
      </Tooltip>
      <Tooltip title="Block" arrow>
        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
          <BlockIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Block" arrow>
        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
          <BlockIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

const SupervisorAction = ({ rmId, item, handleDelete }) => {
  const permissions = usePermission(7);

  //notsubmitted
  if (!item.isSubmitted) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#1864ab",
            color: "white",
          }}
        >
          <span>SAVED</span>
        </TableCell>
        <Editable id={item._id} handleDelete={handleDelete} rmId={rmId} />
      </>
    );
  }

  if (item.is_admin_request === "pending" && item.isSubmitted) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#fdd835",
            color: "black",
            maxWidth: "200px",
          }}
        >
          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "Center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            PENDING FOR APPROVAL
          </span>
        </TableCell>
        <NotEditable id={item._id} rmId={rmId} />
      </>
    );
  }
  if (item.is_admin_request === "accepted" && item.isSubmitted) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#2b8a3e",
            color: "#fff",
          }}
        >
          <span>APPROVED</span>
        </TableCell>
        <NotEditable id={item._id} rmId={rmId} />
      </>
    );
  }

  if (item.is_admin_request === "rejected" && item.isSubmitted) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#f03e3e",
            color: "#fff",
          }}
        >
          <span>SENT BACK FOR RECHECKING</span>
        </TableCell>
        <Editable id={item._id} handleDelete={handleDelete} rmId={rmId} />
      </>
    );
  }
};
export default SupervisorAction;
