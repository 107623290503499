import "date-fns";
import React, { useState } from "react";
import "../../style/product.css";
import { Wrapper } from "../../components/Control/Flexbox";
import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SaveIcon from "@material-ui/icons/Save";
import { DatePicker } from "@material-ui/pickers";
import Input from "../../components/Control/Input";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { toSuperScript } from "../../components/Control/toSuperScript";
import { useAuth } from "../../context/GlobalContext";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../components/Control/useConfirm";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "0rem",
  },
});

function MSAForm({ MSAData, submit, setMSAData }) {
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [expandState, setExpandState] = useState(Array(3).fill(false));
  const { state } = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if ((name = "least_count" || name === "spec")) {
      value = toSuperScript(e.target.value);
    }
    setMSAData({ ...MSAData, [e.target.name]: value });
  };

  return (
    <main>
      {
        <div style={{ paddingBottom: "2rem" }}>
          <div
            className="divflex"
            style={{
              fontSize: "24px",
            }}
          >
            MEASUREMENT SYSTEMS ANALYSIS REPORT
          </div>
          <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
          <form
            style={{
              margin: "1.5rem",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              MSAData.isSubmitted = true;
              submit();
            }}
          >
            <section className="supplier-section-part">
              <Input
                type="text"
                name="part_no"
                placeholder="Part Number/Model"
                value={MSAData?.part_id?.part_number || partNumber}
                disabled
              />
              <Input
                type="text"
                name="instrument_name"
                placeholder="Instrument Name"
                value={MSAData.instrument_name}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="characteristic"
                placeholder="Characteristic"
                value={MSAData.characteristic}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="spec"
                placeholder="Specification"
                value={MSAData.spec}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="identification_no"
                placeholder="Identification No"
                value={MSAData.identification_no}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="least_count"
                placeholder="Instrument LC"
                value={MSAData.least_count}
                onChange={handleChange}
              />
            </section>
            <section
              // className="supplier-section-part"
              style={{
                display: "flex",
              }}
            >
              <Input
                type="text"
                name="appraiser_a"
                placeholder="Appraiser A"
                value={MSAData.data[0].name}
                onChange={(e) => {
                  setMSAData((MSAData) => {
                    MSAData.data[0].name = e.target.value;
                    return { ...MSAData };
                  });
                }}
              />
              <Input
                type="text"
                name="appraiser_b"
                placeholder="Appraiser B"
                value={MSAData.data[1].name}
                onChange={(e) => {
                  setMSAData((MSAData) => {
                    MSAData.data[1].name = e.target.value;
                    return { ...MSAData };
                  });
                }}
              />
              <Input
                type="text"
                name="appraiser_c"
                placeholder="Appraiser C"
                value={MSAData.data[2].name}
                onChange={(e) => {
                  setMSAData((MSAData) => {
                    MSAData.data[2].name = e.target.value;
                    return { ...MSAData };
                  });
                }}
              />

              <Wrapper>
                <div style={{ marginLeft: "0.7rem" }}>
                  <DatePicker
                    label="Date"
                    name="date_performance"
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    value={MSAData.date_performance}
                    onChange={(date) => {
                      setMSAData({ ...MSAData, date_performance: date });
                    }}
                  />
                </div>
              </Wrapper>
            </section>
            {MSAData.data.map((level1, index1) => {
              return (
                <div
                  key={index1}
                  style={{
                    marginBottom: "0.3rem",
                    marginTop: "0.6rem",
                    padding: "0rem",
                    border: "1px solid #f0f2f0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      background: "#fafafa",
                      alignItems: "center",
                    }}
                    className="spec-header"
                  >
                    <Wrapper>
                      <Input
                        readOnly
                        type="text"
                        name="s_no"
                        value={String.fromCharCode("A".charCodeAt(0) + index1)}
                        style={{ width: "80px" }}
                      />
                      <Wrapper
                        justify="center"
                        style={{
                          marginLeft: "0.4rem",
                          fontSize: "0.9rem",
                          textTransform: "uppercase",
                        }}
                      >
                        {MSAData.data[index1].name}
                      </Wrapper>
                    </Wrapper>
                    {expandState[index1] ? (
                      <Wrapper
                        style={{
                          height: "45px",
                          width: "50px",
                          backgroundColor: "#3f51b5",
                          cursor: "pointer",
                          color: "white",
                        }}
                        justify="center"
                        onClick={() =>
                          setExpandState((prev) => {
                            prev[index1] = false;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandLess />
                      </Wrapper>
                    ) : (
                      <Wrapper
                        style={{
                          height: "45px",
                          width: "50px",
                          backgroundColor: "#3f51b5",
                          cursor: "pointer",
                          color: "white",
                        }}
                        justify="center"
                        onClick={() =>
                          setExpandState((prev) => {
                            prev[index1] = true;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandMore />
                      </Wrapper>
                    )}
                  </div>
                  {expandState[index1] && (
                    <div>
                      <Table className={classes.table} size="small">
                        <TableBody>
                          {MSAData.data[index1].values.map((item, index2) => (
                            <TableRow>
                              {item.map((item1, index3) => (
                                <TableCell>
                                  <Input
                                    type="Number"
                                    inputProps={{
                                      step: 0.01,
                                    }}
                                    value={item1}
                                    placeholder={index3 + 1 + index2 * 10}
                                    onChange={(e) =>
                                      setMSAData((MSAData) => {
                                        MSAData.data[index1].values[index2][
                                          index3
                                        ] = e.target.value;
                                        return { ...MSAData };
                                      })
                                    }
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </div>
              );
            })}
            <Wrapper
              style={{
                position: "absolute",
                top: "4.7rem",
                right: "1.6rem",
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{ backgroundColor: "#003566" }}
                onClick={() => {
                  MSAData.isSubmitted = false;
                  submit();
                }}
                startIcon={<SaveIcon />}
              >
                Save & Continue
              </Button>
              {/* <SubmitButton /> */}
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </form>
        </div>
      }
      <DialogClose isSubmitContent={false} />
    </main>
  );
}

export default MSAForm;
