import React, { useEffect, useState } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Input from "../../components/Control/Input";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";

const AddEditDevice = ({ setIsOpenModal, id }) => {
  const [deviceData, setDeviceData] = useState({});

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "MACHINE",
    `Are you sure you want to ${id ? "update" : "save"} this part?`,
  );

  function handleMachineData(e) {
    setDeviceData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getDeviceById/${id}`)
        .then((res) => {
          setDeviceData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setDeviceData({
        date_orig: new Date(),
      });
    }
  }, [id]);


  const onSubmit = async (e) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/updateDevice/${id}`, deviceData)
        .then((res) => {
          setIsOpenModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/device", deviceData)
        .then((res) => {
          setIsOpenModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="root-admin">
      <form
        style={{
          margin: "1.8rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <div>
          <div className="supplier-section">
            <section className="supplier-section-part">
              <Input
                type="text"
                name="device_name"
                placeholder="Device Name"
                required
                onChange={handleMachineData}
                value={deviceData?.device_name}
              />
              <Input
                type="text"
                name="ip_address"
                placeholder="IP Address"
                required
                onChange={handleMachineData}
                value={deviceData.ip_address}
              />
            </section>
            <div
              className="btn_save"
              style={{
                position: "absolute",
                right: "150px",
                top: "9px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="white"
                size="large"
                style={{ color: "#e9e9e9" }}
                startIcon={<PublishIcon />}
              >
                {id ? "UPDATE" : "SUBMIT"}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default AddEditDevice;
