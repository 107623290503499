import React from "react";
import formatDate from "../../../Control/formatDate";
import Styled from "styled-components";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";
import { Wrapper } from "../../../Control/Flexbox";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { useAuth } from "../../../../context/GlobalContext";
import { images, textInputs } from "../../../../constants/constants";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;
const PFMEA = ({ pirData }) => {
  const { state: states } = useAuth();
  function printTable() {
    var divToPrint = document.getElementById("fmea");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          FAILURE MODE & EFFECT ANALYSIS
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <Button
              style={{
                backgroundColor: "#161a1d",
                borderColor: "#161a1d",
                color: "white",
                width: "fit-content",
                borderRadius: "0",
                paddingTop: "9px",
                cursor: "pointer",
              }}
              onClick={() => {
                TableToExcel.convert(document.getElementById("table-to-xls"), {
                  name: "Process Failure Mode And Effect Analysis.xlsx",
                  sheet: {
                    name: "PROCESS PFMEA",
                  },
                });
              }}
            >
              <Icon path={mdiFileExcel} size={1.3} />
            </Button>
          </Tooltip>
        </Wrapper>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
          overflowX: "scroll",
        }}
      >
        <Table
          cellSpacing="0"
          style={{ marginTop: "0.6rem", backgroundColor: "white" }}
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="10, 15, 17, 19, 5, 7, 18, 5, 18, 18, 5, 5, 15, 15, 15, 4, 4, 4, 4"
        >
          <thead>
            <tr data-height="80" style={{ height: "80px" }}>
              <th
                colSpan="14"
                rowSpan="4"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.CompanyLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                align="left"
                colSpan="2"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                DOCUMENT NO:
              </th>
              <th
                align="left"
                colSpan="3"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                BR FMEA 02
              </th>
            </tr>
            <tr data-height="25">
              <th
                align="left"
                colSpan="2"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                ISSUE DATE:
              </th>
              <th
                align="left"
                colSpan="3"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                01/04/2019
              </th>
            </tr>
            <tr data-height="25">
              <th
                align="left"
                colSpan="2"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                REVISION NO:
              </th>
              <th
                align="left"
                colSpan="3"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                01
              </th>
            </tr>
            <tr data-height="25">
              <th
                align="left"
                colSpan="2"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                REVISION DATE:
              </th>
              <th
                align="left"
                colSpan="3"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                15/03/2022
              </th>
            </tr>
            <tr data-height="40">
              <td
                colSpan={19}
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                POTENTIAL FAILURE MODE AND EFFECTS ANALYSIS (PROCESS FMEA)
              </td>
            </tr>
            <tr data-height="30">
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                Item No:
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.part_id?.part_number}</span>
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                Process Resp. -{" "}
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.process_resp}</span>
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                FMEA Date(Orig.) -{" "}
              </th>
              <th
                colSpan={4}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{formatDate(pirData.fmea_date_orig)}</span>
              </th>
            </tr>
            <tr data-height="30">
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                Item Description:
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.part_id?.part_name}</span>
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                Key Date -{" "}
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.key_date}</span>
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                FMEA Rev. No. & Date -{" "}
              </th>
              <th
                colSpan={4}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.rev_and_date}</span>
              </th>
            </tr>
            <tr data-height="30">
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                Model Year(s)/Vehicle (s) -
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.model_year_vehicle}</span>
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                Drawing Rev. No. -{" "}
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.drawing_rev_no}</span>
              </th>
              <th
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                CFT Team -{" "}
              </th>
              <th
                colSpan={4}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                <span>{pirData.core_team}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontWeight: "500" }} data-height="65">
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                ITEM/FUNCTION
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                REQUIREMENT
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                POTENTIAL FAILURE MODE
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                POTENTIAL EFFECT(s) OF FAILURE
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                SEV
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                CLASS
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                POTENTIAL CAUSE(S) OF FAILURE
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                OCC
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                CURRENT PROCESS CONTROLS (PREVENTION)
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                CURRENT PROCESS CONTROLS (DETECTION)
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                DET
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                RPN
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                RECOMMENDED ACTION(S)
              </th>
              <th
                align="center"
                rowSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                RESP. & TARGET COMPLETION DATE
              </th>
              <th
                align="center"
                colSpan={5}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                ACTION RESULTS
              </th>
            </tr>
            <tr data-height="30">
              <th
                align="center"
                style={{ border: "1px solid black", width: "100px" }}
                data-f-sz="10"
              >
                ACTION TAKEN
              </th>
              <th
                align="center"
                style={{ border: "1px solid black", textAlign: "center" }}
              >
                SEV
              </th>
              <th
                align="center"
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                OCC
              </th>
              <th
                align="center"
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                DET
              </th>
              <th
                align="center"
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                RPN
              </th>
            </tr>
            {pirData.processes?.map((process) =>
              process?.failures?.map((fail, failInd) => (
                <tr data-height="65">
                  {failInd === 0 && (
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      rowSpan={process.failures.length}
                      data-f-sz="10"
                    >
                      {process.process_name}
                    </td>
                  )}
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.requirement}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.potential_mode}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.effect_of_fail}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.severity}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.failure_class}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.potential_cause}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.occ}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.control_prevention}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.control_detection}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.det}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.rpn}
                  </td>
                  <td style={{ border: "1px solid black" }} data-f-sz="10">
                    {fail.rec_action}
                  </td>
                  <td align="center" data-f-sz="10">
                    {fail.resp_target}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.action_taken}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.last_sev}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.last_occ}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.last_det}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {fail.last_rpn}
                  </td>
                </tr>
              ))
            )}
            <tr data-height="30">
              <th
                align="center"
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Criteria
              </th>
              <th
                align="center"
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Symbol
              </th>
              <th
                align="center"
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Rev.#
              </th>
              <th
                align="center"
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Description
              </th>
              <th
                align="center"
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Action
              </th>
              <th
                align="center"
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              >
                Root Cause
              </th>
              <th
                align="center"
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Rev. By.
              </th>
              <th
                align="center"
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Date
              </th>
            </tr>
            <tr data-height="30">
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Critical
              </td>
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                C
              </td>
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                {pirData.rev_critical}
              </td>
              <td
                colSpan={7}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                {pirData.desc_action_rootcause_critical}
              </td>
              <td
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                {pirData.rev_by_critical}
              </td>
              <td
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                {formatDate(pirData.date_critical)}
              </td>
            </tr>
            {/* Major */}
            <tr data-height="30">
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Major
              </td>
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                M
              </td>
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{pirData.rev_major}</span>
              </td>
              <td
                colSpan={7}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{pirData.desc_action_rootcause_major}</span>
              </td>
              <td
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{pirData.rev_by_major}</span>
              </td>
              <td
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{formatDate(pirData.date_major)}</span>
              </td>
            </tr>
            {/* minor */}
            <tr data-height="25">
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                Minor
              </td>
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                m
              </td>
              <td
                colSpan={2}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{pirData.rev_minor}</span>
              </td>
              <td
                colSpan={7}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{pirData.desc_action_rootcause_minor}</span>
              </td>
              <td
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{pirData.rev_by_minor}</span>
              </td>
              <td
                colSpan={3}
                style={{ border: "1px solid black", textAlign: "center" }}
                data-f-sz="10"
              >
                <span>{formatDate(pirData.date_minor)}</span>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr style={{ height: "60px" }} data-height="30">
              <td
                colSpan="4"
                align="left"
                style={{
                  border: "1px solid black",
                  fontWeight: "bold",
                }}
                data-f-sz="10"
              >
                Prepared By:
              </td>
              <td
                colSpan="5"
                align="left"
                style={{
                  border: "1px solid black",
                  textTransform: "capitalize",
                }}
                data-f-sz="10"
              >
                {states.user.name}
              </td>

              <td
                colSpan="4"
                align="left"
                style={{
                  border: "1px solid black",
                  fontWeight: "bold",
                }}
                data-f-sz="10"
              >
                Approved By:
              </td>
              <td
                colSpan="6"
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="10"
              ></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default PFMEA;
