import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Wrapper } from "../../components/Control/Flexbox";
import "../../style/controlPlan.css";
import Loader from "../../components/Control/Loader";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import axios from "axios";
import {
  Button,
  Typography,
  Tooltip,
  CircularProgress,
  Radio,
  Paper,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  Update,
  CloudUpload,
  Delete,
  Print,
  Visibility,
} from "@material-ui/icons";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";
import { images, textInputs } from "../../constants/constants";
import { useQuery } from "../../components/Control/useQuery.js";
import { proxy_img } from "../../constants/constants";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RevisionLog from "./RevisionLog";
import { usePermission } from "../../components/usePermission.js";

const Table = Styled.table`
margin-bottom: 1rem;
width:96%;
margin:auto;
border: 1px solid black;
th{
    font-size: 12px;
    font-family:"Roboto",sans-serif;
    border: 1px solid black;
    font-weight:bold;
    padding: 0.2rem;
    p{
        margin:0;
    }
}
td{
    font-size: 10px;
    border: 1px solid black;
    padding: 0.2rem;
    text-align : center;
    p{
        margin:0
    }
}
.input {
    outline: none;
    width: 100%;
    border: 2px solid #f9e0e0;
    width: 100px;
    height: 50px;
    text-align: center;
  }
`;
const footerObj = [
  {
    name1: "PREPARED BY",
    name2: "APPROVED BY",
    param1: "prepared_by",
    param2: "approved_by",
  },

  {
    name1: "SIGNATURE:",
    name2: "SIGNATURE:",
    param1: "prep_sign",
    param2: "verify_sign",
  },
  {
    name1: "NAME:",
    name2: "NAME:",
    param1: "prep_name",
    param2: "verify_name",
  },
  {
    name1: "DESIGNATION:",
    name2: "DESIGNATION:",
    param1: "prep_desig",
    param2: "verify_desig",
  },
];

export default function SopOutputSheet() {
  const [sopData, setSopData] = useState(null);
  const { id } = useParams();
  const query = useQuery();
  const partId = query.get("partId");
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    getSopDataHandler();
    // eslint-disable-next-line
  }, []);

  const getSopDataHandler = async () => {
    try {
      const response = await axios.get(`/api/getSop/${id}?partId=${partId}`);
      setSopData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {sopData ? (
        <>
          <Paper elevation={2}>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(+newValue)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="SOP" />
              <Tab label="REVISION LOG" />
            </Tabs>
          </Paper>
          {tabValue === 0 && (
            <SpanningTable setSopData={setSopData} sopData={sopData} Key={id} />
          )}
          {tabValue === 1 && (
            <RevisionLog revisionData={sopData.revisionData} />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

function SpanningTable({ sopData, setSopData }) {
  const { id } = useParams();
  const query = useQuery();
  const partId = query.get("partId");
  const isView = query.get("isView") === "false" ? false : true;
  const [image, setImage] = useState(null);
  const [render, setRender] = useState(false);
  const history = useHistory();
  const permissions = usePermission(3);

  const [printLoader, setPrintLoader] = useState(false);

  const handleChange = (e, index) => {
    const { value, name } = e.target;
    if (index === 0 || index) {
      const values = { ...sopData };
      values.revision_data[index][name] = value;
      setSopData(values);
      return;
    }
    const values = { ...sopData };
    values[name] = value;
    setSopData(values);
  };
  const onSubmit = async () => {
    if (image) {
      const formData = new FormData();
      formData.append("sop_img", image);
      let res = await axios
        .patch(`/api/update/sopImage/${id}`, formData)
        .catch((err) => console.log(err));
      if (res.data) {
        delete sopData.sop_img;
        setImage(null);
      }
    }
    axios
      .put(`/api/updateSop/${id}`, sopData)
      .then((res) => {
        if (res.data) alert("sop updated successfully");
      })
      .catch((err) => console.log(err));
  };

  function excelDownload() {
    const data = document.getElementById("tableDownloadId")?.innerHTML;
    // console.log(data);
    axios
      .post(
        `/api/excelDownload/SOP`,
        { table: data, name: "SOP" },
        { responseType: "blob" }
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `SOP Table-${sopData?.part_id?.part_number} ${moment().format(
            "dddd, MMMM Do YYYY, h:mm:ss a"
          )}.xlsx`
        );
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function printTable() {
    const data = document.getElementById("fullScreenSopId")?.innerHTML;
    setPrintLoader(true);
    axios
      .post(
        `/api/pdfDownload/fullScreenSOP`,
        { table: data, revisionLog: sopData?.revisionData },
        { responseType: "blob" }
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        // Format the current date
        const formattedDate = moment().format("dddd, MMMM Do YYYY, h:mm:ss a");

        // Set the file name as 'SOP_YYYY-MM-DD.pdf'
        link.setAttribute(
          "download",
          `SOP_${sopData?.part_id?.part_number}_${formattedDate}.pdf`
        );

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        URL.revokeObjectURL(url); // Free up memory
        setPrintLoader(false);
      })
      .catch((error) => {
        setPrintLoader(false);
        console.error("Error:", error);
      });
  }

  const handleLenght = (specs) => {
    //array or not
    if (!Array.isArray(specs)) return [];

    if (sopData?.instructions?.length > specs?.length) {
      //if lenght is more, then append obj to show all the instructions
      let extraCol = sopData?.instructions?.length - specs?.length;
      let adjustedExtraCol = extraCol - 2 >= 0 ? extraCol - 2 : 0;
      return [...specs, ...Array(adjustedExtraCol).fill({})];
    } else {
      return specs;
    }
  };

  return (
    <>
      {sopData && (
        <>
          <div className="divflex">
            <Typography
              variant="inherit"
              style={{
                fontSize: "24px",
              }}
            >
              STANDARD OPERATING PROCEDURE (SOP)
            </Typography>
            <Wrapper justify="space-evenly">
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={printTable}
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={
                  printLoader ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    <Print />
                  )
                }
                disabled={printLoader}
              >
                Print
              </Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                  history.push(`/viewFullScreenSop/${id}?partId=${partId}`)
                }
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={<Visibility />}
              >
                View Full Screen Sop
              </Button>
              {!isView && permissions?.includes("edit") && (
                <>
                  <div>
                    <input
                      id="contained-button-file"
                      type="file"
                      name="pcs_imageOrPdf"
                      accept="image/png,image/jpeg,image/jpg"
                      onChange={(e) => setImage(e.target.files[0])}
                      hidden
                    />
                    <label
                      htmlFor="contained-button-file"
                      style={{
                        marginBottom: "0",
                      }}
                    >
                      <Button
                        variant="outlined"
                        component="span"
                        style={{
                          background: "white",
                          marginRight: "0.6rem",
                        }}
                      >
                        <CloudUpload style={{ marginRight: "1rem" }} />
                        Upload Picture
                      </Button>
                    </label>
                    {image && (
                      <Button
                        variant="outlined"
                        type="button"
                        style={{
                          background: "white",
                          marginRight: "0.6rem",
                        }}
                        onClick={() => setImage(null)}
                        startIcon={<Delete />}
                      >
                        REMOVE
                      </Button>
                    )}
                  </div>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => onSubmit()}
                    style={{
                      background: "#064a29",
                      color: "#fafafa",
                      marginRight: "1rem",
                    }}
                    startIcon={<Update />}
                  >
                    Update
                  </Button>
                </>
              )}
              {isView && (
                <Tooltip arrow title="Download SOP excel Table">
                  <Button
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "1.9rem",
                      // marginRight: "0.6rem",
                    }}
                    onClick={() => {
                      excelDownload();
                    }}
                  >
                    <Icon path={mdiFileExcel} size={1.3} />
                  </Button>
                </Tooltip>
              )}
            </Wrapper>
          </div>

          <div
            className="card_table"
            style={{
              padding: "0.6rem",
              paddingBottom: "5rem",
            }}
            id={"fullScreenSopId"}
          >
            <Table
              id={"tableDownloadId"}
              cellSpacing="0"
              data-default-wrap="true"
              data-cols-width="4, 20, 10, 12, 6, 8, 12, 4, 10, 10, 10, 14, 10, 8, 8, 9, 10, 7, 10 "
            >
              <thead style={{ border: "2px solid black" }}>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan="12"
                    rowSpan="4"
                    align="left"
                    style={{
                      border: "1px solid black",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      position: "relative",
                    }}
                    data-a-h="center"
                    data-img={images.CompanyLogo}
                  >
                    <CenteredLogoImg>
                      <img
                        src={images.CompanyLogo}
                        alt={textInputs.companyName}
                        style={{
                          width: "120px",
                          height: "46px",
                          position: "absolute",
                          left: "2rem",
                        }}
                      />
                      <span>{textInputs.companyName}</span>
                    </CenteredLogoImg>
                  </th>
                  <th
                    data-b-a-s="thin"
                    align="left"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    FORMAT NO:
                  </th>
                  <th
                    data-b-a-s="thin"
                    align="left"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    SLF SOP 01
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    align="left"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    ISSUE DATE:
                  </th>
                  <th
                    data-b-a-s="thin"
                    align="left"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    01/07/2022
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    align="left"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    REVISION NO:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    02
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    align="left"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    REVISION DATE:
                  </th>
                  <th
                    data-b-a-s="thin"
                    align="left"
                    colSpan="2"
                    style={{ border: "1px solid black", fontSize: "10px" }}
                    data-f-sz="8"
                  >
                    3/10/2023
                  </th>
                </tr>
                <tr>
                  <td
                    data-b-a-s="thin"
                    colSpan={12}
                    align="center"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      backgroundColor: "#1d1d1d",
                      color: "white",
                      border: "1px solid black",
                    }}
                    data-f-sz="14"
                    data-a-h="center"
                    data-f-bold="true"
                    data-fill-color="#1d1d1d"
                    data-f-color="FFFFFF"
                  >
                    STANDARD OPERATING PROCEDURE
                  </td>
                  <th
                    data-b-a-s="thin"
                    colSpan={4}
                    data-f-sz="14"
                    style={{ fontSize: "1rem" }}
                  >
                    SOP NO -{sopData?.sop_no}{" "}
                  </th>
                </tr>
              </thead>
              <tbody style={{ border: "2px solid black", textAlign: "left" }}>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    ITEM NO:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {sopData?.part_id?.part_number}
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    rowSpan={9}
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    DRAWING (SKETCH)
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={9}
                    rowSpan={9}
                    data-f-sz="9"
                    style={{ border: "1px solid black" }}
                    data-img={proxy_img + sopData?.sop_img}
                  >
                    <img
                      src={proxy_img + sopData?.sop_img}
                      alt="sop image"
                      height="100%"
                      width="100%"
                    />
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    rowSpan={3}
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    Prototype
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    rowSpan={3}
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {isView ? (
                      sopData.part_type === "prototype" && (
                        <span
                          style={{
                            fontSize: "2.5rem",
                            marginRight: "0.6rem",
                            textAlign: "left",
                          }}
                        >
                          ☑
                        </span>
                      )
                    ) : (
                      <Radio
                        checked={sopData.part_type === "prototype"}
                        onChange={(e) => handleChange(e)}
                        value="prototype"
                        color="default"
                        name="part_type"
                        inputProps={{ "aria-label": "prototype" }}
                      />
                    )}
                  </th>
                </tr>

                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    ITEM DESCRIPTION:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {sopData?.part_id?.part_name}
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    OPERATION NAME:{" "}
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {sopData.product_parameter.map(
                      (process, index) =>
                        `${process.process_name} ${
                          sopData.product_parameter.length !== index + 1
                            ? ","
                            : ""
                        }`
                    )}
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    OPERATION NO:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {sopData.product_parameter.map(
                      (process, index) =>
                        `${process.process_no} ${
                          sopData.product_parameter.length !== index + 1
                            ? ","
                            : ""
                        }`
                    )}
                  </th>
                  <th data-b-a-s="thin" colSpan={1} rowSpan={3}>
                    Pre-launch
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    rowSpan={3}
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {isView ? (
                      sopData.part_type === "pre-launch" && (
                        <span
                          style={{
                            fontSize: "2.5rem",
                            marginRight: "0.6rem",
                            textAlign: "left",
                          }}
                        >
                          ☑
                        </span>
                      )
                    ) : (
                      <Radio
                        checked={sopData.part_type === "pre-launch"}
                        onChange={(e) => handleChange(e)}
                        value="pre-launch"
                        color="default"
                        name="part_type"
                        inputProps={{ "aria-label": "part_type" }}
                      />
                    )}
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    MACHINE NO:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {sopData.machine_no}
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    MACHINE NAME:
                  </th>
                  <th data-b-a-s="thin" colSpan={2} data-f-sz="9">
                    {sopData?.machine_name}
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    RAW MATERIAL:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    <TableReadEditCell
                      style={{ width: "100%", textAlign: "left" }}
                      type="text"
                      name="raw_material"
                      value={sopData.raw_material}
                      {...{ isView, handleChange }}
                    />
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    rowSpan={3}
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    Production
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    rowSpan={3}
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {isView ? (
                      sopData.part_type === "production" && (
                        <span
                          style={{
                            fontSize: "2.5rem",
                            marginRight: "0.6rem",
                            textAlign: "left",
                          }}
                        >
                          ☑
                        </span>
                      )
                    ) : (
                      <Radio
                        checked={sopData.part_type === "production"}
                        onChange={(e) => handleChange(e)}
                        value="production"
                        color="default"
                        name="part_type"
                        inputProps={{ "aria-label": "production" }}
                      />
                    )}
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    RAW MATERIAL DIAMETER:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    <TableReadEditCell
                      style={{ width: "100%" }}
                      type="text"
                      name="raw_material_diameter"
                      value={sopData.raw_material_diameter}
                      {...{ isView, handleChange }}
                    />
                  </th>
                </tr>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={1}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    CUSTOMER OR STANDARD:
                  </th>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    data-f-sz="9"
                    style={{ border: "1px solid black", textAlign: "left" }}
                  >
                    {sopData.customer_name}
                  </th>
                </tr>
              </tbody>

              <tbody
                style={{ border: "2px solid black", borderBottom: "none" }}
              >
                <tr>
                  <th fontWeight="bold" style={{ textAlign: "center" }}>
                    WORK INSTRUCTIONS
                  </th>
                  <th
                    colSpan="14"
                    fontWeight="bold"
                    style={{ textAlign: "center" }}
                  >
                    PROCESS & PRODUCT PARAMETER
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      // fontWeight: "lighter",
                      // fontSize: "10px",
                      // textTransform: "none",
                      minWidth: "300px",
                      textAlign: "left",
                      padding: "0 3px",
                    }}
                  >
                    {sopData.instructions?.[0] ? (
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div>{1} </div>
                        <div>{sopData.instructions[0]}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </th>

                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      width: "40px",
                    }}
                  >
                    PROCESS NO.
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      maxWidth: "100px",
                    }}
                  >
                    PROCESS NAME / OPER./DES.
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      maxWidth: "100px",
                    }}
                  >
                    M/C ,DEVICE JIG/FIX.
                  </th>
                  <th
                    colSpan={3}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    CHARACTERISTICS
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      maxWidth: "80px",
                      overflowWrap: "break-word",
                      hyphens: "auto",
                    }}
                  >
                    SPECIAL CHARACTERISTICS
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      minWidth: "130px",
                      border: "1px solid black",
                    }}
                  >
                    PRODUCT/ PROCESS SPEC. TOLERENCES
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      minWidth: "80px",
                    }}
                  >
                    CHECKING METHOD
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      maxWidth: "60px",
                      overflowWrap: "break-word",
                      hyphens: "auto",
                    }}
                  >
                    RECORDING
                  </th>
                  <th
                    colSpan={2}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    SAMPLE
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      minWidth: "100px",
                    }}
                  >
                    CONTROL METHOD (FORMAT NO)
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      width: "30px",
                      border: "1px solid black",
                    }}
                  >
                    RESP.(O/I/S)
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      // fontWeight: "lighter",
                      // fontSize: "10px",
                      // textTransform: "none",
                      minWidth: "300px",
                      textAlign: "left",
                      padding: "0 3px",
                    }}
                  >
                    {sopData.instructions?.[1] ? (
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div>{2} </div>
                        <div>{sopData.instructions[1]}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </th>
                  <th
                    colSpan={1}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    NO.
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      width: "10%",
                    }}
                  >
                    PRODUCT
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      width: "50px",
                    }}
                  >
                    PROCESS
                  </th>
                  <th
                    colSpan={1}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    YES / NO
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      width: "40px",
                      border: "1px solid black",
                    }}
                  >
                    SIZE (n)
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      minWidth: "100px",
                    }}
                  >
                    FREQUENCY
                  </th>
                </tr>
                {sopData.product_parameter?.map((process, index) => {
                  return handleLenght(process?.specs).map((item, idxSpec) => {
                    return (
                      <React.Fragment key={index + "produc"}>
                        <tr>
                          <td
                            style={{
                              minWidth: "300px",
                              textAlign: "left",
                              padding: "0 3px",
                            }}
                          >
                            {sopData.instructions?.[idxSpec + 2] ? (
                              <div style={{ display: "flex", gap: "5px" }}>
                                <div>{idxSpec + 3} </div>
                                <div>{sopData.instructions[idxSpec + 2]}</div>
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>

                          {idxSpec === 0 && (
                            <>
                              <td
                                colSpan={1}
                                rowSpan={handleLenght(process?.specs).length}
                                style={{
                                  minWidth: "40px",
                                }}
                              >
                                {process?.process_no}
                              </td>
                              <td
                                colSpan={1}
                                rowSpan={handleLenght(process?.specs).length}
                                style={{
                                  minWidth: "95px",
                                }}
                              >
                                {process?.process_name}
                              </td>
                              <td
                                colSpan={1}
                                rowSpan={handleLenght(process?.specs).length}
                              >
                                {sopData?.machine_no}
                              </td>
                            </>
                          )}

                          <td colSpan={1}>
                            {Object.keys(item)?.length > 0 && idxSpec + 1}
                          </td>
                          <td
                            style={{
                              minWidth: "100px",
                            }}
                            colSpan={1}
                          >
                            {item?.char_product}
                          </td>
                          <td
                            style={{
                              minWidth: "70px",
                            }}
                            colSpan={1}
                          >
                            {item?.char_process}
                          </td>
                          <td
                            style={{
                              maxWidth: "40px",
                            }}
                            colSpan={1}
                          >
                            {item?.special_character}
                          </td>
                          <td
                            style={{
                              minWidth: "80px",
                            }}
                            colSpan={1}
                          >
                            {item.control_product}
                            {item.control_process && item.control_product
                              ? "/"
                              : ""}
                            {item.control_process}
                            {item.control_product_remark &&
                              `* ${item.control_product_remark}`}
                          </td>
                          <td
                            style={{
                              minWidth: "50px",
                            }}
                          >
                            {item.methods_measure}
                          </td>
                          <td>{item?.recording}</td>
                          <td>{item.methods_size}</td>
                          <td
                            style={{
                              minWidth: "60px",
                            }}
                          >
                            {item.methods_by}
                          </td>
                          <td
                            style={{
                              minWidth: "60px",
                            }}
                          >
                            {item.methods_process}
                          </td>
                          <td
                            style={{
                              minWidth: "60px",
                            }}
                          >
                            {item.responsibility}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  });
                })}
              </tbody>

              <tfoot style={{ border: "2px solid black", borderTop: "none" }}>
                <tr>
                  <th
                    data-b-a-s="thin"
                    colSpan={2}
                    align="left" // Ensure alignment is set to left
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    LEGENDS:-
                  </th>
                  <td
                    data-b-a-s="thin"
                    colSpan={14}
                    align="left" // Ensure alignment is set to left
                    style={{
                      border: "1px solid black",
                      fontWeight: "normal",
                    }}
                    data-f-sz="8"
                  >
                    n = SAMPLE SIZE , O - OPERATOR , I - INSPECTOR , S -
                    SUPERVISOR, N/A or "-" - Not Applicable, K - Critical
                    Characteristic
                  </td>
                </tr>

                {Array.from({ length: 4 }, (_, index) => (
                  <tr key={"footer" + index}>
                    <th
                      data-b-a-s="thin"
                      colSpan={3}
                      data-f-sz="8"
                      style={{ fontSize: "11", textAlign: "left" }} // Ensure alignment is set to left
                    >
                      {footerObj[index] && footerObj[index].name1}
                    </th>
                    <td data-b-a-s="thin" colSpan={5} align="left">
                      {" "}
                      {/* Ensure alignment is set to left */}
                      <TableReadEditCell
                        style={{ width: "100%" }}
                        name={footerObj[index].param1}
                        value={sopData[footerObj[index].param1]}
                        handleChange={(e) => {
                          sopData[e.target.name] = e.target.value;
                          setRender((prev) => !prev);
                        }}
                        {...{ isView }}
                      />
                    </td>
                    <th
                      data-b-a-s="thin"
                      colSpan={3}
                      data-f-sz="8"
                      style={{ fontSize: "11", textAlign: "left" }} // Ensure alignment is set to left
                    >
                      {footerObj[index] && footerObj[index].name2}
                    </th>
                    <td data-b-a-s="thin" colSpan={5} align="left">
                      {" "}
                      {/* Ensure alignment is set to left */}
                      <TableReadEditCell
                        style={{ width: "100%" }}
                        name={footerObj[index].param2}
                        value={sopData[footerObj[index].param2]}
                        handleChange={(e) => {
                          sopData[e.target.name] = e.target.value;
                          setRender((prev) => !prev);
                        }}
                        {...{ isView }}
                      />
                    </td>
                  </tr>
                ))}
              </tfoot>
            </Table>
          </div>
        </>
      )}
    </>
  );
}
