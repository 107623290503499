import React, { useState } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";
import { Wrapper } from "../../components/Control/Flexbox";
import { DatePicker } from "@material-ui/pickers";
import SaveIcon from "@material-ui/icons/Save";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Loader from "../../components/Control/Loader";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/GlobalContext";
import IncomingHeadForm from "./IncomingHeadForm";
import IncomingSpecs from "./IncomingSpecs";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useConfirm from "../../components/Control/useConfirm";
import {
  SaveAndContinueButton,
  CloseButton,
} from "../../GlobalUtils/ButtonsGlobal";

const IncomingForm = ({ incomingData }) => {
  const [isLoading, setIsloading] = useState(false);
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const { state } = useAuth();
  const history = useHistory();
  const [incomingDate, setincomingDate] = useState({
    incoming_mrir_date: "",
    incoming_invoice_date: "",
    incoming_date: "",
  });
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save this Report?"
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    "Are you sure you want to submit this Report?"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    let ans = await confirmSubmit();
    if (ans) {
      setIsloading(true);
      incomingData.isSubmitted = true;
      incomingData.username = state.user.username;
      await axios
        .post("/api/add/incomingReport", incomingData)
        .then((res) => {
          if (res.data?.status === "success") {
            history.push(
              `/view/incomingTable/${incomingData.part_id}?partNumber=${partNumber}&partName=${partName}`
            );
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };

  const saveLater = async () => {
    let ans = await confirmSave();
    if (ans) {
      setIsloading(true);
      incomingData.isSubmitted = false;
      incomingData.username = state.user.username;
      await axios
        .post("/api/add/incomingReport", incomingData)
        .then((res) => {
          if (res.data?.status === "success") {
            history.push(
              `/view/incomingTable/${incomingData.part_id}?partNumber=${partNumber}&partName=${partName}`
            );
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <div
        className="divflex "
        style={{
          fontSize: "24px",
        }}
      >
        INCOMING REPORT
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
      <form
        style={{
          margin: "0.6rem",
          marginTop: "1rem",
        }}
        onSubmit={(e) => handleSubmit(e)}
      >
        <div style={{ marginLeft: "1%" }}>
          <DatePicker
            inputVariant="filled"
            name="incoming_date"
            label="Date"
            value={
              incomingDate.incoming_date || incomingData.incoming_date || null
            }
            onChange={(date) => {
              incomingData.incoming_date = date;
              setincomingDate((prev) => ({
                ...prev,
                incoming_date: date,
              }));
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
        </div>

        <IncomingHeadForm
          {...{ incomingData, incomingDate, setincomingDate }}
        />
        <IncomingSpecs incomingData={incomingData} />
        <Wrapper
          style={{
            position: "absolute",
            top: "4.7rem",
            right: "1.6rem",
            gap: "1rem",
          }}
        >
          <SaveAndContinueButton onClick={saveLater} />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{ backgroundColor: "#054a29", marginRight: "0.5rem" }}
            startIcon={<CloudUploadIcon />}
          >
            Submit
          </Button>
          <CloseButton
            onClick={async () => {
              let ans = await confirmClose();
              if (ans) {
                history.goBack();
              }
            }}
          />
        </Wrapper>
      </form>
      <DialogSave isSubmitContent={true} />
      <DialogSubmit isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
    </main>
  );
};

export default IncomingForm;
