import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const Div = styled.div`
  background-color: #161a1d;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 0.3rem;
  color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
`;

function Foot() {
  return (
    <Div>
      <Typography>
        <span>
          {" "}
          Copyright &copy; {new Date().getFullYear()} Mushin Innovation Labs.
          All Rights Reserved. Mushin™ AQUA Patent Pending.
        </span>
      </Typography>
    </Div>
  );
}

export default Foot;
