import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../Control/formatDate";
import TableToExcel from "@dayalk/table-to-excel";

import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	border: 1px solid black;
    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const WarrantReport = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PART SUBMISSION WARRANT
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "Part Submission Warrant.xlsx",
                      sheet: {
                        name: "Part Submission Warrant",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="15, 30, 35, 30, 23, 38"
          data-b-a-s="thick"
          style={{ border: "1px solid black" }}
        >
          <tr data-height="80" style={{ height: "100px" }}>
            <th
              colSpan="6"
              align="left"
              style={{
                fontSize: "1.6rem",
                textAlign: "center",
              }}
            >
              <CenteredLogoImg>
                <img
                  src={images.CompanyLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
          </tr>

          <tr>
            <td
              colSpan="6"
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              PART SUBMISSION WARRANT
            </td>
          </tr>
          <tr>
            <td align="left" style={{}} colSpan={3}>
              Part Name : {ppapData.part_id.part_name}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Part Number : {ppapData.part_id.part_number}
            </td>
          </tr>
          <tr>
            <td align="left" style={{}} colSpan={3}>
              Show on Drawing Number : {ppapData.draw_no}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Org. Part Number : {ppapData.org_part_no}
            </td>
          </tr>
          <tr>
            <td align="left" style={{}} colSpan={3}>
              Engineering Change Level : {ppapData.change_level}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Dated : {formatDate(ppapData.psw_engg_change_date)}
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={3}>
              Additional Engineering Change : {ppapData.eng_change}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Dated : {formatDate(ppapData.psw_additional_engg_date)}
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={3}>
              <span>Safety and/or Government Regulation : </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_sagr === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_sagr === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td align="left" colSpan={2} style={{}}>
              Purchase Order No.: {ppapData.purchase_order_no}
            </td>
            <td align="left" style={{}}>
              Weight(Kg): {ppapData.warrant_weight}
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={3}>
              Checking Aid Number: {ppapData.checking_aid_no}
            </td>
            <td align="left" colSpan={2} style={{}}>
              Engineering Change Level: {ppapData.change_level}
            </td>
            <td align="left" style={{}}>
              Dated: {formatDate(ppapData.psw_checking_aid_date)}
            </td>
          </tr>

          <tr>
            <th align="left" style={{}} colSpan={4}>
              SUPPLIER MANUFACTURING INFORMATION
            </th>
            <th align="left" colSpan={2} style={{}}>
              SUBMISSION INFORMATION
            </th>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={3}>
              Supplier Name: {ppapData.supplier_name}
            </td>
            <td align="left" colSpan={1} style={{}}>
              Site Code: {ppapData.psw_vedor_code}
            </td>
            {/* change in variable in mongoose modal */}
            <td align="left" style={{}} colSpan={2}>
              {ppapData.SI_dimensional ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Dimensional
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.SI_material_function ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Material/Function
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.SI_appearance ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Appearance
              </span>
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={4}>
              Street Address: {ppapData.street_address}
            </td>
            <td align="left" colSpan={2} style={{}}>
              Customer Name/Division: {ppapData.customer_name}
            </td>
          </tr>

          <tr>
            <td colSpan={4}></td>
            <td align="left" colSpan={2}>
              Buyer/Buyer Code: {ppapData.street_address}
            </td>
          </tr>

          <tr>
            <td align="left">City : {ppapData.psw_city}</td>
            <td align="left">Region: {ppapData.psw_region}</td>
            <td align="left">Postal Code: {ppapData.psw_postal_code}</td>
            <td align="left">Country: {ppapData.psw_country}</td>
            <td align="left">Application : {ppapData.application}</td>
            <td align="left"></td>
          </tr>
          <tr>
            <th align="left" colSpan={2}>
              Note: Has this part been reported in IMDS (or equivalent)?
            </th>

            {/* checkbox there  mongoose modal */}
            <td colSpan={2}>
              {ppapData.psw_hcrsoci === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_hcrsoci === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td align="left" colSpan={2}>
              IMDS ID:{ppapData.psw_moduleId}
            </td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              REASON FOR SUBMISSION (Check at least one)
            </th>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {" "}
              {ppapData.wis ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              Initial submission:{" "}
            </td>
            <td align="left" colSpan={1}>
              {" "}
              {ppapData.wec ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              Change to Optional Construction or Material :
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {" "}
              {ppapData.wis ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              Engineering Change's:{" "}
            </td>
            <td align="left" colSpan={1}>
              {ppapData.wsc ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}{" "}
            </td>

            <td align="left" colSpan={2}>
              Sub-Supplier or Material Source Change:
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.wt ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              Tooling: Transfer, Replacement, Refurbishment, or additional:{" "}
            </td>
            <td align="left" colSpan={1}>
              {ppapData.wcp ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>

            <td align="left" colSpan={2}>
              Change in Part Processing:
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.wcd ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              Correction of Discrepancy:{" "}
            </td>
            <td align="left" colSpan={1}>
              {ppapData.wpl ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              Parts produced at Additional Location:
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {" "}
              {ppapData.wty ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              {"Tooling Inactive > than 1 year: "}
            </td>
            <td align="left" colSpan={1}>
              {ppapData.w_last_psw ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              {"Last PSW > 1 year old:"}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={3}></td>
            <td align="left" colSpan={1}>
              {ppapData.wos ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    // marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    // marginLeft: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={2}>
              Other - please specify:
            </td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              REQUESTED SUBMISSION LEVEL (Check one)
            </th>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={1}
              //  style={{ width: "1px" }}
            >
              {ppapData.rsl === "Level 1" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={5}>
              Level 1 - Warrant only (and for designated appearance items, an
              Appearance Approval Report) submitted to customer.{" "}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.rsl === "Level 2" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={5}>
              Level 2 - Warrant with product samples and limited supporting data
              submitted to customer.{" "}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.rsl === "Level 3" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={5}>
              Level 3 - Warrant with product samples and complete supporting
              data submitted to customer.{" "}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.rsl === "Level 4" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={5}>
              Level 4 - Warrant and complete supporting data submitted to
              customer.{" "}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.rsl === "Level 5" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td align="left" colSpan={5}>
              Level 5 - Warrant with product samples and complete supporting
              data reviewed at supplier's manufacturing location.{" "}
            </td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              SUBMISSION RESULTS
            </th>
          </tr>
          <tr>
            {/* mongoose modal checkbox */}
            <td align="left" colSpan={6}>
              The results for :
              {ppapData.psw_dm ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    // marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    // marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>dimensional measurements</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_mft ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    // marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    // marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>material and functional tests</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_ac ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>appearance criteria</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_ssp ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>statistical process package</span>
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={4}>
              These results meet all drawing and specification requirements
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_rsl_req === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_rsl_req === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td align="left" colSpan={2}>
              (If "NO" - Explanation Required)
            </td>
          </tr>
          {/* checkbox required mongoose modal  */}
          <tr>
            <td align="left" colSpan={6}>
              Mold / Cavity / Production Process :
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_pp}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={3}>
              Was an SCR required and approved to authorize this change?
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.isRequired_SCR === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.isRequired_SCR === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td align="left" colSpan={3}>
              If 'Yes', SCR # :{ppapData.SCR}
            </td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              DECLARATION
            </th>
          </tr>
          <tr data-height="70" height="100px">
            <td
              align="center"
              colSpan={6}
              style={{ border: "1px solid black" }}
            >
              {ppapData.psw_declaration}
              {/* I affirm that the samples represented by this warrant are
              representative of our parts, have been made by a process that
              meets all Production Part Approval Process Manual 4th Edition
              Requirements. I further warrant these samples were produced at the
              production rate of (___/___) hours. I also certify that documented
              evidence of such compliance is on file and available for review. I
              have noted any deviations from this declaration below. */}
            </td>
          </tr>

          <tr>
            <th align="left" colSpan={6}>
              EXPLANATION/COMMENTS:
            </th>
          </tr>

          <tr data-height="40">
            <td align="left" colSpan={6}>
              {ppapData.notes}
            </td>
          </tr>
          <tr>
            {/* checkbox required mongoose modal */}
            <td align="left" colSpan={4}>
              Is each Customer Tool properly tagged and numbered?
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_ctptan === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_ctptan === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td align="left" colSpan={2}>
              Tool Order No. : {ppapData.tool_order}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              Print Name : {ppapData.print_name}
            </td>
            <td align="left" colSpan={2}>
              Title : {ppapData.title}
            </td>
            <td align="left">Phone : {ppapData.phone}</td>
            <td align="left">Fax : {ppapData.fax}</td>
          </tr>
          <tr>
            <td align="left" colSpan={4}>
              Supplier Authorized Signature: {ppapData.supplier_sign}
            </td>
            <td align="left" colSpan={2}>
              Date: {formatDate(ppapData.approve_date)}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={6}>
              Supplier E-mail Address: {ppapData.email_address}
            </td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <th
              align="center"
              colSpan={6}
              style={{ textAlign: "center", borderTop: "1px solid black" }}
            >
              FOR CUSTOMER USE ONLY (IF APPLICABLE)
            </th>
          </tr>
          <tr>
            <td align="left" colSpan={3}>
              Part Warrant Disposition: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span
                style={{
                  fontSize: "1.2rem",
                  marginRight: "0.6rem",
                }}
              >
                □
              </span>
              <span>Approved</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span
                style={{
                  fontSize: "1.2rem",
                  marginRight: "0.6rem",
                }}
              >
                □
              </span>
              <span>Rejected</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
            <td align="left" colSpan={2}>
              Customer Signature :
            </td>
            <td align="left" colSpan={1}>
              Date :
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={4}>
              Email :
            </td>
            <td align="left" colSpan={2}>
              Print Name :
            </td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default WarrantReport;
