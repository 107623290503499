import Description from "@material-ui/icons/Description";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";
import React from "react";
import { Link } from "react-router-dom";
import homePage from "../../components/Admin/HomePage.module.css";
const Tiles = () => {
  return (
    <main
      style={{ height: "100vh" }}
      className={`${homePage.container} ${homePage.main}`}
    >
      <div>
        <Link to={`/masterFormatList/pfd`} className="panel">
          <SwapHorizontalCircle style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          Process Flow
          <span className="card-btn-span">Diagram</span>
        </p>
      </div>
      <div>
        <Link to={`/masterFormatList/controlplan`} className="panel">
          <Description style={{ fontSize: "3rem", color: "maroon" }} />
        </Link>
        <p className="card-btn-para">
          Control <span className="card-btn-span">Plan</span>
        </p>
      </div>
    </main>
  );
};

export default Tiles;
