import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Wrapper } from "../../../components/Control/Flexbox";
import RMSpecs from "./RMSpecs";
import useConfirm from "../../../components/Control/useConfirm";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import RMHeadForm from "./RMHeadForm";
import Modal from "../../../components/Control/Modal";

const AddEditRMPart = () => {
  const { id } = useParams();
  const history = useHistory();
  const [rmData, setRmData] = useState({
    specs: [],
  });
  const [expandState, setExpandState] = useState([]);
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?",
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save ?",
  );
  const [DialogSubmit, confirmSubmit] = useConfirm("CONFIRM", "Are you sure ?");
  useEffect(() => {
    axios
      .get(`/api/getById/rm/${id}`)
      .then((res) => {
        if (res.data) {
          setRmData(res.data);
        }
      })
      .catch((err) => console.error(err));
  }, [id]);

  const handleSubmit = async (submitType) => {
    rmData.isSubmitted = submitType;
    if (id) {
      axios.put(`/api/edit/rm/${id}`, rmData).then((res) => {
        if (res.data.status === "success") {
          alert("RM Receiving updated successfully");
          history.push("/rmpartlist");
        }
      });
    } else {
      axios.post("/api/create/rm", rmData).then((res) => {
        if (res.data.status === "success") {
          alert("RM Receiving created successfully");
          history.push("/rmpartlist");
        }
      });
    }
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div
          className="divflex"
          style={{ fontSize: "24px", justifyContent: "space-between" }}
        >
          EDIT TDS
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#003566",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
              onClick={async () => {
                let ans = await confirmSave();
                if (ans) {
                  handleSubmit(false);
                }
              }}
            >
              Save & Continue
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
              onClick={async () => {
                let ans = await confirmSubmit();
                if (ans) {
                  handleSubmit(true);
                }
              }}
            >
              Submit
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "red",
                marginRight: "0.5rem",
              }}
              startIcon={<CloseIcon />}
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.push("/rmpartlist");
                }
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </main>
      <main style={{ marginLeft: "1rem", paddingBottom: "4rem" }}>
        <RMHeadForm {...{ rmData, setRmData }} />
        <div>
          <Wrapper
            justify="space-between"
            style={{ borderBottom: "1px solid #c80202", margin: "1rem" }}
          >
            <p className="card-btn-para" style={{ color: "#c80202" }}>
              • ADD{" "}
              <span style={{ fontWeight: 400, color: "black" }}>
                SPECIFICATIONS
              </span>
            </p>
            <Wrapper>
              <Wrapper
                style={{
                  height: "45px",
                  width: "175px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  gap: "0.5rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  rmData.specs.push({
                    key: uuidv4(),
                    appearance: "check3",
                    s_no: rmData?.specs.length + 1,
                  });

                  setExpandState((prev) => {
                    prev[rmData?.specs.length - 1] = true;
                    return [...prev];
                  });
                }}
                justify="center"
              >
                <Add />
                <span>Add Specs</span>
              </Wrapper>
            </Wrapper>
          </Wrapper>

          {rmData?.specs.map((spec, index) => (
            <RMSpecs
              key={spec.key}
              {...{
                rmData,
                setRmData,
                spec,
                index,
                expandState,
                setExpandState,
              }}
            />
          ))}
        </div>
        <DialogClose isSubmitContent={false} />
        <DialogSave isSubmitContent={true} />
        <DialogSubmit isSubmitContent={true} />
      </main>
    </>
  );
};

export default AddEditRMPart;
