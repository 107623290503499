import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Add } from "@material-ui/icons";
import lodash from "lodash";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import PfdProcess from "../../AddEditDuplicatePart/PFD/PfdProcess";
import useConfirm from "../../../components/Control/useConfirm";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";

import {
  SaveAndContinueButton,
  CloseButton,
} from "../../../GlobalUtils/ButtonsGlobal";
import MasterECNModal from "../MasterECNModal";
const CreatePfd = () => {
  const { annexureType, documentType, id } = useParams();
  const history = useHistory();
  const [pfdData, setPfdData] = useState({
    processes: [],
  });
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [isECNModal, setIsECNModal] = useState(false);
  const [prevPfdData, setPrevPfdData] = useState(null);
  const [expandState, setExpandState] = useState([]);
  const [render, setRender] = useState(false);

  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE PART",
    "Revision no. already exists! Are you sure you want to overwrite?"
  );
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdatePfd, confirmUpdatePfd] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );

  useEffect(() => {
    axios
      .get(`/api/getMasterById/${documentType}/${id}`)
      .then((res) => {
        if (res.data) {
          setPfdData(res.data);
          setPrevPfdData(lodash.cloneDeep(res.data));
        }
      })
      .catch((err) => console.error(err));
  }, [useEffectRender]);

  const pfdDataHandler = (e, index) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    pfdData.processes[index][e.target.name] = value;
    if (e.target.name === "s_no") {
      pfdData.processes.sort((a, b) => Number(a.s_no) - Number(b.s_no));
      setRender((prev) => !prev);
    }
    if (e.target.name === "movement") {
      setRender((prev) => !prev);
    }
  };

  const handlePfdSubmit = async () => {
    if (pfdData._id) {
      let isUpdate = false;
      let isPost = false;
      const isRev = await axios
        .get(`/api/isMasterRevno/${documentType}/${annexureType}/${pfdData.revision_no}`)
        .catch((err) => console.log(err));
      if (isRev.data.status === "success") {
        if (id) {
          isUpdate = await confirmUpdate();
        } else {
          isUpdate = true;
        }
      }
      if (isRev.data.status === "fail") {
        isPost = true;
      }
      if (isUpdate) {
        let isSave = await confirmUpdatePfd();
        if (isSave) {
          axios
            .put(
              `/api/updateMasterFormat/${documentType}/${pfdData._id}`,
              pfdData
            )
            .then(() => {
              alert("PFD changes saved successfully");
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
      if (isPost) {
        setIsECNModal(true);
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        pfdData.revision_no = 0;
        axios
          .post(`/api/createMasterFormat/:${documentType}`, pfdData)
          .then(() => {
            alert("PFD data submitted successfully");
            setUseEffectRender((prev) => !prev);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const handleSubmitRevision = async (revisionData) => {
    if (lodash.isEmpty(revisionData)) {
      alert("you can't create revision");
      return;
    }
    delete pfdData._id;
    delete pfdData.createdAt;
    delete pfdData.updatedAt;
    delete pfdData.__v;
    pfdData.processes.forEach((process) => {
      delete process._id;
    });
    revisionData = Object.keys(revisionData).map((key) => ({
      rev_no: pfdData.revision_no,
      summary: revisionData[key].value,
      reason: revisionData[key].reason,
      section: revisionData[key].section,
    }));

    pfdData.revisionData = [...pfdData.revisionData, ...revisionData];
    axios
      .post(`/api/createMasterFormat/:${documentType}`, pfdData)
      .then(() => {
        alert("PFD data submitted successfully");
        history.goBack()
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          EDIT PROCESS FLOW DIAGRAM
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <SaveAndContinueButton onClick={handlePfdSubmit} />
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </div>
        </div>
      </main>
      <main style={{ margin: "1rem", paddingBottom: "4rem" }}>
        <Wrapper
          style={{
            margin: "-2rem 1rem 2rem 1rem",
          }}
        >
          <Input
            className="input_field_width"
            type="Number"
            name="revision_no"
            placeholder="Revision Number"
            onChange={(e) =>
              setPfdData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            disabled={!id}
            value={pfdData.revision_no}
          />
          <Input
            className="input_field_width"
            type="text"
            name="pfd_no"
            placeholder="Process Flow Number"
            onChange={(e) =>
              setPfdData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            value={pfdData.pfd_no}
          />
          <FormControl
            variant="filled"
            className="select-field"
            style={{
              marginLeft: "0.5rem",
              width: "20rem",
            }}
          >
            <InputLabel>Select Type</InputLabel>
            <Select
              name="part_type"
              value={pfdData.part_type}
              key={pfdData.part_type}
              onChange={(e) =>
                setPfdData((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Prototype">Prototype</MenuItem>
              <MenuItem value="Pre-Launch">Pre-Launch</MenuItem>
              <MenuItem value="Production">Production</MenuItem>
            </Select>
          </FormControl>
          <Input
            className="input_field_width"
            type="text"
            name="process_identificaton"
            placeholder="Process Identification"
            onChange={(e) =>
              setPfdData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            value={pfdData.process_identificaton}
          />
        </Wrapper>
        <div>
          <Wrapper
            justify="space-between"
            style={{ borderBottom: "1px solid #c80202", marginTop: "1rem" }}
          >
            <p className="card-btn-para" style={{ color: "#c80202" }}>
              • ADD{" "}
              <span style={{ fontWeight: 400, color: "black" }}>PROCESS</span>
            </p>
            <Wrapper>
              <Wrapper
                style={{
                  height: "45px",
                  width: "175px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  gap: "0.5rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  pfdData.processes.push({
                    s_no: pfdData.processes.length + 1,
                    key: uuidv4(),
                    movement: false,
                    add_to_pir: false,
                  });

                  setExpandState((prev) => {
                    prev[pfdData?.processes.length - 1] = true;
                    return [...prev];
                  });
                }}
                justify="center"
              >
                <Add />
                <span>Add Process</span>
              </Wrapper>
            </Wrapper>
          </Wrapper>

          {pfdData &&
            pfdData?.processes.map((process, index) => (
              <PfdProcess
                key={uuidv4()}
                {...{
                  process,
                  index,
                  pfdData,
                  setExpandState,
                  handlePfdSubmit,
                  setPfdData,
                  pfdDataHandler,
                  expandState,
                }}
              />
            ))}
        </div>
        <DialogUpdate />
        <DialogSave isSubmitContent={true} />
        <DialogUpdatePfd isSubmitContent={true} />
        <DialogClose isSubmitContent={false} />
        {isECNModal && (
          <MasterECNModal
            openPopup={isECNModal}
            closeModal={() => setIsECNModal(false)}
            partData={pfdData}
            documentType="pfd"
            ecnTitle="ECN - PFD CHANGES"
            partDataForRevision={prevPfdData}
            submit={handleSubmitRevision}
          />
        )}
      </main>
    </>
  );
};

export default CreatePfd;
