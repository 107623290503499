import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import formatDate from "../../components/Control/formatDate";
import Paper from "@material-ui/core/Paper";
import useConfirm from "../../components/Control/useConfirm";
import { usePermission } from "../../components/usePermission";
import { useParams } from "react-router-dom";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const SopTable = () => {
  const { partId } = useParams();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const classes = useStyles();
  const [render, setRender] = useState(false);
  const [sopLists, setSopLists] = useState([]);
  const permissions = usePermission(3);
  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE SOP",
    "Are you sure you want to delete this sop"
  );
  const getSopListsData = async () => {
    try {
      const response = await axios.get(`/api/getSops/${partId}`);
      setSopLists(response.data?.sops);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSopListsData();
  }, [render]);

  const deleteSopHandler = async (id) => {
    const isDelete = await confirmDelete();
    if (isDelete) {
      try {
        await axios.delete(`/api/deleteSop/${id}`);
        setRender((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // console.log(sopLists);

  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        STANDARD OPERATING PROCEDURE (SOP)
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />

      <div className="subheading-container">
        <p className="subheading-primary">
          View <span className="subheading-secondary">SOP Reports</span>
        </p>
      </div>
      <div>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            aria-label="collapsible table"
            style={{ marginBottom: "5rem" }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  #
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Item Number
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Item Description
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Process Name
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Created At
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sopLists &&
                sopLists?.map((sop, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {sop?.part_id?.part_number}
                    </TableCell>
                    <TableCell align="center">
                      {sop?.part_id?.part_name}
                    </TableCell>
                    <TableCell align="center">{sop.process_name}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "black",
                        width: "250px",
                      }}
                    >
                      {formatDate(sop.createdAt)}
                    </TableCell>

                    <TableCell align="center">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Tooltip title="View SOP" arrow>
                          {permissions?.includes("view") ? (
                            <Link
                              to={`/viewEdit/sop/${sop._id}?partNumber=${partNumber}&partName=${partName}&isView=true&partId=${partId}`}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Link>
                          ) : (
                            <BlockIcon style={{ color: "#c92a2a" }} />
                          )}
                        </Tooltip>
                        {permissions?.includes("edit") && (
                          <Tooltip title="Edit SOP" arrow>
                            <Link
                              to={`/viewEdit/sop/${sop._id}?partNumber=${partNumber}&partName=${partName}&isView=false&partId=${partId}`}
                            >
                              <IconButton
                                size="small"
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                  marginLeft: "0.5rem",
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        )}
                        {permissions?.includes("delete") && (
                          <Tooltip title="Delete SOP" arrow>
                            <IconButton
                              size="small"
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                                marginLeft: "0.5rem",
                              }}
                              onClick={() => {
                                deleteSopHandler(sop._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default SopTable;
