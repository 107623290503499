import React, { useState } from "react";
import Modal from "../../../components/Control/Modal";
import SettingsIcon from "@material-ui/icons/Settings";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

import {
  Accessibility,
  Add,
  Assignment,
  Build,
  ExpandLess,
  ExpandMore,
  Remove,
} from "@material-ui/icons";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Element({ data, title }) {
  const [isDrop, setIsDrop] = useState(true);
  const [value, setValue] = useState("");
  const [render, setRender] = useState(false);
  function append(item) {
    data.push(item);
    setRender((prev) => !prev);
  }
  function remove(index) {
    data.splice(index, 1);
    setRender((prev) => !prev);
  }
  return (
    <div className="m-section">
      <div>
        <Wrapper
          justify="center"
          style={{
            width: "100%",
            backgroundColor: "#1d1d1d",
            color: "white",
            height: "55px",
            gap: "0.4rem",
            fontWeight: 400,
            fontSize: "0.9rem",
          }}
          className="card-btn-para"
        >
          {
            {
              Man: <Accessibility />,
              Machine: <SettingsIcon />,
              Material: <Assignment />,
              Method: <Build />,
            }[title]
          }{" "}
          {title}
        </Wrapper>
        <Wrapper style={{ border: "1px solid #f0f2f0" }}>
          <Input
            type="text"
            name="name"
            key={value ? "notLoadedYet" : "loaded"}
            placeholder={`${title} Name`}
            onBlur={(e) => setValue(e.target.value)}
            defaultValue={value}
          />
          <Wrapper
            style={{
              width: "55px",
              height: "55px",
              backgroundColor: "#c80202",
              color: "white",
              cursor: "pointer",
            }}
            justify="center"
            onClick={() => {
              append(value);
              setValue("");
            }}
          >
            <Add />
          </Wrapper>
        </Wrapper>
        <Wrapper
          style={{ backgroundColor: "#fafafa", height: "55px" }}
          justify="space-between"
        >
          <p
            style={{
              marginBottom: 0,
              marginLeft: "1.3rem",
              fontSize: "0.98rem",
            }}
          >
            {title} List
          </p>
          <Wrapper justify="center">
            {isDrop ? (
              <Wrapper
                style={{
                  height: "55px",
                  width: "55px",
                  backgroundColor: "#3f51b5",
                  cursor: "pointer",
                  color: "white",
                }}
                justify="center"
                onClick={() => setIsDrop(false)}
              >
                <ExpandLess />
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  height: "55px",
                  width: "55px",
                  backgroundColor: "#3f51b5",
                  cursor: "pointer",
                  color: "white",
                }}
                justify="center"
                onClick={() => setIsDrop(true)}
              >
                <ExpandMore />
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
        {isDrop &&
          data.map((item, index) => (
            <Wrapper
              key={index}
              justify="space-between"
              style={{ border: "1px solid #f0f2f0", padding: "0.4rem" }}
            >
              <span>{item}</span>
              <Remove
                onClick={() => remove(index)}
                style={{ color: "red", cursor: "pointer" }}
              />
            </Wrapper>
          ))}
      </div>
    </div>
  );
}

function Quick4M({ closeModal, openModal, target, partId }) {
  const history = useHistory();
  const handleSubmit = () => {
    axios.post("/api/add/Quick4M", data).then((response) => {
      if (response.status === 200) {
        closeModal();
        if (target !== "") history.push(`${target}`);
      }
    });
  };

  const [data, setData] = useState({
    man: [],
    machine: [],
    material: [],
    method: [],
  });

  console.log(data);

  return (
    <Modal closeModal={closeModal} openPopup={openModal} title="QUICK ADD 4M">
      <div
        style={{
          marginTop: "0rem",
          marginLeft: "0rem",
          width: "100%",
        }}
        className="m-wrapper"
      >
        <Element data={data.man} title="Man" />
        <Element data={data.machine} title="Machine" />
        <Element data={data.method} title="Method" />
        <Element data={data.material} title="Material" />
      </div>
      <Wrapper
        style={{
          position: "absolute",
          top: "0.6rem",
          right: "10rem",
          gap: "1rem",
        }}
      >
        <Button
          type="button"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
          style={{ backgroundColor: "#054a29" }}
          startIcon={<SaveIcon />}
        >
          Submit
        </Button>
      </Wrapper>
    </Modal>
  );
}

export default Quick4M;
