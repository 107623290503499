import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { usePermission } from "../../../components/usePermission";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { Add } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import { useRef } from "react";

import { useParams, Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";
import OccMatrixTitleModal from "./OccMatrixTitleModal";
import Visibility from "@material-ui/icons/Visibility";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const OccMatrixModuleList = () => {
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [isModuleModal, setIsModuleModal] = useState(false);
  const history = useHistory();
  const permissions = usePermission(5);
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );
  const setCurrentEditId = useRef("");
  useEffect(() => {
    axios
      .get(`/api/getAllOccMatrixModule`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, isModuleModal]);

  const deleteModuleItem = async (id) => {
    const ans = await confirmDelete();
    if (!ans) {
      return;
    }
    axios
      .delete(`/api/removeOccMatrixModule/${id}`)
      .then((res) => {
        window.alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          OCCURENCE MATRIX LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                history.push(
                  "/genearateFmea/table_view/${item?.fmea_id}?isView=false"
                );
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Visibility />}
            >
              Generate Fmea
            </Button>
            {permissions?.includes("edit") && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setIsModuleModal(true);
                }}
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={<Add />}
              >
                New Report
              </Button>
            )}
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Title
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Date Range
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data.length > 0 && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {item.title}
                      </TableCell>
                      <TableCell align="center">
                        {getYearRange(item.created_year)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        {permissions?.includes("edit") && (
                          <Tooltip title="Edit " arrow>
                            <Link
                              to={`/occMatrixTable/${item?._id}?isView=false&created_year=${item.created_year}`}
                            >
                              <IconButton
                                size="small"
                                style={{ marginLeft: "0.5rem" }}
                              >
                                <EditIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#003566",
                                  }}
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        )}
                        <Tooltip title="View " arrow>
                          <Link
                            to={`/occMatrixTable/${item?._id}?isView=true&created_year=${item.created_year}`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <VisibilityIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#343a40",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        {permissions?.includes("delete") && (
                          <Tooltip title="Delete " arrow>
                            <IconButton
                              size="small"
                              onClick={() => deleteModuleItem(item._id)}
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <DeleteIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#c80202",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Modal
          title={"create new report"}
          openPopup={isModuleModal}
          closeModal={() => {
            setIsModuleModal(false);
            setCurrentEditId.current = "";
          }}
          backgroundColor="white"
        >
          <OccMatrixTitleModal setIsModuleModal={setIsModuleModal} />
        </Modal>
        <DialogDelete isSubmitContent={true} />
      </main>
    </>
  );
};

export default OccMatrixModuleList;
