import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

const style = {
  backgroundColor: "#fafafa",
  padding: "1rem",
  paddingBottom: "1.1rem",
  textAlign: "center",
  margin: 0,
};
const CreateRequestMaterial = ({ requestData, handleChangeRequest }) => {
  return (
    <>
      <hr />
      <Wrapper
        style={{
          marginLeft: "0.5rem",
          alignItems: "start",
          justifyContent: "center",
        }}
        column
      >
        <Wrapper style={{ marginTop: "0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MATERIAL
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Material received from other approved source, not used earlier
          </p>
          <Input
            type="text"
            name="mat_change_1"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mat_change_1}
          />
          <Input
            type="text"
            name="mat_reason_1"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mat_reason_1}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MATERIAL
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Material used from other unapproved source
          </p>
          <Input
            type="text"
            name="mat_change_2"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mat_change_2}
          />
          <Input
            type="text"
            name="mat_reason_2"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mat_reason_2}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MATERIAL
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Different grade/size material used
          </p>
          <Input
            type="text"
            name="mat_change_3"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mat_change_3}
          />
          <Input
            type="text"
            name="mat_reason_3"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mat_reason_3}
          />
        </Wrapper>
      </Wrapper>
    </>
  );
};

export default CreateRequestMaterial;
