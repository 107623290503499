import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

const Observation = ({
  index,
  pdir,
  pdirData,
  handleChangePdirList,
  handleChangePdirText,
}) => {
  function obsLength() {
    if (
      pdirData.quantity >= 2 &&
      pdirData.quantity <= 8 &&
      pdirData.inspection_level === "s1"
    )
      return 2;
    if (
      pdirData.quantity >= 2 &&
      pdirData.quantity <= 8 &&
      pdirData.inspection_level === "s2"
    )
      return 2;
    if (
      pdirData.quantity >= 9 &&
      pdirData.quantity <= 15 &&
      pdirData.inspection_level === "s1"
    )
      return 2;
    if (
      pdirData.quantity >= 9 &&
      pdirData.quantity <= 15 &&
      pdirData.inspection_level === "s2"
    )
      return 2;
    if (
      pdirData.quantity >= 16 &&
      pdirData.quantity <= 25 &&
      pdirData.inspection_level === "s1"
    )
      return 2;
    if (
      pdirData.quantity >= 16 &&
      pdirData.quantity <= 25 &&
      pdirData.inspection_level === "s2"
    )
      return 2;
    if (
      pdirData.quantity >= 26 &&
      pdirData.quantity <= 50 &&
      pdirData.inspection_level === "s1"
    )
      return 2;
    if (
      pdirData.quantity >= 26 &&
      pdirData.quantity <= 50 &&
      pdirData.inspection_level === "s2"
    )
      return 3;
    if (
      pdirData.quantity >= 51 &&
      pdirData.quantity <= 90 &&
      pdirData.inspection_level === "s1"
    )
      return 3;
    if (
      pdirData.quantity >= 51 &&
      pdirData.quantity <= 90 &&
      pdirData.inspection_level === "s2"
    )
      return 3;
    if (
      pdirData.quantity >= 91 &&
      pdirData.quantity <= 150 &&
      pdirData.inspection_level === "s1"
    )
      return 3;
    if (
      pdirData.quantity >= 91 &&
      pdirData.quantity <= 150 &&
      pdirData.inspection_level === "s2"
    )
      return 3;
    if (
      pdirData.quantity >= 151 &&
      pdirData.quantity <= 280 &&
      pdirData.inspection_level === "s1"
    )
      return 3;
    if (
      pdirData.quantity >= 151 &&
      pdirData.quantity <= 280 &&
      pdirData.inspection_level === "s2"
    )
      return 5;
    if (
      pdirData.quantity >= 281 &&
      pdirData.quantity <= 500 &&
      pdirData.inspection_level === "s1"
    )
      return 3;
    if (
      pdirData.quantity >= 281 &&
      pdirData.quantity <= 500 &&
      pdirData.inspection_level === "s2"
    )
      return 5;
    if (
      pdirData.quantity >= 501 &&
      pdirData.quantity <= 1200 &&
      pdirData.inspection_level === "s1"
    )
      return 5;
    if (
      pdirData.quantity >= 501 &&
      pdirData.quantity <= 1200 &&
      pdirData.inspection_level === "s2"
    )
      return 5;
    if (
      pdirData.quantity >= 1201 &&
      pdirData.quantity <= 3200 &&
      pdirData.inspection_level === "s1"
    )
      return 5;
    if (
      pdirData.quantity >= 1201 &&
      pdirData.quantity <= 3200 &&
      pdirData.inspection_level === "s2"
    )
      return 8;
    if (
      pdirData.quantity >= 3201 &&
      pdirData.quantity <= 10000 &&
      pdirData.inspection_level === "s1"
    )
      return 5;
    if (
      pdirData.quantity >= 3201 &&
      pdirData.quantity <= 10000 &&
      pdirData.inspection_level === "s2"
    )
      return 8;
    if (
      pdirData.quantity >= 10001 &&
      pdirData.quantity <= 35000 &&
      pdirData.inspection_level === "s1"
    )
      return 5;
    if (
      pdirData.quantity >= 10001 &&
      pdirData.quantity <= 35000 &&
      pdirData.inspection_level === "s2"
    )
      return 8;
    if (
      pdirData.quantity >= 35001 &&
      pdirData.quantity <= 150000 &&
      pdirData.inspection_level === "s1"
    )
      return 8;
    if (
      pdirData.quantity >= 35001 &&
      pdirData.quantity <= 150000 &&
      pdirData.inspection_level === "s2"
    )
      return 13;
    if (
      pdirData.quantity >= 150001 &&
      pdirData.quantity <= 500000 &&
      pdirData.inspection_level === "s1"
    )
      return 8;
    if (
      pdirData.quantity >= 150001 &&
      pdirData.quantity <= 500000 &&
      pdirData.inspection_level === "s2"
    )
      return 13;
    if (pdirData.quantity >= 500001 && pdirData.inspection_level === "s1")
      return 8;
    if (pdirData.quantity >= 500001 && pdirData.inspection_level === "s2")
      return 13;
  }
  return (
    <>
      <Wrapper>
        {Array.from({ length: obsLength() }, () => "").map((_, obsIndex) => (
          <>
            {(pdir.appearance === "check1" ||
              pdir.appearance === "check2" ||
              pdir.appearance === "check4") && (
              <Input
                name={`obs_${obsIndex + 1}`}
                label={obsIndex + 1}
                type="number"
                variant="filled"
                inputProps={{
                  step: 0.0001,
                  style: {
                    borderColor: pdir[`obs_${obsIndex + 1}_color`],
                    backgroundColor: pdir[`obs_${obsIndex + 1}_color`],
                  },
                }}
                onChange={(e) =>
                  handleChangePdirList(
                    e,
                    index,
                    pdir.mean,
                    pdir.utolerance,
                    pdir.ltolerance,
                    pdir.utl,
                    pdir.ltl,
                    pdir?.check4_symbol,
                    pdir?.checkbox4_num,
                  )
                }
                defaultValue={pdir[`obs_${obsIndex + 1}`]}
              />
            )}
            {pdir.appearance === "check3" && (
              <FormControl
                variant="filled"
                className="select-field"
                style={{
                  marginTop: "1rem",
                  margin: "0.6rem",
                  width: "192px",
                }}
              >
                <InputLabel>{obsIndex + 1}</InputLabel>
                <Select
                  name={`obs_${obsIndex + 1}`}
                  defaultValue={pdir[`obs_${obsIndex + 1}`]}
                  onChange={(e) => handleChangePdirText(e, index)}
                >
                  <MenuItem>None</MenuItem>
                  <MenuItem value="ok">OK</MenuItem>
                  <MenuItem value="not ok">NOT OK</MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        ))}
      </Wrapper>
    </>
  );
};

export default Observation;
