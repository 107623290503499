import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CreateSetupProcessBody from "./CreateSetupProcessBody";

const CreateSetupProcessHead = ({ setupData }) => {
  const [isProcess, setIsProcess] = useState([]);

  const hidePir = (index) => {
    setIsProcess((prev) => [...prev, index]);
  };

  const openPir = (index) => {
    const values = [...isProcess];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsProcess(values);
  };
  return (
    <div style={{ margin: "1rem 0.6rem" }}>
      {setupData.processes?.map((process, index) => (
        <div key={process._id} style={{ marginTop: "0.1rem" }}>
          {/* start header */}
          <main style={{ border: "1px solid #f0f2f0" }}>
            <Wrapper
              style={{
                background: "#fafafa",
              }}
              justify="space-between"
            >
              <Wrapper>
                <Wrapper
                  style={{
                    fontSize: "0.9rem",
                    height: "45px",
                    width: "45px",
                    fontWeight: 500,
                    color: "white",
                    backgroundColor: "#1d1d1d",
                  }}
                  justify="center"
                >
                  {index + 1}
                </Wrapper>
                <Wrapper
                  justify="center"
                  style={{
                    marginLeft: "1.3rem",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  {process.process_name}
                </Wrapper>
              </Wrapper>
              <Wrapper>
                {isProcess.includes(index) ? (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => openPir(index)}
                  >
                    <ExpandLess />
                  </Wrapper>
                ) : (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => hidePir(index)}
                  >
                    <ExpandMore />
                  </Wrapper>
                )}
              </Wrapper>
            </Wrapper>
            {isProcess.includes(index) && (
              <CreateSetupProcessBody {...{ process, index }} />
            )}
          </main>

          {/* end header */}
        </div>
      ))}
    </div>
  );
};

export default CreateSetupProcessHead;
