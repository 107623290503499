import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ControlPlan from "../components/ControlPlan";
import AdminModules from "../Pages/AdminModules";
import PageNotFound from "../components/PageNotFound";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PCDIndex from "../Pages/PCD";
import Admin from "../components/Admin";
import Login from "../components/Login";
import Supervisor from "../components/Supervisor";
import ViewAdminPartTable from "../Pages/ViewAdminPartTable";
import RevisedTable from "../Pages/RevisedTable/RevisedTable";
import Test from "../components/Test";
import Account from "../components/Account";
import PfdDiagram from "../components/PfdDiagram";
import { useAuth } from "../context/GlobalContext";
import Users from "../components/Users/Users";
import DashBoard from "../components/DashBoard";
import SupervisorPartTable from "../Pages/SupervisorPartTable";
import PartWiseAnalysis from "../components/PartWiseAnalysis";
import FailureModeWiseRejection from "../components/FailureModeWiseRejection.jsx";
import FmeaTable from "../Pages/FmeaReports/FmeaTable";
import PdirMonthlyTable from "../Pages/PDIR/PdirMonthlyTable/PdirMonthlyTable";
import CreatePdir from "../Pages/PDIR/CreatePdir";
import UpdatePdir from "../Pages/PDIR/EditPdir";
import ViewPdirExcel from "../Pages/PDIR/ViewPdirTable";
import SupervisorView4MTable from "../Pages/4M/SupervisorView4MTable";
import AdminView4MTable from "../Pages/4M/AdminView4MTable";
import CreateRequestForm from "../Pages/4M/CreateRequestForm";
import EditRequestForm from "../Pages/4M/EditRequestForm";
import CreateSetupForm from "../Pages/4M/CreateSetupForm";
import EditSetupForm from "../Pages/4M/EditSetupForm";
import ViewSetupForm from "../Pages/4M/ViewSetupForm";
import ViewRequestForm from "../Pages/4M/ViewRequestForm";
import View4MDatabse from "../Pages/4M/View4MDatabase";
import GeneratePPAP from "../components/GeneratePPAP";
import SelectPPAP from "../components/GeneratePPAP/SelectPPAP";
import EditPPAP from "../components/GeneratePPAP/EditPPAP";
import ViewPrintout from "../components/GeneratePPAP/ViewPrintout/ViewPrintout";
import FmeaAllList from "../Pages/FmeaReports/FmeaAllList";
import ViewFmeaTable from "../Pages/FmeaReports/ViewFmeaTable";
import MSATable from "../Pages/MSA/MSATable";
import CreateMSA from "../Pages/MSA/CreateMSA";
import EditMSA from "../Pages/MSA/EditMSA";
import ViewIncomingTable from "../Pages/IncomingReport/Incomingtable";
import CreateInspection from "../Pages/IncomingReport/CreateIncoming";
import ViewIncoming from "../Pages/IncomingReport/ViewIncoming";
import UpdateIncoming from "../Pages/IncomingReport/UpdateIncoming";
import PartList from "../Pages/AddEditDuplicatePart/PartList";
import QuickView from "../Pages/AddEditDuplicatePart/quickView/QuickView";
import CreatePfd from "../Pages/AddEditDuplicatePart/PFD/CreatePfd";
import CreatePfmea from "../Pages/AddEditDuplicatePart/PFMEA/CreatePfmea";
import CreateControlPlan from "../Pages/AddEditDuplicatePart/ControlPLan/CreateControlPlan";
import PfmeaIndex from "../Pages/Diagram/FMEA/PfmeaIndex";
import customerDrawingTable from "../Pages/CustomerDrawing/CustomerDrawing";
import ViewCustomerDrawing from "../Pages/CustomerDrawing/ViewCustomerDrawing";
import ViewDailyProduction from "../Pages/DailyProduction/dailyProductionOld/DailyProduction";
import DialyProductionTable from "../Pages/DailyProduction/dailyProductionOld/DailyProductionTable";
import ViewDailyProductionTable from "../Pages/DailyProduction/dailyProductionOld/ViewDailyProductionTable";
import createProductionPlanning from "../Pages/ProductionPlanning/ProductionPlanning";
import createProductionPlanningTable from "../Pages/ProductionPlanning/ProductionPlanningTable";
import viewProductionPlanningTable from "../Pages/ProductionPlanning/ViewProductionPlanning";
import MaterialTcTable from "../Pages/MaterialTc/MaterialTcTable";
import ViewCMaterialTc from "../Pages/MaterialTc/ViewMaterialTC";
import ProcessProduct from "../Pages/ProcessProductValidation/ProcessProductValidation";
import ViewProcessProduct from "../Pages/ProcessProductValidation/ViewProcessProductValidation";
import DocumentDataControl from "../Pages/DocumentDataControl/DocumentDataControl";
import ViewDocumentDataControl from "../Pages/DocumentDataControl/ViewDocumentDataControl";
import MiscDocumentsDrawings from "../Pages/MiscDocumentsDrawings/MiscDocumentsDrawings";
import ViewMiscDocumentsDrawings from "../Pages/MiscDocumentsDrawings/ViewMiscDocumentsDrawings";
import AdminRMRecieving from "../Pages/AdminRMReceiving";
import AddEditRMPart from "../Pages/AdminRMReceiving/AddEditRMPart/AddEditRMPart";
import ViewRMDiagram from "../Pages/AdminRMReceiving/ViewRMDiagram";
import IncomingBreakloadList from "../Pages/RMIncomingBreakLoad/IncomingBreakloadList";
import IncomingBreakloadTab from "../Pages/RMIncomingBreakLoad/IncomingBreakloadTab";
import SopTable from "../Pages/Sop/SopTable";
import SopOutputSheet from "../Pages/Sop/SopOutputSheet";
import AnnexureManagement from "../Pages/AnnexureManagement";
import InspectionReportTabs from "../Pages/PIR/InspectionReportTabs";
import ViewMonthlyPirAdmin from "../Pages/PIR/ViewMonthlyPirAdmin";
import ViewMonthyPirSupervisor from "../Pages/PIR/ViewMonthyPirSupervisor";
import MachineList from "../Pages/Machine/MachineList";
import PartListFmea from "../Pages/fmeaManagement/PartList";
import DepartmentsList from "../Pages/Departments/DepartmentsList";

// Master Format
import {
    MasterControlFmeaPfdList,
    MasterCreateControlPlan,
    MasterCreateFmea,
    MasterCreatePfd,
    MasterTiles,
    MasterViewControlPlan,
    MasterViewFmea,
    MasterViewPfd,
} from "../Pages/MasterFormat";

import {
    MasterListCreate,
    ListOfMasterList,
} from "../Pages/MasterModule/Index";
import InsrumentsList from "../Pages/Instruments/InsrumentsList";

// fmea management
import FmeaTiles from "../Pages/fmeaManagement/FmeaTiles";
import FmeaTableNew from "../Pages/fmeaManagement/generateFmea/FmeaTable";
import OccMatrixModuleTable from "../Pages/fmeaManagement/occMatrixModule/OccMatrixModuleTable";
import OccMatrixModuleList from "../Pages/fmeaManagement/occMatrixModule/OccMatrixModuleList";
// fmea management  other modules
import CreateViewOccMatrixTable from "../Pages/fmeaManagement/occMatrix/CreateViewOccMatrixTable";
import CreateViewPriorityLevelTable from "../Pages/fmeaManagement/priorityLevel/CreateViewPriorityLevelTable";
import CreateViewSeverityZoneTable from "../Pages/fmeaManagement/severityZone/CreateViewSeverityZoneTable";
import CreateViewDetectionZoneTable from "../Pages/fmeaManagement/detectionZone/CreateViewDetectionZoneTable";
import DeviceList from "../Pages/DeviceList/DeviceList";
import ProcessProductAuditList from "../Pages/processProductAudit/ProcessProductAuditList";
import CreateProcessProductAudit from "../Pages/processProductAudit/CreateProcessProduct";
import SpcTiles from "../components/Admin/SpcTiles";
import MsaTiles from "../components/Admin/MsaTiles";
import MasterFmeaList from "../Pages/fmeaManagement/fmeaFormat/FmeaList";
import AnnualProcessProductAuditTile from "../components/Admin/AnnualProcessProductAuditTiles.jsx";
import FullScreenSop from "../Pages/Sop/FullScreenSop";
import PirTableList from "../Pages/allPirs/PirTableList";
import RmTableList from "../Pages/allRmReceiving/RmTableList";

function Container() {
    const { state } = useAuth();
    if (state.user) {
        if (state.user.userType == 2) {
            return (
                <>
                    <BrowserRouter>
                        <Header />
                        <Switch>
                            <Route path="/account" component={Account} />
                            <Route exact path={["/admin", "/"]} component={Admin} />
                            <Route exact path="/dashboard" component={DashBoard} />

                            <Route
                                path="/rejectionAnalysis/partwiseAnalysis/:partName"
                                component={PartWiseAnalysis}
                            />
                            <Route
                                path="/rejectionAnalysis/failurewiseAnalysis/:partName"
                                component={FailureModeWiseRejection}
                            />
                            <Route path="/userManagement" component={Users} />

                            {/* new add part feature  prashant */}
                            <Route path="/part_list" component={PartList} />
                            <Route
                                exact
                                path="/quick_view_documents/:partId"
                                component={QuickView}
                            />
                            <Route
                                exact
                                path="/edit/pfd/:partId/:id?"
                                component={CreatePfd}
                            />
                            <Route
                                exact
                                path="/edit/fmea/:partId/:id?"
                                component={CreatePfmea}
                            />
                            <Route
                                exact
                                path="/edit/controlplan/:partId/:id?"
                                component={CreateControlPlan}
                            />
                            <Route path="/spcTiles" component={SpcTiles} />
                            <Route path="/msaTiles" component={MsaTiles} />
                            {/* end line of new add part feature */}

                            <Route path="/pfd/diagram/:partId/:id" component={PfdDiagram} />
                            <Route path="/view_process/:partId" component={AdminModules} />
                            <Route path="/viewPartTable" component={ViewAdminPartTable} />
                            <Route
                                path="/revisedTable/:revisedType/:partId"
                                component={RevisedTable}
                            />
                            <Route path="/fmea/diagram/:partId/:id" component={PfmeaIndex} />
                            <Route path="/generatefmea/report/:id?" component={FmeaTable} />
                            <Route path="/control_plan/:partId/:id" component={ControlPlan} />
                            <Route path="/pcd/:partId" component={PCDIndex} />
                            <Route exact path="/admin/view4m" component={View4MDatabse} />

                            {/* PDIR */}
                            <Route path="/pdir/edit/:id" component={UpdatePdir} />
                            <Route path="/view/pdirTable/:id" component={ViewPdirExcel} />

                            <Route
                                exact
                                path="/viewpdir/monthly/:partId"
                                component={PdirMonthlyTable}
                            />

                            {/* End PDIR */}
                            <Route exact path="/select/ppap/:partId" component={SelectPPAP} />
                            <Route path="/generate/ppap/:partId" component={GeneratePPAP} />
                            <Route path="/edit/ppap/:partId/:id" component={EditPPAP} />
                            <Route path="/admin/view/4mTable" component={AdminView4MTable} />
                            <Route
                                exact
                                path="/view4m/setupform/:id"
                                component={ViewSetupForm}
                            />
                            <Route
                                path="/view4m/requestform/:id"
                                component={ViewRequestForm}
                            />
                            <Route path="/spc/:partId" component={Test} />
                            <Route exact path="/view/ppap" component={ViewPrintout} />
                            <Route
                                exact
                                path="/viewfmeaTable/fmea/:partId"
                                component={FmeaAllList}
                            />
                            <Route exact path="/view/fmea/:id" component={ViewFmeaTable} />

                            <Route path="/view/msaTable/:partId" component={MSATable} />
                            <Route path="/create/msa/:partId" component={CreateMSA} />
                            <Route path="/edit/msa/:id" component={EditMSA} />
                            <Route path="/view/msa/:id" component={null} />

                            {/* Incoming report routes for Admin*/}
                            <Route
                                path="/view/incomingTable/:partId"
                                component={ViewIncomingTable}
                            />
                            <Route
                                path="/create/incoming/:partId"
                                component={CreateInspection}
                            />
                            <Route
                                path="/view/incoming/excelTable/:id"
                                component={ViewIncoming}
                            />
                            <Route path="/edit/incoming/:id" component={UpdateIncoming} />

                            {/* customer drawing for Admin*/}
                            <Route
                                path="/customerDrawing/table/:partId"
                                component={customerDrawingTable}
                            />
                            <Route
                                path="/viewCustomerDrawing/:partId"
                                component={ViewCustomerDrawing}
                            />

                            {/* daily production */}
                            <Route
                                path="/viewDailyProduction"
                                component={ViewDailyProduction}
                            />
                            <Route
                                path="/createDailyProduction/table/:id?"
                                component={DialyProductionTable}
                            />
                            <Route
                                path="/viewDailyProductiontable/:id?"
                                component={ViewDailyProductionTable}
                            />

                            {/*   Production Planning & Control" */}
                            <Route
                                path="/productionPlanningcontrol/create"
                                component={createProductionPlanning}
                            />
                            <Route
                                path="/createProductionPlanning/table/:id?"
                                component={createProductionPlanningTable}
                            />
                            <Route
                                path="/viewProductionPlanning/:id?"
                                component={viewProductionPlanningTable}
                            />

                            {/* Material TC for Admin*/}
                            <Route path="/materialTc/table" component={MaterialTcTable} />
                            <Route
                                path="/viewMaterialTc/:partId"
                                component={ViewCMaterialTc}
                            />
                            {/* Process Production TC for Admin*/}
                            <Route
                                path="/processProductData/table"
                                component={ProcessProduct}
                            />
                            <Route
                                path="/viewProcessProductData/:partId"
                                component={ViewProcessProduct}
                            />

                            {/* Document & Data Control for Admin*/}
                            <Route
                                path="/documentDataControl/table"
                                component={DocumentDataControl}
                            />
                            <Route
                                path="/viewDocumentDataControl/:partId"
                                component={ViewDocumentDataControl}
                            />

                            {/* Misc. Documents & Drawings for admin*/}
                            <Route
                                path="/miscDocumentsDrawings/table"
                                component={MiscDocumentsDrawings}
                            />
                            <Route
                                path="/viewMiscDocumentsDrawings/:partId"
                                component={ViewMiscDocumentsDrawings}
                            />

                            <Route exact path="/viewAllRmTableList" component={RmTableList} />
                            {/* pir */}
                            <Route
                                exact
                                path="/viewAllPirTableList"
                                component={PirTableList}
                            />
                            <Route
                                exact
                                path="/viewAdmin/pirLists/:partId"
                                component={ViewMonthlyPirAdmin}
                            />
                            <Route
                                exact
                                path="/admin/CreateEditPir/:partId/:id?"
                                component={InspectionReportTabs}
                            />
                            {/* End Pir */}
                            {/* rm receiving and rm breaklod for admin */}
                            <Route path="/rmpartlist" component={AdminRMRecieving} />
                            <Route path="/add/edit/rmPart/:id?" component={AddEditRMPart} />
                            <Route path="/view/rm/:id" component={ViewRMDiagram} />
                            <Route
                                path="/incoming/breakload/:rmId"
                                component={IncomingBreakloadList}
                            />
                            <Route
                                path="/incomingbreakload/tab/:rmId/:id?"
                                component={IncomingBreakloadTab}
                            />

                            {/* SOP Module for admin */}

                            <Route exact path="/view/sopTable/:partId" component={SopTable} />
                            <Route path="/viewEdit/sop/:id" component={SopOutputSheet} />
                            <Route path="/viewFullScreenSop/:id" component={FullScreenSop} />

                            {/* Anexxure */}
                            <Route
                                path="/annexure_management"
                                component={AnnexureManagement}
                            />
                            {/* Master list of Machine  */}
                            <Route path="/machine_list" component={MachineList} />

                            {/* MasterFormat  */}
                            <Route path="/masterFormatDocumentTile" component={MasterTiles} />
                            <Route
                                path="/masterFormatList/:documentType"
                                component={MasterControlFmeaPfdList}
                            />
                            <Route
                                path="/masterFormatList/:documentType"
                                component={MasterControlFmeaPfdList}
                            />
                            <Route
                                path="/createControlplanFormat/:documentType/:annexureType/:id"
                                component={MasterCreateControlPlan}
                            />
                            <Route
                                path="/viewControlplanFormat/:documentType/:id"
                                component={MasterViewControlPlan}
                            />
                            <Route
                                path="/processProductAuditList/:partId"
                                component={ProcessProductAuditList}
                            />
                            <Route
                                path="/createProcessProductAudit/:partId/:id?"
                                component={CreateProcessProductAudit}
                            />
                            <Route
                                path="/createFmeaFormat/:documentType/:annexureType/:id"
                                component={MasterCreateFmea}
                            />
                            <Route path="/masterFmeaFormatList" component={MasterFmeaList} />
                            <Route
                                path="/viewFmeaFormat/:documentType/:id"
                                component={MasterViewFmea}
                            />
                            <Route
                                path="/createPfdFormat/:documentType/:annexureType/:id"
                                component={MasterCreatePfd}
                            />

                            <Route
                                path="/viewPfdFormat/:documentType/:id"
                                component={MasterViewPfd}
                            />
                            {/* master list module */}
                            <Route path="/listMasterList" component={ListOfMasterList} />
                            <Route path="/masterListCreate" component={MasterListCreate} />

                            {/* instrument */}
                            <Route path="/instrumentsList/" component={InsrumentsList} />

                            {/* fmea management  big module consists of 5 other module named as fmea , occ , sev , det , priority */}

                            <Route path="/fmeamanagement" component={FmeaTiles} />
                            <Route
                                path="/fmeamanagementPartTable/:docType"
                                component={PartListFmea}
                            />
                            {/* generate fmea  */}
                            <Route
                                path="/genearateFmea/table_view/:fmeaId?"
                                component={FmeaTableNew}
                            />
                            {/* end of generate fmea  */}

                            {/* occ matrix module  */}
                            <Route
                                path="/occMatrixModuleList"
                                component={OccMatrixModuleList}
                            />
                            <Route path="/deviceList" component={DeviceList} />
                            <Route
                                path="/occMatrixTable/:occMatrixId?"
                                component={OccMatrixModuleTable}
                            />
                            {/*end of  occ matrix module  */}
                            {/* remaining fmea module  */}

                            <Route
                                path="/tableFmeaOccurenceMatrix"
                                component={CreateViewOccMatrixTable}
                            />
                            <Route
                                path="/tableFmeaSeverityZone"
                                component={CreateViewSeverityZoneTable}
                            />
                            <Route
                                path="/tableFmeaDetectionZone"
                                component={CreateViewDetectionZoneTable}
                            />
                            <Route
                                path="/tableFmeaPriorityLevel"
                                component={CreateViewPriorityLevelTable}
                            />

                            {/*  master list ofdepartment  */}
                            <Route path="/allDepartment/" component={DepartmentsList} />
                            {/* end of fmea management  big module consists of 5 other module named as fmea , occ , sev , det , priority  */}

                            <Route
                                path="/annualProcessProductAuditTile"
                                component={AnnualProcessProductAuditTile}
                            />

                            <Route component={PageNotFound} />
                        </Switch>
                        <Footer />
                    </BrowserRouter>
                </>
            );
        } else {
            return (
                <>
                    <BrowserRouter>
                        <Header />
                        <Switch>
                            <Route path="/account" component={Account} />
                            <Route exact path={["/Supervisor", "/"]} component={Supervisor} />
                            <Route path="/fmea/diagram/:partId/:id" component={PfmeaIndex} />
                            <Route path="/viewPartTable" component={ViewAdminPartTable} />
                            <Route exact path="/supervisor/dashboard" component={DashBoard} />
                            <Route
                                path="/rejectionAnalysis/partwiseAnalysis/:partName"
                                component={PartWiseAnalysis}
                            />
                            <Route path="/control_plan/:partId/:id" component={ControlPlan} />
                            <Route path="/pfd/diagram/:partId/:id" component={PfdDiagram} />
                            <Route
                                path="/rejectionAnalysis/failurewiseAnalysis/:partName"
                                component={FailureModeWiseRejection}
                            />
                            <Route path="/pcd/:partId" component={PCDIndex} />

                            {/* pir */}
                            <Route
                                exact
                                path="/supervisor/CreateEditPir/:partId/:id?"
                                component={InspectionReportTabs}
                            />
                            <Route
                                exact
                                path="/viewSupervisor/pirLists/:partId"
                                component={ViewMonthyPirSupervisor}
                            />
                            <Route
                                exact
                                path="/viewAllPirTableList"
                                component={PirTableList}
                            />
                            {/* End Pir */}
                            <Route exact path="/viewAllRmTableList" component={RmTableList} />

                            <Route
                                path="/revisedTable/:revisedType/:partId"
                                component={RevisedTable}
                            />
                            <Route
                                path="/supervisor/view/4mTable"
                                component={SupervisorView4MTable}
                            />
                            <Route
                                path="/create/request_form/:partId"
                                component={CreateRequestForm}
                            />
                            <Route
                                exact
                                path="/edit/request_form/:id"
                                component={EditRequestForm}
                            />
                            <Route
                                exact
                                path="/edit/setup_form/:id"
                                component={EditSetupForm}
                            />
                            <Route
                                path="/create/setup_form/:id"
                                component={CreateSetupForm}
                            />
                            <Route
                                exact
                                path="/supervisor/viewparttable/:featureName"
                                component={SupervisorPartTable}
                            />
                            <Route
                                exact
                                path="/supervisor/view4m"
                                component={View4MDatabse}
                            />

                            {/* PDIR */}

                            <Route
                                exact
                                path="/viewpdir/monthly/:partId"
                                component={PdirMonthlyTable}
                            />
                            <Route exact path="/pdir/add/:partId" component={CreatePdir} />
                            <Route path="/pdir/edit/:id" component={UpdatePdir} />
                            <Route path="/view/pdirTable/:id" component={ViewPdirExcel} />

                            {/* End PDIR */}
                            <Route path="/view4m/setupform/:id" component={ViewSetupForm} />
                            <Route
                                path="/view4m/requestform/:id"
                                component={ViewRequestForm}
                            />
                            <Route path="/view/msaTable/:partId" component={MSATable} />
                            <Route path="/create/msa/:partId" component={CreateMSA} />
                            <Route path="/edit/msa/:id" component={EditMSA} />
                            <Route path="/view/msa/:id" component={null} />

                            {/* Incoming report routes */}
                            <Route
                                path="/view/incomingTable/:partId"
                                component={ViewIncomingTable}
                            />
                            <Route
                                path="/create/incoming/:partId"
                                component={CreateInspection}
                            />
                            <Route
                                path="/view/incoming/excelTable/:id"
                                component={ViewIncoming}
                            />
                            <Route path="/edit/incoming/:id" component={UpdateIncoming} />

                            {/* customer drawing for supervisor*/}
                            <Route
                                path="/customerDrawing/table/:partId"
                                component={customerDrawingTable}
                            />
                            <Route
                                path="/viewCustomerDrawing/:partId"
                                component={ViewCustomerDrawing}
                            />

                            {/* Material TC for supervisor*/}
                            <Route path="/materialTc/table" component={MaterialTcTable} />
                            <Route
                                path="/viewMaterialTc/:partId"
                                component={ViewCMaterialTc}
                            />

                            {/* daily production */}
                            <Route
                                path="/viewDailyProduction/"
                                component={ViewDailyProduction}
                            />
                            <Route
                                path="/createDailyProduction/table/:id?"
                                component={DialyProductionTable}
                            />
                            <Route
                                path="/viewDailyProductiontable/:id?"
                                component={ViewDailyProductionTable}
                            />

                            {/*   Production Planning & Control" */}
                            <Route
                                path="/productionPlanningcontrol/create"
                                component={createProductionPlanning}
                            />
                            <Route
                                path="/createProductionPlanning/table/:id?"
                                component={createProductionPlanningTable}
                            />
                            <Route
                                path="/viewProductionPlanning/:id?"
                                component={viewProductionPlanningTable}
                            />

                            {/* Process Production TC for supervisor*/}
                            <Route
                                path="/processProductData/table"
                                component={ProcessProduct}
                            />
                            <Route
                                path="/viewProcessProductData/:partId"
                                component={ViewProcessProduct}
                            />
                            {/* Document & Data Control for supervisor*/}
                            <Route
                                path="/documentDataControl/table"
                                component={DocumentDataControl}
                            />
                            <Route
                                path="/viewDocumentDataControl/:partId"
                                component={ViewDocumentDataControl}
                            />
                            {/* Misc. Documents & Drawings for supervisor*/}
                            <Route
                                path="/miscDocumentsDrawings/table"
                                component={MiscDocumentsDrawings}
                            />
                            <Route
                                path="/viewMiscDocumentsDrawings/:partId"
                                component={ViewMiscDocumentsDrawings}
                            />

                            {/* rm for supervisor */}

                            <Route path="/rmpartlist" component={AdminRMRecieving} />
                            <Route
                                path="/incoming/breakload/:rmId"
                                component={IncomingBreakloadList}
                            />
                            <Route
                                path="/incomingbreakload/tab/:rmId/:id?"
                                component={IncomingBreakloadTab}
                            />

                            {/* SOP Module for Supervisior  */}
                            <Route path="/view/sopTable/:partId" component={SopTable} />
                            <Route path="/viewEdit/sop/:id" component={SopOutputSheet} />
                            <Route path="/viewFullScreenSop/:id" component={FullScreenSop} />
                            {/* Master list of Machine  */}
                            <Route path="/machine_list" component={MachineList} />
                            {/* instrument */}
                            <Route path="/instrumentsList/" component={InsrumentsList} />
                            {/*  master list ofdepartment  */}
                            <Route path="/allDepartment/" component={DepartmentsList} />
                            <Route component={PageNotFound} />
                        </Switch>
                        <Footer />
                    </BrowserRouter>
                </>
            );
        }
    } else {
        return (
            <>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={Login} />
                    </Switch>
                </BrowserRouter>
            </>
        );
    }
}

export default Container;
