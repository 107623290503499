import React from "react";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import { images, proxy_img, textInputs } from "../../../constants/constants";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const PackingStandard = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PACKING DETAILS
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "Packing Standard Details.xlsx",
                      sheet: {
                        name: "PACKING STANDARD",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="10, 35, 30, 15, 30, 60"
        >
          <tr>
            <th
              colSpan="5"
              align="left"
              rowSpan={5}
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
            >
              <CenteredLogoImg>
                <img src={images.CompanyLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th align="left" style={{ border: "1px solid black" }}>
              Format No: F APQP 24
            </th>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              ISSUE DATE: April 19
            </th>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              REVISION NO: 00
            </th>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              REVISION DATE: 00
            </th>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              Doc. No.: SLF APQP 01
            </th>
          </tr>

          <tr>
            <td
              colSpan="6"
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              PACKING STANDARD
            </td>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={6}
              style={{
                border: "1px solid black",
              }}
            >
              Customer: {ppapData.customer_name}
            </td>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={6}
              style={{
                border: "1px solid black",
              }}
            >
              Supplier: {ppapData.supplier_name}
            </td>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={2}
              style={{
                border: "1px solid black",
              }}
            >
              Supplier Location: {ppapData.supplier_name}
            </td>
            <td
              align="left"
              colSpan={3}
              style={{
                border: "1px solid black",
              }}
            >
              Part Description: {ppapData?.part_id?.part_name}
            </td>
            <td
              align="left"
              style={{
                border: "1px solid black",
              }}
            >
              Part No.: {ppapData?.part_id?.part_name}
            </td>
          </tr>

          <tr>
            <td
              align="left"
              colSpan={2}
              style={{
                border: "1px solid black",
              }}
            >
              Tyres Of Container Use: {ppapData.type_container}
            </td>
            <td
              align="left"
              colSpan={3}
              style={{
                border: "1px solid black",
              }}
            >
              TYPE Of Delivery: {ppapData.type_dev}
            </td>
            <td
              align="left"
              style={{
                border: "1px solid black",
              }}
            >
              Rate/FEQ: {ppapData.rate_feq}
            </td>
          </tr>
          <tr>
            <th
              align="center"
              colSpan={5}
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              MASTER PACKING
            </th>
            <th
              align="center"
              colSpan={1}
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              PART PICTURE AND DRAWING
            </th>
          </tr>
          <tbody>
            <tr data-height="50">
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                S NO.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                DESCRIPTION
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                SIZE(INCH)
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                QTY.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                REMARKS
              </th>
              <th
                align="center"
                rowSpan={100}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                {" "}
                <img
                  rowSpan={25}
                  src={ppapData.pack_stand_img}
                  width={300}
                  height={300}
                />
              </th>
            </tr>
            {ppapData.master_packstands.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.desc}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.size}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.qty}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.remarks}
                </td>
              </tr>
            ))}

            <tr>
              <th
                align="center"
                colSpan={5}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                SECONDARY PACKING
              </th>
            </tr>

            {ppapData.secondary_packstands.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.desc}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.size}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.qty}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.remarks}
                </td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td
              colSpan={2}
              style={{
                border: "1px solid black",
              }}
            >
              Prepared By: {ppapData?.pack_prepared_by}
            </td>

            <td
              colSpan={3}
              style={{
                border: "1px solid black",
              }}
            >
              Approved By: {ppapData?.pack_approved_by}
            </td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default PackingStandard;
