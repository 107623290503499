import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import useDebounce from "../../components/Control/useDebounce";
import formatDate from "../../components/Control/formatDate";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

function ViewChild({ partId, allSupervisorActionButton, featureName }) {
  const classes = useStyles();
  const [partData, setPartData] = useState([]);

  const fetchData = () => {
    axios
      .get(`/api/getChildPart/${partId}`)
      .then((res) => {
        setPartData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useDebounce(fetchData, 0, []);
  console.log(partData);
  return (
    <section style={{ margin: "1rem", marginBottom: "4rem" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                #
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Number
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Description
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Category
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {partData && (
            <TableBody>
              {partData.map(
                (item, index) =>
                  item._id !== partId && (
                    <React.Fragment key={item._id}>
                      <TableRow>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {item.child_id.part_number}
                        </TableCell>
                        <TableCell align="center">
                          {item.child_id.part_name}
                        </TableCell>
                        <TableCell align="center">
                          {item.child_id.category}
                        </TableCell>
                        <TableCell align="center">
                          {formatDate(item.child_id.createdAt)}
                        </TableCell>
                        <TableCell align="center">
                          {allSupervisorActionButton(
                            featureName,
                            item.child_id
                          )}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </section>
  );
}

export default ViewChild;
