import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PfdTableSheet from "./partPlanQuickView/PfdTableSheet";
import ControlPlanTableSheet from "./partPlanQuickView/ControlPlanTableSheet";
import PfmeaTableSheet from "./partPlanQuickView/PfmeaTableSheet";
import axios from "axios";
import { useParams } from "react-router-dom";
import PageNotFound from "../../../components/PageNotFound";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

// main component
export default function QuickView() {
  const { partId } = useParams();
  const [fmeaData, setFmeaData] = useState(null);
  const [controlPlanData, setControlPlanData] = useState(null);
  const [pfdData, setPfdData] = useState(null);
  useEffect(() => {
    axios
      .get(`/api/get/fmeaPart/${partId}`)
      .then((res) => {
        if (res?.data) {
          setFmeaData(res?.data);
        }
      })
      .catch((err) => console.error(err));

    axios
      .get(`/api/get/controlPlanPart/${partId}`)
      .then((res) => {
        if (res?.data) {
          const temp = { ...res.data };
          temp.processes = res.data.processes.filter(
            (process) => !process.isHide
          );
          setControlPlanData(temp);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get(`/api/get/pfdPart/${partId}`)
      .then((res) => {
        if (res?.data) {
          setPfdData(res?.data);
        }
      })
      .catch((err) => console.error(err));
  }, []);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} style={{ position: "relative" }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "#161a1d", borderTop: "1px solid #494949" }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label="PFD" {...a11yProps(0)} />
          <Tab label="PFMEA" {...a11yProps(1)} />
          <Tab label="Control Plan" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {pfdData ? (
          <PfdTableSheet data={pfdData} partId={partId} />
        ) : (
          <PageNotFound />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {fmeaData ? (
          <PfmeaTableSheet {...{ fmeaData, partId }} />
        ) : (
          <PageNotFound />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {controlPlanData ? (
          <ControlPlanTableSheet Data={controlPlanData} partId={partId} />
        ) : (
          <PageNotFound />
        )}
      </TabPanel>
    </div>
  );
}
