import Input from "../../../components/Control/Input";
import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import Modal from "../../../components/Control/Modal";

const ControlPlanInput = ({
  specModalData,
  openPopup,
  setOpenPopup,
  handleSpec,
  handleAppearance,
}) => {
  return (
    <section>
      <Modal
        title={"Specifications"}
        openPopup={openPopup}
        closeModal={() => setOpenPopup(false)}
      >
        <div className="specs-field">
          <Wrapper
          // className="spec-section"
          >
            <Input
              className="input_field_width"
              type="text"
              name="machine"
              placeholder="Machine"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.machine}
              // className="section-input"
            />
            <Input
              className="input_field_width"
              type="text"
              name="char_product"
              placeholder="Product Char."
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.char_product}
              // className="section-input"
              disabled={specModalData.char_process}
            />
            <Input
              className="input_field_width"
              type="text"
              name="char_process"
              placeholder="Process Char."
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.char_process}
              // className="section-input"
              disabled={specModalData.char_product}
            />
            <FormControl
              style={{
                width: "19rem",
                minWidth: "10rem",
                marginRight: "0.5rem",
                marginLeft: "0.6rem",
              }}
              variant="filled"
              className="select-field"
            >
              <InputLabel>Special Char.</InputLabel>
              <Select
                name="special_character"
                defaultValue={specModalData.special_character}
                onChange={(e) => handleSpec(e, specModalData)}
                className="spec-select-character"
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="K">K</MenuItem>
                <MenuItem value="△">△ - Poka Yoka</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>

          <Wrapper
          //  className="spec-section"
          >
            <Input
              className="input_field_width"
              type="text"
              name="methods_measure"
              placeholder="Evaluation"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.methods_measure}
            />
            <Input
              className="input_field_width"
              type="text"
              name="methods_size"
              placeholder="Sample Size"
              // className="section-input"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.methods_size}
            />
            <Input
              className="input_field_width"
              type="text"
              name="methods_by"
              placeholder="Sample Frequency"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.methods_by}
              // className="section-input"
            />

            <Input
              className="input_field_width"
              type="text"
              name="recording"
              placeholder="Recording"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.recording}
              // className="section-input"
            />
          </Wrapper>

          <Wrapper
          //  className="spec-section"
          >
            <Input
              className="input_field_width"
              type="text"
              name="responsibility"
              placeholder="Responsibility"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.responsibility}
              // className="section-input"
            />
            <Input
              className="input_field_width"
              type="text"
              name="methods_process"
              placeholder="Control Method"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.methods_process}
              // className="section-input"
            />
            <Input
              className="input_field_width"
              type="text"
              name="reaction_plan"
              placeholder="Reaction Plan & Action"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.reaction_plan}
              // className="section-input"
            />

            <Input
              className="input_field_width"
              type="text"
              name="remarks"
              placeholder="Remarks"
              onBlur={(e) => handleSpec(e, specModalData)}
              defaultValue={specModalData.remarks}
              // className="section-input"
            />
          </Wrapper>
          <p
            style={{
              marginLeft: "0.5rem",
              marginTop: "0.5rem",
              fontWeight: 600,
            }}
          >
            Product/Process Specification/Tolerance
          </p>
          <section>
            <Wrapper>
              <input
                type="checkbox"
                className="spec-checkbox"
                name="check1"
                onChange={(e) => handleAppearance(e, specModalData)}
                checked={specModalData.appearance === "check1"}
              />
              <Input
                // className="input_field_width"
                style={{ width: "15.3rem" }}
                name="ltl"
                placeholder="Lower Limit"
                required
                type="Number"
                inputProps={{
                  step: 0.01,
                }}
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.ltl}
                disabled={specModalData.appearance !== "check1"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check1"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              ~
              <Input
                // className="input_field_width"
                style={{ width: "15.3rem" }}
                name="utl"
                placeholder="Upper Limit"
                required
                type="Number"
                inputProps={{
                  step: 0.01,
                }}
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.utl}
                disabled={specModalData.appearance !== "check1"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check1"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              <div style={{ width: "15.3rem", marginRight: "10px" }}></div>
              <Input
                // className="input_field_width"
                style={{ width: "15.3rem" }}
                type="text"
                name="unit"
                placeholder="Unit"
                onChange={(e) => handleSpec(e, specModalData)}
                value={specModalData.unit}
                defaultValue={specModalData.unit}
                disabled={specModalData.appearance !== "check1"}
                // style={{ width: "120px", marginLeft: "0.5rem" }}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check1"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              <Input
                // className="input_field_width"

                type="text"
                name="control_product_remark"
                placeholder="Tolerance Remarks"
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.control_product_remark}
                disabled={specModalData.appearance !== "check1"}
                style={{ width: "15.3rem", marginLeft: "0.5rem" }}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check1"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
            </Wrapper>
            <Wrapper>
              <input
                type="checkbox"
                className="spec-checkbox"
                name="check2"
                onChange={(e) => handleAppearance(e, specModalData)}
                checked={specModalData.appearance === "check2"}
              />
              <Input
                style={{ width: "15.3rem" }}
                className="input_field_width"
                name="mean"
                placeholder="Mean"
                required
                inputProps={{
                  step: 0.01,
                }}
                type="Number"
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.mean}
                disabled={specModalData.appearance !== "check2"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check2"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              +
              <Input
                // className="input_field_width"
                style={{ width: "15.3rem" }}
                name="utolerance"
                placeholder="Upper Tolerance"
                required
                type="Number"
                inputProps={{
                  step: 0.01,
                }}
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.utolerance}
                disabled={specModalData.appearance !== "check2"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check2"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              -
              <Input
                style={{ width: "15.3rem" }}
                name="ltolerance"
                placeholder="Lower Tolerance"
                required
                type="Number"
                inputProps={{
                  step: 0.01,
                }}
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.ltolerance}
                disabled={specModalData.appearance !== "check2"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check2"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              <Input
                type="text"
                name="unit"
                placeholder="Unit"
                onChange={(e) => handleSpec(e, specModalData)}
                value={specModalData.unit}
                defaultValue={specModalData.unit}
                style={{ width: "15.3rem", marginRight: "9px" }}
                disabled={specModalData.appearance !== "check2"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check2"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              <Input
                className="input_field_width"
                type="text"
                name="control_product_remark"
                placeholder="Tolerance Remarks"
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.control_product_remark}
                disabled={specModalData.appearance !== "check2"}
                style={{ width: "15.3rem" }}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check2"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
            </Wrapper>
            <Wrapper wid="15.3rem">
              <input
                name="check3"
                type="checkbox"
                className="spec-checkbox"
                onChange={(e) => handleAppearance(e, specModalData)}
                checked={specModalData.appearance === "check3"}
              />
              <Input
                className="input_field_width"
                type="text"
                name="control_product"
                placeholder="Spec./Tolerance"
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.control_product}
                disabled={specModalData.appearance !== "check3"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check3"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
            </Wrapper>
            <Wrapper>
              <input
                name="check4"
                type="checkbox"
                className="spec-checkbox"
                onChange={(e) => handleAppearance(e, specModalData)}
                checked={specModalData.appearance === "check4"}
              />

              <FormControl
                variant="filled"
                className="select-field"
                style={{
                  width: "14.5rem",
                  marginLeft: "8px",
                  marginRight: "15px",
                }}
                disabled={specModalData.appearance !== "check4"}
              >
                <InputLabel>Min/Max</InputLabel>

                <Select
                  name="check4_symbol"
                  // className={classes.root}
                  defaultValue={specModalData.check4_symbol}
                  onChange={(e) => handleSpec(e, specModalData)}
                  style={{
                    color:
                      specModalData.appearance !== "check4"
                        ? "transparent"
                        : "#111",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value=">=">{"Min."}</MenuItem>
                  <MenuItem value="<=">{"Max."}</MenuItem>
                </Select>
              </FormControl>
              <Input
                style={{ width: "15.3rem" }}
                // className="input_field_width"
                type="Number"
                inputProps={{
                  step: 0.01,
                }}
                name="checkbox4_num"
                placeholder="Limit"
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.checkbox4_num}
                disabled={specModalData.appearance !== "check4"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check4"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
              <div style={{ width: "15.3rem", marginRight: "16px" }}></div>
              <Input
                // className="input_field_width"
                style={{ width: "15.3rem", marginRight: "10px" }}
                type="text"
                name="unit"
                placeholder="Unit"
                onChange={(e) => handleSpec(e, specModalData)}
                value={specModalData.unit}
                defaultValue={specModalData.unit}
                disabled={specModalData.appearance !== "check4"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check4"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />

              <Input
                style={{ width: "15.3rem" }}
                // className="input_field_width"
                type="text"
                name="control_product_remark"
                placeholder="Tolerance Remarks"
                onBlur={(e) => handleSpec(e, specModalData)}
                defaultValue={specModalData.control_product_remark}
                disabled={specModalData.appearance !== "check4"}
                InputProps={{
                  style: {
                    color:
                      specModalData.appearance !== "check4"
                        ? "#fafafa"
                        : "black",
                  },
                }}
              />
            </Wrapper>
          </section>
        </div>
      </Modal>
    </section>
  );
};

export default ControlPlanInput;
