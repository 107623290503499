import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Add } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import formatDate from "../../../components/Control/formatDate";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import { DatePicker } from "@material-ui/pickers";
import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Wrapper } from "../../../components/Control/Flexbox";
import DeleteIcon from "@material-ui/icons/Delete";
import Quick4M from "../View4MDatabase/Quick4M";

function adminRequestForm(item) {
  if (item.is_admin_request === "accepted") {
    return (
      <>
        <Tooltip title="Approved" arrow>
          <IconButton size="small">
            <CheckIcon style={{ fontSize: "1.4rem", color: "green" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="View" arrow>
          <Link to={`/view4m/requestform/${item._id}`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }
  if (item.is_admin_request === "pending") {
    return (
      <>
        <Tooltip title="Pending" arrow>
          <IconButton size="small">
            <AccessTimeIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="View" arrow>
          <Link to={`/view4m/requestform/${item._id}`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }
  if (item.is_admin_request === "rejected") {
    return (
      <>
        <Tooltip title="Rejected" arrow>
          <IconButton size="small">
            <ClearIcon style={{ fontSize: "1.4rem", color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit" arrow>
          <Link to={`/edit/request_form/${item._id}`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }
}

function adminSetupForm(item) {
  // admin confirmed for setup creation
  if (item.is_admin_setup === "admin-confirmed") {
    return (
      <Tooltip title="Create" arrow>
        <Link to={`/create/setup_form/${item._id}`}>
          <IconButton size="small">
            <Add style={{ fontSize: "1.4rem", color: "#003566" }} />
          </IconButton>
        </Link>
      </Tooltip>
    );
  }
  if (!item.is_setup_submitted) {
    return (
      <>
        <Tooltip title="Rejected" arrow>
          <IconButton size="small">
            <SaveIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit" arrow>
          <Link to={`/edit/setup_form/${item._id}`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }

  if (item.is_admin_setup === "pending") {
    return (
      <>
        <Tooltip title="Pending" arrow>
          <IconButton size="small">
            <AccessTimeIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="View" arrow>
          <Link to={`/view4m/setupform/${item._id}`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }

  if (item.is_admin_setup === "accepted") {
    return (
      <>
        <Tooltip title="Approved" arrow>
          <IconButton size="small">
            <CheckIcon style={{ fontSize: "1.4rem", color: "green" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="View" arrow>
          <Link to={`/view4m/setupform/${item._id}`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <VisibilityIcon
                style={{ fontSize: "1.4rem", color: "#003566" }}
              />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }
  if (item.is_admin_setup === "rejected") {
    return (
      <>
        <Tooltip title="Rejected" arrow>
          <IconButton size="small">
            <ClearIcon style={{ fontSize: "1.4rem", color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="View" arrow>
          <Link to={`/edit/setup_form/${item._id}`}>
            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
              <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
    );
  }
}

const SupervisorView4MTable = () => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [render, setRender] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/get/all/supervisor/request_form/${selectedDate}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedDate, render]);

  const deleteForm = (id, desc) => {
    let deleteAns = prompt(
      `Are you sure you want to delete this application - "${desc}" ? \n\n Please enter the Description to continue.`
    );
    if (deleteAns === desc) {
      axios
        .delete(`/api/remove/request_form/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const history = useHistory();
  return (
    <main style={{ paddingBottom: "4rem" }}>
      <Quick4M
        target={"/supervisor/view4m"}
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
      <div className="divflex" style={{ fontSize: "24px" }}>
        4M MANAGEMENT
        <Wrapper style={{ gap: "1rem" }}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setOpenModal(true)}
            style={{
              backgroundColor: "maroon",
            }}
            startIcon={<Add />}
          >
            Quick Add 4M
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            style={{
              backgroundColor: "#003566",
            }}
            onClick={() => history.push("/supervisor/view4m")}
            startIcon={<VisibilityIcon />}
          >
            View 4m Database
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push("/supervisor/viewparttable/4m")}
            style={{
              backgroundColor: "#054a29",
            }}
            startIcon={<Add />}
          >
            NEW Form
          </Button>
        </Wrapper>
      </div>
      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE <span className="subheading-secondary">FORM</span>
        </p>
      </div>
      <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate}
          onChange={setSelectedDate}
        />
      </div>
      <Table size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Type
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Description
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Date
            </TableCell>

            <TableCell style={{ fontWeight: "bold" }} align="center">
              Request Form
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Setup Form
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item.part_id && item.part_id.part_number}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.is4M}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.desc_4m}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {item.is_request_submitted ? (
                      adminRequestForm(item)
                    ) : (
                      <>
                        <Tooltip title="Saved" arrow>
                          <IconButton size="small">
                            <SaveIcon
                              style={{ fontSize: "1.4rem", color: "maroon" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit" arrow>
                          <Link to={`/edit/request_form/${item._id}`}>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item.is_admin_request === "accepted" ? (
                      adminSetupForm(item)
                    ) : (
                      <Tooltip title="Block" arrow>
                        <IconButton size="small">
                          <BlockIcon
                            style={{ fontSize: "1.4rem", color: "red" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        onClick={() => deleteForm(item._id, item.desc_4m)}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",

                            color: "red",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
    </main>
  );
};

export default SupervisorView4MTable;
