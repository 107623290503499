import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import formatDate from "../../../components/Control/formatDate";
import PrintIcon from "@material-ui/icons/Print";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    p{
      margin:0;
    }
    span{
      font-weight:500;
    }

`;

function tConv24(time24) {
  if (time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
}

function ViewSetupForm() {
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/get/request_form/${id}`);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <div style={{ paddingBottom: "4rem" }}>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          4M SETUP FORM
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper>
            <Tooltip title="Print table" arrow>
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip title="Export as spreadsheet" arrow>
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: "4msetup.xlsx",
                        sheet: {
                          name: "4msetup",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </Wrapper>
        </div>
      </div>
      <Table
        cellSpacing="0"
        style={{ marginTop: "0.6rem" }}
        id="table-to-xls"
        data-default-wrap="true"
        data-cols-width="15, 23, 24, 23, 23, 30, 30, 15, 15, 13"
      >
        <tbody>
          <tr data-height="80">
            <th
              colSpan={6}
              rowSpan="4"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                height: "100px",
              }}
            >
              <CenteredLogoImg>
                <img src={images.CompanyLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>

            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              DOCUMENT NO:
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              F SYS 34
            </th>
          </tr>
          <tr data-height="30">
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              ISSUE DATE:
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              01/04/2019
            </th>
          </tr>
          <tr data-height="30">
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              REVISION NO:
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              02
            </th>
          </tr>
          <tr data-height="30">
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              REVISION DATE:
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              24/09/2021
            </th>
          </tr>
          <tr data-height="40">
            <th
              colSpan={10}
              align="center"
              style={{
                border: "1px solid black",
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                textTransform: "uppercase",
                textAlign: "center",
              }}
              data-fill-color="FF000000"
            >
              SETUP APPROVAL REPORT
            </th>
          </tr>
          <tr data-height="50">
            <td
              style={{
                border: "1px solid black",
                width: "100px",
              }}
              align="left"
            >
              New Part
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              ECN Part
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              PCN Part
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              IPC Part
            </td>
            <td
              style={{
                border: "1px solid black",
                height: "60px",
              }}
              colSpan="5"
              align="left"
            >
              {data.setup_type === "regular" ? (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  □
                </span>
              )}
              Regular Production /
              {data.setup_type === "4m" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑ /
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}
              4M Change /
              {data.setup_type === "abnormality" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}
              Abnormality
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              P.O NO:
            </td>
          </tr>
          <tr data-height="50">
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.new_part}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.ecn_part}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.pcn_part}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.ipc_part}
            </td>
            <td
              style={{
                border: "1px solid black",
                width: "150px",
              }}
              align="left"
            >
              {data.setup_approval ? (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  □
                </span>
              )}{" "}
              Setup Approval
            </td>
            <td
              style={{
                border: "1px solid black",
                width: "200px",
              }}
              align="left"
            >
              {data.retro_insp ? (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  □
                </span>
              )}{" "}
              Retroactive Inspection
            </td>
            <td
              style={{
                border: "1px solid black",
                width: "200px",
              }}
              align="left"
            >
              {data.containment_insp ? (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  □
                </span>
              )}{" "}
              Containment Inspection
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.other_setup ? (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  □
                </span>
              )}{" "}
              Other
            </td>
            <td style={{ border: "1px solid black" }}></td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.po_no}
            </td>
          </tr>
          <tr data-height="30">
            <td
              style={{
                border: "1px solid black",
                width: "140px",
              }}
              colSpan={4}
              align="left"
            >
              Date: {formatDate(data.setup_date)}
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              colSpan={4}
              align="left"
            >
              Item No: {data.part_id?.part_number}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              MRN:
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.mrn}
            </td>
          </tr>
          <tr data-height="30">
            <td
              style={{
                border: "1px solid black",
                width: "140px",
              }}
              colSpan={4}
              align="left"
            >
              Machine ID & Name: {data.machine_id} - {data.machine_name}
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              colSpan={4}
              align="left"
            >
              Item Description: {data.part_number}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              SHIFT:
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.setup_shift}
            </td>
          </tr>
          <tr data-height="30">
            <td
              style={{
                border: "1px solid black",
                width: "140px",
              }}
              colSpan={4}
              align="left"
            >
              Customer Name: {data.customer_name}
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              colSpan={4}
              align="left"
            >
              Tool No & Name: {data.tool_no_name}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              TIME:
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              {data.setup_time}
            </td>
          </tr>
          <tr data-height="50">
            <td
              style={{
                border: "1px solid black",
                width: "140px",
              }}
              colSpan={4}
              align="left"
            >
              Operation No & Name: {data.oper_no}
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              colSpan={4}
              align="left"
            >
              Type of Change:{" "}
              {data.type_change === "man" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}
              Man /
              {data.type_change === "machine" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}
              Machine /
              {data.type_change === "material" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}
              Material /
              {data.type_change === "method" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}
              Method
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
              rowSpan={2}
            >
              Sample Size: {data.sample_size}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              Job Setup Size: {data.job_setup}
            </td>
          </tr>
          <tr data-height="40">
            <td
              style={{
                border: "1px solid black",
              }}
              colSpan={8}
              align="left"
            >
              Category of Change:{" "}
              {data.category_change === "planned" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}{" "}
              Planned /
              {data.category_change === "unplanned" ? (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "2rem",
                    marginLeft: "1rem",
                  }}
                >
                  □
                </span>
              )}
              Umplanned
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              Retroactive & Containment: {data.retroactive}
            </td>
          </tr>
          <tr data-height="30">
            <td
              style={{
                border: "1px solid black",
                width: "140px",
              }}
              align="left"
              colSpan={2}
            >
              Total Production Qty: {data.total_produced_qty}
            </td>
            <td
              style={{
                border: "1px solid black",
                width: "140px",
              }}
              align="left"
            >
              Total OK Qty: {data.total_ok_qty}
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              Total Retroactive Qty: {data.total_retro_qty}
            </td>

            <td
              style={{
                border: "1px solid black",
              }}
              colSpan="2"
              align="left"
            >
              Containment Qty: {data.containment_qty}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              align="left"
              colSpan={2}
            >
              Rejection Qty: {data.rejection_qty}
            </td>
            <td
              style={{
                border: "1px solid black",
              }}
              colSpan={2}
              align="left"
            >
              Total Rework Qty: {data.total_rework_qty}
            </td>
          </tr>
          {data.processes?.map((process) => (
            <Fragment key={process._id}>
              <tr data-height="35">
                <th
                  colSpan={10}
                  style={{
                    border: "1px solid black",
                    fontSize: "1rem",
                    fontWeight: 500,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                  data-fill-color="FF000000"
                >
                  {process.process_name}
                </th>
              </tr>
              <tr data-height="40">
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  S.NO
                </th>

                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "150px",
                  }}
                  align="center"
                  rowSpan={2}
                >
                  PARAMETER
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "150px",
                  }}
                  align="center"
                  rowSpan={2}
                >
                  SPECIFICATION
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    width: "150px",
                  }}
                  align="center"
                  rowSpan={2}
                >
                  METHODS
                </th>

                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={5}
                >
                  OBSERVATIONS (IN RANGE)
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  REMARKS
                </th>
              </tr>
              <tr data-height="30">
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  1
                </th>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  2
                </th>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  3
                </th>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  4
                </th>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  5
                </th>
              </tr>
              {process?.specs.map((spec, specIdx) => (
                <tr key={spec._id} data-height="35">
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                    align="center"
                  >
                    {specIdx + 1}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                    align="center"
                  >
                    {spec.parameter}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                    align="center"
                  >
                    {spec.specification}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                    align="center"
                  >
                    {spec.methods}
                  </td>
                  <td
                    style={{
                      textTransform: "uppercase",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {spec.obs_1}
                  </td>
                  <td
                    style={{
                      textTransform: "uppercase",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {spec.obs_2}
                  </td>
                  <td
                    style={{
                      textTransform: "uppercase",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {spec.obs_3}
                  </td>
                  <td
                    style={{
                      textTransform: "uppercase",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {spec.obs_4}
                  </td>
                  <td
                    style={{
                      textTransform: "uppercase",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {spec.obs_5}
                  </td>

                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                    align="center"
                  >
                    {spec.results}
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
        <tr style={{ height: "60px" }} data-height="35">
          <td
            align="left"
            colSpan={10}
            style={{ fontWeight: 600, border: "1px solid black" }}
          >
            NOTE: {data.notes}
          </td>
        </tr>
        <tr style={{ height: "60px" }} data-height="35">
          <td
            align="left"
            colSpan={2}
            style={{ fontWeight: 500, border: "1px solid black" }}
          >
            Prepared By:
          </td>
          <td
            align="left"
            colSpan={2}
            style={{ border: "1px solid black", textTransform: "capitalize" }}
          >
            {data.report_prepared_by}
          </td>

          <td
            align="left"
            colSpan={2}
            style={{ fontWeight: 500, border: "1px solid black" }}
          >
            Approved By:
          </td>
          <td
            align="left"
            style={{ border: "1px solid black", textTransform: "capitalize" }}
            colSpan={4}
          >
            {data.is_admin_setup === "accepted" && data.report_approved_by}
          </td>
        </tr>
      </Table>
    </div>
  );
}

export default ViewSetupForm;
