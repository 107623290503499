import React from "react";
import Styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";
const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	border: 1px solid black;


    th{
        font-size: 13px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100%;
        border: none;
        padding-left: 4px;
        height: 40px;
    }
`;

const heading = {
  "annexure-a": "ANNEXURE A",
  "annexure-b": "ANNEXURE B",
  "annexure-c": "ANNEXURE C",
};
const AnnexureBForm = ({ id, data, handleChange, isView, annexureType }) => {
  return (
    <section>
      <Table>
        <tr>
          <th
            colSpan={2}
            style={{
              fontSize: "1.2rem",
              fontWeight: 600,
              backgroundColor: "#1d1d1d",
              color: "white",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {heading[annexureType]}
          </th>
        </tr>
        <tr>
          <td>Wire Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="wire_dia"
              required
              value={data.wire_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Wire Breakload</td>
          <td>
            <TableReadEditCell
              type="text"
              name="wire_breakload"
              required
              value={data.wire_breakload}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Shank Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="annex_b_shank_dia"
              required
              value={data.annex_b_shank_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Length</td>
          <td>
            <TableReadEditCell
              type="text"
              name="annex_b_length"
              required
              value={data.annex_b_length}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Head Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="head_dia"
              required
              value={data.head_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Head Diameter 2</td>
          <td>
            <TableReadEditCell
              type="text"
              name="head_dia_2"
              required
              value={data.head_dia_2}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Break Point Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="break_point_dia"
              value={data.break_point_dia}
              required
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Mandrel Breakload</td>
          <td>
            <TableReadEditCell
              type="text"
              name="mandrel_breakload"
              required
              value={data.mandrel_breakload}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Mandrel Retention</td>
          <td>
            <TableReadEditCell
              type="text"
              name="mandrel_retention"
              required
              value={data.mandrel_retention}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Step Length</td>
          <td>
            <TableReadEditCell
              type="text"
              name="step_length"
              required
              value={data.step_length}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Grip Distance</td>
          <td>
            <TableReadEditCell
              type="text"
              name="grip_distance"
              required
              value={data.grip_distance}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Grip Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="grip_dia"
              isView={isView}
              required
              {...{ handleChange }}
              value={data.grip_dia}
            />
          </td>
        </tr>
      </Table>
    </section>
  );
};

export default AnnexureBForm;
