import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { Add, Remove } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { useAuth } from "../../../context/GlobalContext";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import ForMTable from "./ForMTable";
import Modal from "../../../components/Control/Modal";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 200px;
        border: none;
        height: 50px;
        text-align:center;
    }

`;

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const DailyProductionTable = () => {
  const classes = dropdownStyles();
  const { state } = useAuth();
  const { id } = useParams();
  const [is4mModal, setIs4mModal] = useState(false);
  const [render, setRender] = useState(false);
  const [partDetails, setPartDetails] = useState([]);
  const index4m = useRef(0);
  const tabValue4m = useRef(0);
  const history = useHistory();
  const [operation, setOperation] = useState(
    new Array(25).fill().map((_) => [])
  );
  const [failures, setFailures] = useState(new Array(25).fill().map((_) => []));
  const [dailyProductionData, setDailyProductionData] = useState({
    processes: new Array(25).fill().map((_) => ({})),
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );

  useEffect(() => {
    axios
      .get(`/api/getPartDetails`)
      .then((res) => {
        setPartDetails(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAllOperation = (partId, index) => {
    axios
      .get(`/api/getOperationDetails/${partId}`)
      .then((res) => {
        operation[index] = res.data;
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const handleChangeProductionQty = (e, partId, processName) => {
    dailyProductionData.processes.some((process, idx) => {
      let part_id = process.part_id?._id || process.part_id;
      if (process.process_name === processName && part_id === partId) {
        if (!e) {
          console.log(e);
          setRender((prev) => !prev);
          return true;
        }
        const values = { ...dailyProductionData };
        values.processes[idx].production = e.target.value;
        console.log(values.processes[idx].production);
        setDailyProductionData(values);
        return true;
      }
    });
  };
  const handleFailures = async (partId, index, processName) => {
    axios
      .get(`/api/getFailuresfromFmea/${partId}?process_name=${processName}`)
      .then((res) => {
        failures[index] = res.data;
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!id) return;
    if (partDetails.length) {
      axios
        .get(`/api/getOneDailyProduction/${id}`)
        .then((res) => {
          res.data.processes.map((item, index) => {
            if (item.part_id) {
              handleAllOperation(item.part_id._id, index);
              handleFailures(item.part_id._id, index, item.process_name);
            }
          });
          setDailyProductionData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [partDetails]);

  const handleChangeProduction = (e, index) => {
    const value = e.target.innerText;
    const name = e.target.dataset.name;
    dailyProductionData.processes[index][name] = value;
  };

  const handleInputValue = (e, index) => {
    dailyProductionData.processes[index][e.target.name] = e.target.value;
  };

  const selectFailure = (e, index) => {
    const value = e.target.value;
    let potentialMode = failures[index].filter(
      (item) => item.potential_cause[0] === value
    )[0].potential_mode;
    dailyProductionData.processes[index].potential_mode = potentialMode;
    dailyProductionData.processes[index].potential_cause = value;
  };

  const handlePartDetails = (e, index) => {
    const value = e.target.value;
    dailyProductionData.processes[index].part_id = value;
    const getPartNo = partDetails.filter((part) => part._id === value);
    dailyProductionData.processes[index].part_name = getPartNo[0].part_name;
    handleAllOperation(getPartNo[0]._id, index);
    if (dailyProductionData.processes[index].process_name) {
      handleFailures(
        getPartNo[0]._id,
        index,
        dailyProductionData.processes[index].process_name
      );
    }
  };

  const handle4m = (value, tabValue) => {
    if (tabValue === 0) {
      dailyProductionData.processes[index4m.current].machine_no = value;
    }
    if (tabValue === 1) {
      dailyProductionData.processes[index4m.current].operator_name = value;
    }
    setRender((prev) => !prev);
  };
  const reset4m = (tabValue) => {
    if (tabValue === 0) {
      dailyProductionData.processes[index4m.current].machine_no = null;
    }
    if (tabValue === 1) {
      dailyProductionData.processes[index4m.current].operator_name = null;
    }
    setRender((prev) => !prev);
  };

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        dailyProductionData.isSubmitted = bool;
        axios
          .put(`/api/updateDailyProduction/${id}`, dailyProductionData)
          .then((res) => {
            if (res.data.status === "success") {
              history.push("/viewDailyProduction");
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        dailyProductionData.isSubmitted = bool;
        axios
          .post(`/api/dailyProduction/create`, dailyProductionData)
          .then((res) => {
            if (res.data.status === "success") {
              history.push("/viewDailyProduction");
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            DAILY PRODUCTION REPORT
          </Typography>
          <div style={{ display: "flex" }}>
            <Wrapper
              style={{
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#003566",
                  marginRight: "0.5rem",
                }}
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleSubmitAndSave(false);
                }}
              >
                Save & Continue
              </Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  handleSubmitAndSave(true);
                }}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </div>
        </div>
        <div
          style={{
            margin: "0.1rem 0.5rem",
            paddingBottom: "4rem",
            maxHeight: "100vh",
            overflowX: "scroll",
            maxWidth: "100vw",
            position: "relative",
          }}
        >
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 5, 7, 18, 5, 18, 18, 5, 5, 15, 15, 15"
          >
            <thead>
              <tr data-height="80" style={{ height: "70px" }}>
                <th
                  colSpan="13"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.CompanyLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC. NO:- ARC/F/PRD/24
                </th>
              </tr>

              <tr>
                <th
                  colSpan="13"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  DAILY PRODUCTION REPORT
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REV. NO.:- 00/01/07/2018
                </th>
              </tr>
              <tr>
                <th colSpan={8} style={{ border: "1px solid black" }}>
                  <Wrapper style={{ gap: "5px" }}>
                    Date:-
                    <DatePicker
                      // label="pic Date"
                      name="date_performance"
                      format="dd/MM/yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#f8f9fa",
                      }}
                      value={dailyProductionData.dailyProduction_date}
                      onChange={(date) => {
                        dailyProductionData.dailyProduction_date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  </Wrapper>
                </th>
                <th style={{ border: "1px solid black" }}>Shift</th>
                <th
                  colSpan={2}
                  align="center"
                  contenteditable="true"
                  data-name="shift"
                  onKeyUp={(e) => {
                    dailyProductionData[e.target.dataset.name] =
                      e.target.innerText;
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {dailyProductionData.shift}
                </th>
                <th style={{ border: "1px solid black" }}>Department:-</th>
                <th
                  colSpan={4}
                  align="center"
                  contenteditable="true"
                  data-name="department"
                  onKeyUp={(e) => {
                    dailyProductionData[e.target.dataset.name] =
                      e.target.innerText;
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {dailyProductionData.department}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "M/C NO.",
                  "OPERATOR NAME",
                  "PART NO.",
                  "PART NAME",
                  "OPERATION NO",
                  "LOT NO.",
                  "IN TIME",
                  "OUT TIME",
                  "WORKING HOUR",
                  "CYCLE TIME",
                  "PRODUCTION",
                  "REJECTION",
                  "Reason of Failure",
                  "REWORK",
                  "WORK EFF.",
                  "REMARK",
                ].map((heading, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </tbody>
            {dailyProductionData.processes.map((item, index) => (
              <tr key={item._id || index}>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                    padding: "0 0.4rem",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <Wrapper
                    justify="space-between"
                    style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                  >
                    <p
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {item.machine_no || "Pick Machine"}
                    </p>
                    {item.machine_no ? (
                      <Wrapper
                        className="pir_add_modal"
                        style={{ backgroundColor: "#003566" }}
                        justify="center"
                        onClick={() => {
                          item.machine_no = null;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Remove />
                      </Wrapper>
                    ) : (
                      <Wrapper
                        className="pir_add_modal"
                        justify="center"
                        style={{ backgroundColor: "#495057" }}
                        onClick={() => {
                          index4m.current = index;
                          tabValue4m.current = 0;
                          setIs4mModal(true);
                        }}
                      >
                        <Add />
                      </Wrapper>
                    )}
                  </Wrapper>
                </th>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                    padding: "0 0.4rem",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  <Wrapper
                    justify="space-between"
                    style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                  >
                    <p
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {item.operator_name || "Pick Operator"}
                    </p>
                    {item.operator_name ? (
                      <Wrapper
                        className="pir_add_modal"
                        style={{ backgroundColor: "#003566" }}
                        justify="center"
                        onClick={() => {
                          item.operator_name = null;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Remove />
                      </Wrapper>
                    ) : (
                      <Wrapper
                        className="pir_add_modal"
                        justify="center"
                        style={{ backgroundColor: "#495057" }}
                        onClick={() => {
                          index4m.current = index;
                          tabValue4m.current = 1;
                          setIs4mModal(true);
                        }}
                      >
                        <Add />
                      </Wrapper>
                    )}
                  </Wrapper>
                </th>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <FormControl
                    style={{
                      width: "250px",
                      border: "none",
                    }}
                    fullWidth={true}
                    variant="filled"
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <InputLabel>Select Part Number</InputLabel>
                    <Select
                      defaultValue={item.part_id?._id || ""}
                      classes={{
                        // underline: classes.underline,
                        root: classes.root,

                        select: classes.select,
                      }}
                      style={{ fontSize: "0.8rem" }}
                      disableUnderline
                      onChange={(e) => handlePartDetails(e, index)}
                    >
                      {partDetails.map((data, PIndex) => (
                        <MenuItem
                          style={{ fontSize: "0.8rem" }}
                          className="option"
                          key={PIndex}
                          value={data._id}
                        >
                          {data.part_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </th>
                <td
                  style={{
                    minWidth: "180px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  <div style={{ maxHeight: "50px", overflow: "auto" }}>
                    {item.part_name}
                  </div>
                </td>
                <th
                  style={{
                    width: "100px",
                    border: "1px solid black",
                    textAlign: "center",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <FormControl
                    style={{
                      width: "250px",
                      border: "none",
                    }}
                    fullWidth={true}
                    variant="filled"
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <InputLabel>Select Operation</InputLabel>
                    <Select
                      defaultValue={item.process_name || ""}
                      classes={{
                        // underline: classes.underline,
                        root: classes.root,
                        select: classes.select,
                      }}
                      style={{ fontSize: "0.8rem" }}
                      disableUnderline
                      onChange={(e) => {
                        dailyProductionData.processes[index].process_name =
                          e.target.value;
                        handleFailures(
                          item.part_id._id || item.part_id,
                          index,
                          e.target.value
                        );
                        handleChangeProductionQty(
                          null,
                          item.part_id._id || item.part_id,
                          e.target.value
                        );
                      }}
                    >
                      {operation[index].map((data, opIndex) => (
                        <MenuItem
                          className="option"
                          key={opIndex}
                          style={{ fontSize: "0.8rem" }}
                          value={data.process_name}
                        >
                          {data.process_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </th>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="lot_no"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.lot_no}
                </td>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="in_time"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.in_time}
                </td>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="out_time"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.out_time}
                </td>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="working_hour"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.working_hour}
                </td>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="cycle_time"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.cycle_time}
                </td>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <input
                    autoComplete="off"
                    name="production"
                    className="input"
                    type="number"
                    disabled={!item.process_name}
                    onChange={(e) =>
                      handleChangeProductionQty(
                        e,
                        item.part_id._id || item.part_id,
                        item.process_name
                      )
                    }
                    value={
                      dailyProductionData.processes.find(
                        (process) =>
                          process.process_name === item.process_name &&
                          (item.part_id?._id || item.part_id) ===
                            (process.part_id?._id || process.part_id)
                      ).production
                    }
                  />
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <input
                    autoComplete="off"
                    className="input"
                    name="rejection"
                    type="number"
                    defaultValue={item.rejection}
                    onChange={(e) => handleInputValue(e, index)}
                  />
                </th>

                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <FormControl
                    style={{
                      width: "250px",
                      border: "none",
                    }}
                    fullWidth={true}
                    variant="filled"
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <InputLabel>Select Reason</InputLabel>
                    <Select
                      name="reason"
                      defaultValue={item.potential_cause || ""}
                      classes={{
                        // underline: classes.underline,
                        root: classes.root,
                        select: classes.select,
                      }}
                      style={{ fontSize: "0.8rem" }}
                      disableUnderline
                      onChange={(e) => selectFailure(e, index)}
                    >
                      {failures[index].map((data, PIndex) => (
                        <MenuItem
                          className="option"
                          style={{ fontSize: "0.8rem" }}
                          key={PIndex}
                          value={data.potential_cause[0]}
                        >
                          {data.potential_mode} , {data.potential_cause[0]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </th>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="rework"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.rework}
                </td>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="work_eff"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.work_eff}
                </td>
                <td
                  align="center"
                  contenteditable="true"
                  data-name="remark"
                  onKeyUp={(e) => {
                    handleChangeProduction(e, index);
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                    minWidth: "90px",
                  }}
                >
                  {item.remark}
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
      <Modal
        openPopup={is4mModal}
        title="4M DATABASE"
        closeModal={(e) => setIs4mModal(false)}
      >
        <ForMTable
          {...{ handle4m, reset4m }}
          processes={dailyProductionData.processes[index4m.current]}
          index4m={index4m.current}
          tabValue4m={tabValue4m.current}
        />
      </Modal>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default DailyProductionTable;
