import { Button, Checkbox } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import CreateRequestHeadForm from "../CreateRequestForm/CreateRequestHeadForm";
import CreateRequestMachine from "../CreateRequestForm/CreateRequestMachine";
import CreateRequestMan from "../CreateRequestForm/CreateRequestMan";
import CreateRequestMaterial from "../CreateRequestForm/CreateRequestMaterial";
import CreateRequestMethod from "../CreateRequestForm/CreateRequestMethod";
import Loader from "../../../components/Control/Loader";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/GlobalContext";

const style = {
  backgroundColor: "#fafafa",
  width: "400px",
  padding: "0.5rem",
  marginLeft: "0.5rem",
};
const EditRequestForm = () => {
  const { id } = useParams();
  const { state } = useAuth();
  const [isLoading, setIsLoading] = useState();
  const [requestData, setRequestData] = useState(null);
  const history = useHistory();
  useEffect(() => {
    axios
      .get(`/api/get/request_form/${id}`)
      .then((res) => {
        setRequestData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const isSubmit = window.confirm(
      "Are you sure you want to submit this Request Form?"
    );
    if (isSubmit) {
      setIsLoading(true);
      requestData.is_request_submitted = true;
      requestData.is_admin_request = "pending";
      requestData.admin_request_no = 1;

      axios
        .put(`/api/edit/request_form/${id}`, requestData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === "success") {
            history.push("/supervisor/view/4mTable");
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const saveLater = () => {
    const isSubmit = window.confirm(
      "Are you sure you want to save this Request Form?"
    );
    if (isSubmit) {
      setIsLoading(true);
      requestData.is_request_submitted = false;
      axios
        .put(`/api/edit/request_form/${id}`, requestData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.status === "success") {
            history.push("/supervisor/view/4mTable");
          }
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const handleChangeRequest = ({ target }) => {
    console.log(target.type);
    let value = target.type === "checkbox" ? target.checked : target.value;
    setRequestData((prev) => ({ ...prev, [target.name]: value }));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
      <div className="divflex" style={{ fontSize: "24px" }}>
        REQUEST FORM
      </div>
      {requestData && (
        <form
          style={{
            marginTop: "2rem",
            marginLeft: "1rem",
            marginRight: "1rem",
            paddingBottom: "4rem",
          }}
        >
          <CreateRequestHeadForm
            {...{ handleChangeRequest, requestData, setRequestData }}
          />
          <CreateRequestMan {...{ requestData, handleChangeRequest }} />
          <CreateRequestMachine {...{ requestData, handleChangeRequest }} />
          <CreateRequestMaterial {...{ requestData, handleChangeRequest }} />
          <CreateRequestMethod {...{ requestData, handleChangeRequest }} />
          <Wrapper>
            <Input
              type="text"
              name="change_input"
              label="Change Input"
              defaultValue={requestData.change_input}
              onChange={handleChangeRequest}
            />
            <Input
              type="text"
              name="change_output"
              label="Change Output"
              defaultValue={requestData.change_input}
              onChange={handleChangeRequest}
            />
            <div style={style}>
              Change Request Approved{" "}
              <Checkbox
                disabled={true}
                style={{ marginLeft: "1rem" }}
                defaultChecked={requestData.is_admin_request === "accepted"}
              />{" "}
              YES
              <Checkbox
                style={{ marginLeft: "1rem" }}
                disabled={true}
                defaultChecked={requestData.is_admin_request === "rejected"}
              />{" "}
              NO
            </div>
          </Wrapper>
          {(state.user.userType === 2 || !requestData.isSubmitted) && (
            <Wrapper
              style={{
                position: "absolute",
                top: "4.6rem",
                right: "1.6rem",
                gap: "1rem",
              }}
            >
              {!requestData.isSubmitted && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={saveLater}
                  style={{ backgroundColor: "#003566" }}
                  startIcon={<DoubleArrowIcon />}
                >
                  Save & Continue
                </Button>
              )}
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                style={{ backgroundColor: "#054a29" }}
                startIcon={<SaveIcon />}
              >
                SUBMIT
              </Button>
            </Wrapper>
          )}
        </form>
      )}
    </main>
  );
};

export default EditRequestForm;
