import React, { useState } from "react";
import EditableTableCell from "../../../components/Control/EditableTableCell";
import {
  calcPPM,
  calcAvgPPM,
  calcAvgPPMByThousand,
  getOccRank,
  calcTotalProduction,
  calcTotalRejection,
} from "./helper/calcucaltionHelper";
import { StyledTableHeaderTh1 } from "../../../golbalStyles/tableFreeze/ColumnFrezze.styled";

const TBodyRow = ({
  process,
  processInd,
  occMatrix,
  occMatIndx,
  occData,
  isView,
  //   handleChange,
}) => {
  const [render, setRender] = useState(false);
  // console.log(occMatrix);
  const handleChange = (e, occMatrix, key) => {
    const { name, value } = e.target;
    occMatrix[key][name] = value;
    setRender((prev) => !prev);
  };

  function calculateAllData(occMatrix) {
    calcTotalProduction(occMatrix);
    calcTotalRejection(occMatrix);
    calcAvgPPM(occMatrix);
    calcAvgPPMByThousand(occMatrix);
    getOccRank(occMatrix, occData);
    return occMatrix;
  }

  return (
    <React.Fragment>
      <tr key={occMatIndx + "tbodyrow"}>
        {occMatIndx === 0 && (
          <StyledTableHeaderTh1
            left={0}
            width="110px"
            minWidth="110px"
            align="center"
            bgColor="#fff9db"
            style={{ border: "1px solid black" }}
            rowSpan={process.occ_process.length}
            data-f-sz="10"
          >
            {process?.process_name}
          </StyledTableHeaderTh1>
        )}
        <StyledTableHeaderTh1
          left={"110px"}
          width="150px"
          minWidth="150px"
          bgColor="#fff9db"
          align="center"
          style={{ border: "1px solid black" }}
          data-f-sz="10"
        >
          {occMatrix?.requirement}
        </StyledTableHeaderTh1>
        <StyledTableHeaderTh1
          left={"260px"}
          width="150px"
          minWidth="150px"
          bgColor="#fff9db"
          align="center"
          style={{ border: "1px solid black" }}
          data-f-sz="10"
        >
          {occMatrix?.potential_mode}
        </StyledTableHeaderTh1>
        {[
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sept",
          "oct",
          "nov",
          "dec",
          "jan",
          "feb",
          "mar",
        ].map((objKey, ind) => {
          return (
            <React.Fragment key={ind + objKey}>
              <th>
                <EditableTableCell
                  name="prod_qty"
                  type="number"
                  isView={isView}
                  value={occMatrix?.[objKey]?.prod_qty}
                  handleChange={(e) => {
                    handleChange(e, occMatrix, objKey);
                    //   setRender((prev) => !prev);
                  }}
                  style={{ width: "90px" }}
                />
              </th>
              <th>
                <EditableTableCell
                  name="reject_qty"
                  type="number"
                  isView={isView}
                  value={occMatrix?.[objKey]?.reject_qty}
                  handleChange={(e) => {
                    handleChange(e, occMatrix, objKey);
                    //   setRender((prev) => !prev);
                  }}
                  style={{ width: "90px" }}
                />
              </th>
              <th>
                {calcPPM(
                  occMatrix?.[objKey]?.prod_qty,
                  occMatrix?.[objKey]?.reject_qty
                ) || null}
              </th>
            </React.Fragment>
          );
        })}
        {/* <th>{calcAvgPPM(occMatrix) || null}</th>
        <th>{calcAvgPPMByThousand(occMatrix) || null}</th>
        <th>{getOccRank(occMatrix, occData)}</th>
        <th>{calcTotalProduction(occMatrix) || null}</th>
        <th>{calcTotalRejection(occMatrix) || null}</th> */}
        {/* <th>{occMatrix.avg_ppm}</th>
        <th>{occMatrix.avg_ppm_by_thousand}</th>
        <th>{occMatrix.occ_rank}</th>
        <th>{occMatrix.total_prod_qty}</th>
        <th>{occMatrix.total_reject_qty}</th> */}
        <th>{calculateAllData(occMatrix)?.avg_ppm}</th>
        <th>{calculateAllData(occMatrix)?.avg_ppm_by_thousand}</th>
        <th>{calculateAllData(occMatrix)?.occ_rank}</th>
        <th>{calculateAllData(occMatrix)?.total_prod_qty}</th>
        <th>{calculateAllData(occMatrix)?.total_reject_qty}</th>
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow;
