import { DatePicker } from "@material-ui/pickers";
import React from "react";
import { Wrapper } from "../Control/Flexbox";
import Input from "../Control/Input";
import { useQuery } from "../Control/useQuery";

const CoverForm = ({ ppapData, setPpapData }) => {
  const query = useQuery();
  const partNumber = query.get("partnumber");
  const handleChangeCover = (e) => {
    ppapData[e.target.name] = e.target.value;
  };
  return (
    <>
      <Wrapper style={{ flexWrap: "wrap" }}>
        <div>
          <Input
            label="Part Name"
            name="part_name"
            type="text"
            disabled
            defaultValue={
              ppapData.part_id?.part_name
              // || partName
            }
          />
        </div>
        <div>
          <Input
            label="Part Number"
            name="part_number"
            type="text"
            disabled
            defaultValue={ppapData.part_id?.part_number || partNumber}
          />
        </div>
        {/* <div>
          <Input
            label="Drawing Stage"
            name="drawing_stage"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.drawing_stage}
          />
        </div> */}
        {/* <div>
          <Input
            label="Plant"
            name="plant"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.plant}
          />
        </div> */}

        <div>
          <Input
            label="Customer"
            name="customer_name"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.customer_name}
          />
        </div>
        <div>
          <Input
            label="Supplier Name"
            name="supplier_name"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.supplier_name}
          />
        </div>

        <div>
          <Input
            label="Supplier Code"
            name="supplier_code"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.supplier_code}
          />
        </div>
        {/* <div>
          <Input
            label="Supplier Contact Name"
            name="supplier_code"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.supplier_code}
          />
        </div> */}
        <div style={{ marginLeft: "0.7rem" }}>
          <DatePicker
            label="Submission Date"
            className="date-field"
            name="submission_date"
            onChange={(date) =>
              setPpapData((prev) => ({ ...prev, submission_date: date }))
            }
            inputVariant="filled"
            format="dd/MM/yyyy"
            value={ppapData.submission_date || new Date()}
          />
        </div>
        {/* <div>
          <Input
            label="Samples Submitted PPAP"
            name="sample_submitted"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.sample_submitted}
          />
        </div> */}
        {/* <div>
          <Input
            label="Checklist Prepared"
            name="checklist_prepared"
            type="text"
            onBlur={(e) => handleChangeCover(e)}
            defaultValue={ppapData.checklist_prepared}
          />
        </div>
        <div style={{ marginLeft: "0.7rem" }}>
          <DatePicker
            label="Check List Date"
            className="date-field"
            name="checklist_date"
            onChange={(date) =>
              setPpapData((prev) => ({ ...prev, checklist_date: date }))
            }
            inputVariant="filled"
            format="dd/MM/yyyy"
            value={ppapData.checklist_date || new Date()}
          />
        </div> */}
      </Wrapper>
    </>
  );
};

export default CoverForm;
