import { Link, useHistory } from "react-router-dom";
import "../../style/admin.css";
import "../../style/supervisor.css";
import { useAuth } from "../../context/GlobalContext";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DescriptionIcon from "@material-ui/icons/Description";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import TableChartIcon from "@material-ui/icons/TableChart";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";

import homePage from "./HomePage.module.css";
import useConfirm from "../Control/useConfirm";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { Assessment } from "@material-ui/icons";

const Supervisor = () => {
    const { state, logout } = useAuth();
    const history = useHistory();
    const access = state.user?.access || [];
    const [Dialog, confirmLogoutUser] = useConfirm(
        "LOG OUT",
        "Are you sure you want to log out?",
    );
    const logoutUser = async () => {
        const ans = await confirmLogoutUser();
        if (ans) {
            logout();
            history.push("/");
        }
    };
    if (state.user.userType == 3) {
        return (
            <>
                <main className={`${homePage.container} ${homePage.main}`}>
                    {access.some((obj) => obj.id === 21) && (
                        <div>
                            <Link
                                to="/supervisor/viewparttable/controlplan"
                                className="panel"
                            >
                                <DescriptionIcon
                                    style={{ fontSize: "3rem", color: "maroon" }}
                                />
                            </Link>
                            <p className="card-btn-para">
                                Control <span className="card-btn-span">Plan</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 22) && (
                        <div>
                            <Link
                                to="/supervisor/viewparttable/pir"
                                className="panel"
                                style={{ marginLeft: "2rem" }}
                            >
                                <ListAltIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </Link>
                            <p className="card-btn-para">
                                Inspection & Setup
                                <span className="card-btn-span">Reports</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 22) && (
                        <div>
                            <Link
                                to="/viewAllPirTableList"
                                className="panel"
                                style={{ marginLeft: "2rem" }}
                            >
                                <ListAltIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </Link>
                            <p className="card-btn-para">
                                SAVED Inspection
                                <span className="card-btn-span">& Setup Reports</span>
                            </p>
                        </div>
                    )}

                    {access.some((obj) => obj.id === 23) && (
                        <div>
                            <Link to="/supervisor/viewparttable/pdir" className="panel">
                                <DynamicFeedIcon
                                    style={{ fontSize: "3rem", color: "#003566" }}
                                />
                            </Link>
                            <p className="card-btn-para">
                                Pre-dispatch
                                <span className="card-btn-span">Inspection Reports</span>
                            </p>
                        </div>
                    )}

                    {access.some((obj) => obj.id === 24) && (
                        <div>
                            <Link to="/supervisor/viewparttable/sop" className="panel">
                                <VerticalSplitIcon
                                    style={{ fontSize: "3rem", color: "#003566" }}
                                />
                            </Link>
                            <p className="card-btn-para">
                                SOP <span className="card-btn-span">Module</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 25) && (
                        <div>
                            <Link to="/materialTc/table" className="panel">
                                <TableChartIcon
                                    style={{ fontSize: "3rem", color: "#003566" }}
                                />
                            </Link>
                            <p className="card-btn-para">
                                Material
                                <span className="card-btn-span"> TC</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 7) && (
                        <div>
                            <Link to="/rmpartlist" className="panel">
                                <AccountTreeIcon
                                    style={{ fontSize: "3rem", color: "#003566" }}
                                />
                            </Link>
                            <p className="card-btn-para">
                                Raw Material
                                <span className="card-btn-span">Receiving</span>
                            </p>
                        </div>
                    )}
                    {access.some((obj) => obj.id === 7) && (
                        <div>
                            <Link
                                to="/viewAllRmTableList"
                                className="panel"
                                style={{ marginLeft: "2rem" }}
                            >
                                <ListAltIcon style={{ fontSize: "3rem", color: "#003566" }} />
                            </Link>
                            <p className="card-btn-para">
                                SAVED RM
                                <span className="card-btn-span">Reports</span>
                            </p>
                        </div>
                    )}

                    {/*<div>
                        <Link to="/allDepartment" className="panel">
                            <DeveloperBoardIcon
                                style={{ fontSize: "3rem", color: "#135089" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Master List Of<span className="card-btn-span">Department</span>
                        </p>
                    </div>*/}
                    {access.some((obj) => obj.id === 16) && (
                        <div>
                            <a
                                href="https://slf.secure.aqua.mushinlabs.com/common/abnormalityHandling"
                                rel="noopener noreferrer"
                                className="panel"
                                style={{ fontSize: "3rem", color: "#003566" }}
                            >
                                <Assessment style={{ fontSize: "3rem", color: "#003566" }} />
                            </a>
                            <p className="card-btn-para">
                                ABNORMALITY HANDLING
                                <span className="card-btn-span">REPORT</span>
                            </p>
                        </div>
                    )}
                    <div>
                        <Link
                            style={{ cursor: "pointer" }}
                            to="/"
                            className="panel"
                            onClick={logoutUser}
                        >
                            <ExitToAppIcon style={{ fontSize: "3rem", color: "maroon" }} />
                        </Link>{" "}
                        <p className="card-btn-para">
                            Logout <span className="card-btn-span">Aqua</span>
                        </p>
                    </div>
                </main>
                <Dialog />
            </>
        );
    } else {
        return (
            <div>
                <h1>Page Not Found</h1>
            </div>
        );
    }
};

export default Supervisor;
