import { Button, Checkbox } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import CreateRequestHeadForm from "./CreateRequestHeadForm";
import CreateRequestMachine from "./CreateRequestMachine";
import CreateRequestMan from "./CreateRequestMan";
import CreateRequestMaterial from "./CreateRequestMaterial";
import CreateRequestMethod from "./CreateRequestMethod";
import Loader from "../../../components/Control/Loader";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/GlobalContext";

const style = {
  backgroundColor: "#fafafa",
  width: "400px",
  padding: "0.5rem",
  marginLeft: "0.5rem",
};
const generateSetupData = (data, requestData, partId) => {
  requestData.processes = [];
  requestData.part_id = partId;
  requestData.is4M = "man";
  data.processes?.forEach((process) => {
    let obj = {};
    obj.process_name = process.process_name;
    obj.specs = [];
    process.specs.forEach((spec) => {
      let objSpec = {};
      objSpec.parameter = spec.char_product;
      objSpec.specification = spec.control_product;
      objSpec.methods = spec.methods_measure;
      objSpec.appearance = spec.appearance;
      objSpec.mean = spec.mean;
      objSpec.utolerance = spec.utolerance;
      objSpec.ltolerance = spec.ltolerance;
      objSpec.utl = spec.utl;
      objSpec.ltl = spec.ltl;
      obj.specs.push(objSpec);
    });
    requestData.processes.push(obj);
  });
  return requestData;
};
const CreateRequestForm = () => {
  const { partId } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [requestData, setRequestData] = useState(null);
  const history = useHistory();
  const { state } = useAuth();

  useEffect(() => {
    axios
      .get(`/api/get/controlPlanPart/${partId}`)
      .then((res) => {
        let obtainedData = generateSetupData(res.data, {}, partId);
        setRequestData(obtainedData);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const isSubmit = window.confirm(
      "Are you sure you want to submit this Request Form?"
    );
    if (isSubmit) {
      setIsLoading(true);
      requestData.is_request_submitted = true;
      requestData.is_admin_request = "pending";
      requestData.admin_request_no = 1;
      requestData.report_prepared_by = state.user.name;
      axios
        .post("/api/create/request_form", requestData)
        .then((res) => {
          if (res.data.status === "success") {
            history.push("/supervisor/view/4mTable");
          }
          setIsLoading(false);
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const saveLater = () => {
    const isSubmit = window.confirm(
      "Are you sure you want to save this Request Form?"
    );
    if (isSubmit) {
      setIsLoading(true);
      requestData.is_request_submitted = false;
      requestData.report_prepared_by = state.user.name;
      axios
        .post("/api/create/request_form", requestData)
        .then((res) => {
          if (res.data.status === "success") {
            history.push("/supervisor/view/4mTable");
          }
          setIsLoading(false);
        })
        .catch((err) => setIsLoading(false));
    }
  };

  const handleChangeRequest = ({ target }) => {
    let value = target.type === "checkbox" ? target.checked : target.value;
    setRequestData((prev) => ({ ...prev, [target.name]: value }));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <main>
      <div className="divflex" style={{ fontSize: "24px" }}>
        REQUEST FORM
      </div>

      {requestData && (
        <form
          style={{
            marginTop: "2rem",
            marginLeft: "1rem",
            marginRight: "1rem",
            paddingBottom: "4rem",
          }}
        >
          <CreateRequestHeadForm
            {...{ handleChangeRequest, requestData, setRequestData }}
          />
          <CreateRequestMan {...{ requestData, handleChangeRequest }} />
          <CreateRequestMachine {...{ requestData, handleChangeRequest }} />
          <CreateRequestMaterial {...{ requestData, handleChangeRequest }} />
          <CreateRequestMethod {...{ requestData, handleChangeRequest }} />
          <Wrapper>
            <Input
              type="text"
              name="change_input"
              label="Change Input"
              defaultValue={requestData.change_input}
              onChange={handleChangeRequest}
            />
            <Input
              type="text"
              name="change_output"
              label="Change Output"
              defaultValue={requestData.change_input}
              onChange={handleChangeRequest}
            />
            <div style={style}>
              Change Request Approved{" "}
              <Checkbox disabled={true} defaultChecked={requestData.approved} />{" "}
              YES
              <Checkbox
                disabled={true}
                defaultChecked={requestData.approved}
              />{" "}
              NO
            </div>
          </Wrapper>
          <Wrapper
            style={{
              position: "absolute",
              top: "4.6rem",
              right: "1.6rem",
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={saveLater}
              style={{ backgroundColor: "#003566" }}
              startIcon={<DoubleArrowIcon />}
            >
              Save & Continue
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSubmit}
              style={{ backgroundColor: "#054a29" }}
              startIcon={<SaveIcon />}
            >
              Submit
            </Button>
          </Wrapper>
        </form>
      )}
    </main>
  );
};

export default CreateRequestForm;
