import { Button, Paper, Tab, Tabs, Tooltip } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Modal from "../../components/Control/Modal";
import { useQuery } from "../../components/Control/useQuery";
import MaterialTCInputs from "../MaterialTc/MaterialTCInputs";
import CreateEditViewBreakLoad from "./CreateEditViewBreakLoad";
import CreateEditViewIncoming from "./CreateEditViewIncoming";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { mdiFileExcel } from "@mdi/js";
import TableToExcel from "@dayalk/table-to-excel";

import Icon from "@mdi/react";
import { Wrapper } from "../../components/Control/Flexbox";
import UpdateIcon from "@material-ui/icons/Update";
import useConfirm from "../../components/Control/useConfirm";
import { useAuth } from "../../context/GlobalContext";
const convertDataForSupervisor = (partData) => {
  let newPartData = {};
  newPartData.rm_id = partData._id;
  newPartData.item_desc = partData.item_desc;
  newPartData.item_code = partData.item_code;
  newPartData.material = partData.material;
  newPartData.disposition_way = partData.disposition_way;
  newPartData.rejected = partData.rejected;
  newPartData.rework = partData.rework;
  newPartData.deviation = partData.deviation;
  newPartData.incoming_remarks = partData.remarks;
  newPartData.incomings = [];
  partData.specs.forEach((spec) => {
    newPartData.incomings.push({
      s_no: spec.s_no,
      characteristic: spec.characteristic,
      appearance: spec.appearance,
      method: spec.method,
      sample_taken: spec.sample_taken,
      control_product: spec.control_product,
      mean: spec.mean,
      utolerance: spec.utolerance,
      ltolerance: spec.ltolerance,
      unit: spec.unit,
      utl: spec.utl,
      ltl: spec.ltl,
      checkbox4_num: spec.checkbox4_num,
      check4_symbol: spec.check4_symbol,
    });
  });
  newPartData.breakloads = [];
  for (let i = 0; i < 16; i++) {
    newPartData.breakloads.push({ s_no: i + 1 });
  }
  return newPartData;
};
const dynamicBreakLoadRow = (name, data) => {
  if (name === "qty") {
    if (+data.qty >= 16) {
      data.breakloads.length = 16;
      for (let i = 0; i < +data.qty - 16; i++) {
        data.breakloads.push({ s_no: 16 + i + 1 });
      }
    } else {
      data.breakloads.length = 16;
    }
  }
  // if (data.qty && data.lot_code) {
  //   for (let i = 0; i < +data.qty; i++) {
  //     data.breakloads[i].coil_id = data.lot_code + (i + 1);
  //   }
  // }
};
function checkMinMax(name, data) {
  if (name === "ts_breakload") {
    data.ot_max = Math.max(
      ...data.breakloads
        .map((item) => item.ts_breakload && +item.ts_breakload)
        .filter((item) => {
          if (item === undefined) {
            return false;
          }
          if (item === "") {
            return false;
          }
          return true;
        })
    );
    data.ot_min = Math.min(
      ...data.breakloads
        .map((item) => item.ts_breakload && +item.ts_breakload)
        .filter((item) => {
          if (item === undefined) {
            return false;
          }
          if (item === "") {
            return false;
          }
          return true;
        })
    );
  }
  if (name === "size") {
    data.obtained_size_min = Math.min(
      ...data.breakloads
        .map((item) => item.size && +item.size)
        .filter((item) => {
          if (item === undefined) {
            return false;
          }
          if (item === "") {
            return false;
          }
          return true;
        })
    );
    data.obtained_size_max = Math.max(
      ...data.breakloads
        .map((item) => item.size && +item.size)
        .filter((item) => {
          if (item === undefined) {
            return false;
          }
          if (item === "") {
            return false;
          }
          return true;
        })
    );
  }
}
const IncomingBreakloadTab = () => {
  const { rmId, id } = useParams();
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const isModal = query.get("isModal");
  const history = useHistory();
  const [data, setData] = useState();
  const { state } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [compRender, setCompRender] = useState(false);

  const handleChange = (e, index) => {
    const value = e.target.value;
    const name = e.target.name;
    if (index === 0 || index) {
      data.breakloads[index][name] = value;
      checkMinMax(name, data);
    } else {
      data[name] = value;
      dynamicBreakLoadRow(name, data);
      if (name === "lot_code") {
        data.breakloads.forEach((item) => {
          item.lot_code = value;
        });
      }
    }
    setCompRender((prev) => !prev);
  };
  useEffect(() => {
    if (isModal) {
      setOpenModal(true);
    }
  }, []);
  const [render, setRender] = useState(false);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE",
    "Are you sure you want to update?"
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    "Are you sure you want to save?"
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getById/inspection_breakload/${id}`)
        .then((res) => {
          if (res.data) {
            setData(res.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(`/api/getById/rm/${rmId}`)
        .then((res) => {
          if (res.data) {
            const convertedPartData = convertDataForSupervisor(res.data);
            convertedPartData.inspection_date = new Date();
            setData(convertedPartData);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [render]);

  const handleSubmit = async (isSubmitted) => {
    const ans = data._id ? await confirmUpdate() : await confirmSubmit();
    data.isSubmitted = isSubmitted;
    data.is_admin_request = "pending";
    data.report_prepared_by = state.user?.name;
    return new Promise((resolve, reject) => {
      if (data._id) {
        if (ans) {
          axios
            .put(`/api/edit/inspection_breakload/${id}`, data)
            .then((res) => {
              if (res.data.status === "success") {
                resolve();
                alert("Data updated successfully");
              }
            })
            .catch((err) => {
              reject();
              console.error(err);
            });
        }
      } else {
        if (ans) {
          axios
            .post("/api/create/inspection_breakload", data)
            .then((res) => {
              if (res.data.status === "success") {
                alert("Data submitted successfully");
                history.push(
                  `/incoming/breakload/${rmId}?item_desc=${data.item_desc}&item_code=${data.item_code}`
                );
              }
            })
            .catch((err) => console.error(err));
        }
      }
    });
  };
  return (
    <main>
      <div
        className="divflex"
        style={{ fontSize: "24px", justifyContent: "space-between" }}
      >
        RM INCOMING/BREAKLOAD
        {isView ? (
          <Wrapper justify="space-evenly">
            {/* <Tooltip arrow title="Print table">
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip> */}
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `RM Incoming/Breakload - ${data?.item_desc}.xlsx`,
                        sheet: {
                          name: "RM Incoming Breakload",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
            {data && data?.material_tc_id && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#003566",
                }}
                onClick={() => {
                  history.push(
                    `/viewMaterialTc/${data && data.material_tc_id}`
                  );
                }}
              >
                View Material TC{" "}
              </Button>
            )}
          </Wrapper>
        ) : (
          <Wrapper>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              {id && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#003566",
                  }}
                  startIcon={<AddCircleIcon />}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Attach Material TC{" "}
                </Button>
              )}
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{ backgroundColor: "#003566" }}
                startIcon={id ? <UpdateIcon /> : <CloudUploadIcon />}
                onClick={() => handleSubmit(false)}
              >
                SAVE & CONTINUE
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{ backgroundColor: "#003566" }}
                startIcon={id ? <UpdateIcon /> : <CloudUploadIcon />}
                onClick={() => handleSubmit(true)}
              >
                {id ? "Update" : "Submit"}
              </Button>
            </div>
          </Wrapper>
        )}
        {/* {isView && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={data && !data?.material_tc_id}
            style={{
              backgroundColor: "#003566",
            }}
            onClick={() => {
              history.push(`/viewMaterialTc/${data && data.material_tc_id}`);
            }}
          >
            View Material TC{" "}
          </Button>
        )} */}
      </div>
      <div>
        <DialogUpdate />
        <DialogSubmit isSubmitContent={true} />
      </div>
      <Paper elevation={2} style={{ margin: "1rem" }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
        >
          <Tab label="BREAKLOAD REGISTER" />
          <Tab label="INCOMING MATERIAL INSPECTION" />
        </Tabs>
      </Paper>
      {data && tabValue === 0 && (
        <CreateEditViewBreakLoad {...{ data, isView, handleChange }} />
      )}
      {data && tabValue === 1 && (
        <CreateEditViewIncoming {...{ data, isView, handleChange }} />
      )}
      <Modal
        title="Add MATERIAL TC"
        openPopup={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      >
        <MaterialTCInputs
          {...{ id, setOpenModal, setRender }}
          submitBreakLoad={handleSubmit}
          currentId={data && data.material_tc_id}
        />
      </Modal>
    </main>
  );
};

export default IncomingBreakloadTab;
