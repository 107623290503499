import ViewChild from "./ViewChild";
import AddChild from "./AddChild";

const AddChildAndChildPartList = ({ partId, isChildList }) => {
  return (
    <div>
      {isChildList ? (
        <ViewChild {...{ partId }} />
      ) : (
        <AddChild {...{ partId }} />
      )}
    </div>
  );
};

export default AddChildAndChildPartList;
