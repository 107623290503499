import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import formatDate from "../../../components/Control/formatDate";
import PrintIcon from "@material-ui/icons/Print";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    p{
      margin:0;
    }
    span{
      font-weight:500;
    }

`;

function tConv24(time24) {
  if (time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
}

function ViewRequestForm() {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/get/request_form/${id}`);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <div style={{ paddingBottom: "4rem" }}>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          4M REQUEST FORM
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper>
            <Tooltip title="Print table" arrow>
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip title="Export as spreadsheet" arrow>
              <div>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn1"
                  table="table-to-xls"
                  filename="requestForm"
                  sheet="requestForm"
                  buttonText={
                    <Button
                      style={{
                        backgroundColor: "#161a1d",
                        borderColor: "#161a1d",
                        color: "white",
                        width: "fit-content",
                        borderRadius: "0",
                        paddingTop: "9px",
                      }}
                    >
                      <Icon path={mdiFileExcel} size={1.2} />
                    </Button>
                  }
                />
              </div>
            </Tooltip>
          </Wrapper>
        </div>
      </div>
      <Table cellSpacing="0" style={{ marginTop: "0.6rem" }} id="table-to-xls">
        <tbody>
          <tr>
            <th
              colSpan={2}
              rowSpan="4"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                height: "100px",
              }}
            >
              <CenteredLogoImg>
                <img src={images.CompanyLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th align="left" style={{ border: "1px solid black" }}>
              DOCUMENT NO:
            </th>
            <th align="left" style={{ border: "1px solid black" }}>
              F SYS 31
            </th>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              ISSUE DATE:
            </th>
            <th align="left" style={{ border: "1px solid black" }}>
              01/04/2019
            </th>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              REVISION NO:
            </th>
            <th align="left" style={{ border: "1px solid black" }}>
              04
            </th>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              REVISION DATE:
            </th>
            <th align="left" style={{ border: "1px solid black" }}>
              01/02/2022
            </th>
          </tr>
          <tr>
            <th
              colSpan={4}
              align="center"
              style={{
                border: "1px solid black",
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              4M CHANGE REQUEST FORM
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
              }}
              align="left"
              colSpan={2}
            >
              Request deptt. / Name: <span>{data.req_deptt}</span>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
              colSpan={2}
              align="left"
            >
              Time: <span>{tConv24(data.time)}</span>
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
              }}
              align="left"
              colSpan={2}
            >
              Machine Name / No: <span>{data.machine_name}</span>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
              colSpan={2}
              align="left"
            >
              Part No / Item No: <span>{data.part_id?.part_number}</span>
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              Man: <span>{data.is4M === "man" && data.desc_4m}</span>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              Machine: <span>{data.is4M === "machine" && data.desc_4m}</span>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              Method: <span>{data.is4M === "method" && data.desc_4m}</span>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
              align="left"
            >
              Material: <span>{data.is4M === "material" && data.desc_4m}</span>
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
              }}
              align="left"
              colSpan={2}
            >
              Change requested by: <span>{data.change_req_by}</span>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
              colSpan={2}
              align="left"
            >
              Request Date: <span>{formatDate(data.req_date)}</span>
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              CHANGE ITEM
            </th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              CHANGE DETAILS
            </th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              CHANGE REQUIRED
            </th>
            <th
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              REASON of CHANGE
            </th>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              rowSpan={11}
            >
              MAN
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator/Inspector on Leave with information
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_1}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_1}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator/Inspector on Leave without information
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_2}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_2}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator/Inspector leave middle of shift due to any work
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_3}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_3}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator suddenly leave work place due to accident/illness
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_4}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_4}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator/Inspector Works after more than 30 days leave
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_5}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_5}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              New inspector appointed at final stage
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_6}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_6}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator/Inspector Works Continous after 12 hours
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_7}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_7}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              New inspector/operator appointed
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_8}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_8}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Inspector fail in poison test
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_9}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_9}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator fail in observance
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_10}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_10}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Operator/ Inspector not working as per work instructions
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_change_11}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.man_reason_11}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              rowSpan={7}
            >
              MACHINE
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Machine/Tool under preventive maintenance
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_change_1}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_reason_1}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Machine/Tool under break down
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_change_2}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_reason_2}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              New Tool Issue
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_change_3}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_reason_3}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              New machine install / Machine Change
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_change_4}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_reason_4}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Poka Yoke Failure
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_change_5}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_reason_5}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Power Failure
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_change_6}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_reason_6}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Abnormal Sounds from machine
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_change_7}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mac_reason_7}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              rowSpan={3}
            >
              MATERIAL
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Material received from other approved source, not used earlier
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mat_change_1}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mat_reason_1}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Material used from other unapproved source
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mat_change_2}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mat_reason_2}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Different grade/size material used
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mat_change_3}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.mat_reason_3}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
              rowSpan={6}
            >
              METHOD
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Inspection Method Change
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_change_1}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_reason_1}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Process Parameter/ Layout Change
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_change_2}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_reason_2}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Process add, remove or process no change
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_change_3}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_reason_3}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Inspection Equipment failure
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_change_4}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_reason_4}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Part Fall down on floor from Bins / Trolley
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_change_5}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_reason_5}
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              Parts Mixing
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_change_6}
            </td>
            <td
              style={{
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              {data.meth_reason_6}
            </td>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
              }}
              colSpan="2"
              align="left"
            >
              Change Input: <span>{data.change_input}</span>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
              colSpan="2"
              align="left"
            >
              Change Output: <span>{data.change_output}</span>
            </th>
          </tr>
          <tr>
            <th
              style={{
                border: "1px solid black",
                position: "relative",
                height: "60px",
              }}
              colSpan="5"
              align="left"
            >
              Change Request Approved ( YES / NO ):{" "}
              {data.is_admin_request === "accepted" && (
                <>
                  <span
                    style={{
                      fontSize: "2.5rem",
                      position: "absolute",
                      bottom: "-1.1px",
                      marginLeft: "2.5rem",
                    }}
                  >
                    ☑
                  </span>
                  <span style={{ marginLeft: "4.7rem", marginRight: "3rem" }}>
                    YES
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "4rem",
                      position: "absolute",
                      bottom: "-12px",
                    }}
                  >
                    □
                  </span>{" "}
                  <span style={{ marginLeft: "2.4rem" }}>NO</span>
                </>
              )}
              {data.is_admin_request === "rejected" && (
                <>
                  <span
                    style={{
                      fontSize: "4rem",
                      position: "absolute",
                      bottom: "-12px",
                    }}
                  >
                    □
                  </span>{" "}
                  <span style={{ marginLeft: "2.4rem" }}>YES</span>{" "}
                  <span
                    style={{
                      fontSize: "2.5rem",
                      position: "absolute",
                      bottom: "-12px",
                    }}
                  >
                    ☑
                  </span>
                  <span style={{ marginLeft: "2.2rem" }}>NO</span>
                </>
              )}
            </th>
            {/* <th
              style={{
                border: "1px solid black",
              }}
              colSpan="2"
              align="left"
            >
              Approved by ( Production Manager ): <span></span>
            </th> */}
          </tr>
          <tr style={{ height: "60px" }}>
            <td
              align="left"
              colSpan={1}
              style={{ fontWeight: 500, border: "1px solid black" }}
            >
              Prepared By:
            </td>
            <td
              align="left"
              colSpan={1}
              style={{ border: "1px solid black", textTransform: "capitalize" }}
            >
              {data.report_prepared_by}
            </td>

            <td
              align="left"
              colSpan={1}
              style={{ fontWeight: 500, border: "1px solid black" }}
            >
              Approved By:
            </td>
            <td
              align="left"
              style={{ border: "1px solid black", textTransform: "capitalize" }}
              colSpan={1}
            >
              {data.is_admin_request === "accepted" && data.report_approved_by}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ViewRequestForm;
