import styled from "styled-components";

export const StyledTableHeaderTh1 = styled.th`
  position: sticky;
  border: 1px solid black;
  z-index: ${(props) => (props?.zIndex ? props?.zIndex : 1)};
  color: ${(props) => (props?.color ? props?.color : "#000000")};
  background-color: ${(props) => (props?.bgColor ? props?.bgColor : "#4f81bd")};
  /* color: #fff; */
  left: ${(props) => (props?.left ? props?.left : 0)};
  width: ${(props) => (props?.width ? props?.width : "50px")};
  height: ${(props) => (props?.height ? props?.height : "auto")};
  min-width: ${(props) => (props?.minWidth ? props?.minWidth : "50px")};
`;
