import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@material-ui/core/Tooltip";
import { DatePicker } from "@material-ui/pickers";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { usePermission } from "../../../components/usePermission";
import formatDate from "../../../components/Control/formatDate";
import { Wrapper } from "../../../components/Control/Flexbox";
import { useAuth } from "../../../context/GlobalContext";
import useConfirm from "../../../components/Control/useConfirm";
import { useQuery } from "../../../components/Control/useQuery";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {},
});

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      // borderBottom: "unset",
      padding: "6px",
      textAlign: "center",
      fontSize: "13px",
    },
    "& .MuiTableCell-head": {
      fontWeight: "600",
      textTransform: "uppercase",
    },
  },
  head: {
    backgroundColor: "#ffb333",

    "& .MuiTableCell-head": {
      fontWeight: "600",
    },
  },
  container: {
    maxHeight: 550,
  },
});

// function formatDate(string) {
//   var options = { year: "numeric", month: "long", day: "numeric" };
//   var day = new Date(string).toLocaleDateString([], options);
//   return day;
// }

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

function Row(props) {
  const {
    row,
    id,
    index,
    updateRequestForm,
    partId,
    partNumber,
    partName,
    deletePdir,
  } = props;
  const permissions = usePermission(3);
  const classes = useRowStyles();
  const [render, isRender] = useState(false);
  const { state } = useAuth();
  const handleChangeRequestStatus = (e, index) => {
    row.is_admin_request = null;
    row.is_admin_request_no = e.target.value;
    isRender((prev) => !prev);
  };
  const showStausIcons = () => {
    if (row.is_admin_request === "pending") {
      return (
        <Tooltip title="Pending" arrow>
          <IconButton size="small">
            <AccessTimeIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
          </IconButton>
        </Tooltip>
      );
    }
    if (row.is_admin_request === "accepted") {
      return (
        <Tooltip title="Approved" arrow>
          <IconButton size="small">
            <CheckIcon style={{ fontSize: "1.4rem", color: "green" }} />
          </IconButton>
        </Tooltip>
      );
    }

    if (row.is_admin_request === "rejected") {
      return (
        <Tooltip title="Send Back" arrow>
          <IconButton size="small">
            <ClearIcon style={{ fontSize: "1.4rem", color: "red" }} />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{formatDate(row.pdir_date)}</TableCell>
        <TableCell align="center">{row.part_id.part_number}</TableCell>
        <TableCell style={{ textTransform: "capitalize" }} align="center">
          {row.report_prepared_by}
        </TableCell>
        <TableCell align="center">
          {showStausIcons()}
          {permissions?.includes("edit") ? (
            <select
              style={{
                fontSize: "0.9rem",
                outline: "none",
                border: "none",
                padding: "5px",
                marginLeft: "0.5rem",
                width: "150px",
                textAlign: "center",
                textTransform: "capitalize",
                ...(row.is_admin_request === "pending" && {
                  backgroundColor: "#FDD835",
                }),
                ...(row.is_admin_request === "accepted" && {
                  backgroundColor: "#2b8a3e",
                  color: "#fff",
                }),
                ...(row.is_admin_request === "rejected" && {
                  backgroundColor: "#f03e3e",
                  color: "#fff",
                }),
              }}
              name="is_admin_request"
              value={statusObj[row.is_admin_request] || row.is_admin_request_no}
              onChange={(e) => handleChangeRequestStatus(e, index)}
            >
              <option value="1">PENDING</option>
              <option value="2">APPROVE</option>
              <option value="3">SEND BACK</option>
            </select>
          ) : (
            <BlockIcon style={{ color: "#c92a2a" }} />
          )}
        </TableCell>
        <TableCell align="center">
          <Wrapper style={{ gap: "0.5rem" }} justify="center">
            <Tooltip title="View PIR" arrow>
              <Link to={`/view/pdirTable/${row._id}`}>
                <IconButton size="small">
                  <VisibilityIcon
                    style={{
                      fontSize: "1.4rem",
                      color: "#003566",
                    }}
                  />
                </IconButton>
              </Link>
            </Tooltip>
            {permissions?.includes("edit") && (
              <Tooltip title="Edit" arrow>
                <Link
                  to={`/pdir/edit/${row._id}?partNumber=${partNumber}&partName=${partName}`}
                >
                  <IconButton size="small">
                    <EditIcon
                      style={{ fontSize: "1.4rem", color: "#003566" }}
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {permissions?.includes("delete") && (
              <Tooltip title="Delete" arrow>
                <IconButton
                  size="small"
                  style={{
                    color: "#c80202",
                  }}
                  onClick={() => deletePdir(id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {permissions?.includes("edit") && (
              <Button
                variant="contained"
                size="small"
                style={{ background: "#054a29", color: "#fff" }}
                onClick={(e) => updateRequestForm(id, index)}
                startIcon={<UpdateIcon />}
              >
                UPDATE
              </Button>
            )}
          </Wrapper>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function PdirAdminTable() {
  const classes = useStyles();
  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE PDIR",
    "Are you sure you want to delete this PDIR?"
  );
  const { partId } = useParams();
  const [pdirData, setPdirData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE PDIR",
    "Are you sure you want to update this PDIR?"
  );

  const deletePdir = async (id) => {
    let isDeleted = await confirmDelete();
    if (isDeleted) {
      axios
        .delete(`/api/delete/pdir/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };
  const updateRequestForm = async (pdirID, index) => {
    let isConfirm = await confirmUpdate();
    if (isConfirm) {
      axios
        .patch(`/api/statusupdate/pdir/${pdirID}`, {
          is_admin_request_no: pdirData[index].is_admin_request_no,
          report_approved_by: state.user.name,
          approved_by_designation: state.user.designation,
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert("Status Change successfully");
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    axios
      .get(
        `/api/get/monthly/pdir/${state.user.userType}/${partId}/${selectedDate}`
      )
      .then((res) => {
        setPdirData(
          res.data.sort((a, b) => a.pdir_date.localeCompare(b.pdir_date))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedDate, partId, render]);
  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PRE-DISPATCH INSPECTION REPORT
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />

      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE
          <span className="subheading-secondary"> REPORTS</span>
        </p>
      </div>

      <div>
        <div style={{ display: "flex", margin: "1rem" }}>
          <DatePicker
            views={["month", "year"]}
            label="Month & Year"
            className="date-field"
            inputVariant="filled"
            value={selectedDate || null}
            onChange={setSelectedDate}
          />
        </div>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            aria-label="collapsible table"
            style={{ marginBottom: "5rem" }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  #
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  PDIR Date
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Part Number
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Username
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Status
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pdirData.map((row, index) => (
                <Row
                  key={uuidv4()}
                  row={row}
                  index={index}
                  id={row._id}
                  partId={partId}
                  updateRequestForm={updateRequestForm}
                  {...{ partNumber, partNumber, deletePdir }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <DialogUpdate isSubmitContent={false} />
      <DialogDelete isSubmitContent={false} />
    </>
  );
}

export default PdirAdminTable;
