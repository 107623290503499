import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";
import { Wrapper } from "../../components/Control/Flexbox";
import Modal from "../../components/Control/Modal";

const API = {
  "annexure-a": `/api/importRevisedAnnexure/data?annexureType=annexure-a`,
  "annexure-b": `/api/importRevisedAnnexure/data?annexureType=annexure-b`,
  "annexure-c": `/api/importRevisedAnnexure/data?annexureType=annexure-c`,
};

const UploadAnnexure = ({
  setIsAnnexureUpload,
  setErrorModal,
  errorObject,
}) => {
  const [annexureType, setAnnexureType] = useState("annexure-a");

  const handleAnnexureImport = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios
      .post(`${API[annexureType]}`, formData)
      .then((res) => {
        if (res.data.isSuccess) window.alert("successfully uploaded");
        setIsAnnexureUpload(false);
        if (res.data.errorObj.length > 0) {
          errorObject.current = res.data.errorObj;
          setErrorModal(true);
        }
      })
      .catch((err) => {
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else if (
          err.response.data.error.message
            .split(" ")
            .join("")
            .toLowerCase()
            .includes("duplicatekeyerror")
        ) {
          window.alert(
            "Successfully uploaded. All the include parts has been skipped."
          );
        } else {
          window.alert(
            "Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted."
          );
        }
      });
  };

  return (
    <section>
      <Wrapper
        style={{ padding: "1rem", gap: "4rem" }}
        justify="center"
        align="center"
      >
        <FormControl
          className="select-field"
          variant="filled"
          style={{ width: "310px" }}
        >
          <InputLabel>Select Annexure Type</InputLabel>
          <Select
            name="category"
            value={annexureType}
            key={annexureType}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={(e) => {
              setAnnexureType(e.target.value);
            }}
          >
            <MenuItem value="annexure-a">Annexure A</MenuItem>
            <MenuItem value="annexure-b">Annexure B</MenuItem>
            <MenuItem value="annexure-c">Annexure C</MenuItem>
          </Select>
        </FormControl>
        <div style={{ marginTop: "0.5rem" }}>
          <input
            id="contained-button-file"
            type="file"
            name="excelFile"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleAnnexureImport}
            hidden
          />
          <label htmlFor="contained-button-file">
            <Button
              type="button"
              variant="contained"
              color="primary"
              component="span"
              size="large"
              style={{
                background: "#003566",
                marginRight: "1rem",
              }}
            >
              <CloudUploadIcon style={{ marginRight: "1rem" }} />
              Upload Annexure
            </Button>
          </label>
        </div>
      </Wrapper>
    </section>
  );
};

export default UploadAnnexure;
