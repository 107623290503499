import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Add, DoneAll } from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Control/Flexbox";

const CategoryList = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);
  const [value1, setValue1] = useState(null);
  const [render, setRender] = useState(false);
  const [isEdit, setIsEdit] = useState([]);
  useEffect(() => {
    axios
      .get("/api/getInstrumentCategory")
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [render]);
  const onSubmit = (value) => {
    if (value) {
      axios.post("/api/addInstrumentCategory", { name: value }).then((res) => {
        if (res.data) {
          alert("document added successfully");
          setValue(null);
          setRender((prev) => !prev);
        }
      });
    }
  };
  const updateCategory = (id, value) => {
    if (!value) return;
    if (!window.confirm("Are you sure you want to update this document?"))
      return;
    axios
      .put(`/api/updateInstrumentCategory/${id}`, { name: value })
      .then((res) => {
        if (res.data) {
          alert("document update successfully");
          setRender((prev) => !prev);
        }
      });
  };
  const deleteCategory = (id) => {
    axios
      .delete(`/api/removeInstrumentCategory/${id}`)
      .then(() => {
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Wrapper
        style={{ border: "1px solid #f0f2f0", width: "360px", margin: "1rem" }}
      >
        <Input
          type="text"
          name="name"
          style={{ height: "55px", width: "330px" }}
          key={value ? "notLoadedYet" : "loaded"}
          placeholder={`Category Name`}
          onBlur={(e) => setValue(e.target.value)}
          defaultValue={value}
        />
        <Wrapper
          style={{
            width: "55px",
            height: "55px",
            backgroundColor: "#c80202",
            color: "white",
            cursor: "pointer",
          }}
          justify="center"
          onClick={() => {
            onSubmit(value);
            setValue("");
          }}
        >
          <Add />
        </Wrapper>
      </Wrapper>
      <TableContainer component={Paper} style={{ margin: "1rem" }}>
        <Table size="small">
          <TableHead>
            <TableRow style={{ padding: "6px" }}>
              {["S.NO", "CATEGORY NAME", "ACTION"].map((heading, index) => (
                <TableCell
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  align="center"
                >
                  {heading}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              // <React.Fragment key={item._id}>
              <TableRow key={item._id}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell
                  align="center"
                  contentEditable={isEdit[index]===true}
                  onKeyUp={(e) => setValue1(e.target.innerText)}
                  style={{ textTransform: "capitalize" }}
                >
                  {item?.name}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ whiteSpace: "nowrap", width: "150px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      minWidth: "150px",
                      width: "150px",
                      alignItems: "center",
                    }}
                  >
                    {isEdit[index]===true ? (
                      <div>
                        <Tooltip title="Close" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: ".5rem",
                            }}
                            onClick={() => {
                              setIsEdit(false);
                            }}
                          >
                            <Close
                              style={{ fontSize: "1.4rem", color: "black" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Update" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: ".5rem",
                            }}
                            onClick={() => {
                              updateCategory(item._id, value1);
                              const values = [...isEdit];
                              values[index] = false;
                              setIsEdit(values);
                            }}
                          >
                            <DoneAll
                              style={{ fontSize: "1.4rem", color: "#c80202" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : (
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          size="small"
                          style={{
                            marginRight: ".5rem",
                          }}
                          onClick={() => {
                            const values = [...isEdit];
                            values[index] = true;
                            setIsEdit(values);
                          }}
                        >
                          <Edit
                            style={{ fontSize: "1.4rem", color: "black" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        style={{
                          marginRight: "0.8rem",
                        }}
                        onClick={() => {
                          deleteCategory(item._id);
                        }}
                      >
                        <Delete
                          style={{
                            fontSize: "1.4rem",
                            color: "#c80202",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default CategoryList;
