import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";
import { Wrapper } from "../../components/Control/Flexbox";
import { DatePicker } from "@material-ui/pickers";
import SaveIcon from "@material-ui/icons/Save";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import UpdateIcon from "@material-ui/icons/Update";

import Loader from "../../components/Control/Loader";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../context/GlobalContext";

import IncomingHeadForm from "./IncomingHeadForm";
import IncomingSpecs from "./IncomingSpecs";
import { useQuery } from "../../components/Control/useQuery";

import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useConfirm from "../../components/Control/useConfirm";
import {
  SaveAndContinueButton,
  CloseButton,
} from "../../GlobalUtils/ButtonsGlobal";

const UpdateIncoming = () => {
  const [isLoading, setIsloading] = useState(false);
  const { state } = useAuth();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [incomingData, setIncomingData] = useState(0);
  const [incomingDate, setincomingDate] = useState({
    incoming_mrir_date: "",
    incoming_invoice_date: "",
    incoming_date: "",
  });
  const history = useHistory();
  const { id } = useParams();
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save this Report?"
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    "Are you sure you want to submit this Report?"
  );

  useEffect(() => {
    axios
      .get(`/api/get/incomingReport/${id}`)
      .then((res) => {
        setIncomingData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let ans = await confirmSubmit();
    if (ans) {
      setIsloading(true);
      incomingData.isSubmitted = true;
      incomingData.username = state.user.username;

      await axios
        .put(`/api/edit/incomingReport/${id}`, incomingData)
        .then((res) => {
          if (res.data?.status === "success") {
            if (state.user.userType === 3) {
              history.push(
                `/view/incomingTable/${incomingData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
              );
            } else {
              history.push(
                `/view/incomingTable/${incomingData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
              );
            }
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };

  const saveLater = async () => {
    let ans = await confirmSave();
    if (ans) {
      setIsloading(true);
      incomingData.isSubmitted = false;
      incomingData.username = state.user.username;

      await axios
        .put(`/api/edit/incomingReport/${id}`, incomingData)
        .then((res) => {
          if (state.user.userType === 3) {
            history.push(
              `/view/incomingTable/${incomingData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
            );
          } else {
            history.push(
              `/view/incomingTable/${incomingData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
            );
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      {incomingData && (
        <>
          <div
            className="divflex "
            style={{
              fontSize: "24px",
            }}
          >
            INCOMING REPORT
          </div>
          <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
          <form
            style={{
              margin: "0.6rem",
              marginTop: "1rem",
            }}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div style={{ marginLeft: "1%" }}>
              <DatePicker
                inputVariant="filled"
                name="incoming_date"
                label="Date"
                value={
                  incomingDate.incoming_date ||
                  incomingData.incoming_date ||
                  null
                }
                onChange={(date) => {
                  incomingData.incoming_date = date;
                  setincomingDate((prev) => ({
                    ...prev,
                    incoming_date: date,
                  }));
                }}
                format="dd/MM/yyyy"
                className="date-field"
              />
            </div>

            <IncomingHeadForm
              {...{ incomingData, incomingDate, setincomingDate }}
            />
            <IncomingSpecs incomingData={incomingData} />

            {state.user.userType === 2 ? (
              <Wrapper
                style={{
                  position: "absolute",
                  top: "4.7rem",
                  right: "1.6rem",
                  gap: "1rem",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#054a29" }}
                  startIcon={<UpdateIcon />}
                >
                  Update
                </Button>
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  position: "absolute",
                  top: "4.7rem",
                  right: "1.6rem",
                  gap: "1rem",
                }}
              >
                <SaveAndContinueButton onClick={saveLater} />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#054a29", marginRight: "0.5rem" }}
                  startIcon={<CloudUploadIcon />}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            )}
          </form>
        </>
      )}
      <DialogSave isSubmitContent={true} />
      <DialogSubmit isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
    </main>
  );
};

export default UpdateIncoming;
