import React from "react";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import BorderInnerIcon from "@material-ui/icons/BorderInner";
import GamesIcon from "@material-ui/icons/Games";
import homePage from "../../components/Admin/HomePage.module.css";
const SpcTiles = () => {
  return (
    <main
      style={{ height: "100vh" }}
      className={`${homePage.container} ${homePage.main}`}
    >
      <div>
        <a
          href="https://slf.secure.aqua.mushinlabs.com/common/spcPlanlist"
          rel="noopener noreferrer"
          className="panel"
        >
          <VerticalAlignCenterIcon
            style={{ fontSize: "3rem", color: "maroon" }}
          />
        </a>
        <p className="card-btn-para">
          SPC
          <span className="card-btn-span">PLAN</span>
        </p>
      </div>
      <div>
        <a
          href="https://slf.secure.aqua.mushinlabs.com/common/spcPlanChecklist"
          rel="noopener noreferrer"
          className="panel"
        >
          <BorderInnerIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </a>
        <p className="card-btn-para">
          SPC <span className="card-btn-span">STATUS</span>
        </p>
      </div>
      <div>
        <a
          href="https://slf.secure.aqua.mushinlabs.com/common/spc/partlist"
          rel="noopener noreferrer"
          className="panel"
        >
          <GamesIcon style={{ fontSize: "3rem", color: "maroon" }} />
        </a>
        <p className="card-btn-para">
          SPC <span className="card-btn-span">REPORT</span>
        </p>
      </div>
    </main>
  );
};

export default SpcTiles;
