import React from "react";

const TableReadEditCell = ({
  isView,
  name,
  value,
  type,
  handleChange,
  index,
  style,
  required,
  disabled,
  step,
}) => {
  return (
    <div
      style={{
        padding: "0.1rem",
        display: isView ? "inline-block" : "block",
      }}
    >
      {isView ? (
        value
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          required={required}
          autoComplete="off"
          className="input"
          style={{
            border: "1px solid #f9e0e0",
            ...style,
          }}
          step={step}
          disabled={disabled || false}
          onChange={(e) => handleChange(e, index)}
          onWheel={(e) => e.target.blur()}
          onScroll={(e) => e.target.blur()}
        />
      )}
    </div>
  );
};

export default TableReadEditCell;
