import { useAuth } from "../../../context/GlobalContext";
import PdirAdminTable from "./PdirAdminTable";
import PdirSupervisorTable from "./PdirSupervisorTable";

function PdirList() {
  const { state } = useAuth();
  return (
    <div style={{ paddingBottom: "3rem" }}>
      {state.user.userType === 2 ? <PdirAdminTable /> : <PdirSupervisorTable />}
    </div>
  );
}

export default PdirList;
