import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

const style = {
  backgroundColor: "#fafafa",
  padding: "1rem",
  paddingBottom: "1.1rem",
  textAlign: "center",
  margin: 0,
};
const CreateRequestMachine = ({ requestData, handleChangeRequest }) => {
  return (
    <>
      <hr />
      <Wrapper
        style={{
          marginLeft: "0.5rem",
          alignItems: "start",
          justifyContent: "center",
        }}
        column
      >
        <Wrapper style={{ marginTop: "0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MACHINE
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Machine/Tool under preventive maintenance
          </p>
          <Input
            type="text"
            name="mac_change_1"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_change_1}
          />
          <Input
            type="text"
            name="mac_reason_1"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_reason_1}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MACHINE
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Machine/Tool under break down
          </p>
          <Input
            type="text"
            name="mac_change_2"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_change_2}
          />
          <Input
            type="text"
            name="mac_reason_2"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_reason_2}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MACHINE
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            New Tool Issue
          </p>
          <Input
            type="text"
            name="mac_change_3"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_change_3}
          />
          <Input
            type="text"
            name="mac_reason_3"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_reason_3}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MACHINE
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            New machine install / Machine Change
          </p>
          <Input
            type="text"
            name="mac_change_4"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_change_4}
          />
          <Input
            type="text"
            name="mac_reason_4"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_reason_4}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MACHINE
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Poka Yoke Failure
          </p>
          <Input
            type="text"
            name="mac_change_5"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_change_5}
          />
          <Input
            type="text"
            name="mac_reason_5"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_reason_5}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MACHINE
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Power Failure
          </p>
          <Input
            type="text"
            name="mac_change_6"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_change_6}
          />
          <Input
            type="text"
            name="mac_reason_6"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_reason_6}
          />
        </Wrapper>
        <Wrapper style={{ marginTop: "-0.5rem" }}>
          <p
            style={{
              ...style,
              width: "192px",
            }}
          >
            MACHINE
          </p>
          <p
            style={{
              ...style,
              width: "595px",
              marginLeft: "1.2rem",
            }}
          >
            Abnormal Sounds from machine
          </p>
          <Input
            type="text"
            name="mac_change_7"
            style={{ marginLeft: "0.8rem" }}
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_change_7}
          />
          <Input
            type="text"
            name="mac_reason_7"
            onBlur={handleChangeRequest}
            defaultValue={requestData.mac_reason_7}
          />
        </Wrapper>
      </Wrapper>
    </>
  );
};

export default CreateRequestMachine;
