import React, { useState, useEffect } from "react";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import PdfImage from "../../images/pdf.png";
import Loader from "../../components/Control/Loader";

const MaterialTCInputs = ({
  currentId,
  setOpenModal,
  id,
  submitBreakLoad,
  setRender,
}) => {
  const [uploadFiles, setUploadFiles] = useState({
    file: null,
  });
  const [preview, setPreview] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [materialTcData, setMaterialTcData] = useState({});
  const [inputRender, setInputRender] = useState(false);

  useEffect(() => {
    if (!currentId) return;
    axios(`/api/getOneMaterialTc/data/${currentId}`)
      .then((res) => {
        if (res.data) {
          setMaterialTcData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsloading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [isLoading]);

  useEffect(() => {
    if (!uploadFiles.file) return;
    setPreview(URL.createObjectURL(uploadFiles.file));
    return () => {
      URL.revokeObjectURL(uploadFiles.file);
    };
  }, [uploadFiles]);

  const handleImagePdf = (e) => {
    setUploadFiles({
      file: e.target.files[0],
    });
    e.target.value = "";
    setIsloading(true);
  };

  const removeFiles = () => {
    setUploadFiles({
      file: null,
    });
    setPreview(null);
    materialTcData.materialFiles = null;
  };

  const handleDrawingData = (e) => {
    materialTcData[e.target.name] = e.target.value;
    setInputRender((prev) => !prev);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("document_name", materialTcData.document_name);
    formData.append("document_type", materialTcData.document_type);
    formData.append(
      "document_description",
      materialTcData.document_description
    );

    if (materialTcData._id) {
      formData.append(
        "materialFiles",
        uploadFiles.file || materialTcData.materialFiles
      );
      if (submitBreakLoad) {
        await submitBreakLoad().catch((err) => console.error(err));
      }
      axios
        .put(
          `/api/updateMaterialTc/data/${currentId}${id && `?break_id=${id}`}`,
          formData
        )
        .then((res) => {
          if (setRender) {
            setRender((prev) => !prev);
          }
          alert("success");
          setOpenModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      if (submitBreakLoad) {
        await submitBreakLoad(true).catch((err) => console.log(err));
      }
      formData.append("materialFiles", uploadFiles.file);
      axios
        .post(`/api/materialTC/create${id && `?break_id=${id}`}`, formData)
        .then((res) => {
          if (setRender) {
            setRender((prev) => !prev);
          }
          alert("success");
          setOpenModal(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const ShowPdf = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2rem 0 0.5rem 0",
          filter: "drop-shadow(10px 10px 5px #868e96)",
        }}
      >
        <img src={PdfImage} height="250px" width="250px" />
      </div>
    );
  };

  return (
    <div>
      <Wrapper style={{ marginTop: "1rem" }}>
        <div>
          <Input
            label="Document Name"
            name="document_name"
            type="text"
            defaultValue={materialTcData.document_name}
            onChange={handleDrawingData}
            value={materialTcData.document_name}
          />
        </div>
        <div>
          <Input
            label="Document Type"
            name="document_type"
            type="text"
            defaultValue={materialTcData.document_type}
            onChange={handleDrawingData}
            value={materialTcData.document_type}
          />
        </div>
        <div>
          <Input
            label="Document Description"
            name="document_description"
            type="text"
            defaultValue={materialTcData.document_description}
            onChange={handleDrawingData}
            value={materialTcData.document_description}
          />
        </div>
      </Wrapper>

      <Wrapper style={{ justifyContent: "center" }}>
        <div>
          {isLoading ? (
            <Loader height={"20vh"} />
          ) : (
            <>
              {uploadFiles.file &&
                uploadFiles.file?.type === "application/pdf" && <ShowPdf />}
              {preview ? (
                uploadFiles.file.type !== "application/pdf" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "2rem 0 0rem 0",
                    }}
                  >
                    <img
                      src={preview ? preview : ""}
                      alt=""
                      height="auto"
                      width="50%"
                    />
                  </div>
                )
              ) : (
                <>
                  {materialTcData?.materialFiles &&
                  materialTcData.materialFiles.slice(-3) === "pdf" ? (
                    <ShowPdf />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "2rem 0 0rem 0",
                      }}
                    >
                      {materialTcData?.materialFiles &&
                      materialTcData?.materialFiles !== "null" ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={materialTcData?.materialFiles}
                            alt=""
                            height="auto"
                            width="50%"
                          />
                        </div>
                      ) : (
                        <CloudUploadOutlinedIcon
                          style={{ fontSize: "105px", color: "#868e96" }}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1.2rem",
            }}
          >
            <input
              id="contained-button-file"
              type="file"
              name="pcs_imageOrPdf"
              accept="image/png,image/jpeg,image/jpg/,application/pdf"
              onChange={handleImagePdf}
              hidden
            />
            <label
              htmlFor="contained-button-file"
              style={{
                marginBottom: "0",
              }}
            >
              <Button variant="outlined" component="span" color="secondary">
                <CloudUploadIcon style={{ marginRight: "1rem" }} />
                Upload Picture / Pdf
              </Button>
            </label>
            {(uploadFiles.file || materialTcData.materialFiles) && (
              <Button
                variant="outlined"
                type="button"
                color="primary"
                style={{
                  marginLeft: "0.6rem",
                }}
                onClick={removeFiles}
                startIcon={<DeleteIcon />}
              >
                REMOVE
              </Button>
            )}
          </div>
        </div>
      </Wrapper>

      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<SaveIcon />}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default MaterialTCInputs;
