import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../style/controlPlan.css";
import Loader from "../../components/Control/Loader";
import axios from "axios";
import Styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";

import { useQuery } from "../../components/Control/useQuery.js";
import { proxy_img } from "../../constants/constants";
import { Button, Radio } from "@material-ui/core";
import { Print } from "@material-ui/icons";
import moment from "moment";

const Table = Styled.table`
margin-bottom: 1rem;
width:96%;
margin:auto;
border: 1px solid black;
th{
    font-size: 10px;
    font-family:"Roboto",sans-serif;
    border: 1px solid black;
    font-weight:bold;
    padding: 0.2rem;
    p{
        margin:0;
    }
}
td{
    font-size: 10px;
    border: 1px solid black;
    padding: 1px;
    text-align : center;
    font-weight:600;
    p{
        margin:0
    }
}
`;

export default function FullScreenSop() {
  const [sopData, setSopData] = useState(null);
  const { id } = useParams();
  const query = useQuery();
  const partId = query.get("partId");
  useEffect(() => {
    getSopDataHandler();
  }, []);

  const getSopDataHandler = async () => {
    try {
      const response = await axios.get(`/api/getSop/${id}?partId=${partId}`);
      setSopData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {sopData ? (
        <SpanningTable setSopData={setSopData} sopData={sopData} Key={id} />
      ) : (
        <Loader />
      )}
    </>
  );
}

function SpanningTable({ sopData, setSopData }) {
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const [render, setRender] = useState(false);
  const handleChange = (e, index) => {
    const { value, name } = e.target;
    if (index === 0 || index) {
      const values = { ...sopData };
      values.revision_data[index][name] = value;
      setSopData(values);
      return;
    }
    const values = { ...sopData };
    values[name] = value;
    setSopData(values);
  };
  //   function printTable() {
  //     window.print();
  //   }

  useEffect(() => {
    const count = sopData.product_parameter?.reduce(
      (occ, process) => occ + +process.specs.length,
      0,
    );
    sopData.workInstructionRowSpan = count;
    setRender((prev) => !prev);
  }, []);

  const handleLenght = (specs) => {
    //checking specs is array or not
    if (!Array.isArray(specs)) return [];

    if (sopData?.instructions?.length > specs?.length) {
      //if the lenght of inst is more then we are appending bland obj to show all the inst
      let extraCol = sopData?.instructions?.length - specs?.length;
      return [...specs, ...Array(extraCol).fill({})];
    } else {
      return specs;
    }
  };
  return (
    <>
      {sopData && (
        <>
          <div
            className="card_table"
            style={{
              // padding: "0.6rem",
              paddingBottom: "5rem",
              paddingTop: "0.1rem",
            }}
          >
            <Table
              id={"tableDownloadId"}
              cellSpacing="0"
              data-default-wrap="true"
            >
              <thead>
                <tr>
                  <td
                    colSpan={12}
                    align="center"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 600,
                      backgroundColor: "#1d1d1d",
                      color: "white",
                      border: "1px solid black",
                    }}
                  >
                    STANDARD OPERATING PROCEDURE
                  </td>
                  <th colSpan={3} style={{ fontSize: "1rem" }}>
                    SOP NO -{sopData?.sop_no}{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* first row */}
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    ITEM NO:
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    {sopData?.part_id?.part_number}
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={9}
                    style={{ border: "1px solid black", textAlign: "start" }}
                  >
                    DRAWING (SKETCH)
                  </th>
                  <th
                    colSpan={8}
                    rowSpan={9}
                    style={{ border: "1px solid black" }}
                    data-img={proxy_img + sopData?.sop_img}
                  >
                    <img
                      src={proxy_img + sopData?.sop_img}
                      alt="sop image"
                      height="65%"
                      width="65%"
                    />
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={3}
                    style={{ border: "1px solid black" }}
                  >
                    Prototype
                  </th>
                  <th
                    colSpan={2}
                    rowSpan={3}
                    style={{ border: "1px solid black" }}
                  >
                    {isView ? (
                      sopData.part_type === "prototype" && (
                        <span
                          style={{
                            fontSize: "2.5rem",
                            marginRight: "0.6rem",
                          }}
                        >
                          ☑
                        </span>
                      )
                    ) : (
                      <Radio
                        checked={sopData.part_type === "prototype"}
                        onChange={(e) => handleChange(e)}
                        value="prototype"
                        color="default"
                        name="part_type"
                        inputProps={{ "aria-label": "prototype" }}
                      />
                    )}
                  </th>
                </tr>

                {/* 2nd row */}
                <tr>
                  <th
                    colSpan={1}
                    style={{ border: "1px solid black", maxWidth: "40px" }}
                  >
                    ITEM DESCRIPTION:
                  </th>
                  <th
                    colSpan={2}
                    style={{ border: "1px solid black", minWidth: "100px" }}
                  >
                    {sopData?.part_id?.part_name}
                  </th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    OPERATION NAME:
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    {sopData.product_parameter.map(
                      (process, index) =>
                        `${process.process_name} ${
                          sopData.product_parameter.length !== index + 1
                            ? ","
                            : ""
                        }`,
                    )}
                  </th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    OPERATION NO:
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    {sopData.product_parameter.map(
                      (process, index) =>
                        `${process.process_no} ${
                          sopData.product_parameter.length !== index + 1
                            ? ","
                            : ""
                        }`,
                    )}
                  </th>
                  <th colSpan={1} rowSpan={3}>
                    Pre-launch
                  </th>
                  <th
                    colSpan={2}
                    rowSpan={3}
                    style={{ border: "1px solid black" }}
                  >
                    {isView ? (
                      sopData.part_type === "pre-launch" && (
                        <span
                          style={{
                            fontSize: "2.5rem",
                            marginRight: "0.6rem",
                          }}
                        >
                          ☑
                        </span>
                      )
                    ) : (
                      <Radio
                        checked={sopData.part_type === "pre-launch"}
                        onChange={(e) => handleChange(e)}
                        value="pre-launch"
                        color="default"
                        name="part_type"
                        inputProps={{ "aria-label": "part_type" }}
                      />
                    )}
                  </th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    MACHINE NO:
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    {sopData.machine_no}
                  </th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    MACHINE NAME:
                  </th>
                  <th colSpan={3}>{sopData?.machine_name}</th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    RAW MATERIAL:
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    <TableReadEditCell
                      style={{ width: "100%" }}
                      type="text"
                      name="raw_material"
                      value={sopData.raw_material}
                      {...{ isView, handleChange }}
                    />
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={3}
                    style={{ border: "1px solid black" }}
                  >
                    Production
                  </th>
                  <th
                    colSpan={2}
                    rowSpan={3}
                    style={{ border: "1px solid black" }}
                  >
                    {isView ? (
                      sopData.part_type === "production" && (
                        <span
                          style={{
                            fontSize: "2.5rem",
                            marginRight: "0.6rem",
                          }}
                        >
                          ☑
                        </span>
                      )
                    ) : (
                      <Radio
                        checked={sopData.part_type === "production"}
                        onChange={(e) => handleChange(e)}
                        value="production"
                        color="default"
                        name="part_type"
                        inputProps={{ "aria-label": "production" }}
                      />
                    )}
                  </th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    RAW MATERIAL DIAMETER:
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    <TableReadEditCell
                      style={{ width: "100%" }}
                      type="text"
                      name="raw_material_diameter"
                      value={sopData.raw_material_diameter}
                      {...{ isView, handleChange }}
                    />
                  </th>
                </tr>
                <tr>
                  <th colSpan={1} style={{ border: "1px solid black" }}>
                    CUSTOMER OR STANDARD:
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    {sopData.customer_name}
                  </th>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th
                    rowSpan={3}
                    fontWeight="bold"
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    WORK INSTRUCTIONS
                  </th>
                  <th
                    colSpan="14"
                    fontWeight="bold"
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    PROCESS & PRODUCT PARAMETER
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      width: "40px",
                    }}
                  >
                    PROCESS NO.
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      maxWidth: "100px",
                    }}
                  >
                    PROCESS NAME / OPER./DES.
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      maxWidth: "100px",
                    }}
                  >
                    M/C ,DEVICE JIG/FIX.
                  </th>
                  <th
                    colSpan={3}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    CHARACTERISTICS
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                    }}
                  >
                    SPECIAL
                    <br />
                    CHARACTERISTICS
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      minWidth: "100px",
                      border: "1px solid black",
                    }}
                  >
                    PRODUCT/ PROCESS SPEC. TOLERENCES
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      minWidth: "100px",
                    }}
                  >
                    CHECKING METHOD
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      width: "40px",
                    }}
                  >
                    RECORDING
                  </th>
                  <th
                    colSpan={2}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    SAMPLE
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      minWidth: "150px",
                    }}
                  >
                    CONTROL METHOD (FORMAT NO)
                  </th>
                  <th
                    colSpan={1}
                    rowSpan={2}
                    style={{
                      textAlign: "center",
                      width: "30px",
                      border: "1px solid black",
                    }}
                  >
                    RESP.(O/I/S)
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan={1}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    NO.
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      width: "10%",
                    }}
                  >
                    PRODUCT
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      width: "50px",
                    }}
                  >
                    PROCESS
                  </th>
                  <th
                    colSpan={1}
                    style={{ textAlign: "center", border: "1px solid black" }}
                  >
                    YES / NO
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      width: "40px",
                      border: "1px solid black",
                    }}
                  >
                    SIZE (n)
                  </th>
                  <th
                    colSpan={1}
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      minWidth: "100px",
                    }}
                  >
                    FREQUENCY
                  </th>
                </tr>
                {sopData.product_parameter?.map((process, index) => {
                  return handleLenght(process?.specs).map((item, idxSpec) => {
                    return (
                      <React.Fragment key={index + "produc"}>
                        <tr>
                          <td
                            style={{
                              minWidth: "400px",
                              textAlign: "start",
                              padding: "0 3px",
                            }}
                          >
                            {sopData.instructions?.[idxSpec]
                              ? `${idxSpec + 1} ${
                                  sopData.instructions?.[idxSpec]
                                }`
                              : ""}
                          </td>
                          {/* we need to delete this commented code but need to get approval first the delete it */}
                          {/* {index === 0 && idxSpec === 0 && (
                                                        <td
                                                            colSpan={1}
                                                            style={{
                                                                minWidth: "450px",
                                                            }}
                                                            rowSpan={sopData.workInstructionRowSpan}
                                                        >
                                                            {sopData.instructions.map((instruction, iIdx) => (
                                                                <>

                                                                    <div
                                                                        style={{
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            textAlign: "start",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 900,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {iIdx + 1}{" "}
                                                                        </span>
                                                                        -{instruction}
                                                                    </div>
                                                                    <br />
                                                                </>
                                                            ))}
                                                        </td>
                                                    )} */}
                          {idxSpec === 0 && (
                            <>
                              <td
                                colSpan={1}
                                rowSpan={handleLenght(process?.specs).length}
                                style={{
                                  minWidth: "40px",
                                }}
                              >
                                {process?.process_no}
                              </td>
                              <td
                                colSpan={1}
                                rowSpan={handleLenght(process?.specs).length}
                                style={{
                                  minWidth: "95px",
                                }}
                              >
                                {process?.process_name}
                              </td>
                              <td
                                colSpan={1}
                                rowSpan={handleLenght(process?.specs).length}
                              >
                                {sopData?.machine_no}
                              </td>
                            </>
                          )}

                          <td colSpan={1}>
                            {Object.keys(item)?.length > 0 && idxSpec + 1}
                          </td>
                          <td
                            style={{
                              minWidth: "75px",
                            }}
                            colSpan={1}
                          >
                            {item.char_product}
                          </td>
                          <td
                            style={{
                              minWidth: "70px",
                            }}
                            colSpan={1}
                          >
                            {item?.char_process}
                          </td>
                          <td
                            style={{
                              maxWidth: "40px",
                            }}
                            colSpan={1}
                          >
                            {item?.special_character}
                          </td>
                          <td
                            style={{
                              minWidth: "80px",
                            }}
                            colSpan={1}
                          >
                            {item.control_product}
                            {item.control_process && item.control_product
                              ? "/"
                              : ""}
                            {item.control_process}
                            {item.control_product_remark &&
                              `* ${item.control_product_remark}`}
                          </td>
                          <td
                            style={{
                              minWidth: "50px",
                            }}
                          >
                            {item.methods_measure}
                          </td>
                          <td>{item?.recording}</td>
                          <td>{item.methods_size}</td>
                          <td
                            style={{
                              minWidth: "60px",
                            }}
                          >
                            {item.methods_by}
                          </td>
                          <td
                            style={{
                              minWidth: "60px",
                            }}
                          >
                            {item.methods_process}
                          </td>
                          <td
                            style={{
                              minWidth: "60px",
                            }}
                          >
                            {item.responsibility}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  });
                })}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
}
