import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Add, Search } from "@material-ui/icons";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import formatDate from "../../components/Control/formatDate";
import { useAuth } from "../../context/GlobalContext";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

function AddChild({ partId }) {
  const classes = useStyles();
  const [partData, setPartData] = useState([]);
  const globalState = useAuth();

  const [page, setPage] = useState(1);
  const [render, setRender] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();
  const [filter, setFilter] = useState("part_number");

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/get/part/publish?isNotParent=true&isChild=true&partId=${partId}&page=${page}&sortBy=${filter}&search=${search}`,
        {
          headers: { isFullAdmin: globalState.state?.user?.isFullAdmin },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setPartData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, render, filter]);

  const addChild = (partId, childId) => {
    if (window.confirm("Are you sure you want to add this child?")) {
      let body = { part_id: partId, child_id: childId };
      axios
        .post(`/api/create/childToPart/${partId}/${childId}`, body)
        .then((res) => {
          if (res.data.status === "success") {
            alert("child added successfully");
            setRender((prev) => !prev);
          }
        });
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Part Number"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginRight: "1.5rem", marginTop: "0.8rem", flex: "0.2" }}
          >
            <InputLabel>Filter</InputLabel>
            <Select
              name="part_type"
              onChange={(e) => setFilter(e.target.value)}
              defaultValue={filter}
            >
              <MenuItem value="part_number">A - Z</MenuItem>
              <MenuItem value="createdAt">Most Recent First</MenuItem>
              <MenuItem value="createdAt_old">Oldest First</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                #
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Number
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Description
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Category
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {partData && (
            <TableBody>
              {partData.map(
                (item, index) =>
                  partId !== item._id && (
                    <>
                      <React.Fragment key={item._id}>
                        <TableRow>
                          <TableCell align="center">
                            {perPage * (page - 1) + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {item.part_number}
                          </TableCell>
                          <TableCell align="center">{item.part_name}</TableCell>
                          <TableCell align="center">{item.category}</TableCell>
                          <TableCell align="center">
                            {formatDate(item.createdAt)}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              onClick={() => addChild(partId, item._id)}
                            >
                              <Add
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    </>
                  )
              )}
            </TableBody>
          )}
        </Table>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </TableContainer>
    </>
  );
}

export default AddChild;
