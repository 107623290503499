import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

import FmeaProcess from "../../AddEditDuplicatePart/PFMEA/FmeaProcess";
import { Add } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import lodash from "lodash";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import axios from "axios";
import useConfirm from "../../../components/Control/useConfirm";

import "../../AddEditDuplicatePart/PFMEA/fmea.style.css";
import MasterECNModal from "../MasterECNModal";

const CreatePfmea = () => {
  const { annexureType, documentType, id } = useParams();
  const [expandState, setExpandState] = useState([]);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const history = useHistory();
  const [render, setRender] = useState(false);
  const [isECNModal, setIsECNModal] = useState(false);

  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE PART",
    "Revision no. already exists! Are you sure you want to overwrite?",
  );
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?",
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?",
  );
  const [DialogUpdateFmea, confirmUpdateFmea] = useConfirm(
    "UPDATE",
    "Are you sure you want to update?",
  );
  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    "REMOVE",
    "Are you sure you want to remove this process?",
  );
  const [fmeaData, setFmeaData] = useState({
    processes: [],
  });
  const [prevFmeaData, setPrevFmeaData] = useState(null);

  useEffect(() => {
    axios
      .get(`/api/getMasterById/${documentType}/${id}`)
      .then((res) => {
        if (res.data) {
          setFmeaData(res.data);
          setPrevFmeaData(lodash.cloneDeep(res.data));
        }
      })
      .catch((err) => console.error(err));
  }, [useEffectRender]);

  const handleFmeaSubmit = async () => {
    if (fmeaData._id) {
      let isUpdate = false;
      let isPost = false;
      const isRev = await axios
        .get(
          `/api/isMasterRevno/${documentType}/${annexureType}/${fmeaData.revision_no}`,
        )
        .catch((err) => console.log(err));
      if (isRev.data.status === "success") {
        if (id) {
          isUpdate = await confirmUpdate();
        } else {
          isUpdate = true;
        }
      }
      if (isRev.data.status === "fail") {
        isPost = true;
      }
      if (isUpdate) {
        let isSave = await confirmUpdateFmea();
        if (isSave) {
          axios
            .put(
              `/api/updateMasterFormat/${documentType}/${fmeaData._id}`,
              fmeaData,
            )
            .then(() => {
              alert("FMEA changes saved successfully");
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
      if (isPost) {
        setIsECNModal(true);
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        fmeaData.revision_no = 0;
        axios
          .post(`/api/createMasterFormat/${documentType}`, fmeaData)
          .then(() => {
            alert("FMEA submitted successfully");
            setUseEffectRender((prev) => !prev);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const handleSubmitRevision = async (revisionData) => {
    if (lodash.isEmpty(revisionData)) {
      alert("you can't create revision");
      return;
    }
    delete fmeaData._id;
    delete fmeaData.createdAt;
    delete fmeaData.updatedAt;
    delete fmeaData.__v;
    fmeaData.processes.forEach((process) => {
      delete process._id;
      process.fmeas.forEach((fail) => delete fail._id);
    });
    revisionData = Object.keys(revisionData).map((key) => ({
      rev_no: fmeaData.revision_no,
      summary: revisionData[key].value,
      reason: revisionData[key].reason,
      section: revisionData[key].section,
    }));

    fmeaData.revisionData = [...fmeaData.revisionData, ...revisionData];
    axios
      .post(`/api/createMasterFormat/${documentType}`, fmeaData)
      .then(() => {
        alert("FmeaPart submitted successfully");
        history.goBack();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const removeProcess = async (index) => {
    const isDelete = await confirmRemoveProcess();
    if (isDelete) {
      const values = { ...fmeaData };
      values.processes.splice(index, 1);
      setFmeaData(values);
    }
  };

  const handleChangeProcess = (e, index) => {
    fmeaData.processes[index][e.target.name] = e.target.value;
    if (e.target.name === "s_no") {
      fmeaData.processes.sort((a, b) => Number(a.s_no) - Number(b.s_no));
      setRender((prev) => !prev);
    }
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          EDIT PFMEA
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleFmeaSubmit}
              style={{
                backgroundColor: "#003566",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
            >
              Save & Continue
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "red",
                marginRight: "0.5rem",
              }}
              startIcon={<CloseIcon />}
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </main>

      <main style={{ margin: "1rem", paddingBottom: "4rem" }}>
        <Wrapper
          style={{
            margin: "-2rem 1rem 2rem 1rem",
          }}
        >
          <Input
            type="Number"
            name="revision_no"
            placeholder="Revision Number"
            onChange={(e) =>
              setFmeaData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            disabled={!id}
            value={fmeaData.revision_no}
          />
        </Wrapper>
        <div>
          <Wrapper
            justify="space-between"
            style={{ borderBottom: "1px solid #c80202" }}
          >
            <p className="card-btn-para" style={{ color: "#c80202" }}>
              • ADD{" "}
              <span style={{ fontWeight: 400, color: "black" }}>Process</span>
            </p>
            <Wrapper>
              <Wrapper
                style={{
                  height: "45px",
                  width: "175px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  gap: "0.5rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  let values = { ...fmeaData };
                  values.processes.push({
                    s_no: fmeaData.processes.length + 1,
                    fmeas: [],
                  });
                  setFmeaData(values);
                  setExpandState((prev) => {
                    prev[fmeaData.processes.length - 1] = true;
                    return [...prev];
                  });
                }}
                justify="center"
              >
                <Add />
                <span>Add Process</span>
              </Wrapper>
            </Wrapper>
          </Wrapper>

          {fmeaData.processes.map((process, index) => (
            <FmeaProcess
              key={uuidv4()}
              {...{
                removeProcess,
                process,
                index,
                fmeaData,
                handleChangeProcess,
                expandState,
                setExpandState,
              }}
            />
          ))}
        </div>
        <DialogUpdate />
        <DialogSave isSubmitContent={true} />
        <DialogUpdateFmea isSubmitContent={true} />
        <DialogRemoveProcess />
        <DialogClose isSubmitContent={false} />

        {isECNModal && (
          <MasterECNModal
            openPopup={isECNModal}
            closeModal={() => setIsECNModal(false)}
            partData={fmeaData}
            documentType="fmea"
            ecnTitle="ECN - PFMEA CHANGES"
            partDataForRevision={prevFmeaData}
            submit={handleSubmitRevision}
          />
        )}
      </main>
    </>
  );
};

export default CreatePfmea;
