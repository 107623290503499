import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAuth } from "../../../context/GlobalContext";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import { usePermission } from "../../../components/usePermission";
function MasterFmeaList() {
  const { state } = useAuth();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const documentType = "fmea";
  const permissions = usePermission(5);
  useEffect(() => {
    axios
      .get(`/api/getMasterFmeaTableList`)
      .then((data) => setData(data.data))
      .catch((err) => console.log(err.message));
  }, [render]);

  function removeDocument(id) {
    if (window.confirm("Are you sure you want to delete this Part?")) {
      axios
        .delete(`/api/deleteMasterFormat/${documentType}/${id}`)
        .then(() => {
          alert(`${documentType} deleted successfully.`);
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }
  return (
    <div style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        FMEA LIST
      </div>

      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            {/*
            <TableCell style={{ fontWeight: "bold" }} align="center">
               Annexure Type 
            </TableCell>
            */}
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Type
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Revision number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <React.Fragment key={item._id}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                {/*  <TableCell align="center">{item.annexure_type}</TableCell>*/}
                <TableCell align="center">
                  {item.revision_no > 0 ? "Revised" : "Original"}
                </TableCell>
                <TableCell align="center">{item.revision_no}</TableCell>
                <TableCell align="center">
                  <Tooltip title="FMEA" arrow>
                    <Link to={`/viewFmeaFormat/${documentType}/${item._id}`}>
                      <IconButton size="small">
                        <VisibilityIcon
                          style={{ fontSize: "1.4rem", color: "maroon" }}
                        />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  {permissions?.includes("edit") && (
                    <Tooltip title="Edit" arrow>
                      <Link
                        to={`/createFmeaFormat/${documentType}/${item.annexure_type}/${item._id}`}
                      >
                        <IconButton
                          size="small"
                          style={{
                            marginLeft: "0.6rem",
                            color: "#161a1d",
                            fontSize: "1.4rem",
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                  {permissions?.includes("delete") && (
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        style={{ marginLeft: "0.5rem" }}
                        onClick={() => removeDocument(item._id)}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",

                            color: "red",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default MasterFmeaList;
